import { QueryLazyOptions, useLazyQuery  , gql} from "@apollo/client";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Button, Chip, IconButton, Popover, TextField, Typography, useAutocomplete } from "@mui/material";
import { ColorPicker } from "../../../components/ColorPicker";
import { companySearch, companyUUIDQuery } from "../../../graphql/queries";
import { debounce } from "lodash";
import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { CompanyData, CompanySearchResult, CompanySearchVar, EditableDataRow, InNetworkLeads, SourcePerson } from "../../../types";
import { truncateString } from "../../../views/EditableView/utils";
import CompanyCard from "../CompanyCard/CompanyCard";
import { Column } from "../fakeData";

interface ColProps extends Column {
  index: number;
  addCompany: (colId: string, data: CompanyData) => void;
  width?: number;
  innerNetworkData: InNetworkLeads[];
  columns: Column[];
  handleStageChange?: (item: EditableDataRow, destination: Column) => void;
  sourcePersonsData?: SourcePerson[];
  updateCard?: (
    options?:
      | QueryLazyOptions<{
          userid?: String;
          method: String;
          columns?: JSON;
          companylist?: String[];
          companyId?: String;
          value?: string;
          action?: string;
        }>
      | undefined
  ) => void;
  updateResponse: {
    EditableTable: {
      value: [{ id: Number; name: String }];
    };
  };
}

const ColumnComponent = ({ id, label, items, index, color, addCompany, innerNetworkData, columns, handleStageChange, updateCard, updateResponse, sourcePersonsData }: ColProps) => {
  const [addNewRefEl, setAddNewRefEl] = React.useState<HTMLButtonElement | null>(null);
  const [addNewPopperEl, setAddNewPopperEl] = React.useState<HTMLDivElement | null>(null);
  const [showAddNewModal, setShowAddNewModal] = React.useState(false);
  const [popperArrowEl, setPopperArrowEl] = React.useState<HTMLDivElement | null>(null);
  const [contextEl, setContextAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openContextMenu = Boolean(contextEl);
  const [colAttributes, setColAttributes] = React.useState<{ label: string; color: string }>({ label, color: color ?? "#ffffff" });

  const [findCompany, { data: findCompData }] = useLazyQuery<{ CompanyUUIDQuery: CompanyData }, { CompanyUUID: string }>(gql(companyUUIDQuery));

  //useEffect for updating state for colors
  React.useEffect(() => {
    setColAttributes({
      label,
      color: color ?? "#ffffff"
    });
  }, [color, label]);

  // Add when get Data;
  React.useEffect(() => {
    if (findCompData?.CompanyUUIDQuery) {
      const data = findCompData.CompanyUUIDQuery;
      if (addCompany) addCompany(id, data);
    }
    //eslint-disable-next-line
  }, [findCompData]);

  //Popper handler for the the search box.
  const { styles, attributes } = usePopper(addNewRefEl, addNewPopperEl, {
    placement: "auto",
    strategy: "fixed",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: popperArrowEl,
          padding: 6
        }
      }
    ]
  });

  /***
   *@description  Function for handling context click on the menu label on the column header.
   */
  const handleColumnContextClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setContextAnchorEl(event.currentTarget);
  };

  /***
   *@description  Context Menu close handler function
   */

  const handleContextMenuClose = () => {
    if (updateCard) {
      updateCard({
        variables: {
          method: "updateColumns",
          value: JSON.stringify({ id, ...colAttributes })
        }
      });
    }
    setContextAnchorEl(null);
  };

  // console.log("innerNetworkData::" , innerNetworkData)

  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <ColumnContainer ref={provided.innerRef} {...provided.draggableProps}>
          <div className="col-header-container" style={{ backgroundColor: colAttributes.color }}>
            <Button variant="outlined" size="small" onContextMenu={handleColumnContextClick} title={label}>
              {truncateString(label, 15) ?? "Column Header"}
            </Button>
            <div className="col-utilities">
              <Chip label={items ? items.length.toString() : 0} size="small" />
              <IconButton
                size="small"
                title="Add new"
                ref={setAddNewRefEl}
                onClick={e => {
                  setShowAddNewModal(true);
                }}
              >
                <PlaylistAddIcon />
              </IconButton>
              <IconButton {...provided.dragHandleProps} size="small" title="Move the position">
                <DragIndicatorIcon />
              </IconButton>
            </div>
          </div>
          <Droppable droppableId={id}>
            {providedDrop => (
              <div className="list" {...providedDrop.droppableProps} ref={providedDrop.innerRef}>
                {items.map((item, index) => (
                  <CompanyCard index={index} key={item.websitefinal} columnTitle={label} innerNetworkData={innerNetworkData} columns={columns} handleStageChange={handleStageChange} updateCard={updateCard} {...item} updateResponse={updateResponse} sourcePersonsData={sourcePersonsData} />
                ))}
                {providedDrop.placeholder}
              </div>
            )}
          </Droppable>
          {showAddNewModal && <div className="overlay" onClick={e => setShowAddNewModal(false)} />}
          {showAddNewModal && (
            <AddNewPopperContainer {...attributes.popper} ref={setAddNewPopperEl} style={{ ...styles.popper, zIndex: 1201 }} className="popperContainerWithArrow">
              <div className="arrow" ref={setPopperArrowEl} style={{ ...styles.arrow }} {...attributes.arrow} />
              <AddNewFormComponent closeModal={() => setShowAddNewModal(false)} addCompany={addCompany} colId={id} findCompany={findCompany} />
            </AddNewPopperContainer>
          )}
          <Popover
            id={openContextMenu ? "ContextMenu" : ""}
            open={openContextMenu}
            anchorEl={contextEl}
            onClose={handleContextMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            <div style={{ padding: "10px" }}>
              <TextField label={"Funnel Stage Name"} value={colAttributes.label} onChange={event => setColAttributes(d => ({ ...d, label: event.target.value }))} name="label" variant="outlined" disabled={true} />
            </div>
            <div style={{ padding: "10px" }}>
              <ColorPicker value={colAttributes.color} onChange={event => setColAttributes(d => ({ ...d, color: event.target.value }))} name="color" />
            </div>
          </Popover>
        </ColumnContainer>
      )}
    </Draggable>
  );
};
interface AddNewCompProps {
  closeModal?: () => void;
  addCompany: any;
  colId: string;
  findCompany: any;
}
const AddNewFormComponent = ({ closeModal, findCompany }: AddNewCompProps) => {
  const [getCompanies, searchResponse] = useLazyQuery<CompanySearchResult, CompanySearchVar>(gql(companySearch));
  const [options, setOptions] = React.useState<readonly CompanyData[]>([]);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    if (!searchResponse.loading) {
      setOptions(searchResponse.data?.CompanySearch.company ?? []);
    }
    //eslint-disable-next-line
    return () => {
      setOptions([]);
    };
  }, [searchResponse.data, searchResponse.loading]);

  const handleCompanySearch = (queryText: string, pageNum = 1) => {
    getCompanies({ variables: { NameContains: queryText, Offset: (pageNum - 1) * 25 } });
  };
  //eslint-disable-next-line
  const debouncedQuery = React.useCallback(
    debounce((queryText: string) => handleCompanySearch(queryText), 600),
    [options]
  );

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: "searchCompanies-for-adding-in-filter-view",
    options: options,
    getOptionLabel: option => option.namefinal,
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
      debouncedQuery(newInputValue);
    },
    onChange: (event, value) => {
      findCompany({
        variables: {
          CompanyUUID: value?.uuid
        }
      });
      // addCompany(colId, value);
      if (closeModal) closeModal();
    }
  });
  return (
    <div className="AddNewAutoCompleteFrom">
      <div {...getRootProps()} className="inputBox">
        {/* <Label {...getInputLabelProps()}>Search records</Label> */}
        <Input {...getInputProps()} value={inputValue} onBlur={e => setInputValue("")} placeholder="Enter here for searching records..." />
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof options).map((option, index) => (
            <li {...getOptionProps({ option, index })} key={option.uuid}>
              <div className="autocomplete-list-item">
                <img
                  className={"logo-img"}
                  alt={option.websitefinal}
                  src={"//logo.clearbit.com/" + option.websitefinal}
                  onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                    e.currentTarget.src = "/images/default-corporate-image.jpg"; // some replacement image
                    //e.currentTarget.style = 'padding-top: 25px; padding-bottom: 25px;' // inline styles in html format
                  }}
                />
                <Typography variant="h5" color="text.secondary">
                  {option.namefinal}
                </Typography>
              </div>
            </li>
          ))}
        </Listbox>
      ) : null}
    </div>
  );
};

const AddNewPopperContainer = styled.div`
  display: flex;
  flex-flow: column;
  background-color: rgb(255, 255, 255, 0.3);
  width: 300px;
  .AddNewAutoCompleteFrom {
    .inputBox {
      background-color: #fff;
      padding: 4px;
      border-radius: 5px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.24);
      input {
        padding: 10px;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
        width: 100%;
        font-size: 1rem;
        &::placeholder {
          font-size: 13px;
        }
      }
    }
    .logo-img {
      width: 30px;
      height: 30px;
      border-radius: 3px;
      object-fit: cover;
      object-position: center;
      margin-right: 12px;
    }
    .autocomplete-list-item {
      display: inline-flex;
      align-items: center;
      width: 100%;
    }
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  border: 1px solid #ececec;
  border-radius: 5px;
  background-color: hsl(210, 22%, 93%);
  max-width: 22%;
  width: 300px;
  height: 100%;
  /* overflow-y: auto; */
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 5px;
  }
  .col-header-container {
    display: flex;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    align-items: center;
    height: 40px;
    padding: 3px 6px;
  }
  .col-utilities {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .list {
    padding: 0 5px;
    height: calc(100% - 40px);
    overflow: auto;
    padding-bottom: 40px;
    position: relative;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(250px);
  }
  .list-item {
    margin-top: 10px;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    position: relative;
    &:first-child {
      margin-top: 10px;
    }
    &:hover {
      .utilities {
        visibility: visible;
      }
    }
  }
`;

const Input = styled("input")(({ theme }) => ({
  width: 200,
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  color: theme.palette.mode === "light" ? "#000" : "#fff"
}));

const Listbox = styled("ul")(({ theme }) => ({
  width: 300,
  marginTop: 3,
  padding: "7px 3px",
  borderRadius: 5,
  boxShadow: "0 3px 10px rgba(0,0,0,0.24)",
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  overflow: "auto",
  maxHeight: 250,
  border: "1px solid rgba(0,0,0,.25)",
  "& li": {
    borderRadius: 3,
    margin: "3px 0",
    padding: 3,
    display: "flex",
    width: "100%"
  },
  "& li.Mui-focused": {
    backgroundColor: "#e4e4e4",
    color: "white",
    cursor: "pointer"
  },
  "& li:active": {
    backgroundColor: "#e4e4e4",
    color: "white"
  }
}));

export default ColumnComponent;
