import { useQuery , gql } from "@apollo/client";
import { Skeleton } from "@mui/lab";
import { Button, Chip, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, SelectChangeEvent, Switch, Typography, styled } from "@mui/material";
import ShowMore from "../../../../components/ShowMore";

import { allEmployeeQuery } from "../../../../graphql/queries";
import { useState } from "react";
import { CompanyData, EmployeeShort, roles } from "../../../../types";
import EmployeeCard from "../EmployeeCard";
import React from "react"

const PREFIX = "EmployeeOverview";
const classes = {
  root: `${PREFIX}-root`,
  chipStyle: `${PREFIX}-chipStyle`,
  cardHolder: `${PREFIX}-cardHolder`,
  skeletonClass: `${PREFIX}-skeletonClass`,
  nothingFoundText: `${PREFIX}-nothingFoundText`,
  prevWrapper: `${PREFIX}-prevWrapper`,
  nextWrapper: `${PREFIX}-nextWrapper`,
  button: `${PREFIX}-button`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%"
  },
  [`& .${classes.chipStyle}`]: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: "5px",
    marginBottom: "5px"
  },
  [`& .${classes.cardHolder}`]: {
    minWidth: "350px"
  },
  [`& .${classes.skeletonClass}`]: {
    marginTop: "16px"
  },
  [`& .${classes.nothingFoundText}`]: {
    width: "100%",
    textAlign: "center",
    padding: "20px"
  },
  [`& .${classes.prevWrapper}`]: {
    textAlign: "left"
  },
  [`& .${classes.nextWrapper}`]: {
    textAlign: "right"
  },
  [`& .${classes.button}`]: {
    color: theme.palette.info.main
  }
}));

const structureSeniority = (seniority: number): roles => {
  if (seniority === 10 || seniority === 8) {
    return "Founders & C-Suite";
  } else if (seniority === 9) {
    return "Investors & Advisors";
  } else if (seniority === 7 || seniority === 6) {
    return "VP & Director";
  } else if (seniority === 5) {
    return "Managers";
  } else {
    return "Employees";
  }
};

interface EmployeeOverviewProps {
  data: CompanyData;
  seniority: string;
  currentEmployees: number;
  offsetEmployee: number;
  handleChange: Function;
}

function EmployeeOverview(props: EmployeeOverviewProps) {
  const { data, seniority, offsetEmployee, currentEmployees, handleChange } = props;
  const [selected, setSelected] = useState("All");

  //eslint-disable-next-line
  const { error: employeeError, loading: employeeLoading, data: employeeData } = useQuery<{ AllEmployeeQuery: EmployeeShort[] }>(gql(allEmployeeQuery), {
    variables: {
      CompanyUUID: data.liintuuid,
      Seniority: selected,
      Offset: offsetEmployee,
      onlyCurrent: currentEmployees === 0
    }
  });

  const handleInputChange = (value: string | boolean | number, type: "seniorityType" | "currentEmployees" | "NextButton" | "PrevButton") => {
    if (type === "seniorityType") {
      handleChange({ seniority: value, currentEmployees: currentEmployees, offsetEmployee: offsetEmployee });
    } else if (type === "currentEmployees") {
      var intValue = value ? 1 : 0;
      handleChange({ currentEmployees: intValue, seniority: seniority, offsetEmployee: offsetEmployee });
    } else if (type === "NextButton") {
      handleChange({ currentEmployees: currentEmployees, seniority: seniority, offsetEmployee: value });
    } else if (type === "PrevButton") {
      handleChange({ currentEmployees: currentEmployees, seniority: seniority, offsetEmployee: value });
    }
  };

  const createCard = <T extends EmployeeShort>(person: T) => {
    const start = Date.now();
    var values = parseInt(person.startepoch) > start / 1000 - 24 * 60 * 60 * 30 * 4 ? (currentEmployees === 0 ? ["Recent Hire"] : ["Recent Departure"]) : [];
    values.push(structureSeniority(person.seniority));

    var employeeTags = (
      <ShowMore
        isInline={true}
        data={values.map(value => {
          return <Chip label={value} className={classes.chipStyle} />;
        })}
        trimIndex={4}
      ></ShowMore>
    );
    return (
      <Grid item xs={6} md={4} className={classes.cardHolder}>
        <EmployeeCard employeeName={person.personname} employeeTitle={person.companyrole} employeeStartDate={person.startdate} employeeEndDate={person.enddate} employeeTags={employeeTags} employeeImage={person.profilepicture} employeeID={person.employeeuuid}></EmployeeCard>
      </Grid>
    );
  };

  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Typography gutterBottom variant="h4" component="h2">
            Team Members:
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl>
            <Select
              value={selected}
              onChange={(event: SelectChangeEvent) => {
                setSelected(event.target.value);
                handleInputChange(event.target.value, "seniorityType");
              }}
              name="seniorityType"
            >
              <MenuItem value={"All"}>{"Full Team"}</MenuItem>
              <MenuItem value={"Founder"}>{"Founders & C-Suite"}</MenuItem>
              <MenuItem value={"VP"}>{"VP & Director"}</MenuItem>
              <MenuItem value={"Management"}>{"Managers"}</MenuItem>
              <MenuItem value={"Employee"}>{"Employees"}</MenuItem>
              <MenuItem value={"Investor"}>{"Investors & Advisors"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <FormGroup row>
            <FormControlLabel control={<Switch checked={Boolean(currentEmployees)} onChange={event => handleInputChange(event.target.checked, "currentEmployees")} id="currentEmployees" />} label="Show Former Employees" />
          </FormGroup>
        </Grid>
        {!employeeLoading ? (
          employeeData && employeeData.AllEmployeeQuery.length > 0 ? (
            employeeData.AllEmployeeQuery.slice(0, 24).map(teammember => {
              return createCard(teammember);
            })
          ) : (
            <Typography variant="h4" className={classes.nothingFoundText}>
              {"No Employees Found"}
            </Typography>
          )
        ) : (
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width={"100%"} height={133} className={classes.skeletonClass} />
            <Skeleton variant="rectangular" width={"100%"} height={133} className={classes.skeletonClass} />
            <Skeleton variant="rectangular" width={"100%"} height={133} className={classes.skeletonClass} />
          </Grid>
        )}

        <Grid item xs={12}></Grid>

        {employeeData && employeeData.AllEmployeeQuery.length > 0 && (
          <Grid item xs={6} className={classes.prevWrapper}>
            <Button variant="outlined" color="inherit" className={classes.button} disabled={offsetEmployee === 0} name={"PrevButton"} onClick={e => handleInputChange(offsetEmployee - 25, "PrevButton")}>
              Previous
            </Button>
          </Grid>
        )}

        {employeeData && employeeData.AllEmployeeQuery.length > 0 && (
          <Grid item xs={6} className={classes.nextWrapper}>
            <Button variant="outlined" color="inherit" className={classes.button} disabled={employeeData.AllEmployeeQuery.length < 24} name={"NextButton"} onClick={e => handleInputChange(offsetEmployee + 25, "NextButton")}>
              Next
            </Button>
          </Grid>
        )}
      </Grid>
    </Root>
  );
}

export default EmployeeOverview;
