import React from "react";
import { Grid, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import { ChangePassword, AccountDetails } from "./components";

const wlEmailsData = process.env.REACT_APP_ADMIN_EMAILS;
const wlEmailsArray = wlEmailsData?.trim().split(" ");


const Settings = () => {
  const [currentUserEmail, setCurrentUserEmail] = React.useState<string | null>(null);


  React.useEffect(() => {
    (async () => {
      const data = await Auth.currentUserInfo();
      setCurrentUserEmail(data.attributes.email);
    })();
  }, []);

  return (
    <Box sx={{p:4}}>
      <Grid container spacing={4}>
        <Grid item lg={8} md={8} xl={8} xs={12}>
          <AccountDetails />
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          {currentUserEmail && wlEmailsArray?.includes(currentUserEmail) ? (
            <Button component={Link} to={"admin-operation"} size="large">
              Admin Operation
            </Button>
          ) : null}
        </Grid>
        <Grid item lg={6} md={6} xl={6} xs={12}>
          <ChangePassword></ChangePassword>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
