import { Grid, Typography, styled } from "@mui/material";
import React from "react";

const PREFIX = "InvestmentForms";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(4)
  },
  [`&.${classes.content}`]: {
    paddingTop: 150,
    textAlign: "center"
  }
}));

const ErrorPage = () => {
  return (
    <Root className={classes.root}>
      <Grid
        container
        spacing={4}
        sx={{
          justifyContent: "center"
        }}
      >
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">Oops! Something went wrong.</Typography>
          </div>
        </Grid>
      </Grid>
    </Root>
  );
};

export default ErrorPage;
