import { Box, Link } from "@mui/material";
import ShowMore from "../../../../components/ShowMore";
import { EmployeeData } from "../../../../types";
import ExpandingSection from "../ExpandingSection";
import ExperienceCard from "../ExperienceCard";
import React from 'react'

interface PropType {
  className?: string;
  data: EmployeeData;
}

const Publications = (props: PropType) => {
  const { data } = props;

  const publicationData = (
    <ShowMore
      data={data["publications"].map(publication => {
        return (
          <ExperienceCard
            data={{
              header:
                publication.publicationlink && publication.publicationlink !== "-" ? (
                  <Link color="inherit" href={publication.publicationlink}>
                    {publication.title}
                  </Link>
                ) : (
                  publication.title
                ),
              subHeader: publication.publisher,
              subTitle1: publication.publicationdate,
              description: publication.description
            }}
          />
        );
      })}
      trimIndex={3}
    />
  );
  return (
    <Box sx={{ width: "100%" }}>
      <ExpandingSection title="Publications" itemIndex={"title"} data={data["publications"]} fullBody={publicationData} />
    </Box>
  );
};

export default Publications;
