import { Box, Grid, Link, useTheme } from "@mui/material";
import React, { useState } from "react";

interface PropTypes {
  data: React.ReactNode[];
  trimIndex: number;
  isInline?: boolean;
}

function ShowMore(props: PropTypes) {
  const { data, trimIndex, isInline } = props;
  const theme = useTheme();
  const [shown, setShown] = useState(false);
  const shortData = data.slice(0, trimIndex);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setShown(!shown);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        {!isInline
          ? [
              shown
                ? data.map(item => {
                    return (
                      <Grid item xs={12}>
                        {item}
                      </Grid>
                    );
                  })
                : shortData.map(item => {
                    return (
                      <Grid item xs={12}>
                        {item}
                      </Grid>
                    );
                  })
            ]
          : [
              shown ? (
                <Grid item xs={12}>
                  {data.map(item => {
                    return item;
                  })}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  {shortData.map(item => {
                    return item;
                  })}
                </Grid>
              )
            ]}

        <Grid item xs={12}>
          {data.length > trimIndex && (
            <Link href="#" sx={{ fontSize: theme.typography.pxToRem(15), color: theme.palette.info.main }} onClick={handleClick} variant="body2">
              {shown ? "Show Less" : "Show More"}
            </Link>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ShowMore;
