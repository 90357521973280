import { QueryLazyOptions, gql, useLazyQuery } from "@apollo/client";
import { ArrowDropDown, ArrowDropUp, GraphicEqSharp } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import WebIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import SubjectIcon from "@mui/icons-material/Subject";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { Badge, Card, Chip, Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import { debounce, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import Moment from "react-moment";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { searchInvestor } from "../../../graphql/queries";
import { EditableDataRow, InNetworkLeads, Investors, SourcePerson } from "../../../types";
import { truncateString } from "../../../views/EditableView/utils";
import { Column } from "../fakeData";
import AutoCompleteInputFromHook from "./AutoCompleteInput";
import { NotesEditor, PreviewNotes } from "./Notes";

interface CompanyCardProps extends EditableDataRow {
  index: number;
  columnTitle: string;
  innerNetworkData: InNetworkLeads[];
  columns: Column[];
  handleStageChange?: (item: EditableDataRow, destination: Column) => void;
  updateCard?: (
    options?:
      | QueryLazyOptions<{
          userid?: String;
          method: String;
          columns?: JSON;
          companylist?: String[];
          companyId?: String;
          value?: string;
          action?: string;
        }>
      | undefined
  ) => void;
  sourcePersonsData?: SourcePerson[];
  updateResponse: any;
}

interface CompanyHoverCardProps {
  name: string;
  website: string;
  description: string;
  uuid: string;
}

const CompanyCard = ({ index, columnTitle, innerNetworkData, columns, handleStageChange, updateCard, updateResponse, sourcePersonsData, ...companyData }: CompanyCardProps) => {
  const { namefinal, websitefinal, finaldescription, date_added, funnel_stages, uuid, source_firm, point_person, notes, source_person } = companyData;
  //!! Stats for the company details card popover
  const [isTitleHovered, setIsTitleIsHovered] = React.useState(false);
  const [personOptions, setPersonOptions] = React.useState<SourcePerson[] | []>([]);
  const [addNewNotes, setAddNewNotes] = React.useState<boolean>(false);
  const [referenceElement, setReferenceEl] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperEl] = React.useState<HTMLDivElement | null>(null);
  const [popperArrowEl, setPopperArrowEl] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "auto",
    strategy: "fixed",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: popperArrowEl,
          padding: 6
        }
      }
    ]
  });
  // States for the more option details popover.
  const [isMoreOptionOpen, setMoreOptionOpen] = React.useState(false);
  const [moreOptPopperEl, setMoreOptPopperEl] = React.useState<HTMLDivElement | null>(null);
  const [moreOptRefEl, setMoreOptRefEl] = React.useState<HTMLButtonElement | null>(null);
  const [moreOptArwEl, setMoreOptArwEl] = React.useState<HTMLDivElement | null>(null);
  const [moreOptsEl, setMoreOptEl] = React.useState<null | HTMLElement>(null);
  const moreOptOpen = Boolean(moreOptsEl);
  const { styles: moreOptStyles, attributes: moreOptAttributes } = usePopper(moreOptRefEl, moreOptPopperEl, {
    placement: "auto",
    strategy: "fixed",
    modifiers: [
      {
        name: "arrow",
        options: {
          element: moreOptArwEl,
          padding: 6
        }
      }
    ]
  });
  //States for comments options
  const [commentsPopperEl, setCommentsPopperEl] = React.useState<null | HTMLElement>(null);
  const commentsOpen = Boolean(commentsPopperEl);

  // States For Handling Investors Search
  const [investorOptions, setInvestorsOptions] = React.useState<Investors[] | []>([]);
  const [searchInvestors, { data: investorsSearchResult, loading: investorsLoading }] = useLazyQuery<{ searchInvestor: { investor: Investors[] } }>(gql(searchInvestor));

  /**
   * @description Modal Popup closing functions.
   **/
  const handleCloseStagesList = (item: EditableDataRow, destination: Column) => {
    if (handleStageChange) handleStageChange(item, destination);
    setMoreOptEl(null);
    setMoreOptionOpen(false);
  };

  const handleCommentPopperClose = () => {
    setCommentsPopperEl(null);
    setAddNewNotes(false);
  };

  // debouncing for searchTerm
  const handleInvestorsSearch = (queryText: string) => {
    searchInvestors({ variables: { search_term: queryText } });
  };
  //eslint-disable-next-line
  const debouncedQuery = React.useCallback(
    debounce((queryText: string) => handleInvestorsSearch(queryText), 1000),
    [investorOptions]
  );

  // debouncing for sourcePerson
  const handlePersonSearch = (queryText: string) => {
    if (sourcePersonsData) {
      const filteredData = sourcePersonsData.filter(d => d.name.search(queryText) >= 0);
      setPersonOptions([...(filteredData.length > 0 ? filteredData : sourcePersonsData)]);
    }
  };
  //eslint-disable-next-line
  const debouncedPersonQuery = React.useCallback(
    debounce((queryText: string) => {
      handlePersonSearch(queryText);
    }, 1000),
    [updateCard]
  );
  useEffect(() => {
    if (updateResponse?.EditableTable?.value) {
      let options = JSON.parse(updateResponse.EditableTable.value);
      if (personOptions.length === 0) {
        setPersonOptions(options);
      }
    }
  //eslint-disable-next-line
  }, [updateResponse]);
  // When we have search text reasult;
  React.useEffect(() => {
    if (investorsSearchResult?.searchInvestor) {
      const data = investorsSearchResult.searchInvestor.investor;
      setInvestorsOptions(data);
    }
  }, [investorsSearchResult]);

  /***/
  const updatePost = async (data: Investors[]) => {
    if (updateCard)
      updateCard({
        variables: {
          method: "PATCH",
          companyId: uuid,
          value: JSON.stringify({ ...companyData, source_firm: data })
        }
      });
  };
  return (
    <Draggable draggableId={websitefinal} index={index}>
      {(provided, snapshot) => (
        <div className="list-item" {...provided.draggableProps} ref={provided.innerRef}>
          <CompanyCardContainer className={snapshot.isDragging ? "dragging" : ""}>
            <div className="card-header">
              <img
                className={"logo"}
                alt={websitefinal}
                src={"//logo.clearbit.com/" + websitefinal}
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = "/images/default-corporate-image.jpg"; // some replacement image
                  //e.currentTarget.style = 'padding-top: 25px; padding-bottom: 25px;' // inline styles in html format
                }}
              />
              <div ref={setReferenceEl} className="title" onMouseEnter={e => setIsTitleIsHovered(true)} onMouseLeave={e => setIsTitleIsHovered(false)}>
                <Typography variant="subtitle1" component={Link} href={"/companies/" + uuid}>
                  {truncateString(namefinal, 15)}
                </Typography>
                {isTitleHovered && (
                  <HoverCardContainer {...attributes.popper} style={{ ...styles.popper, zIndex: 1201 }} className="popperContainerWithArrow" ref={setPopperEl}>
                    <div className="arrow" {...attributes.arrow} ref={setPopperArrowEl} style={{ ...styles.arrow }} />
                    <CompanyHoverCardDetails name={namefinal} website={websitefinal} description={finaldescription} uuid={uuid} />
                  </HoverCardContainer>
                )}
              </div>
              <div className="dragHandler" {...provided.dragHandleProps} />
              <div className={"show"}>
                <IconButton size="small" title="Comments" onClick={event => setCommentsPopperEl(event.currentTarget)}>
                  <Badge badgeContent={notes.length} color="secondary">
                    <TextSnippetIcon fontSize="inherit" />
                  </Badge>
                </IconButton>
                <IconButton
                  size="small"
                  title="More options"
                  ref={setMoreOptRefEl}
                  onClick={e => {
                    setMoreOptionOpen(true);
                  }}
                >
                  <MoreVertIcon fontSize="inherit" />
                </IconButton>
              </div>
            </div>
            <div className="main-content">
              <div className="main-content-item">
                <AutoCompleteInputFromHook
                  label="Point (Person)"
                  defaultValue={point_person}
                  options={innerNetworkData}
                  onChange={(event, value, reason, details) => {
                    if (updateCard)
                      updateCard({
                        variables: {
                          method: "PATCH",
                          companyId: uuid,
                          value: JSON.stringify({ ...companyData, point_person: value })
                        }
                      });
                  }}
                  getOptionLabel={Option => Option.investor}
                  renderTag={(option, index, getTagProps) => <Chip label={option.investor} variant="outlined" size="small" sx={{ marginRight: "3px", marginBottom: "3px" }} {...getTagProps({ index })} />}
                  renderDisplayListItem={(option, index) => (
                    <>
                      <span>{option.investor}</span>
                      <CheckIcon fontSize="small" />
                    </>
                  )}
                />
              </div>
              <Divider />
              <div className="main-content-item">
                <AutoCompleteInputFromHook
                  loading={investorsLoading}
                  label="Source (Firm)"
                  defaultValue={source_firm}
                  onInputChange={(event, newInputValue) => {
                    newInputValue.length > 0 && debouncedQuery(newInputValue);
                  }}
                  options={investorOptions}
                  getOptionLabel={Option => Option.investor}
                  onChange={(event, value, reason, details) => updatePost([...value])}
                  renderTag={(option, index, getTagProps) => <Chip label={option.investor} variant="outlined" size="small" sx={{ marginRight: "3px", marginBottom: "3px" }} {...getTagProps({ index })} />}
                  renderDisplayListItem={(option, index, selected) => (
                    <>
                      <span>{option.investor}</span>
                      <CheckIcon style={{ display: "inline-block" }} />
                    </>
                  )}
                />
              </div>
              <Divider />
              <div className="main-content-item">
                <AutoCompleteInputFromHook
                  label="Source Person"
                  defaultValue={source_person}
                  options={personOptions}
                  onInputChange={(event, newInputValue) => {
                    newInputValue.length > 0 && debouncedPersonQuery(newInputValue);
                  }}
                  onChange={(event, value, reason, details) => {
                    if (updateCard)
                      updateCard({
                        variables: {
                          method: "PATCH",
                          companyId: uuid,
                          //value: JSON.stringify({ ...companyData, source_person: [] })
                          value: JSON.stringify({ ...companyData, source_person: value })
                        }
                      });
                  }}
                  getOptionLabel={(Option: { id: string; name: string }) => Option?.name}
                  renderTag={(option, index, getTagProps) => <Chip label={option?.name} variant="outlined" size="small" sx={{ marginRight: "3px", marginBottom: "3px" }} {...getTagProps({ index })} />}
                  renderDisplayListItem={(option, index) => (
                    <>
                      <span>{option?.name}</span>
                      <CheckIcon fontSize="small" />
                    </>
                  )}
                />
              </div>
            </div>
            <div className="footer">
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <RunningWithErrorsIcon fontSize="inherit" />{" "}
                <Moment interval={60000} fromNow ago>
                  {funnel_stages.updatedAt ?? date_added}
                </Moment>{" "}
                &nbsp; in {columnTitle}
              </Typography>
            </div>

            {/* Popover for the companycard more option */}
            {isMoreOptionOpen && (
              <div
                className="overlay"
                onClick={e => {
                  setMoreOptionOpen(false);
                  setMoreOptEl(null);
                }}
              />
            )}
            {isMoreOptionOpen ? (
              <MoreOptionPopupContainer ref={setMoreOptPopperEl} {...moreOptAttributes.popper} style={{ ...moreOptStyles.popper, zIndex: 1201 }} className="popperContainerWithArrow">
                <div className="arrow" ref={setMoreOptArwEl} style={{ ...moreOptStyles.arrow }} {...moreOptAttributes.arrow} />
                <MenuList>
                  <MenuItem onClick={e => setMoreOptEl(e.currentTarget)}>
                    <ListItemIcon>
                      <GraphicEqSharp />
                    </ListItemIcon>
                    <ListItemText>Move Stages</ListItemText>
                    {moreOptOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <VisibilityOffRoundedIcon />
                    </ListItemIcon>
                    <ListItemText color="primary">Hide Item</ListItemText>
                  </MenuItem>
                </MenuList>
                <Menu
                  id="Move-stages-select-menu"
                  anchorEl={moreOptsEl}
                  open={moreOptOpen}
                  onClose={() => {
                    setMoreOptEl(null);
                    setMoreOptionOpen(false);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button"
                  }}
                >
                  {columns.map(item => (
                    <MenuItem onClick={() => handleCloseStagesList(companyData, item)} key={item.id}>
                      <ListItemIcon>
                        <span
                          style={{
                            display: "inline-block",
                            width: 16,
                            height: 16,
                            borderRadius: 9,
                            backgroundColor: item.color
                          }}
                        ></span>
                      </ListItemIcon>
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </MoreOptionPopupContainer>
            ) : null}

            {/** Popover for the comments Button **/}
            <Popover
              id={`comments-popover-${websitefinal}`}
              open={commentsOpen}
              anchorEl={commentsPopperEl}
              onClose={handleCommentPopperClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
            >
              {notes.length > 0 && !addNewNotes ? (
                <PreviewNotes notes={notes} addNewNotesHandler={setAddNewNotes} />
              ) : (
                <NotesEditor
                  onChange={_notes => {
                    if (updateCard) {
                      updateCard({
                        variables: {
                          method: "PATCH",
                          companyId: uuid,
                          value: JSON.stringify({ ...companyData, notes: isEmpty(notes) ? [{ ..._notes }] : [...notes, { ..._notes }] })
                        }
                      });
                    }
                    handleCommentPopperClose();
                  }}
                />
              )}
            </Popover>
          </CompanyCardContainer>
        </div>
      )}
    </Draggable>
  );
};

/***
 *@description Card component for company details. which will pops out while hover on the name
 */

const CompanyHoverCardDetails = ({ name, website, description, uuid }: CompanyHoverCardProps) => {
  return (
    <div className="cardDetails">
      <div className="head">
        <img
          className={"logo"}
          alt={website}
          src={"//logo.clearbit.com/" + website}
          onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
            e.currentTarget.src = "/images/default-corporate-image.jpg"; // some replacement image
            //e.currentTarget.style = 'padding-top: 25px; padding-bottom: 25px;' // inline styles in html format
          }}
        />
        <div className="label">
          <Typography variant="subtitle1" component={Link} href={"/companies/" + uuid}>
            {name}
          </Typography>
          <Link target="_blank" href={`//${website}`}>
            {website}
          </Link>
        </div>
        {/* <Chip size="small" icon={<AdjustIcon fontSize="inherit" />} label="No communication found" /> */}
      </div>
      <div className="content">
        <div className="content_item">
          <div className="content_item-icon">
            <SubjectIcon fontSize="small" htmlColor="#9b9b9b" />
          </div>
          <div className="content_item-desc">
            <Typography variant="body2">{description}</Typography>
          </div>
        </div>
        <div className="content_item">
          <div className="content_item-icon">
            <WebIcon fontSize="small" htmlColor="#9b9b9b" />
          </div>
          <div className="content_item-desc">
            <Link target="_blank" href={`//${website}`}>
              {`www.${website}`}
            </Link>
          </div>
        </div>
        <div className="content_item">
          <div className="content_item-icon">
            <LocationOnIcon fontSize="small" htmlColor="#9b9b9b" />
          </div>
          <div className="content_item-desc">
            <Typography variant="body1">San Fansisco</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

const MoreOptionPopupContainer = styled.div`
  width: 220px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.24);
`;

const HoverCardContainer = styled("div")`
  display: flex;
  width: 310px;
  min-height: 230px;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 3px 15px rgba(0, 0, 0, 0.24);

  .cardDetails {
    background-color: transparent;
    max-height: 500px;
    overflow-y: auto;
    /* padding: 12px; */
    .head {
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 5px;
      .logo {
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }

      .label {
        width: calc(100% - 55px);
        .MuiTypography-subtitle1 {
          display: block;
        }
      }
      .MuiChip-root {
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .content {
      padding: 20px 5px;
      &_item {
        margin-bottom: 10px;
        display: flex;
        &-icon {
          width: 35px;
        }
        &-desc {
          flex-grow: 1;
          flex-basis: 0;
          max-width: 100%;
        }
      }
    }
    .MuiLink-root {
      font-size: 13px;
    }
  }
`;

const CompanyCardContainer = styled(Card)`
  height: 100%;
  &.dragging {
    background-color: #e4f1f8;
    border: 1px solid #a5e0ff;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.35);
  }
  .card-header {
    height: 30px;
    border-bottom: 1px solid #e0e0e0;
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding: 0px 10px;
    .logo {
      width: 22px;
      height: 22px;
      object-fit: cover;
      border-radius: 3px;
      display: inline-block;
      margin-right: 10px;
    }
    .utilities {
      margin-left: auto;
      width: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      visibility: hidden;
      transition: 0.3ms cubic-bezier(0.39, 0.575, 0.565, 1);
      &.show {
        visibility: visible;
      }
    }
    .dragHandler {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
      height: 100%;
    }
    .title {
      .MuiTypography-subtitle1 {
        text-decoration: underline;
        line-height: 1.2;
      }
    }
  }
  .footer {
    height: 30px;
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    border-top: 1px solid #e0e0e0;
    width: 100%;
  }
  .main-content {
    padding: 10px;
    & * {
      /* font-size: 13px; */
      /* line-height: 1.2; */
    }
    &-item {
      margin-bottom: 10px;
    }
  }
  .company-name {
    margin-left: 7px;
  }
`;

// const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     right: -3,
//     top: 13,
//     border: `2px solid ${theme.palette.background.paper}`
//     // padding: "0 4px"
//   }
// }));

export default CompanyCard;
