import { FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography, styled } from "@mui/material";
import React from "react";
import { CodaDocs } from "../../../../types";

const PREFIX = "DealBrief1";
const classes = {
  root: `${PREFIX}-root`,
  selectCard: `${PREFIX}-selectCard`,
  codaRoot: `${PREFIX}-codaRoot`
};

const Root = styled("div")({
  [`&.${classes.root}`]: {
    width: "100%"
  },

  [`& .${classes.selectCard}`]: {
    marginLeft: "24px",
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  [`& .${classes.codaRoot}`]: {
    width: "100%",
    height: "1000px",
    borderWidth: "0px"
  }
});

interface PropType {
  data: CodaDocs[];
}

function DealBrief(props: PropType) {
  const { data } = props;

  const [state, setState] = React.useState({
    companyDocId: data[0].codaurl
  });

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.name === "companyDocId") {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };
  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={4} md={3}>
          <Typography gutterBottom variant="h4" component="h2">
            Coda Documents:

          </Typography>
        </Grid>

        <Grid item xs={6} md={2}>
          <FormControl>
            <Select value={state.companyDocId} onChange={handleChange} name="companyDocId" className={classes.selectCard}>
              {data.map(elem => {
                return <MenuItem value={elem.codaurl}>{elem.doctitle}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" component="p">
            These file are read only. Any changes made in the dashboard will not be saved.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* eslint-disable-next-line */}
          <iframe sandbox="allow-scripts allow-same-origin allow-popups" className={classes.codaRoot} src={state.companyDocId}></iframe>
        </Grid>
      </Grid>
    </Root>
  );
}

export default DealBrief;
