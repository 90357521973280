import { Grid, Typography, styled } from "@mui/material";
import { CompanyTableColumns, ObjectLiteral } from "../../types";
import React from 'react'

const PREFIX = "Header3";
const classes = {
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  boldHeading: `${PREFIX}-boldHeading`,
  fullWidth: `${PREFIX}-fullWidth`
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  [`& .${classes.boldHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500
  },
  [`&.${classes.fullWidth}`]: {
    width: "100%"
  }
}));

interface PropType<T> {
  data: T;
  headers: CompanyTableColumns<T>[];
  maxWidth?: number | string;
  boldHeader?: boolean;
}

const Header = <T extends ObjectLiteral>(props: PropType<T>) => {
  const { data, headers, maxWidth, boldHeader } = props;
  const bodyWidth = !maxWidth ? 5 : 9;
  return (
    <Root className={classes.fullWidth}>
      {headers
        .filter(record => {
          if (data[record.id] && data[record.id] !== "-" && data[record.id] !== "") {
            return true;
          }
          return false;
        })
        .map(header => {
          const value = data[header.id];
          return (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography className={boldHeader ? classes.boldHeading : classes.secondaryHeading}>{header.name}</Typography>
              </Grid>
              <Grid item xs={bodyWidth}>
                <Typography>{header.format ? header.format(value) : value}</Typography>
              </Grid>
              {!maxWidth && (
                <Grid item xs={4}>
                  {" "}
                </Grid>
              )}
            </Grid>
          );
        })}
    </Root>
  );
};

export default Header;
