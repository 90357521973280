import { Image, View } from "@aws-amplify/ui-react";
import { Grid, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import React from "react";

const PREFIX = "NotFound";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(4)
  },
  [`& .${classes.content}`]: {
    paddingTop: 150,
    textAlign: "center"
  }
}));

const NotFound = () => {
  const theme = useTheme();
  return (
    <Root className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <View textAlign="center" padding={theme.spacing(2)}>
            <Image alt="Renegade logo" src="/images/logos/ranegade_partners_logo.png" width={150} />
          </View>
          <Typography sx={{ textAlign: "center" }}>
            Return to dashboard <Link to={"/dashboard"}>Dashboard</Link>
          </Typography>
        </Grid>
        <Grid item lg={12} xs={12} md={12} xl={12}>
          <div className={classes.content}>
            <Typography variant="h1">404: The page you are looking for isn’t here</Typography>
          </div>
        </Grid>
      </Grid>
    </Root>
  );
};

export default NotFound;
