/* eslint-disable */
import { CompanyData, CompanyTableColumns, ObjectLiteral, PagesCategory, CompanyDomains } from "../types";

const pageTypeMap: { [keys in CompanyDomains]: string[] } = {
  API: ["api", "integrations"],
  Login: ["login"],
  "Developer Resources": ["developers"],
  "Mobile App": ["playstore", "appstore"],
  Jobs: ["jobs"],
  "E-Commerce": ["store", "retail", "marketplace"],
  Pricing: ["pricing"],
  Community: ["community"],
  Demo: ["demo"],
  Support: ["feedback", "support"],
  Blog: ["news", "blog"]
};

const pageTypeHeaders: {
  id: "pagetype" | "hasPage";
  name?: string;
}[] = [
  {
    name: "Page Type",
    id: "pagetype"
  },
  {
    name: "Has Page?",
    id: "hasPage"
  }
];

const parsePageType = (dataIn: PagesCategory[]) => {
  var outData= [];
  var data = dataIn.map(v => v.category);
  for (const _key of Object.keys(pageTypeMap)) {
    const key = _key as CompanyDomains;
    const filteredArray = data.filter(value => pageTypeMap[key].includes(value));
    const contains = filteredArray.length > 0;
    outData.push({
      pagetype: key,
      hasPage: contains ? "✅" : "❌"
    });
    outData.sort((elem1In, elem2In) => {
      const elem1 = elem1In.hasPage;
      const elem2 = elem2In.hasPage;

      if (elem1 === "✅" && elem2 === "❌") {
        return -1;
      } else if (elem1 === "❌" && elem2 === "✅") {
        return 1;
      } else {
        if (elem1In.pagetype < elem2In.pagetype) {
          return -1;
        }
        if (elem1In.pagetype > elem2In.pagetype) {
          return 1;
        }
        return 0;
      }
    });
  }
  return outData;
};

const webPercentileHeader: CompanyTableColumns<CompanyData>[] = [
  {
    name: "3 Month",
    id: "growthpercentile3evm",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        const retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return `${retValue} th Percentile`;
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  },
  {
    name: "6 Month",
    id: "growthpercentile6evm",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        const retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return retValue + "th Percentile";
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  }
];

const reviewPercentileHeader: CompanyTableColumns<CompanyData>[] = [
  {
    name: "3 month",
    id: "reviewgrowthpercentile3",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        var retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return retValue + "th Percentile";
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  },
  {
    name: "6 month",
    id: "reviewgrowthpercentile6",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        const retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return retValue + "th Percentile";
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  }
];

const webEvolutionPercentileHeader: CompanyTableColumns<CompanyData>[] = [
  {
    name: "3 month",
    id: "evolutionpercentile3",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        const retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return retValue + "th Percentile";
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  },
  {
    name: "6 month",
    id: "evolutionpercentile6",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        const retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return retValue + "th Percentile";
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  },
  {
    name: "12 month",
    id: "evolutionpercentile12",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        const retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return retValue + "th Percentile";
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  }
];

const webEvolutionHeader: CompanyTableColumns<CompanyData>[] = [
  {
    name: "3 month",
    id: "webev3m",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        return Math.max(value) + " Updates";
      } else {
        return "";
      }
    }
  },
  {
    name: "6 month",
    id: "webev6m",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        return Math.max(value) + " Updates";
      } else {
        return "";
      }
    }
  },
  {
    name: "12 month",
    id: "webev12m",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        return Math.max(value) + " Updates";
      } else {
        return "";
      }
    }
  }
];

const jobOpeningHeader: CompanyTableColumns<CompanyData>[] = [
  {
    name: "Median Job Opening Time",
    id: "medianjobopeningtime",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        return Math.round(value / 86400) + " days";
      } else {
        return "";
      }
    }
  },
  {
    name: "Job Opening Time Percentile",
    id: "jobopeningpercentile",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        const retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return retValue + "th Percentile";
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  }
];

const jobOpeningVolumeHeader: CompanyTableColumns<CompanyData>[] = [
  {
    name: "Current Open Roles",
    id: "opencount",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        return Math.max(value) + " Open Roles";
      } else {
        return "";
      }
    }
  },
  {
    name: "Roles Closed (1 Year)",
    id: "jobcountyear",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        return Math.max(value) + " Closed Roles";
      } else {
        return "";
      }
    }
  }
];

const cIHCGPercentileHeader: CompanyTableColumns<CompanyData>[] = [
  /*{
        name: "Growth Score",
        id: "cihcg",
    },*/
  {
    name: "Percentile",
    id: "cihcgpercentile",
    format: _value => {
      const value = _value as number;
      if (value != null) {
        const retValue = Math.round(value * 100);
        if (retValue < 20 && retValue > 4) {
          return retValue + "th Percentile";
        } else if (retValue % 10 === 1) {
          return retValue + "rst Percentile";
        } else if (retValue % 10 === 2) {
          return retValue + "nd Percentile";
        } else if (retValue % 10 === 3) {
          return retValue + "rd Percentile";
        } else {
          return retValue + "th Percentile";
        }
        return retValue;
      } else {
        return "";
      }
    }
  }
];

export default {
  webPercentileHeader,
  reviewPercentileHeader,
  cIHCGPercentileHeader,
  webEvolutionPercentileHeader,
  webEvolutionHeader,
  jobOpeningVolumeHeader,
  jobOpeningHeader,
  parsePageType,
  pageTypeHeaders
};
