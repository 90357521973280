import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { GridColDef, GridColumnMenuColumnsItem, GridColumnMenuFilterItem, GridColumnMenuItemProps, GridColumnMenuPinningItem, GridColumnMenuProps, GridColumnMenuSortItem } from "@mui/x-data-grid-pro";
import React from "react";

const MenuCloseComponent = (props: GridColumnMenuItemProps) => {
  return (
    <Button color="primary" onClick={props.onClick}>
      Close Menu
    </Button>
  );
};

interface MenuSingleSelectOptionsManageMenuProps extends GridColumnMenuItemProps {
  manageOptionsClickHandler: (colDef: GridColDef) => void;
}

const MenuSingleSelectOptionsManageMenu = (props: MenuSingleSelectOptionsManageMenuProps) => {
  const onclickHandler = (event: React.MouseEvent) => {
    props.onClick(event);
    props.manageOptionsClickHandler(props.colDef);
  };

  return (
    <MenuItem onClick={onclickHandler}>
      <ListItemIcon>
        <SettingsApplicationsIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{"Manage Options"}</ListItemText>
    </MenuItem>
  );
};

interface CustomColumnsMenuProps extends GridColumnMenuProps {
  manageOptionsClickHandler: (colDef: GridColDef) => void;
}

const CustomColumnsMenu = (props: CustomColumnsMenuProps) => {
  const itemProps = {
    colDef: props.colDef,
    onClick: props.hideMenu
  };

  return (
    <React.Fragment>
      <Stack px={0.5} py={0.5}>
        {itemProps.colDef.filterable ? <GridColumnMenuFilterItem {...itemProps} /> : null}
        {/* Only provide filtering on desk */}
        {itemProps.colDef.sortable ? <GridColumnMenuSortItem {...itemProps} /> : null}
        {itemProps.colDef.pinnable ? <GridColumnMenuPinningItem {...itemProps} /> : null}
      </Stack>
      <Divider />
      {itemProps.colDef.type === "singleSelect" ? (
        <React.Fragment>
          <MenuSingleSelectOptionsManageMenu manageOptionsClickHandler={props.manageOptionsClickHandler} {...itemProps} />
        </React.Fragment>
      ) : null}
      <Stack px={0.5} py={0.5}>
        <GridColumnMenuColumnsItem {...itemProps} />
        <MenuCloseComponent {...itemProps} />
      </Stack>
    </React.Fragment>
  );
};

export default CustomColumnsMenu;
