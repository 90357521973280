import { Avatar, Stack, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

interface RenderCompanyNameProps {
  params: GridRenderCellParams,
  keys: { url: string, name: string }
}
/***
 * @description Render Company name cell component.
 * @param props GridRenderEditCellParams
 * @returns JSX
 */

const RenderCompanyName = (props: RenderCompanyNameProps) => {
  const { row } = props.params;
  const { url, name } = props.keys;
  return (
    <Stack spacing={0.5}>
      <Stack direction={"row"} spacing={1} alignItems="center">
        <Avatar
          sx={{ width: "24px", height: "24px" }}
          src={"//logo.clearbit.com/" + row[url]}
          variant="rounded"
          sizes="small"
          imgProps={{
            onError: (e: React.SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = "/images/default-corporate-image.jpg")
          }}
        />
        <Typography
          component={Link}
          to={"/company-details/" + row.uuid + "?fromPage=WeeklyLeadList"}
        // state={{ fromPage: "WeeklyLeadList", toPage: "CompanyDetail", activityType: "PAGE_VIEW" }}
        >
          {row[name]}
        </Typography>
      </Stack>
      {row.lastVisitedAt ? <Typography variant="caption">Last visited {moment(row.lastVisitedAt).fromNow()}</Typography> : null}
    </Stack>
  );
};

export default RenderCompanyName;