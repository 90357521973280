import { gql, useLazyQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import ListIcon from "@mui/icons-material/List";
import { Button, IconButton, Menu, MenuProps, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";
import { saveAs } from "file-saver";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { getCompanyList } from "../../graphql/queries";
import { GetCompaniesListData } from "../../types";
import { CompaniesByNameSearchBox } from "../../components";
import { DataSources } from "./DataSourceManagement";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

interface DownloadRecordsProps {
  dataSources: DataSources[];
}

const DownloadRecords = ({ dataSources }: DownloadRecordsProps) => {
  const [dataSourceName, setDataSource] = useState("");
  const [companiesData, setCompaniesData] = useState<GetCompaniesListData[]>([]);
  const [exportOptAnchorEl, setExportOptAnchorEl] = React.useState<null | HTMLElement>(null);
  //Api::- get json data for the remaining records for which pitchbook records need to download.
  const [getCompaniesList, { loading }] = useLazyQuery<{ getCompanyList: GetCompaniesListData[] }>(gql(getCompanyList));

  // theme
  const theme = useTheme();
  //open
  const exportOptionsOpen = Boolean(exportOptAnchorEl);

  /**
   * @description  get json from the api and make it into csv or xlx file type and then download it.
   */
  const handleDownLoad = async () => {
    if (isEmpty(dataSourceName)) {
      return toast.warn("Select data source first.");
    }
    const request = await getCompaniesList({
      variables: {
        type: dataSourceName.toLocaleLowerCase()
        // companies
      }
    });
    let companiesList = request.data?.getCompanyList;
    if (!companiesList) {
      toast.error("No records found.");
      return;
    }

    //set data to state;
    setCompaniesData(companiesList.map(({ __typename, ...rest }) => rest));
  };

  const exportData = async (companiesList: Partial<GetCompaniesListData>[], fileType: "json" | "xlsx" | "csv") => {
    //Close the menu popover
    setExportOptAnchorEl(null);

    //? query for the records.
    // const request = await getCompaniesList({
    //   variables: {
    //     type: dataSourceName.toLocaleLowerCase(),
    //     companies: JSON.stringify(companiesList.map(d => d.company_name))
    //   }
    // });

    // if (!request.data?.getCompanyList) {
    //   toast.error("No records found.");
    //   return;
    // }

    //redeclare the list for the downloadable data;
    // companiesList = request.data?.getCompanyList;
    companiesList = companiesList.map(({ __typename, ...rest }) => rest);

    const time = new Date().getTime();
    //if selected file type is json
    if (fileType === "json") {
      const jsonData = JSON.stringify(companiesList, null, 2);

      // Create a Blob with the JSON data
      const blob = new Blob([jsonData], { type: "application/json;charset=utf-8" });

      // Download the JSON file
      return saveAs(blob, `companiesList_${time}.json`);
    }

    // either go  for file type .csv OR .json
    const worksheet = XLSX.utils.json_to_sheet(companiesList); // init xlsx;

    if (fileType === "csv") {
      // Convert JSON to CSV
      const csvData = XLSX.utils.sheet_to_csv(worksheet);

      // Create a Blob with the CSV data
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });

      // Download the CSV file
      saveAs(blob, `companiesList_${time}.csv`);
    }
    // download file format for the
    else if (fileType === "xlsx") {
      // Convert JSON to XLSX
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "data");

      // Generate XLSX file buffer
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

      // Convert buffer to Blob
      const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

      // Download the XLSX file
      saveAs(blob, `companiesList_${time}.xlsx`);
    }
  };

  const handleOnselect = ({ __typename, ...rest }: GetCompaniesListData) => {
    const isAvailable = companiesData.find(i => i.company_name === rest.company_name);
    if (!isAvailable) {
      setCompaniesData([...companiesData, { ...rest }]);
    } else {
      toast.warn("This record is already added");
    }
  };

  //delete record handler
  const handleDeleteFromList = (index: number) => {
    companiesData.splice(index, 1);
    setCompaniesData([...companiesData]);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ marginBottom: 2 }}>Select data source and and click on <b>SHOW LIST</b> button to display companies list.</Typography>
      {/* Form for the showing the list */}
      <Stack direction={"row"} spacing={2}>
        <FormControl size="small" fullWidth>
          <InputLabel id="data-source-selector">Data Source</InputLabel>
          <Select sx={{ textTransform: "capitalize" }} labelId="data-source-selector" id="data-source-select" value={dataSourceName} label="Data Source" onChange={event => setDataSource(event.target.value)}>
            {dataSources.map(source => (
              <MenuItem sx={{ textTransform: "capitalize" }} selected value={source.sourceName}>
                {source.sourceName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button  size="small" disabled={Boolean(isEmpty(dataSourceName)) || loading} type="button" variant="outlined" sx={{ width: 150 }} startIcon={<ListIcon />} onClick={handleDownLoad}>
          Show list
        </Button>
      </Stack>

      {/* Show the table of the data */}
      {companiesData.length > 0 ? (
        <Stack sx={{ marginTop: 2, p: 1.5, border: 1, borderColor: theme.palette.grey["400"], borderRadius: 1 }} spacing={2}>
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <CompaniesByNameSearchBox icon={<AddIcon />} placeholder="Search for add more..." type="pitchbook" onSelect={handleOnselect} />

            <Button
              disableElevation
              onClick={event => setExportOptAnchorEl(event.currentTarget)}
              aria-controls={exportOptionsOpen ? "export-options-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={exportOptionsOpen ? "true" : undefined}
              variant="contained"
              size="small"
              startIcon={<DownloadIcon />}
            >
              Export
            </Button>

            <StyledMenu
              id="export-options-menu"
              MenuListProps={{
                "aria-labelledby": "export-options-menu"
              }}
              anchorEl={exportOptAnchorEl}
              open={exportOptionsOpen}
              onClose={() => setExportOptAnchorEl(null)}
            >
              <MenuItem onClick={() => exportData(companiesData, "json")} disableRipple>
                Export as JSON
              </MenuItem>
              <MenuItem onClick={() => exportData(companiesData, "xlsx")} disableRipple>
                Export as Excel document
              </MenuItem>
              <MenuItem onClick={() => exportData(companiesData, "csv")} disableRipple>
                Export as csv document
              </MenuItem>
            </StyledMenu>
          </Stack>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company name</TableCell>
                <TableCell>Company Url</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Ticker</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companiesData.map((data, index) => (
                <TableRow key={`tableListItemOfDowloadableData-${index}`}>
                  <TableCell>{data.company_name}</TableCell>
                  <TableCell>{data.company_url}</TableCell>
                  <TableCell>{data.city}</TableCell>
                  <TableCell>{data.state}</TableCell>
                  <TableCell>{data.country}</TableCell>
                  <TableCell>{data.ticker}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteFromList(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      ) : null}
    </Box>
  );
};

export default DownloadRecords;
