import { CompanyData, EditableDataRow, EditableTableCols, HeadcountGrowth, TableActions, TableDataTypes, TableDataTypesVal } from "../../../types";
export function shortId() {
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}

/***
 *@description Function for converting HSL to hexdecimal color code.
 */
export function HSLToHex(h: number, s: number, l: number) {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return `#${r.toString(16).length === 1 ? "0" + r.toString(16) : r.toString(16)}${g.toString(16).length === 1 ? "0" + g.toString(16) : g.toString(16)}${b.toString(16).length === 1 ? "0" + b.toString(16) : b.toString(16)}`;
}

/***
 * @description This function will generate random color for the list
 * **/
export function randomColor() {
  const h = Math.floor(Math.random() * 360);
  const s = Math.floor(Math.random() * 16) + 80;
  const l = Math.floor(Math.random() * 11) + 80;
  return HSLToHex(h, s, l);
}

// "checkbox", "date", "multiSelect", "text", "long_text", "autocomplete", "number", "source_types", "notes", "select_button", "link"

/**
 * @description Function for truncateString
 */
export function truncateString(str:string, num:number) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

// truncateString("A-tisket a-tasket A green and yellow basket", 8);

export const DataTypes: {
  [key in TableDataTypes]: TableDataTypesVal;
} = Object.freeze({
  NUMBER: "number",
  TEXT: "text",
  SELECT: "select",
  SELECT_BUTTON: "select_button",
  CHECKBOX: "checkbox",
  LOGO: "logo",
  LONG_TEXT: "long_text",
  LINK: "link",
  VERTICALS: "verticals",
  DATE: "date",
  NOTES: "notes",
  MULTI_SELECT: "multiSelect",
  AUTOCOMPLETE: "autocomplete"
});

export const ActionTypes: {
  [key in TableActions]: string;
} = Object.freeze({
  ADD_OPTION_TO_COLUMN: "add_option_to_column",
  ADD_ROW: "add_row",
  UPDATE_COLUMNS: "update_columns",
  UPDATE_COLUMN_TYPE: "update_column_type",
  UPDATE_COLUMN_HEADER: "update_column_header",
  UPDATE_CELL: "update_cell",
  ADD_COLUMN_TO_LEFT: "add_column_to_left",
  ADD_COLUMN_TO_RIGHT: "add_column_to_right",
  DELETE_COLUMN: "delete_column",
  ENABLE_RESET: "enable_reset",
  UPDATE_COL_WITh_ACCESSOR: "update_col_with_accessor",
  ADD_NEW_NOTES: "add_new_notes"
});

export const companyDataCols: EditableTableCols<CompanyData>[] = [
  {
    id: "linkedinfinal",
    label: "Linkedin",
    accessor: "linkedinfinal",
    minWidth: 100,
    width: 150,
    datatype: DataTypes.LINK,
    option: []
  },
  {
    id: "finaldescription",
    label: "Description",
    accessor: "finaldescription",
    minWidth: 150,
    width: 175,
    datatype: DataTypes.LONG_TEXT,
    option: []
  },
  {
    id: "finaldescriptions",
    label: "Industry Tag",
    accessor: "verticals",
    minWidth: 150,
    width: 175,
    datatype: DataTypes.VERTICALS,
    option: []
  }
];

export function makeData() {
  // let data = realData;

  let columns: EditableTableCols<CompanyData>[] = [
    {
      id: "CompanyImage",
      label: "Company Name",
      accessor: data => ({ link: data["websitefinal"], name: data["namefinal"], compId: data["uuid"] }),
      minWidth: 120,
      width: 200,
      iseditable: false,
      datatype: DataTypes.LOGO,
      option: []
    },
    {
      id: 999999,
      width: 20,
      label: "+",
      disableResizing: true,
      //@ts-ignore
      datatype: "null"
    }
  ];
  return { columns: columns, data: [], skipReset: false, isNotesSidebarOpen: false, colAttributes: companyDataCols };
}

/***
 *@description - For creating an object from `CompanyData` data to `EditableDataRow`
 * @params input - Inout data of type `CompanyData`
 * @params funnel_stages - Default data  will be for Lead. Type `funnelStage`
 */

export function makeDataForTable(
  input: CompanyData,
  funnel_stages = {
    label: "Lead",
    id: "lead",
    updatedAt: new Date().toISOString()
  }
): EditableDataRow {
  const { namefinal, finaldescription, locationfinal, fundingroundspb, verticals, totalfunding, headcountgrowth, linkedinfinal, websitefinal, uuid } = input;
  const totalEmployee = (value: HeadcountGrowth[] | null) => (value && value.find(e => e.industryname === "All")?.departmentcount) ?? "No Data";

  return {
    namefinal,
    finaldescription: finaldescription ?? "",
    location: locationfinal ?? "",
    renegade_description: "",
    series: fundingroundspb ? fundingroundspb[0].series : "",
    industry_tag: verticals ? verticals.map(v => ({ label: v.vertical, id: v.companyuuid })) : [],
    funnel_stages,
    live_deal: false,
    discuss: false,
    investor_user: null,
    date_added: new Date().toISOString(),
    notes: [],
    source_types: {
      label: "Co-investor",
      id: "co-investor"
    },
    source_firm: [],
    source_person: [],
    foundation_year: new Date().toDateString(),
    total_raised: `$${totalfunding} million`,
    last_round_date: fundingroundspb ? fundingroundspb[0].dealdate : "",
    total_employees: totalEmployee(headcountgrowth),
    linkedin: linkedinfinal ?? "",
    valuation_overview: fundingroundspb ? `$${fundingroundspb[0].postmoneyvaluation} million` : "",
    investors: [],
    websitefinal: websitefinal ?? "",
    uuid: uuid,
    point_person:[]
  };
}
