import { createGlobalStyle, css } from "styled-components";

const tablecss = css`
  .transition-fade-enter {
    opacity: 0;
  }

  .transition-fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  .transition-fade-exit {
    opacity: 1;
  }

  .transition-fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .svg-icon-table svg {
    position: relative;
    height: 20px;
    width: 20px;
  }

  /* .svg-text svg {
    stroke: #424242;
  } */

  .svg-180 svg {
    transform: rotate(180deg);
  }

  .form-input {
    padding: 0.375rem;
    background-color: #eeeeee;
    border: none;
    border-radius: 4px;
    font-size: 0.875rem;
    color: #424242;
  }

  .form-input:focus {
    outline: none;
    box-shadow: 0 0 1px 2px #8ecae6;
  }

  .is-fullwidth {
    width: 100%;
  }

  .bg-white {
    background-color: white;
  }

  .data-input {
    white-space: pre-wrap;
    border: none;
    padding: 0.5rem;
    color: #424242;
    font-size: 1rem;
    border-radius: 4px;
    resize: none;
    background-color: white;
    box-sizing: border-box;
    flex: 1 1 auto;
    height: 100%;
    &[contenteditable="true"]:empty:before {
      content: attr(placeholder);
      pointer-events: none;
      color: rgba(117, 117, 117, 0.55);
      display: block; /* For Firefox */
    }
  }

  .data-input:focus {
    outline: none;
  }

  .shadow-5 {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12), 0 4px 6px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12), 0 16px 32px rgba(0, 0, 0, 0.12);
  }

  .svg-icon-table-sm svg {
    position: relative;
    height: 1rem;
    width: 1rem;
  }

  .svg-theme svg {
    color: #fa2217;
  }

  .option-input {
    width: 100%;
    font-size: 1rem;
    border: none;
    background-color: transparent;
  }

  .option-input:focus {
    outline: none;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 2;
    overflow: hidden;
  }

  .sort-button {
    padding: 0.25rem 0.75rem;
    width: 100%;
    background-color: transparent;
    border: 0;
    font-size: 0.875rem;
    color: #757575;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .sort-button:hover {
    background-color: #eeeeee;
  }

  .tr:last-child .td {
    border-bottom: 0;
  }

  tr:focus {
    background-color: LightGray;
  }

  .add-row {
    color: #9e9e9e;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    cursor: pointer;
    height: 50px;
  }

  .add-row:hover {
    background-color: #f5f5f5;
  }

  .th {
    color: #9e9e9e;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .th:hover {
    background-color: #f5f5f5;
  }

  .th-content {
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    height: 50px;
  }

  .td {
    overflow: hidden;
    color: #424242;
    align-items: stretch;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .td-content {
    display: block;
  }

  .table {
    display: inline-block;
    border-spacing: 0;
  }

  .th:last-child {
    border-right: 0;
  }

  .td:last-child {
    border-right: 0;
  }

  .resizer {
    display: inline-block;
    background: transparent;
    width: 8px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    cursor: col-resize;
    touch-action: none;
  }

  .resizer:hover {
    background-color: #8ecae6;
  }

  .th,
  .td {
    white-space: nowrap;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    position: relative;
  }

  .text-align-right {
    text-align: right;
  }

  .cell-padding {
    padding: 0.5rem;
  }

  .d-flex {
    display: flex;
  }

  .d-inline-block {
    display: inline-block;
  }

  .cursor-default {
    cursor: default;
  }

  .align-items-center {
    align-items: center;
  }

  .flex-wrap-wrap {
    flex-wrap: wrap;
  }

  .border-radius-md {
    border-radius: 5px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .icon-margin {
    margin-right: 4px;
  }

  .font-weight-600 {
    font-weight: 600;
  }

  .font-weight-400 {
    font-weight: 400;
  }

  .font-size-75 {
    font-size: 0.75rem;
  }

  .flex-1 {
    flex: 1;
  }

  .mt-5 {
    margin-top: 0.5rem;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mr-5 {
    margin-right: 0.5rem;
  }

  .justify-content-center {
    justify-content: center;
  }

  .flex-column {
    flex-direction: column;
  }

  .overflow-auto {
    overflow: auto;
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }
  .overflow-y-hidden ~ div {
    display: none;
  }

  .list-padding {
    padding: 4px 0px;
  }

  .bg-grey-200 {
    background-color: #eeeeee;
  }

  .color-grey-800 {
    color: #424242;
  }

  .color-grey-600 {
    color: #757575;
  }

  .color-grey-500 {
    color: #9e9e9e;
  }

  .border-radius-sm {
    border-radius: 4px;
  }

  .text-transform-uppercase {
    text-transform: uppercase;
  }

  .text-transform-capitalize {
    text-transform: capitalize;
  }

  .companySearchBox {
    position: fixed;
    top: -50px;
    z-index: 1201;
    left: 100px;
    top: 10px;
  }

  .company-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    img {
      margin-left: 10px;
      margin-right: 10px;
    }
    text-decoration: none;
  }
  .table-header {
    &-tab {
      display: block;
    }
    &-tablist {
      display: flex;
      border-radius: 5px 0 0 5px;
      border-bottom: 2px solid rgb(238, 238, 238);
      button {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        height: 35px;
        background-color: #eeeeee;
        border: none;
        cursor: pointer;
        font-size: 0.975rem;
        line-height: 35px;
        color: #9e9e9e;
        &:first-child {
          border-radius: 5px 0 0 0;
        }
        &:last-child {
          border-radius: 0 5px 0 0;
        }
        &.Mui-selected {
          background-color: white;
          color: #fa2217;
        }
      }
    }
    &-tabpane {
      &.tab-1 {
        padding: 10px;
      }
    }
  }
  .long-data-cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-wrap: break-word;
    padding: 3px;
  }
  .data-full-height {
    height: 100%;
    margin-bottom: 0;
  }
  .MuiDataGrid-cell {
    border-color: rgba(224, 224, 224, 1)!important;
  }
`;

const AppGlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}



.popperContainerWithArrow{
  .arrow {
    position: absolute;
    left: -6px;
    border: 6px solid transparent;
    border-left: none;
    border-right-color: #fff;
  }
  &[data-popper-placement="left"] {
    .arrow {
      right: -6px;
      left: unset;
      border-right: none;
      border-left: 6px solid #fff;
    }
  }
  &[data-popper-placement="top"] {
    .arrow {
      bottom: -6px;
      border: 6px solid transparent;
      border-bottom: none;
      border-top: 6px solid #fff;
    }
  }
  &[data-popper-placement="bottom"] {
    .arrow {
      top: -6px;
      border: 6px solid transparent;
      border-top: none;
      border-bottom: 6px solid #fff;
    }
  }
}
${tablecss}
`;

export default AppGlobalStyles;
