import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Stack, Avatar, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const CompanyNameRenderCell = (params: GridRenderCellParams) => {
  return (
    <Stack direction={"row"} spacing={1} alignItems="center">
      <Avatar
        sx={{ width: "24px", height: "24px" }}
        src={"//logo.clearbit.com/" + params.row.companyurl}
        variant="rounded"
        sizes="small"
        imgProps={{
          onError: (e: React.SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = "/images/default-corporate-image.jpg")
        }}
      />
      {params.row.uuid ? (
        <Typography component={Link} to={"/companies/" + params.row.uuid} target="_blank">
          {params.value}
        </Typography>
      ) : (
        <Typography>{params.value}</Typography>
      )}
    </Stack>
  );
};

export default CompanyNameRenderCell;
