import { Grid, Pagination, Paper, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CompanyCard from "../CompanyCard";

const PREFIX = "SavedSearches";

const classes = {
  root: `${PREFIX}-root`,
  noMargin: `${PREFIX}-noMargin`,
  paginationCenter: `${PREFIX}-paginationCenter`,
  searchSize: `${PREFIX}-searchSize`,
  sortByText: `${PREFIX}-sortByText`,
  sortSelect: `${PREFIX}-sortSelect`,
  skeletonStyle: `${PREFIX}-skeletonStyle`,
  nothingFoundText: `${PREFIX}-nothingFoundText`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%"
  },

  [`& .${classes.noMargin}`]: {
    marginTop: "0px !important",
    paddingTop: "0px !important"
  },
  [`& .${classes.paginationCenter}`]: {
    display: "table",
    margin: "0 auto",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  [`& .${classes.searchSize}`]: {
    width: "100%",
    textAlign: "right",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  [`& .${classes.sortByText}`]: {
    width: "fit-content",
    display: "inline-block",
    padding: "10px"
  },
  [`& .${classes.sortSelect}`]: {
    marginLeft: "10px"
  },
  [`& .${classes.skeletonStyle}`]: {
    width: "100%",
    height: "250px"
  },
  [`& .${classes.nothingFoundText}`]: {
    width: "100%",
    textAlign: "center",
    padding: "20px"
  }
}));

type LeadsListBodyProps = {
  loading: number;
  data?: any;
  page?: any;
  onChange: Function;
};
const LeadsListBody = (props: LeadsListBodyProps) => {
  const { loading, data, page, onChange } = props;

  return (
    <Root className={classes.root}>
      <Grid container className={classes.noMargin}>
        {!loading ? (
          <Grid item xs={12}>
            <Paper elevation={2} square={true}>
              <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Typography className={classes.searchSize} variant="body1" color="textSecondary" component="p">
                    {data.count ? ["Showing " + (parseInt(data.count[0].count) < 500 ? data.count[0].count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "500+") + " results"] : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          ""
        )}

        {!loading ? (
          [
            data.length > 0 ? (
              data.map((company: any) => {
                return (
                  <Grid item xs={12}>
                    <CompanyCard
                      companyName={company.namefinal}
                      companyImage={"//logo.clearbit.com/" + company.websitefinal}
                      companyIndustries={company.categories}
                      description={company.finaldescription}
                      companyID={company.uuid}
                      seriesmerged={company.seriesmerged}
                      totalfunding={company.totalfunding}
                      introPointCompany={company.personname}
                      introPointInternal={company.investor}
                      introPointID={company.employeeid}
                      introPointTitle={company.liheadline}
                      introPointPicture={company.profilepicture}
                    />{" "}
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Paper elevation={2} square={true}>
                  <Typography variant="h4" className={classes.nothingFoundText}>
                    No Companies Found
                  </Typography>
                </Paper>
              </Grid>
            )
          ]
        ) : (
          <Grid item xs={12}>
            <Skeleton variant="text" height={65} />
            <Skeleton variant="circular" width={75} height={75} />
            <Skeleton variant="rectangular" width={"100%"} height={125} />
            <Skeleton variant="circular" width={75} height={75} />
            <Skeleton variant="rectangular" width={"auto"} height={125} />
            <Skeleton variant="text" height={65} />
          </Grid>
        )}

        <Grid item xs={12}>
          {!loading && data.count ? (
            <Paper elevation={2} square={true}>
              <Pagination className={classes.paginationCenter} color="primary" count={Math.max(Math.min(Math.ceil(data.count[0].count / 25), 20), 1)} page={page} size="large" onChange={(e, v) => onChange("pageNum", v)} />
            </Paper>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Root>
  );
};

export default LeadsListBody;
