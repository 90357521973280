import { useQuery, gql } from "@apollo/client";
import { Grid, Skeleton, styled } from "@mui/material";
import React from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { companyUUIDQuery } from "../../graphql/queries";
import { PageState } from "../../types";
import CompanyHeader from "./components/CompanyHeader";
import TabBar from "./components/TabBar";

const PREFIX = "Company";
const classes = {
  root: `${PREFIX}-root`,
  skeletonStyle: `${PREFIX}-root`,
  circleSkeleton: `${PREFIX}-root`,

  gridNoMargin: `${PREFIX}-root`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(3),
    width: "100%"
  },

  [`& .${classes.skeletonStyle}`]: {
    marginLeft: "15%",
    marginRight: "15%"
  },
  [`& .${classes.circleSkeleton}`]: {
    marginLeft: "15%"
  },

  [`& .${classes.gridNoMargin}`]: {
    marginBottom: "0px",
    paddingBottom: "0px",
    paddingTop: "0px",
    marginTop: "0px"
  }
}));

const queryString = require("qs");

const Company = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const routeInput = queryString.parse(location.search, {
    arrayFormat: "indices",
    strictNullHandling: true,
    ignoreQueryPrefix: true
  });
  const pageValue = routeInput.pathValue ? parseInt(routeInput.pathValue) : 0;
  const seniority = routeInput.seniority ? routeInput.seniority : "All";
  const currentEmployees = routeInput.currentEmployees ? parseInt(routeInput.currentEmployees) : 0;
  const offsetEmployee = routeInput.offsetEmployee ? parseInt(routeInput.offsetEmployee) : 0;

  const { loading, data } = useQuery(gql(companyUUIDQuery), {
    variables: {
      CompanyUUID: id
    }
  });

  const onPageChange = (pageState: PageState) => {
    var searchString = queryString.stringify(pageState, {
      arrayFormat: "indices",
      strictNullHandling: true
    });
    navigate(`/companies/${id}?${searchString}`, { state: { searchString } });
  };
  if ((!loading && data === undefined) || (!loading && data.CompanyUUIDQuery.hashkey != null)) {
    return <Navigate to="/not-found" />;
  }
  const header = !loading ? (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.gridNoMargin}></Grid>
      <Grid item xs={12}>
        {loading ? (
          <div>
            <Skeleton className={classes.circleSkeleton} variant="circular" width={200} height={200} />
            <Skeleton variant="circular" className={classes.skeletonStyle} height={200} />
          </div>
        ) : (
          <CompanyHeader data={data.CompanyUUIDQuery}></CompanyHeader>
        )}
      </Grid>

      <Grid item xs={12}>
        {loading ? <div></div> : <TabBar data={data.CompanyUUIDQuery} value={pageValue} seniority={seniority} offsetEmployee={offsetEmployee} currentEmployees={currentEmployees} handleChange={onPageChange}></TabBar>}
      </Grid>
    </Grid>
  ) : (
    <div></div>
  );

  return <Root className={classes.root}>{header}</Root>;
};

export default Company;
