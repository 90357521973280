import { GenerateOutputs } from "../helpers"
import { SearchHelper} from "../helpers"
const queryString = require('qs');





const generateHistoryStringForTag = (department, seniority,tag) =>{
  var searchInput = SearchHelper.getDefaultInput()
  searchInput.EmployeeTags.push(GenerateOutputs.generateOutputForEmployeeTags([department,seniority,tag]))
  return queryString.stringify(searchInput,{ arrayFormat: 'indices',strictNullHandling: true })
}

const generateHistoryStringForIndustry = (industry) =>{
  var searchInput = SearchHelper.getDefaultInput()
  searchInput.Industries.push(industry)
  return queryString.stringify(searchInput,{ arrayFormat: 'indices',strictNullHandling: true })
}
const generateHistoryStringForSkill = (skill) =>{
  var searchInput = SearchHelper.getDefaultInput()
  searchInput.EmployeeSkills.push({Department:"Any Department", Seniority:"Any Seniority", Skill:skill})
  return queryString.stringify(searchInput,{ arrayFormat: 'indices',strictNullHandling: true })
}
const generateHistoryStringForStrength = (department, seniority, strengthRange) =>{
  var searchInput = SearchHelper.getDefaultInput()
  searchInput.DepartmentStrengthList.push(GenerateOutputs.generateOutputForSignalStrength([department,seniority,strengthRange]))
  return queryString.stringify(searchInput,{ arrayFormat: 'indices',strictNullHandling: true })
}

const generateHistoryStringForDate = (epochTime) =>{
  var date = new Date(parseInt(epochTime) * 1000).toJSON().split("T")[0]
  var searchInput = SearchHelper.getDefaultInput()
  searchInput.RecordCreated = date
  return queryString.stringify(searchInput,{ arrayFormat: 'indices',strictNullHandling: true })
}
//eslint-disable-next-line
export default {
  generateHistoryStringForTag,
  generateHistoryStringForIndustry,
  generateHistoryStringForStrength,
  generateHistoryStringForDate,
  generateHistoryStringForSkill
  };