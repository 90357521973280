import { useLazyQuery, useQuery  , gql} from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Card, IconButton, Popover, Skeleton, TextField } from "@mui/material";
import { ColorPicker } from "../../components/ColorPicker";
import { editableTable, leadsListQuery } from "../../graphql/queries";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import styled from "styled-components";
import { CompanyData, EditableDataRow, InNetworkLeads, LeadListData, SourcePerson } from "../../types";
import { makeDataForTable } from "../../views/EditableView/utils";
import ColumnComponent from "./ColumnComponent/ColumnComponent";
import { Column } from "./fakeData";

const KanbanView = () => {
  // const [allData, setAllData] = useState<EditableDataRow[] | null>(null);
  const [kanbanData, setData] = useState<Column[] | null>(null);
  const [inNetworkData, setInNetworkData] = useState<InNetworkLeads[] | null>(null);
  const [sourcePersonsData, setSourcePersonData] = useState<SourcePerson[] | null>(null);
  const [newColumn, setColumn] = useState("");
  const [newColor, setColor] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getSourcePersonList, { data: getSourcePersonResponse }] = useLazyQuery(gql(editableTable), {
    variables: {
      method: "getPerson"
    },
    fetchPolicy: "network-only"
  });
  const [queryData, { data: allData }] = useLazyQuery(gql(editableTable), {
    fetchPolicy: "network-only"
  });
  const { data: leadListData } = useQuery<{ LeadsListQuery: LeadListData }>(gql(leadsListQuery));
  const [postUpdate, { data: updateResponse, error: updateError }] = useLazyQuery(gql(editableTable));

  //get Data
  useEffect(() => {
    queryData({
      variables: {
        method: "GET"
      }
    });
    //eslint-disable-next-line
  }, []);

  //get Source Person on render
  useEffect(() => {
    getSourcePersonList();
    //eslint-disable-next-line
  }, []);

  //Process data of InNetwork for removing duplicates\
  useEffect(() => {
    if (leadListData?.LeadsListQuery.innetwork) {
      const ind = leadListData?.LeadsListQuery.innetwork;
      const uniqueData = Array.from(new Set(ind.map(a => a.investor))).map(investor => {
        return ind.find(a => a.investor === investor);
      });
      //@ts-ignore;
      setInNetworkData(uniqueData);
    }
  }, [leadListData]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function uuidv4() {
    //@ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
  }

  const addColumn = () => {
    const newCol = { id: uuidv4(), color: newColor, label: newColumn };
    postUpdate({
      variables: {
        method: "updateColumns",
        value: JSON.stringify(newCol)
      }
    });
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  /**
 *@description This function  for handling card drag. 
 === Algo for this action handler=====
 1. Identify both source and destination column with index.
 2. Remove data at index in source column and  add data at the drop index in destination column.
 */

  const handleCardDragEnd = (result: DropResult) => {
    //Identify Source
    const srcColIndex = kanbanData?.findIndex(d => d.id === result.source.droppableId);
    const destColIndex = kanbanData?.findIndex(d => d.id === result.destination?.droppableId);

    if (kanbanData && srcColIndex !== undefined && destColIndex !== undefined) {
      const sourceCol = kanbanData[srcColIndex];
      const sourceData = sourceCol?.items[result.source.index];

      //Identify destination
      const destinationCol = kanbanData[destColIndex];

      //Remove data from source.
      sourceCol?.items.splice(result.source.index, 1);

      //Add data at destination index.
      // destinationCol?.items.splice(result.destination!.index, 0, sourceData!);

      sourceData.funnel_stages = {
        id: destinationCol.id,
        label: destinationCol.label,
        updatedAt: moment().toISOString()
      };

      setTimeout(() => {
        postUpdate({
          variables: {
            method: "PATCH",
            companyId: sourceData.uuid,
            value: JSON.stringify(sourceData)
          }
        });
      }, 1000);
    }
  };

  /**
   *@description Function for handling column drag 
   == Alo===
   1. Identify source index 
   2. Identify destination Index.
   3. Remove data from source index;
   4. Add data to destination index;
   */

  const handleColumnDrag = (result: DropResult) => {
    if (kanbanData) {
      const _kanbanData = [...kanbanData];
      const srcIndex = result.source.index;
      const destIndex = result.destination?.index;

      if (destIndex !== undefined) {
        const sourceData = _kanbanData[srcIndex];

        //Remove data from source index;
        _kanbanData.splice(srcIndex, 1);
        // Add  Data at destination index;
        _kanbanData.splice(destIndex, 0, sourceData);
        setData([..._kanbanData]);
      }
    }
  };

  /***
   *@description This  will evoked after drag end.
   * @params  result - this is an object will get after  drag end.
   */
  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    switch (result.type) {
      case "COLUMN":
        handleColumnDrag(result);
        break;
      case "DEFAULT":
        handleCardDragEnd(result);
        break;
      default:
        toast.warn("Something Unhandled!!");
        break;
    }
  };

  /***
   *@description A pipeline function for formatting api data for required data fromat.
   */
  const dpip = (data: string[]) => {
    //@ts-ignore
    const _data = data
      .map((d: string) => JSON.parse(d))
      .map(d => {
        //@ts-ignore
        Object.keys(d).forEach(e => {
          if (typeof d[e] === "string") d[e] = d[e].trim();
          // if(e === "option" && d[e]) d[e] = JSON.parse(d[e]);
        });
        return d;
      });
    return _data;
  };

  /**
   *@description This function will handle data restructuring for representing in KanBan-view.
   ***/
  const processDataFromGetQuery = (responseData: any) => {
    const dataList = (responseData?.EditableTable?.columns as any) as string[];
    const columnListData = (responseData?.EditableTable?.columnslist as any) as string[];
    let funel_stages: any;

    //columns of the table
    const cols = dpip(columnListData);
    funel_stages = cols.find(d => d.accessor === "funnel_stages")?.option;

    if (dataList && dataList.length > 0) {
      const rows: EditableDataRow[] = dpip(dataList);
      const grouped = _.groupBy(rows, row => row?.funnel_stages?.id);
      funel_stages = funel_stages.map((d: { label: string | number; id: string }) => ({ ...d, items: _.sortBy(grouped[d.id], d => d.date_added).reverse() ?? [] }));
      // console.log("funel_stages", funel_stages);
      setData(funel_stages);
    } else {
      funel_stages = funel_stages.map((d: { label: string | number; id: string }) => ({ ...d, items: [] }));
      setData(funel_stages);
    }
  };
  /**
   *  @description Adding new records to columns.
   * 1. Find column in which data is added
   * 2. Formate data for send the  server.
   * 2. Post  request for updating data on the server.
   */
  const addCompany = (colId: string, value: CompanyData) => {
    // Find column
    const column = kanbanData?.find(d => d.id === colId);
    // formate data  to required formate.
    const newCompanyData = makeDataForTable(value, {
      id: column?.id!,
      label: column?.label!,
      updatedAt: moment().toISOString()
    });

    if (!allData?.EditableTable?.columns) {
      // Post and update to the server.
      postUpdate({
        variables: {
          method: "POST",
          companyId: value.uuid,
          value: JSON.stringify(newCompanyData)
        }
      });
    } else {
      const dataList = (allData?.EditableTable?.columns as any) as string[];
      const rows: EditableDataRow[] = dpip(dataList);
      if (rows && rows.find(d => d.uuid === value.uuid)) {
        return toast.warn("Data already existing..");
      }
      postUpdate({
        variables: {
          method: "POST",
          companyId: value.uuid,
          value: JSON.stringify(newCompanyData)
        }
      });
    }
  };

  /***
   *@description Card stage change handler. That will triggered when change will be performed from the  dropdown menu.
   */
  const handleStageChange = (sourceData: EditableDataRow, destination: Column) => {
    const sourceCallIndex = kanbanData?.findIndex(d => d.id === sourceData.funnel_stages.id);
    if (kanbanData && sourceCallIndex !== undefined) {
      const sourceCol = kanbanData[sourceCallIndex];
      const srcDataIndex = sourceCol.items.findIndex(d => d.uuid === sourceData.uuid);

      //Remove data from source.
      sourceCol?.items.splice(srcDataIndex, 1);
      //Update funel stage with new value.
      sourceData.funnel_stages = {
        id: destination.id,
        label: destination.label,
        updatedAt: moment().toISOString()
      };

      //Update api request on server.
      postUpdate({
        variables: {
          method: "PATCH",
          companyId: sourceData.uuid,
          value: JSON.stringify(sourceData)
        }
      });
    }
  };

  //=== Data Structure handling/

  // Effect when data is updating form Query Api form Editabletable.
  useEffect(() => {
    if (allData) processDataFromGetQuery(allData);
    //eslint-disable-next-line
  }, [allData]);

  //Update state for added data.
  useEffect(() => {
    if (updateResponse) {
      queryData({
        variables: {
          method: "GET"
        }
      });
      toast.success("Record updated successfully.");
    }
    if (updateError) {
      toast.error("Unable to update.");
    }
    //eslint-disable-next-line
  }, [updateResponse, updateError]);

  //Get Person query handler
  useEffect(() => {
    if (getSourcePersonResponse?.EditableTable?.value) {
      const personData: SourcePerson[] = JSON.parse(getSourcePersonResponse?.EditableTable?.value);
      setSourcePersonData([...personData]);
    }
  }, [getSourcePersonResponse]);

  return (
    <KanbanViewContainer>
      <div className="header"></div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="filter-view-container">
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {provided => (
              <div className="col-lists" ref={provided.innerRef} {...provided.droppableProps}>
                {kanbanData
                  ? kanbanData.map((col, index) => (
                      <ColumnComponent
                        index={index}
                        {...col}
                        key={index}
                        addCompany={addCompany}
                        // innerNetworkData={leadListData?.LeadsListQuery.innetwork ?? []}
                        innerNetworkData={inNetworkData!}
                        columns={kanbanData}
                        handleStageChange={handleStageChange}
                        updateCard={postUpdate}
                        updateResponse={updateResponse}
                        sourcePersonsData={sourcePersonsData!}
                      />
                    ))
                  : [...new Array(6)].map((d, index) => <ColumnSkelton key={index} />)}
                <AddNewColButtonContainer>
                  <IconButton aria-label="delete" size="large">
                    <AddIcon fontSize="inherit" aria-describedby={id} onClick={handleClick} />
                  </IconButton>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left"
                    }}
                  >
                    <div style={{ padding: "10px" }}>
                      <TextField label={"Funnel Stage Name"} value={newColumn} onChange={event => setColumn(event.target.value)} name="label" variant="outlined" />
                    </div>
                    <div style={{ padding: "10px" }}>
                      <ColorPicker onChange={event => setColor(event.target.value)} value={newColor} name="color" />
                    </div>
                    <div style={{ padding: "10px" }}>
                      <Button variant="outlined" onClick={() => addColumn()}>
                        Add Column
                      </Button>
                    </div>
                  </Popover>
                </AddNewColButtonContainer>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </KanbanViewContainer>
  );
};

const ColumnSkelton = () => (
  <AddNewColButtonContainer
    style={{
      display: "block"
    }}
  >
    <Box
      sx={{
        height: 40,
        display: "flex",
        alignItems: "center",
        background: "#fff",
        marginBottom: "12px",
        width: "100%",
        padding: "5px",
        borderRadius: "5px 5px 0 0"
      }}
    >
      <Skeleton variant="rectangular" width={80} height={24} animation="pulse" sx={{ marginRight: "12px", borderRadius: "3px" }} />
      <Skeleton variant="rectangular" animation="wave" height={12} width={100} />
    </Box>
    {[...new Array(4)].map((d, index) => (
      <SkeltonCard key={index} />
    ))}
  </AddNewColButtonContainer>
);

const SkeltonCard = () => (
  <Box
    component={Card}
    elevation={1}
    sx={{
      width: "calc(100% - 10px)",
      margin: "8px auto",
      height: "max-content"
    }}
  >
    <Box
      height={30}
      sx={{
        display: "flex",
        alignItems: "center",
        background: "#fff",
        padding: "3px",
        borderBottom: "1px solid #e0e0e0"
      }}
    >
      <Skeleton
        variant="circular"
        width={24}
        height={24}
        animation="pulse"
        sx={{
          marginRight: "12px"
        }}
      />
      <Skeleton variant="rectangular" height={10} width={100} />
      <Skeleton
        variant="circular"
        width={22}
        height={22}
        animation="pulse"
        sx={{
          marginLeft: "auto"
        }}
      />
      <Skeleton
        variant="circular"
        width={22}
        height={22}
        animation="pulse"
        sx={{
          marginLeft: "10px"
        }}
      />
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "12px"
      }}
    >
      <Skeleton variant="rectangular" height={10} width={150} sx={{ marginBottom: "7px" }} />
      <Skeleton variant="rectangular" height={20} sx={{ marginBottom: "12px" }} />
      <Skeleton variant="rectangular" animation="wave" height={10} width={150} sx={{ marginBottom: "7px" }} />
      <Skeleton variant="rectangular" height={35} sx={{ marginBottom: "12px" }} />
      <Skeleton variant="rectangular" animation="wave" height={10} width={150} sx={{ marginBottom: "7px" }} />
      <Skeleton variant="rectangular" height={35} sx={{ marginBottom: "12px" }} />
    </Box>
  </Box>
);

const KanbanViewContainer = styled("div")`
  height: calc(100vh - 64px);
  .filter-view-container {
    width: 100%;
    height: calc(100vh - 64px);
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
  }

  .col-lists {
    height: calc(100vh - 64px);
    display: inline-flex;
    padding-top: 5px;
    align-items: flex-start;
    padding-bottom: 5px;
  }
`;

const AddNewColButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border: 1px solid #ececec;
  border-radius: 5px;
  background-color: hsl(210, 22%, 93%);
  max-width: 22%;
  min-width: 300px;
  height: 100%;
  overflow-y: auto;
`;

export default KanbanView;
