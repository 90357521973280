import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Box, Card, useTheme } from "@mui/material";
import { DataGridPro, GridCallbackDetails, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { debounce, isEmpty } from "lodash";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { toast } from "react-toastify";
import DataNotFound from "../../assets/Svgs/DataNotFound";
import { createCompaniesUserView, updateCompaniesUserView, updateCompanyFunnelStage } from "../../graphql/mutations";
import { getAllCompanies } from "../../graphql/queries";
import { emptyConditionCheck } from "../../helpers/DataGridHelpers";
import { CompaniesUserView, CompaniesUserViewInput, GetAllCompaniesListData, GetAllCompaniesListDataResponse, ViewsListItem } from "../../types";
import { buildFinalQuery } from "../Dashboard/components/utils";
import CustomColumnsMenu from "./CustomColumnsMenu";
import CustomGridToolBar from "./CustomGridToolBar";
import CustomPagination from "./CustomPagination";
import ModalDialogForTheCreateNewView from "./ModalDialogForNewView";
type requestDataParams = { offset?: number; limit?: number; filters?: GridFilterModel; sort?: GridSortModel };

interface CompaniesListTableProps {
  columns: GridColDef[];
  rows: GetAllCompaniesListData[];
  rowsCount: number;
  currentView: ViewsListItem;
  viewList: ViewsListItem[];
  manageOptionsClickHandler: (colDef: GridColDef) => void;
}
interface GetAllCompVariables {
  limit: number;
  offset: number;
  sort?: string;
  filters?: string;
}

const CompaniesListTable = (props: CompaniesListTableProps) => {
  const { columns, rows: rowsdata, rowsCount: countData, currentView, viewList, manageOptionsClickHandler } = props;
  /** MUI Theme */
  const theme = useTheme();

  //Queries
  /** Query for getting the companies */
  const [getCompanies, { loading }] = useLazyQuery<{ getAllCompanies: GetAllCompaniesListDataResponse }, GetAllCompVariables>(gql(getAllCompanies), {
    fetchPolicy: "network-only"
  });

  //MUTATIONS

  /** Mutation API for the create new view */
  const [createNewView, { loading: createNewViewLoading }] = useMutation<{ createCompaniesUserView: CompaniesUserView<string> }, { input: CompaniesUserViewInput }>(gql(createCompaniesUserView));
  /** Mutation API for the create new view */
  const [updateView] = useMutation<{ createCompaniesUserView: CompaniesUserView<string> }, { input: Partial<CompaniesUserViewInput> }>(gql(updateCompaniesUserView), { fetchPolicy: "network-only" });
  /** Update funnel stage to company api */
  const [updateFunnelStage, { loading: funnelStageUpdateLoading }] = useMutation<{ updateCompanyFunnelStage: GetAllCompaniesListData }, { input: { uuid: string; funnel_stage: string } }>(gql(updateCompanyFunnelStage));

  /** State for pagination modal */
  const [paginationModal, setPaginationModal] = React.useState<GridPaginationModel>({ page: 0, pageSize: 50 });
  /** State for the saving of the filter modal */
  const [filters, setFilters] = React.useState<GridFilterModel | null>(null);
  /** State for the sorting. */
  const [sorting, setSorting] = React.useState<GridSortModel | null>(null);

  /** state for the rows GridRowsModal */
  const [rows, setRows] = React.useState<GetAllCompaniesListData[]>(rowsdata);
  /** Total roes count for the saved view */
  const [rowsCount, setRowsCount] = React.useState(countData);

  // States for the add view
  const [addViewOpen, setAddViewOpen] = React.useState(false); /** Add new view modal boolean state */

  /** API ref object */
  const apiRef = useGridApiRef();

  /** Query data on the change */
  const requestData = React.useCallback(
    async (params?: requestDataParams) => {
      const limit = params?.limit;
      const offset = params?.offset;
      const filters = params?.filters;
      const sort = params?.sort;

      const getDataVars: GetAllCompVariables = {
        limit: limit ?? paginationModal.pageSize,
        offset: offset ?? 0
      };

      if (filters) getDataVars["filters"] = buildFinalQuery(filters, columns);
      if (sort) getDataVars["sort"] = JSON.stringify(sort);

      const { data } = await getCompanies({
        variables: getDataVars
      });
      const result = data?.getAllCompanies.result;
      const count = data?.getAllCompanies.count;
      if (result) setRows([...result]);
      // if (result) apiRef.current.setRows([...result]);
      if (count) setRowsCount(count);

      console.log("API is called::");
    },
    [columns, getCompanies, paginationModal.pageSize]
  );

  /** Filter Query with debounce */
  const debounceFilterQuery = debounce(async (filters: GridFilterModel) => {
    if (!emptyConditionCheck(filters)) {
      //If there is no valid filters available for data fetching
      toast.error("Please revisit the combination of filter options.");
      return;
    }

    const queryVars: requestDataParams = {};
    if (filters) queryVars["filters"] = filters;
    if (sorting) queryVars["sort"] = sorting;

    if (paginationModal) {
      const { pageSize } = paginationModal;
      queryVars.limit = pageSize;
    }

    /** request data from the server for this filter. */
    await requestData(queryVars);
    // reset the pagination modal
    if (paginationModal) {
      setPaginationModal(prev => ({ ...prev, page: 0 }));
    }
  }, 1000);

  /** Query sorting with debounce */
  const debounceSortingQuery = debounce(async (sort: GridSortModel) => {
    const { pageSize, page } = paginationModal;

    const vars: requestDataParams = {
      limit: pageSize,
      offset: pageSize * page,
      sort: sort
    };

    if (filters && filters.items.length > 0) vars.filters = filters;

    await requestData(vars);
  }, 1000); // Set the debounce delay to 500 milliseconds

  /** Pagination change handler */
  const handlePaginationChange = async (model: GridPaginationModel, details: GridCallbackDetails<any>) => {
    const { reason } = details;
    const { page, pageSize } = model;

    // const currentPage = paginationModal.page;
    // const currentPageSize = paginationModal.pageSize;

    // const offset = currentPageSize !== pageSize ? (currentPage + 1) * currentPageSize : page * pageSize;

    if (reason && reason === "setPaginationModel") {
      /** Embed params for query data on page change */
      const params: requestDataParams = { offset: page * pageSize, limit: pageSize };
      if (filters) params["filters"] = filters;
      if (sorting) params["sort"] = sorting;

      /** Query data then set the page change */
      await requestData(params);
      setPaginationModal(model);
    }
  };

  /** Filter modal change handler */
  const handleFilterModalChange = React.useCallback(
    async (filterModel: GridFilterModel) => {
      // Here you save the data you need from the filter model
      // As per the discussion with backend team for these two cases value should be the name of the operator itself.
      const items = filterModel?.items
        .map(item => {
          if (item.operator === "isNotEmpty" || item.operator === "isEmpty") return { ...item, value: item.operator };
          return item;
        })
        //Removing empty value object from thee items array. so filter will occur on valid object only
        .filter(item => !isEmpty(item.value));

      const _filters = { ...filterModel, items };

      debounceFilterQuery(_filters); // Debounce query on the filter
      setFilters(_filters); // Set the filter in the state.
    },
    [debounceFilterQuery]
  );

  /** sorting handler  */
  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      setSorting(sortModel); //Sort modal state update.
      debounceSortingQuery(sortModel); // Call the query with debounce.
    },
    [debounceSortingQuery]
  );

  /** Save new view handler */
  const handleSaveNewView = async (params: { name: string; description: string }) => {
    try {
      const currentState = apiRef?.current?.exportState();
      const variables: CompaniesUserViewInput = {
        name: params.name,
        description: params.description,
        viewType: "LIST",
        viewData: JSON.stringify({ density: "compact", state: currentState })
      };
      const response = await createNewView({ variables: { input: variables } });
      return response.data;
    } catch (err) {
      //@ts-ignore
      toast.error(err.message);
      return false;
    }
  };

  /** Update the view handler */
  const updateViewHandler = async (viewId: string, name: string) => {
    try {
      const currentState = apiRef?.current?.exportState();
      const variables: Partial<CompaniesUserViewInput> = {
        id: viewId,
        viewData: JSON.stringify({ density: "standard", state: currentState }),
        name
      };
      const response = await updateView({ variables: { input: variables } });
      return response.data;
    } catch (err) {
      //@ts-ignore
      toast.error(err.message);
      return false;
    }
  };

  /** Set all modal data to the local component state */

  const manageInitialState = () => {
    const state = currentView.viewData.state;
    const paginationModal = state.pagination?.paginationModel;
    const filterModal = state.filter?.filterModel;
    const sortingModal = state.sorting?.sortModel;
    unstable_batchedUpdates(() => {
      if (paginationModal && paginationModal.page && paginationModal.pageSize)
        setPaginationModal({
          page: paginationModal.page,
          pageSize: paginationModal.pageSize
        });
      if (filterModal) setFilters(filterModal);
      if (sortingModal) setSorting(sortingModal);
    });
  };

  //EditFunctions
  const processRowUpdate = async (newRow: GetAllCompaniesListData, oldRow: GetAllCompaniesListData) => {
    if (newRow.funnel_stage && oldRow.funnel_stage !== newRow.funnel_stage) {
      const updateRequest = await updateFunnelStage({ variables: { input: { uuid: newRow.uuid, funnel_stage: newRow.funnel_stage } } });
      return updateRequest.data?.updateCompanyFunnelStage ?? newRow;
    }
    return newRow;
  };

  React.useEffect(() => {
    manageInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box
        component={Card}
        elevation={1}
        
        sx={{
          height: "calc(100vh - 81px)",
          // width: isManageOptOpen ? "calc(100% - 200px)" : "100%",
          p: 1,
          "& .companies-list-header": {
            backgroundColor: theme.palette.grey["200"],
            color: theme.palette.primary["light"]
          }
        }}
      >
        <DataGridPro
          columns={columns}
          getRowId={row => row.uuid}
          loading={loading || funnelStageUpdateLoading}
          rowCount={rowsCount}
          rows={rows}
          density={currentView.viewData.density}
          //Pagination
          pagination
          paginationMode="server"
          pageSizeOptions={[50, 100, 200]}
          paginationModel={paginationModal}
          onPaginationModelChange={handlePaginationChange}
          //Filter
          filterMode="server"
          onFilterModelChange={handleFilterModalChange}
          filterDebounceMs={1000}
          //Sorting
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          slots={{
            noRowsOverlay: DataNotFound,
            toolbar: CustomGridToolBar,
            pagination: CustomPagination,
            columnMenu: CustomColumnsMenu
          }}
          slotProps={{
            noRowsOverlay: { className: "muiDataGridNotfound" },
            toolbar: { apiRef, setAddViewOpen, viewList, updateViewHandler },
            //@ts-ignore
            columnMenu: { manageOptionsClickHandler }
          }}
          // initialState={passedState?.viewData.state ?? loadGridInitialState()}
          initialState={currentView.viewData.state}
          apiRef={apiRef}
          //Table edit apis
          editMode="row"
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={error => {
            toast.error(error.message);
            // handleCancelClick()
          }}
        />
      </Box>

      <ModalDialogForTheCreateNewView addViewOpen={addViewOpen} setAddViewOpen={setAddViewOpen} saveHandler={handleSaveNewView} loading={createNewViewLoading} />
    </React.Fragment>
  );
};

export default CompaniesListTable;
