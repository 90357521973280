import { gql, useMutation } from "@apollo/client";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { LoadingButton } from "@mui/lab";
import { Box, Button, LinearProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { DataGridPro as DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { toast } from "react-toastify";
import { WorkSheet, read, utils } from "xlsx";
import { uploadCompanyData } from "../../graphql/mutations";
import { DataSources } from "./DataSourceManagement";
import { isEmpty } from "lodash";

interface FileUploadProps {
  dataSources: DataSources[];
}
type DataSet = { [index: string]: WorkSheet };
type Row = any[];
type RowCol = { rows: Row[]; columns: GridColDef[] };
// type dataSources = "pitchbook" | "harmonic";
// type BucketsList = { sourceName: string; bucketName: string };

const SIDEBAR_WIDTH = 250;
const ALLOWED_FILE_TYPE = ["text/csv"];

// "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
// Add other allowed MIME types as needed
const MAX_FILES_LIMIT = 1; // Set the maximum number of files you want to allow

// const BUCKET_LIST: BucketsList[] = [
//   { sourceName: "pitchbook", bucketName: "pb-data-in-new" },
//   { sourceName: "harmonic", bucketName: "" }
// ];

type AllSourceNames = DataSources[][number]["sourceName"];

const arrayify = (rows: any[]): Row[] => {
  return rows.map(row => {
    if (Array.isArray(row)) return row;
    var length = Object.keys(row).length;
    for (; length > 0; --length) if (row[length - 1] != null) break;
    return Array.from({ length, ...row });
  });
};

/* this method returns `rows` and `columns` data for sheet change */
const getRowsCols = (data: DataSet, sheetName: string): RowCol => {
  /** Sheet to json and check for empty row  */
  const rowsOfSheet = utils
    .sheet_to_json<Row>(data[sheetName], { header: 1 })
    .filter(r => !isEmpty(r));

  /** MUIDataGrid is object is return form the function. */
  return {
    rows: rowsOfSheet.map((r, id) => ({ ...r, id })),
    columns: Array.from(
      {
        length: utils.decode_range(data[sheetName]["!ref"] || "A1").e.c + 1
      },
      (_, i) => ({
        field: String(i),
        headerName: utils.encode_col(i),
        // editable: true,
        filterable: false,
        resizable: true,
        sortable: false
      })
    )
  };
};

const DragORUpload: React.FC<FileUploadProps> = ({ dataSources }) => {
  const theme = useTheme();
  const [files, setFiles] = useState<{ [x: string]: File }>({}); // array of files in case of multiple file upload
  const [dataSourceName, setDataSource] = useState<AllSourceNames>(""); // state for data source selector
  const [processing, setProcessing] = useState<boolean>(false); // processing loader.

  // States for data grid and preview
  const [rows, setRows] = useState<Row[]>([]); // data rows
  const [columns, setColumns] = useState<GridColDef[]>([]); // columns
  const [workBook, setWorkBook] = useState<DataSet>({} as DataSet); // workbook
  const [sheets, setSheets] = useState<string[]>([]); // list of sheet names
  const [currentSheet, setCurrent] = useState<string>(""); // selected sheet

  const [selectedFile, setSelectedFile] = useState(""); // selected current file name

  const [uploading, setUploading] = useState(false); // state for the uploading status.

  const [uploadFiles] = useMutation(gql(uploadCompanyData)); // mutation for uploading data to server as json

  /** Drag and drop handler */
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isEmpty(dataSourceName)) {
      toast.error("Select data source first.");
      return;
    }
    //@ts-ignore
    const newFiles = [...e.dataTransfer.files];
    processFiles(newFiles);
  };

  /** Upload to s3 handler */
  const handleUpload = React.useCallback(
    async (type: string, data: string) => {
      return await uploadFiles({
        variables: {
          input: {
            type,
            data
          }
        }
      });
    },
    [uploadFiles]
  );

  /** Change handler for input[file] */
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    const newFiles = [...e.target.files];
    processFiles(newFiles);
  };

  /** Process files and update state for preview */
  const processFiles = async (newFiles: File[]) => {
    setProcessing(true);
    // Filter out unsupported file types
    const filteredFiles = newFiles.filter(file => ALLOWED_FILE_TYPE.includes(file.type));

    // Limit the number of files
    const limitedFiles = filteredFiles.slice(0, MAX_FILES_LIMIT);

    if (filteredFiles.length === 0) {
      setProcessing(false);
      toast.info("Only .csv file is allowed to upload.");
      return;
    }

    const filesObject: { [x: string]: File } = {};

    limitedFiles.map(async file => {
      // put file in object to set into state.
      filesObject[file.name.replace(/\s/g, "_")] = file;
    });

    await handleAB(limitedFiles[0]);

    unstable_batchedUpdates(() => {
      setFiles(filesObject);
      setProcessing(false);
    });
  };

  /* this method handles refreshing the state with new workbook data */
  const handleAB = async (file: File): Promise<void> => {
    const fileData = await file.arrayBuffer();
    /* read file data */
    const data = read(fileData);

    const name = data.SheetNames[0];
    const { rows: new_rows, columns: new_columns } = getRowsCols(data.Sheets, name);

    if (new_rows.length > 1) {
      /**state update for worksheet process */
      unstable_batchedUpdates(() => {
        /* update workbook state */
        setWorkBook(data.Sheets);
        setSheets(data.SheetNames);

        /* select the first worksheet */

        setRows(new_rows);
        setColumns(new_columns);
        setCurrent(name);
        setSelectedFile(file.name.replace(/\s/g, "_"));
      });
    } else {
      setProcessing(false);
      throw toast.warn("Uploaded file is empty");
    }
  };

  /* called when sheet dropdown is changed */
  const selectSheet = async (event: SelectChangeEvent) => {
    const name = event.target.value;

    console.log(name);
    /* update workbook cache in case the current worksheet was changed */
    workBook[currentSheet] = utils.aoa_to_sheet(arrayify(rows));

    /* get data for desired sheet and update state */
    const { rows: new_rows, columns: new_columns } = getRowsCols(workBook, name);
    setRows(new_rows);
    setColumns(new_columns);
    setCurrent(name);
  };

  /** called when file is changed */
  const handleFileChange = async (event: SelectChangeEvent) => {
    event.preventDefault();
    const value = event.target.value;
    const selectedFile = files![value as string];
    await handleAB(selectedFile);
  };

  const processRowUpdate = React.useCallback(
    (rowNew: GridRowModel, rowOld: GridRowModel) => {
      for (var j = 0; j < columns.length; ++j) if (rowNew[j] != null) rows[rowNew.id][j] = isNaN(+rowNew[j]) ? rowNew[j] : +rowNew[j];
      setRows(rows);
      return rowNew;
    },
    [columns, rows]
  );

  /** Reset handler */
  const resetHandler = (event?: React.MouseEvent) => {
    event?.preventDefault();
    unstable_batchedUpdates(() => {
      setFiles({});
      setWorkBook({});
      setSelectedFile("");
      setCurrent("");
      setSheets([]);
      setWorkBook({});
      setColumns([]);
      setRows([]);
      setDataSource("");
    });
  };

  const handleFileUpload = async () => {
    setUploading(true);
    try {
      /* update current worksheet in case changes were made */
      workBook[currentSheet] = utils.aoa_to_sheet(arrayify(rows));

      /* construct workbook and loop through worksheets */
      const wb = utils.book_new();
      sheets.forEach(n => {
        utils.book_append_sheet(wb, workBook[n], n);
      });

      const fileJsonData = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);

      /* generate XLSX file bytes */
      // const data = write(wb, { bookType: "csv", type: "array" });

      /**  Creating File object for csv*/
      // const file = new File([data], selectedFile);

      /** call s3 upload for selected source. */
      const result = await handleUpload("pitchbook", JSON.stringify(fileJsonData));

      /** if have result then */
      // console.log("File uploaded successfully:", result.key);
      if (result.data.uploadCompanyData.success) toast.success(result.data.uploadCompanyData.message ?? "File uploaded successfully.");

      /** reset the UI for fresh upload */

      resetHandler();
    } catch (error) {
      console.error("Error uploading file:", error);
      //@ts-ignore
      toast.error("Error uploading file: " + error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Stack direction={"row"} spacing={2}>
      <Stack sx={{ width: SIDEBAR_WIDTH, paddingTop: 2 }} spacing={2}>
        {Boolean(!processing && Object.keys(files!).length === 0) ? (
          <FormControl size="small" fullWidth>
            {/* <Typography>Select data source.</Typography> */}
            <InputLabel id="data-source-selector">Select data source</InputLabel>
            <Select sx={{ textTransform: "capitalize" }} required labelId="data-source-selector" name="dataSource" id="data-source-select" value={dataSourceName} label="Data source" onChange={event => setDataSource(event.target.value)}>
              {dataSources.map(bucket => (
                <MenuItem sx={{ textTransform: "capitalize" }} key={bucket.bucketName} selected value={bucket.bucketName}>
                  {bucket.sourceName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {files && Object.keys(files).length > 0 ? (
          <FormControl size="small" fullWidth>
            {/* <Typography>Select files to view/edit</Typography> */}
            <InputLabel id="data-file-selector">Select file.</InputLabel>
            <Select labelId="data-file-selector" defaultValue={Object.keys(files)[0]} id="data-file-select" value={selectedFile} label="Select File" onChange={handleFileChange}>
              {Object.keys(files).map(key => {
                return (
                  <MenuItem key={`fileList-${key}`} value={key}>
                    {files[key].name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}

        {sheets.length > 1 ? (
          <FormControl size="small" fullWidth>
            {/* <Typography> Use the dropdown to switch to a worksheet:&nbsp;</Typography> */}
            <InputLabel id="data-sheet-selector">Select sheet</InputLabel>
            <Select onChange={selectSheet} defaultValue={currentSheet} value={currentSheet}>
              {sheets.map(sheet => (
                <MenuItem key={sheet} value={sheet}>
                  {sheet}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {sheets.length > 0 ? (
          <React.Fragment>
            <Box className="flex-cont">
              <b>Current Sheet: {currentSheet}</b>
            </Box>
            <LoadingButton sx={{ width: "100%" }} loading={uploading} size="small" variant="outlined" startIcon={<UploadFileIcon />} onClick={handleFileUpload}>
              Upload current file
            </LoadingButton>
            <Button sx={{ width: "100%" }} color="primary" variant="contained" disableElevation type="button" onClick={resetHandler}>
              Reset
            </Button>
          </React.Fragment>
        ) : null}
      </Stack>
      <Box sx={{ width: `calc(100% - ${SIDEBAR_WIDTH}px - ${2 * 8}px)`, paddingTop: 2 }}>
        {/* show file upload section */}
        {Boolean(!processing && Object.keys(files!).length === 0) ? (
          <Stack alignItems={"center"} justifyContent={"center"} sx={{ border: `2px dashed ${isEmpty(dataSourceName) ? "#ccc" : theme.palette.primary.main}`, borderRadius: 1, height: 350 }} className="drop-area" onDragOver={e => e.preventDefault()} onDrop={handleDrop}>
            <input type="file" accept={ALLOWED_FILE_TYPE.join(",")} style={{ display: "none" }} multiple={MAX_FILES_LIMIT > 1} max={MAX_FILES_LIMIT} disabled={isEmpty(dataSourceName)} id="file-upload-input" onChange={handleFileInputChange} />
            <Box component={"label"} htmlFor="file-upload-input" sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", cursor: "pointer" }}>
              {isEmpty(dataSourceName) ? <Typography sx={{ marginBottom: 2 }}>Select data source first. Then upload CSV</Typography> : null}
              <Button type="button" disabled={isEmpty(dataSourceName)} size="large" variant="contained" sx={{ zIndex: -1, cursor: "pointer" }} color="primary" disableElevation startIcon={<UploadFileIcon />}>
                Click to upload
              </Button>
            </Box>
          </Stack>
        ) : null}
        {/* Show progress bar */}
        {processing && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        {/* show json editor */}

        {sheets.length > 0 && (
          <Box
            sx={{
              width: "100%",
              [theme.breakpoints.up("xl")]: {
                height: 765
              },
              [theme.breakpoints.up("lg")]: {
                height: 550
              },
              [theme.breakpoints.up("md")]: {
                height: 430
              },
              [theme.breakpoints.up("sm")]: {
                height: 350
              },
              [theme.breakpoints.down("sm")]: {
                height: 300
              }
            }}
          >
            <DataGrid loading={uploading} columns={columns} rows={rows} processRowUpdate={processRowUpdate} pagination pageSizeOptions={[10, 20, 50, 100]} initialState={{ pagination: { paginationModel: { pageSize: rows.length > 10 ? 20 : 10 } } }} />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default DragORUpload;
