/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const greeting = /* GraphQL */ `
  query Greeting($name: String!) {
    Greeting(name: $name) {
      Greeting
      __typename
    }
  }
`;
export const companyPersonDataByUUIDQuery = /* GraphQL */ `
  query CompanyPersonDataByUUIDQuery($uuid: String) {
    CompanyPersonDataByUUIDQuery(uuid: $uuid) {
      result {
        company_uuid
        company_name
        company_url {
          linkedin_url
        }
        headcount
        founding_date
        company_data {
          website {
            url
            domain
            is_broken
          }
          logo_url
          name
          legal_name
          description
          customer_type
          founding_date {
            date
            granularity
          }
          headcount
          ownership_status
          company_type
          location {
            address_formatted
            location
            street
            city
            state
            zip
            country
          }
          contact {
            emails
            phone_numbers
          }
          socials {
            LINKEDIN {
              url
              follower_count
              username
              status
            }
            TWITTER {
              url
              follower_count
              username
              status
            }
            CRUNCHBASE {
              url
              follower_count
              username
              status
            }
            PITCHBOOK {
              url
              follower_count
              username
              status
            }
            FACEBOOK {
              url
              follower_count
              username
              status
            }
            ANGELLIST {
              url
              follower_count
              username
              status
            }
          }
          funding {
            funding_total
            num_funding_rounds
            investors {
              entity_urn
              name
            }
            last_funding_at
            last_funding_type
            last_funding_total
            funding_stage
          }
          name_aliases
          website_domain_aliases
          entity_urn
          id
          funding_attribute_null_status
          tags {
            display_value
            type
            date_added
            entity_urn
          }
          snapshots {
            website {
              url
              domain
              is_broken
            }
            logo_url
            name
            legal_name
            description
            customer_type
            founding_date {
              date
              granularity
            }
            headcount
            ownership_status
            company_type
            location {
              address_formatted
              location
              street
              city
              state
              zip
              country
            }
            contact {
              emails
              phone_numbers
            }
            socials {
              LINKEDIN {
                url
                follower_count
                username
                status
              }
              TWITTER {
                url
                follower_count
                username
                status
              }
              CRUNCHBASE {
                url
                follower_count
                username
                status
              }
              PITCHBOOK {
                url
                follower_count
                username
                status
              }
              FACEBOOK {
                url
                follower_count
                username
                status
              }
              ANGELLIST {
                url
                follower_count
                username
                status
              }
            }
            funding {
              funding_total
              num_funding_rounds
              investors {
                entity_urn
                name
              }
              last_funding_at
              last_funding_type
              last_funding_total
              funding_stage
            }
            name_aliases
            website_domain_aliases
            entity_urn
            initialized_date
            funding_attribute_null_status
          }
          initialized_date
          highlights {
            category
            date_added
            text
          }
          traction_metrics {
            facebook_like_count
            facebook_follower_count
            facebook_following_count
            linkedin_follower_count
            instagram_follower_count
            twitter_follower_count
            headcount
            funding_total
            headcount_advisor
            headcount_customer_success
            headcount_data
            headcount_design
            headcount_engineering
            headcount_finance
            headcount_legal
            headcount_marketing
            headcount_operations
            headcount_other
            headcount_people
            headcount_product
            headcount_sales
            headcount_support
          }
          updated_at
          people {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            person
            person_company_urn
          }
        }
        person_data {
          full_name
          first_name
          last_name
          profile_picture_url
          contact
          location {
            address_formatted
            location
            street
            city
            state
            zip
            country
          }
          education {
            school {
              name
              linkedin_url
              logo_url
              entity_urn
            }
            degree
            field
            grade
            start_date
            end_date
          }
          socials {
            LINKEDIN {
              url
              follower_count
              username
              status
            }
            TWITTER {
              url
              follower_count
              username
              status
            }
            CRUNCHBASE {
              url
              follower_count
              username
              status
            }
            PITCHBOOK {
              url
              follower_count
              username
              status
            }
            FACEBOOK {
              url
              follower_count
              username
              status
            }
            ANGELLIST {
              url
              follower_count
              username
              status
            }
          }
          experience {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            company
            company_name
          }
          unstandardized_experience__internal {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            company
          }
          websites
          linkedin_connections
          entity_urn
          id
          highlights {
            category
            date_added
            text
          }
          updated_at
          awards__beta
          recommendations__beta
          current_company_urns
        }
        dategenerated
        totalraised
        lastround
        lastrounddate
        investors
        interesting
        employeegrowth
        __typename
      }
      __typename
    }
  }
`;
export const companyPersonDataQuery = /* GraphQL */ `
  query CompanyPersonDataQuery($limit: Int, $offset: Int) {
    CompanyPersonDataQuery(limit: $limit, offset: $offset) {
      count
      result {
        company_uuid
        company_name
        company_url {
          linkedin_url
        }
        headcount
        founding_date
        company_data {
          website {
            url
            domain
            is_broken
          }
          logo_url
          name
          legal_name
          description
          customer_type
          founding_date {
            date
            granularity
          }
          headcount
          ownership_status
          company_type
          location {
            address_formatted
            location
            street
            city
            state
            zip
            country
          }
          contact {
            emails
            phone_numbers
          }
          socials {
            LINKEDIN {
              url
              follower_count
              username
              status
            }
            TWITTER {
              url
              follower_count
              username
              status
            }
            CRUNCHBASE {
              url
              follower_count
              username
              status
            }
            PITCHBOOK {
              url
              follower_count
              username
              status
            }
            FACEBOOK {
              url
              follower_count
              username
              status
            }
            ANGELLIST {
              url
              follower_count
              username
              status
            }
          }
          funding {
            funding_total
            num_funding_rounds
            investors {
              entity_urn
              name
            }
            last_funding_at
            last_funding_type
            last_funding_total
            funding_stage
          }
          name_aliases
          website_domain_aliases
          entity_urn
          id
          funding_attribute_null_status
          tags {
            display_value
            type
            date_added
            entity_urn
          }
          snapshots {
            website {
              url
              domain
              is_broken
            }
            logo_url
            name
            legal_name
            description
            customer_type
            founding_date {
              date
              granularity
            }
            headcount
            ownership_status
            company_type
            location {
              address_formatted
              location
              street
              city
              state
              zip
              country
            }
            contact {
              emails
              phone_numbers
            }
            socials {
              LINKEDIN {
                url
                follower_count
                username
                status
              }
              TWITTER {
                url
                follower_count
                username
                status
              }
              CRUNCHBASE {
                url
                follower_count
                username
                status
              }
              PITCHBOOK {
                url
                follower_count
                username
                status
              }
              FACEBOOK {
                url
                follower_count
                username
                status
              }
              ANGELLIST {
                url
                follower_count
                username
                status
              }
            }
            funding {
              funding_total
              num_funding_rounds
              investors {
                entity_urn
                name
              }
              last_funding_at
              last_funding_type
              last_funding_total
              funding_stage
            }
            name_aliases
            website_domain_aliases
            entity_urn
            initialized_date
            funding_attribute_null_status
          }
          initialized_date
          highlights {
            category
            date_added
            text
          }
          traction_metrics {
            facebook_like_count
            facebook_follower_count
            facebook_following_count
            linkedin_follower_count
            instagram_follower_count
            twitter_follower_count
            headcount
            funding_total
            headcount_advisor
            headcount_customer_success
            headcount_data
            headcount_design
            headcount_engineering
            headcount_finance
            headcount_legal
            headcount_marketing
            headcount_operations
            headcount_other
            headcount_people
            headcount_product
            headcount_sales
            headcount_support
          }
          updated_at
          people {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            person
            person_company_urn
          }
        }
        person_data {
          full_name
          first_name
          last_name
          profile_picture_url
          contact
          location {
            address_formatted
            location
            street
            city
            state
            zip
            country
          }
          education {
            school {
              name
              linkedin_url
              logo_url
              entity_urn
            }
            degree
            field
            grade
            start_date
            end_date
          }
          socials {
            LINKEDIN {
              url
              follower_count
              username
              status
            }
            TWITTER {
              url
              follower_count
              username
              status
            }
            CRUNCHBASE {
              url
              follower_count
              username
              status
            }
            PITCHBOOK {
              url
              follower_count
              username
              status
            }
            FACEBOOK {
              url
              follower_count
              username
              status
            }
            ANGELLIST {
              url
              follower_count
              username
              status
            }
          }
          experience {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            company
            company_name
          }
          unstandardized_experience__internal {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            company
          }
          websites
          linkedin_connections
          entity_urn
          id
          highlights {
            category
            date_added
            text
          }
          updated_at
          awards__beta
          recommendations__beta
          current_company_urns
        }
        dategenerated
        totalraised
        lastround
        lastrounddate
        investors
        interesting
        employeegrowth
        __typename
      }
      __typename
    }
  }
`;
export const processListQuery = /* GraphQL */ `
  query ProcessListQuery {
    processListQuery
  }
`;
export const companyDetailsQuery = /* GraphQL */ `
  query CompanyDetailsQuery($limit: Int, $offset: Int) {
    CompanyDetailsQuery(limit: $limit, offset: $offset) {
      count
      result
      __typename
    }
  }
`;
export const harmonicCompanyQuery = /* GraphQL */ `
  query HarmonicCompanyQuery($input: String) {
    HarmonicCompanyQuery(input: $input) {
      result
      __typename
    }
  }
`;
export const getCompanyHarmonicQuery = /* GraphQL */ `
  query GetCompanyHarmonicQuery($limit: Int, $offset: Int) {
    GetCompanyHarmonicQuery(limit: $limit, offset: $offset) {
      count
      result {
        uuid
        website_domain
        linkedin_url
        crunchbase_url
        pitchbook_url
        twitter_url
        facebook_url
        harmonic_uuid
        __typename
      }
      __typename
    }
  }
`;
export const portfolioListQuery = /* GraphQL */ `
  query PortfolioListQuery(
    $limit: Int
    $offset: Int
    $method: String
    $companyname: String
    $initialinvestment: String
    $cost: String
    $fmv: String
    $lastround: String
    $lastrounddate: String
    $coinvestors: String
    $ceo: String
    $companyurl: String
    $uuid: String
  ) {
    portfolioListQuery(
      limit: $limit
      offset: $offset
      method: $method
      companyname: $companyname
      initialinvestment: $initialinvestment
      cost: $cost
      fmv: $fmv
      lastround: $lastround
      lastrounddate: $lastrounddate
      coinvestors: $coinvestors
      ceo: $ceo
      companyurl: $companyurl
      uuid: $uuid
    ) {
      result {
        companyname
        initialinvestment
        cost
        fmv
        lastround
        lastrounddate
        coinvestors {
          investor
          dealid
        }
        ceo {
          name
          linkedinUrl
        }
        companyurl
        uuid
        __typename
      }
      count
      message
      __typename
    }
  }
`;
export const weeklyLeadsListQuery = /* GraphQL */ `
  query WeeklyLeadsListQuery(
    $limit: Int
    $offset: Int
    $sort: AWSJSON
    $filters: AWSJSON
  ) {
    weeklyLeadsListQuery(
      limit: $limit
      offset: $offset
      sort: $sort
      filters: $filters
    ) {
      result {
        uuid
        totalfunding
        departmentcount
        totalwebgrowthpercentile
        totalwebgrowthpercentileewm
        cihcgpercentile
        totalreviewpercentile
        namefinal
        leaddate
        uniqueid
        websitefinal
        seriesmerged
        interesting
        comment
        socials {
          LINKEDIN {
            url
            follower_count
            username
            status
          }
          TWITTER {
            url
            follower_count
            username
            status
          }
          CRUNCHBASE {
            url
            follower_count
            username
            status
          }
          PITCHBOOK {
            url
            follower_count
            username
            status
          }
          FACEBOOK {
            url
            follower_count
            username
            status
          }
          ANGELLIST {
            url
            follower_count
            username
            status
          }
          __typename
        }
        lastVisitedAt
        __typename
      }
      count
      __typename
    }
  }
`;
export const personUUIDQuery = /* GraphQL */ `
  query PersonUUIDQuery($LinkedInUUID: String) {
    PersonUUIDQuery(LinkedInUUID: $LinkedInUUID) {
      employeeuuid
      backgroundstrength
      ed {
        schooldegree
        edstrength
        endepoch
        imagelink
        school
        schoolintuuid
        schoollink
        startepoch
        enddate
        startdate
        subject
        __typename
      }
      experience
      fulltext
      personname
      liheadline
      linkedinurl
      profilelocation
      profilepicture
      salesnavlink
      shortdescription
      skills {
        skill
        __typename
      }
      volunteer {
        cause
        company
        companylink
        companylinksales
        description
        duration
        enddate
        endepoch
        imagelink
        volunteerrole
        startdate
        startepoch
        companyintuuid
        __typename
      }
      interests {
        followercount
        grouplink
        imagelink
        interestname
        __typename
      }
      jobs {
        company
        companylink
        companylinksales
        department
        description
        duration
        enddate
        endepoch
        imagelink
        location
        rank
        companyrole
        rolestrength
        seniority
        startdate
        startepoch
        companyintuuid
        iscurrent
        __typename
      }
      awards {
        award
        description1
        description2
        __typename
      }
      certifications {
        certificate
        certificateend
        certificateendepoch
        certificatestart
        certificatestartepoch
        issuer
        issuerlink
        issuerlogo
        link
        issuerintuuid
        __typename
      }
      languages {
        profilelanguage
        proficiency
        __typename
      }
      patents {
        description
        filingdate
        filingdateepoch
        patentlink
        patentnum
        title
        __typename
      }
      projects {
        description
        projectend
        projectendepoch
        projectlink
        projectstart
        projectstartepoch
        projecttitle
        __typename
      }
      publications {
        description
        publicationdate
        publicationdateepoch
        publicationlink
        publisher
        title
        __typename
      }
      __typename
    }
  }
`;
export const allEmployeeQuery = /* GraphQL */ `
  query AllEmployeeQuery(
    $CompanyUUID: String
    $Seniority: String
    $Limit: Int
    $Offset: Int
    $onlyCurrent: Boolean
  ) {
    AllEmployeeQuery(
      CompanyUUID: $CompanyUUID
      Seniority: $Seniority
      Limit: $Limit
      Offset: $Offset
      onlyCurrent: $onlyCurrent
    ) {
      count
      result {
        employeeuuid
        backgroundstrength
        personname
        liheadline
        linkedinurl
        profilelocation
        profilepicture
        seniority
        department
        enddate
        endepoch
        companyrole
        imagelink
        startdate
        startepoch
        iscurrent
        __typename
      }
      __typename
    }
  }
`;
export const companyUUIDQuery = /* GraphQL */ `
  query CompanyUUIDQuery($CompanyUUID: String) {
    CompanyUUIDQuery(CompanyUUID: $CompanyUUID) {
      uuid
      websitefinal
      hashkey
      seriesmerged
      namefinal
      cburl
      pburl
      cid
      facebook
      twitter
      listringuuid
      liintuuid
      totalfunding
      acquisitionstatus
      finaldescription
      locationfinal
      linkedinfinal
      trafficgrowth6
      trafficgrowth3
      trafficpercentile6
      trafficpercentile3
      facebooklikes
      facebookfollows
      twitterfollows
      twitterfollowers
      capscore
      g2score
      reviewgrowthpercentile6
      reviewgrowthpercentile3
      semtraffic
      ahstraffic
      swtraffic
      tptotalreviewcount
      tptotalreviewscore
      tpreviewscount3
      tpreviewsscore3
      tpreviewscount6
      tpreviewsscore6
      g2totalreviewcount
      g2totalreviewscore
      g2reviewscount3
      g2reviewsscore3
      g2reviewscount6
      g2reviewsscore6
      captotalreviewcount
      captotalreviewscore
      capreviewscount3
      capreviewsscore3
      capreviewscount6
      capreviewsscore6
      g2growthpercentile6
      capgrowthpercentile6
      tpgrowthpercentile6
      g2growthpercentile3
      capgrowthpercentile3
      tpgrowthpercentile3
      scorecardsa {
        scorecardid
        companyuuid
        author
        companyurl
        meetingdate
        completedate
        advorpass
        reasonpass
        reasonadv
        adadr
        passadr
        dealsourceli
        dealsourcef
        sourcedby
        dealstamp
        completionstamp
        __typename
      }
      scorecardsb {
        scorecardid
        companyuuid
        author
        companyurl
        meetingdate
        completedate
        deallead
        founderstr
        founderjust
        teamstr
        teamjust
        marketstr
        marketjust
        productstr
        productjust
        bmstr
        bmjust
        dealstr
        dealjust
        toptierprob
        toptierprob2
        moveic
        noreason
        ddreason
        yesreason
        dealstamp
        completionstamp
        __typename
      }
      scorecardsc {
        scorecardid
        companyuuid
        author
        companyurl
        meetingdate
        completedate
        deallead
        f1
        f2
        f3
        f4
        f5
        f6
        f7
        f8
        f9
        founderexp
        founderstr
        t1
        t2
        t3
        t4
        t5
        teamexp
        teamstr
        m1
        m2
        m3
        m4
        m5
        marketexp
        marketstr
        btype
        b2bp1
        b2bp2
        b2bp3
        b2bp4
        b2bp5
        b2bp6
        b2bp7
        b2bp8
        b2bprodexp
        b2bprodstr
        b2cp1
        b2cp2
        b2cp3
        b2cp4
        b2cp5
        b2cp6
        b2cprodexp
        b2cprodstr
        b2bbm1
        b2bbm2
        b2bbm3
        b2bbm4
        b2bbm5
        b2bbm6
        b2bbm7
        b2bbm8
        b2bbm9
        b2bbm10
        b2bbm11
        b2bbmexp
        b2bbmstr
        b2cbm1
        b2cbm2
        b2cbm3
        b2cbm4
        b2cbm5
        b2cbm6
        b2cbm7
        b2cbm8
        b2cbm9
        b2cbmexp
        b2cbmstr
        p1
        p2
        p3
        p4
        p5
        p6
        pexp
        pstr
        redflag
        icdecile
        betsize
        toptierprob
        toptierprob2
        exit10x
        dealstamp
        completionstamp
        monkey
        monkeyexp
        __typename
      }
      scorecardspartner {
        scorecardid
        companyuuid
        author
        companyurl
        meetingdate
        completedate
        deallead
        f1
        f2
        f3
        f4
        f5
        f6
        f7
        f8
        f9
        founderexp
        founderstr
        t1
        t2
        t3
        t4
        t5
        teamexp
        teamstr
        m1
        m2
        m3
        m4
        m5
        marketexp
        marketstr
        btype
        b2bp1
        b2bp2
        b2bp3
        b2bp4
        b2bp5
        b2bp6
        b2bp7
        b2bp8
        b2bprodexp
        b2bprodstr
        b2cp1
        b2cp2
        b2cp3
        b2cp4
        b2cp5
        b2cp6
        b2cprodexp
        b2cprodstr
        b2bbm1
        b2bbm2
        b2bbm3
        b2bbm4
        b2bbm5
        b2bbm6
        b2bbm7
        b2bbm8
        b2bbm9
        b2bbm10
        b2bbm11
        b2bbmexp
        b2bbmstr
        b2cbm1
        b2cbm2
        b2cbm3
        b2cbm4
        b2cbm5
        b2cbm6
        b2cbm7
        b2cbm8
        b2cbm9
        b2cbmexp
        b2cbmstr
        p1
        p2
        p3
        p4
        p5
        p6
        pexp
        pstr
        redflag
        icdecile
        betsize
        toptierprob
        toptierprob2
        exit10x
        dealstamp
        completionstamp
        monkey
        monkeyexp
        __typename
      }
      verticals {
        vertical
        companyuuid
        __typename
      }
      headcountgrowth {
        companyuuid
        industryname
        departmentcount
        departmentgrowth12
        departmentgrowth6
        departmentgrowth3
        departmentgrowthpercentile12
        departmentgrowthpercentile6
        departmentgrowthpercentile3
        headcountdata
        __typename
      }
      fundingroundspb {
        dealdate
        pbcid
        dealdateepoch
        dealid
        dealsize
        dealtype
        investors
        leadinvestors
        postmoneyvaluation
        series
        __typename
      }
      scorecards {
        websitefinal
        feedbackfor
        dateofmeeting
        meetingcontext
        whichrenegades
        whofromcompany
        team
        teamstrength
        teamrationale
        market
        marketstrength
        marketrationale
        product
        productstrength
        productrationale
        businessmodel
        businessmodelstrength
        businessmodelrationale
        potentialdeal
        potentialdealstrength
        potentialdealrationale
        feedbacktopics
        moveforward
        rationaleforward
        toptierprob
        nextsteps
        author
        __typename
      }
      leadinvestors {
        investor
        dealid
        __typename
      }
      investors {
        investor
        dealid
        __typename
      }
      webev3m
      webev6m
      webev12m
      evolutionpercentile3
      evolutionpercentile6
      evolutionpercentile12
      medianjobopeningtime
      jobcountyear
      opencount
      jobopeningpercentile
      webgrowthpercentile3
      webgrowthpercentile6
      codadocs {
        doctitle
        codaurl
        __typename
      }
      googledocs {
        driveid
        foldertitle
        __typename
      }
      googlesheets {
        sheetid
        sheettitle
        __typename
      }
      cihcg
      cihcgpercentile
      growthpercentile12evm
      growthpercentile6evm
      growthpercentile3evm
      datetime
      headcount
      founding_date
      company_data {
        website {
          url
          domain
          is_broken
        }
        logo_url
        name
        legal_name
        description
        customer_type
        founding_date {
          date
          granularity
        }
        headcount
        ownership_status
        company_type
        location {
          address_formatted
          location
          street
          city
          state
          zip
          country
        }
        contact {
          emails
          phone_numbers
        }
        socials {
          LINKEDIN {
            url
            follower_count
            username
            status
          }
          TWITTER {
            url
            follower_count
            username
            status
          }
          CRUNCHBASE {
            url
            follower_count
            username
            status
          }
          PITCHBOOK {
            url
            follower_count
            username
            status
          }
          FACEBOOK {
            url
            follower_count
            username
            status
          }
          ANGELLIST {
            url
            follower_count
            username
            status
          }
        }
        funding {
          funding_total
          num_funding_rounds
          investors {
            entity_urn
            name
          }
          last_funding_at
          last_funding_type
          last_funding_total
          funding_stage
        }
        name_aliases
        website_domain_aliases
        entity_urn
        id
        funding_attribute_null_status
        tags {
          display_value
          type
          date_added
          entity_urn
        }
        snapshots {
          website {
            url
            domain
            is_broken
          }
          logo_url
          name
          legal_name
          description
          customer_type
          founding_date {
            date
            granularity
          }
          headcount
          ownership_status
          company_type
          location {
            address_formatted
            location
            street
            city
            state
            zip
            country
          }
          contact {
            emails
            phone_numbers
          }
          socials {
            LINKEDIN {
              url
              follower_count
              username
              status
            }
            TWITTER {
              url
              follower_count
              username
              status
            }
            CRUNCHBASE {
              url
              follower_count
              username
              status
            }
            PITCHBOOK {
              url
              follower_count
              username
              status
            }
            FACEBOOK {
              url
              follower_count
              username
              status
            }
            ANGELLIST {
              url
              follower_count
              username
              status
            }
          }
          funding {
            funding_total
            num_funding_rounds
            investors {
              entity_urn
              name
            }
            last_funding_at
            last_funding_type
            last_funding_total
            funding_stage
          }
          name_aliases
          website_domain_aliases
          entity_urn
          initialized_date
          funding_attribute_null_status
        }
        initialized_date
        highlights {
          category
          date_added
          text
        }
        traction_metrics {
          facebook_like_count
          facebook_follower_count
          facebook_following_count
          linkedin_follower_count
          instagram_follower_count
          twitter_follower_count
          headcount
          funding_total
          headcount_advisor
          headcount_customer_success
          headcount_data
          headcount_design
          headcount_engineering
          headcount_finance
          headcount_legal
          headcount_marketing
          headcount_operations
          headcount_other
          headcount_people
          headcount_product
          headcount_sales
          headcount_support
        }
        updated_at
        people {
          contact
          title
          department
          description
          start_date
          end_date
          is_current_position
          location
          role_type
          person
          person_company_urn
        }
        __typename
      }
      __typename
    }
  }
`;
export const companySearch = /* GraphQL */ `
  query CompanySearch(
    $NameContains: String
    $InvestorName: [String]
    $LeadInvestorName: [String]
    $Verticals: [String]
    $NextSeries: String
    $Offset: Int
  ) {
    CompanySearch(
      NameContains: $NameContains
      InvestorName: $InvestorName
      LeadInvestorName: $LeadInvestorName
      Verticals: $Verticals
      NextSeries: $NextSeries
      Offset: $Offset
    ) {
      company {
        uuid
        websitefinal
        hashkey
        seriesmerged
        namefinal
        cburl
        pburl
        cid
        facebook
        twitter
        listringuuid
        liintuuid
        totalfunding
        acquisitionstatus
        finaldescription
        locationfinal
        linkedinfinal
        trafficgrowth6
        trafficgrowth3
        trafficpercentile6
        trafficpercentile3
        facebooklikes
        facebookfollows
        twitterfollows
        twitterfollowers
        capscore
        g2score
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        semtraffic
        ahstraffic
        swtraffic
        tptotalreviewcount
        tptotalreviewscore
        tpreviewscount3
        tpreviewsscore3
        tpreviewscount6
        tpreviewsscore6
        g2totalreviewcount
        g2totalreviewscore
        g2reviewscount3
        g2reviewsscore3
        g2reviewscount6
        g2reviewsscore6
        captotalreviewcount
        captotalreviewscore
        capreviewscount3
        capreviewsscore3
        capreviewscount6
        capreviewsscore6
        g2growthpercentile6
        capgrowthpercentile6
        tpgrowthpercentile6
        g2growthpercentile3
        capgrowthpercentile3
        tpgrowthpercentile3
        scorecardsa {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          advorpass
          reasonpass
          reasonadv
          adadr
          passadr
          dealsourceli
          dealsourcef
          sourcedby
          dealstamp
          completionstamp
        }
        scorecardsb {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          founderstr
          founderjust
          teamstr
          teamjust
          marketstr
          marketjust
          productstr
          productjust
          bmstr
          bmjust
          dealstr
          dealjust
          toptierprob
          toptierprob2
          moveic
          noreason
          ddreason
          yesreason
          dealstamp
          completionstamp
        }
        scorecardsc {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        scorecardspartner {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        verticals {
          vertical
          companyuuid
        }
        headcountgrowth {
          companyuuid
          industryname
          departmentcount
          departmentgrowth12
          departmentgrowth6
          departmentgrowth3
          departmentgrowthpercentile12
          departmentgrowthpercentile6
          departmentgrowthpercentile3
          headcountdata
        }
        fundingroundspb {
          dealdate
          pbcid
          dealdateepoch
          dealid
          dealsize
          dealtype
          investors
          leadinvestors
          postmoneyvaluation
          series
        }
        scorecards {
          websitefinal
          feedbackfor
          dateofmeeting
          meetingcontext
          whichrenegades
          whofromcompany
          team
          teamstrength
          teamrationale
          market
          marketstrength
          marketrationale
          product
          productstrength
          productrationale
          businessmodel
          businessmodelstrength
          businessmodelrationale
          potentialdeal
          potentialdealstrength
          potentialdealrationale
          feedbacktopics
          moveforward
          rationaleforward
          toptierprob
          nextsteps
          author
        }
        leadinvestors {
          investor
          dealid
        }
        investors {
          investor
          dealid
        }
        webev3m
        webev6m
        webev12m
        evolutionpercentile3
        evolutionpercentile6
        evolutionpercentile12
        medianjobopeningtime
        jobcountyear
        opencount
        jobopeningpercentile
        webgrowthpercentile3
        webgrowthpercentile6
        codadocs {
          doctitle
          codaurl
        }
        googledocs {
          driveid
          foldertitle
        }
        googlesheets {
          sheetid
          sheettitle
        }
        cihcg
        cihcgpercentile
        growthpercentile12evm
        growthpercentile6evm
        growthpercentile3evm
        datetime
        headcount
        founding_date
        company_data {
          website {
            url
            domain
            is_broken
          }
          logo_url
          name
          legal_name
          description
          customer_type
          founding_date {
            date
            granularity
          }
          headcount
          ownership_status
          company_type
          location {
            address_formatted
            location
            street
            city
            state
            zip
            country
          }
          contact {
            emails
            phone_numbers
          }
          socials {
            LINKEDIN {
              url
              follower_count
              username
              status
            }
            TWITTER {
              url
              follower_count
              username
              status
            }
            CRUNCHBASE {
              url
              follower_count
              username
              status
            }
            PITCHBOOK {
              url
              follower_count
              username
              status
            }
            FACEBOOK {
              url
              follower_count
              username
              status
            }
            ANGELLIST {
              url
              follower_count
              username
              status
            }
          }
          funding {
            funding_total
            num_funding_rounds
            investors {
              entity_urn
              name
            }
            last_funding_at
            last_funding_type
            last_funding_total
            funding_stage
          }
          name_aliases
          website_domain_aliases
          entity_urn
          id
          funding_attribute_null_status
          tags {
            display_value
            type
            date_added
            entity_urn
          }
          snapshots {
            website {
              url
              domain
              is_broken
            }
            logo_url
            name
            legal_name
            description
            customer_type
            founding_date {
              date
              granularity
            }
            headcount
            ownership_status
            company_type
            location {
              address_formatted
              location
              street
              city
              state
              zip
              country
            }
            contact {
              emails
              phone_numbers
            }
            socials {
              LINKEDIN {
                url
                follower_count
                username
                status
              }
              TWITTER {
                url
                follower_count
                username
                status
              }
              CRUNCHBASE {
                url
                follower_count
                username
                status
              }
              PITCHBOOK {
                url
                follower_count
                username
                status
              }
              FACEBOOK {
                url
                follower_count
                username
                status
              }
              ANGELLIST {
                url
                follower_count
                username
                status
              }
            }
            funding {
              funding_total
              num_funding_rounds
              investors {
                entity_urn
                name
              }
              last_funding_at
              last_funding_type
              last_funding_total
              funding_stage
            }
            name_aliases
            website_domain_aliases
            entity_urn
            initialized_date
            funding_attribute_null_status
          }
          initialized_date
          highlights {
            category
            date_added
            text
          }
          traction_metrics {
            facebook_like_count
            facebook_follower_count
            facebook_following_count
            linkedin_follower_count
            instagram_follower_count
            twitter_follower_count
            headcount
            funding_total
            headcount_advisor
            headcount_customer_success
            headcount_data
            headcount_design
            headcount_engineering
            headcount_finance
            headcount_legal
            headcount_marketing
            headcount_operations
            headcount_other
            headcount_people
            headcount_product
            headcount_sales
            headcount_support
          }
          updated_at
          people {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            person
            person_company_urn
          }
        }
        __typename
      }
      count {
        count
        __typename
      }
      __typename
    }
  }
`;
export const growthDataQuery = /* GraphQL */ `
  query GrowthDataQuery(
    $CompanyUUID: String
    $QueryType: String
    $Offset: Int
  ) {
    GrowthDataQuery(
      CompanyUUID: $CompanyUUID
      QueryType: $QueryType
      Offset: $Offset
    ) {
      pages {
        category
        __typename
      }
      vendor {
        partnerid
        firstepoch
        firstdate
        lastepoch
        lastdate
        url
        companyuuid
        counterpartyurl
        companyname
        ticker
        sourceurl
        partnershiptype
        __typename
      }
      partner {
        partnerid
        firstepoch
        firstdate
        lastepoch
        lastdate
        url
        companyuuid
        counterpartyurl
        companyname
        ticker
        sourceurl
        partnershiptype
        __typename
      }
      integration {
        partnerid
        firstepoch
        firstdate
        lastepoch
        lastdate
        url
        companyuuid
        counterpartyurl
        companyname
        ticker
        sourceurl
        partnershiptype
        __typename
      }
      __typename
    }
  }
`;
export const investorSearch = /* GraphQL */ `
  query InvestorSearch($InvestorName: String) {
    InvestorSearch(InvestorName: $InvestorName) {
      investorNames {
        investor
        __typename
      }
      __typename
    }
  }
`;
export const leadsListQuery = /* GraphQL */ `
  query LeadsListQuery {
    LeadsListQuery {
      innetwork {
        investor
        leadDate
        uuid
        websitefinal
        hashkey
        seriesmerged
        namefinal
        totalfunding
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        webgrowthpercentile3
        webgrowthpercentile6
        finaldescription
        cihcg
        cihcgpercentile
        cihcgtype
        profilepicture
        personname
        liheadline
        employeeid
        __typename
      }
      outnetwork {
        uuid
        websitefinal
        hashkey
        seriesmerged
        namefinal
        cburl
        pburl
        cid
        facebook
        twitter
        listringuuid
        liintuuid
        totalfunding
        acquisitionstatus
        finaldescription
        locationfinal
        linkedinfinal
        trafficgrowth6
        trafficgrowth3
        trafficpercentile6
        trafficpercentile3
        facebooklikes
        facebookfollows
        twitterfollows
        twitterfollowers
        capscore
        g2score
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        semtraffic
        ahstraffic
        swtraffic
        tptotalreviewcount
        tptotalreviewscore
        tpreviewscount3
        tpreviewsscore3
        tpreviewscount6
        tpreviewsscore6
        g2totalreviewcount
        g2totalreviewscore
        g2reviewscount3
        g2reviewsscore3
        g2reviewscount6
        g2reviewsscore6
        captotalreviewcount
        captotalreviewscore
        capreviewscount3
        capreviewsscore3
        capreviewscount6
        capreviewsscore6
        g2growthpercentile6
        capgrowthpercentile6
        tpgrowthpercentile6
        g2growthpercentile3
        capgrowthpercentile3
        tpgrowthpercentile3
        scorecardsa {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          advorpass
          reasonpass
          reasonadv
          adadr
          passadr
          dealsourceli
          dealsourcef
          sourcedby
          dealstamp
          completionstamp
        }
        scorecardsb {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          founderstr
          founderjust
          teamstr
          teamjust
          marketstr
          marketjust
          productstr
          productjust
          bmstr
          bmjust
          dealstr
          dealjust
          toptierprob
          toptierprob2
          moveic
          noreason
          ddreason
          yesreason
          dealstamp
          completionstamp
        }
        scorecardsc {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        scorecardspartner {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        verticals {
          vertical
          companyuuid
        }
        headcountgrowth {
          companyuuid
          industryname
          departmentcount
          departmentgrowth12
          departmentgrowth6
          departmentgrowth3
          departmentgrowthpercentile12
          departmentgrowthpercentile6
          departmentgrowthpercentile3
          headcountdata
        }
        fundingroundspb {
          dealdate
          pbcid
          dealdateepoch
          dealid
          dealsize
          dealtype
          investors
          leadinvestors
          postmoneyvaluation
          series
        }
        scorecards {
          websitefinal
          feedbackfor
          dateofmeeting
          meetingcontext
          whichrenegades
          whofromcompany
          team
          teamstrength
          teamrationale
          market
          marketstrength
          marketrationale
          product
          productstrength
          productrationale
          businessmodel
          businessmodelstrength
          businessmodelrationale
          potentialdeal
          potentialdealstrength
          potentialdealrationale
          feedbacktopics
          moveforward
          rationaleforward
          toptierprob
          nextsteps
          author
        }
        leadinvestors {
          investor
          dealid
        }
        investors {
          investor
          dealid
        }
        webev3m
        webev6m
        webev12m
        evolutionpercentile3
        evolutionpercentile6
        evolutionpercentile12
        medianjobopeningtime
        jobcountyear
        opencount
        jobopeningpercentile
        webgrowthpercentile3
        webgrowthpercentile6
        codadocs {
          doctitle
          codaurl
        }
        googledocs {
          driveid
          foldertitle
        }
        googlesheets {
          sheetid
          sheettitle
        }
        cihcg
        cihcgpercentile
        growthpercentile12evm
        growthpercentile6evm
        growthpercentile3evm
        datetime
        headcount
        founding_date
        company_data {
          website {
            url
            domain
            is_broken
          }
          logo_url
          name
          legal_name
          description
          customer_type
          founding_date {
            date
            granularity
          }
          headcount
          ownership_status
          company_type
          location {
            address_formatted
            location
            street
            city
            state
            zip
            country
          }
          contact {
            emails
            phone_numbers
          }
          socials {
            LINKEDIN {
              url
              follower_count
              username
              status
            }
            TWITTER {
              url
              follower_count
              username
              status
            }
            CRUNCHBASE {
              url
              follower_count
              username
              status
            }
            PITCHBOOK {
              url
              follower_count
              username
              status
            }
            FACEBOOK {
              url
              follower_count
              username
              status
            }
            ANGELLIST {
              url
              follower_count
              username
              status
            }
          }
          funding {
            funding_total
            num_funding_rounds
            investors {
              entity_urn
              name
            }
            last_funding_at
            last_funding_type
            last_funding_total
            funding_stage
          }
          name_aliases
          website_domain_aliases
          entity_urn
          id
          funding_attribute_null_status
          tags {
            display_value
            type
            date_added
            entity_urn
          }
          snapshots {
            website {
              url
              domain
              is_broken
            }
            logo_url
            name
            legal_name
            description
            customer_type
            founding_date {
              date
              granularity
            }
            headcount
            ownership_status
            company_type
            location {
              address_formatted
              location
              street
              city
              state
              zip
              country
            }
            contact {
              emails
              phone_numbers
            }
            socials {
              LINKEDIN {
                url
                follower_count
                username
                status
              }
              TWITTER {
                url
                follower_count
                username
                status
              }
              CRUNCHBASE {
                url
                follower_count
                username
                status
              }
              PITCHBOOK {
                url
                follower_count
                username
                status
              }
              FACEBOOK {
                url
                follower_count
                username
                status
              }
              ANGELLIST {
                url
                follower_count
                username
                status
              }
            }
            funding {
              funding_total
              num_funding_rounds
              investors {
                entity_urn
                name
              }
              last_funding_at
              last_funding_type
              last_funding_total
              funding_stage
            }
            name_aliases
            website_domain_aliases
            entity_urn
            initialized_date
            funding_attribute_null_status
          }
          initialized_date
          highlights {
            category
            date_added
            text
          }
          traction_metrics {
            facebook_like_count
            facebook_follower_count
            facebook_following_count
            linkedin_follower_count
            instagram_follower_count
            twitter_follower_count
            headcount
            funding_total
            headcount_advisor
            headcount_customer_success
            headcount_data
            headcount_design
            headcount_engineering
            headcount_finance
            headcount_legal
            headcount_marketing
            headcount_operations
            headcount_other
            headcount_people
            headcount_product
            headcount_sales
            headcount_support
          }
          updated_at
          people {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            person
            person_company_urn
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const companyFilter = /* GraphQL */ `
  query CompanyFilter(
    $condition: String
    $isEmptyFilter: [String]
    $isNotEmptyFilter: [String]
    $containFilter: filterData
    $notContainFilter: filterData
  ) {
    CompanyFilter(
      condition: $condition
      isEmptyFilter: $isEmptyFilter
      isNotEmptyFilter: $isNotEmptyFilter
      containFilter: $containFilter
      notContainFilter: $notContainFilter
    ) {
      innetwork {
        investor
        leadDate
        uuid
        websitefinal
        hashkey
        seriesmerged
        namefinal
        totalfunding
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        webgrowthpercentile3
        webgrowthpercentile6
        finaldescription
        cihcg
        cihcgpercentile
        cihcgtype
        profilepicture
        personname
        liheadline
        employeeid
        __typename
      }
      outnetwork {
        uuid
        websitefinal
        hashkey
        seriesmerged
        namefinal
        cburl
        pburl
        cid
        facebook
        twitter
        listringuuid
        liintuuid
        totalfunding
        acquisitionstatus
        finaldescription
        locationfinal
        linkedinfinal
        trafficgrowth6
        trafficgrowth3
        trafficpercentile6
        trafficpercentile3
        facebooklikes
        facebookfollows
        twitterfollows
        twitterfollowers
        capscore
        g2score
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        semtraffic
        ahstraffic
        swtraffic
        tptotalreviewcount
        tptotalreviewscore
        tpreviewscount3
        tpreviewsscore3
        tpreviewscount6
        tpreviewsscore6
        g2totalreviewcount
        g2totalreviewscore
        g2reviewscount3
        g2reviewsscore3
        g2reviewscount6
        g2reviewsscore6
        captotalreviewcount
        captotalreviewscore
        capreviewscount3
        capreviewsscore3
        capreviewscount6
        capreviewsscore6
        g2growthpercentile6
        capgrowthpercentile6
        tpgrowthpercentile6
        g2growthpercentile3
        capgrowthpercentile3
        tpgrowthpercentile3
        scorecardsa {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          advorpass
          reasonpass
          reasonadv
          adadr
          passadr
          dealsourceli
          dealsourcef
          sourcedby
          dealstamp
          completionstamp
        }
        scorecardsb {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          founderstr
          founderjust
          teamstr
          teamjust
          marketstr
          marketjust
          productstr
          productjust
          bmstr
          bmjust
          dealstr
          dealjust
          toptierprob
          toptierprob2
          moveic
          noreason
          ddreason
          yesreason
          dealstamp
          completionstamp
        }
        scorecardsc {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        scorecardspartner {
          scorecardid
          companyuuid
          author
          companyurl
          meetingdate
          completedate
          deallead
          f1
          f2
          f3
          f4
          f5
          f6
          f7
          f8
          f9
          founderexp
          founderstr
          t1
          t2
          t3
          t4
          t5
          teamexp
          teamstr
          m1
          m2
          m3
          m4
          m5
          marketexp
          marketstr
          btype
          b2bp1
          b2bp2
          b2bp3
          b2bp4
          b2bp5
          b2bp6
          b2bp7
          b2bp8
          b2bprodexp
          b2bprodstr
          b2cp1
          b2cp2
          b2cp3
          b2cp4
          b2cp5
          b2cp6
          b2cprodexp
          b2cprodstr
          b2bbm1
          b2bbm2
          b2bbm3
          b2bbm4
          b2bbm5
          b2bbm6
          b2bbm7
          b2bbm8
          b2bbm9
          b2bbm10
          b2bbm11
          b2bbmexp
          b2bbmstr
          b2cbm1
          b2cbm2
          b2cbm3
          b2cbm4
          b2cbm5
          b2cbm6
          b2cbm7
          b2cbm8
          b2cbm9
          b2cbmexp
          b2cbmstr
          p1
          p2
          p3
          p4
          p5
          p6
          pexp
          pstr
          redflag
          icdecile
          betsize
          toptierprob
          toptierprob2
          exit10x
          dealstamp
          completionstamp
          monkey
          monkeyexp
        }
        verticals {
          vertical
          companyuuid
        }
        headcountgrowth {
          companyuuid
          industryname
          departmentcount
          departmentgrowth12
          departmentgrowth6
          departmentgrowth3
          departmentgrowthpercentile12
          departmentgrowthpercentile6
          departmentgrowthpercentile3
          headcountdata
        }
        fundingroundspb {
          dealdate
          pbcid
          dealdateepoch
          dealid
          dealsize
          dealtype
          investors
          leadinvestors
          postmoneyvaluation
          series
        }
        scorecards {
          websitefinal
          feedbackfor
          dateofmeeting
          meetingcontext
          whichrenegades
          whofromcompany
          team
          teamstrength
          teamrationale
          market
          marketstrength
          marketrationale
          product
          productstrength
          productrationale
          businessmodel
          businessmodelstrength
          businessmodelrationale
          potentialdeal
          potentialdealstrength
          potentialdealrationale
          feedbacktopics
          moveforward
          rationaleforward
          toptierprob
          nextsteps
          author
        }
        leadinvestors {
          investor
          dealid
        }
        investors {
          investor
          dealid
        }
        webev3m
        webev6m
        webev12m
        evolutionpercentile3
        evolutionpercentile6
        evolutionpercentile12
        medianjobopeningtime
        jobcountyear
        opencount
        jobopeningpercentile
        webgrowthpercentile3
        webgrowthpercentile6
        codadocs {
          doctitle
          codaurl
        }
        googledocs {
          driveid
          foldertitle
        }
        googlesheets {
          sheetid
          sheettitle
        }
        cihcg
        cihcgpercentile
        growthpercentile12evm
        growthpercentile6evm
        growthpercentile3evm
        datetime
        headcount
        founding_date
        company_data {
          website {
            url
            domain
            is_broken
          }
          logo_url
          name
          legal_name
          description
          customer_type
          founding_date {
            date
            granularity
          }
          headcount
          ownership_status
          company_type
          location {
            address_formatted
            location
            street
            city
            state
            zip
            country
          }
          contact {
            emails
            phone_numbers
          }
          socials {
            LINKEDIN {
              url
              follower_count
              username
              status
            }
            TWITTER {
              url
              follower_count
              username
              status
            }
            CRUNCHBASE {
              url
              follower_count
              username
              status
            }
            PITCHBOOK {
              url
              follower_count
              username
              status
            }
            FACEBOOK {
              url
              follower_count
              username
              status
            }
            ANGELLIST {
              url
              follower_count
              username
              status
            }
          }
          funding {
            funding_total
            num_funding_rounds
            investors {
              entity_urn
              name
            }
            last_funding_at
            last_funding_type
            last_funding_total
            funding_stage
          }
          name_aliases
          website_domain_aliases
          entity_urn
          id
          funding_attribute_null_status
          tags {
            display_value
            type
            date_added
            entity_urn
          }
          snapshots {
            website {
              url
              domain
              is_broken
            }
            logo_url
            name
            legal_name
            description
            customer_type
            founding_date {
              date
              granularity
            }
            headcount
            ownership_status
            company_type
            location {
              address_formatted
              location
              street
              city
              state
              zip
              country
            }
            contact {
              emails
              phone_numbers
            }
            socials {
              LINKEDIN {
                url
                follower_count
                username
                status
              }
              TWITTER {
                url
                follower_count
                username
                status
              }
              CRUNCHBASE {
                url
                follower_count
                username
                status
              }
              PITCHBOOK {
                url
                follower_count
                username
                status
              }
              FACEBOOK {
                url
                follower_count
                username
                status
              }
              ANGELLIST {
                url
                follower_count
                username
                status
              }
            }
            funding {
              funding_total
              num_funding_rounds
              investors {
                entity_urn
                name
              }
              last_funding_at
              last_funding_type
              last_funding_total
              funding_stage
            }
            name_aliases
            website_domain_aliases
            entity_urn
            initialized_date
            funding_attribute_null_status
          }
          initialized_date
          highlights {
            category
            date_added
            text
          }
          traction_metrics {
            facebook_like_count
            facebook_follower_count
            facebook_following_count
            linkedin_follower_count
            instagram_follower_count
            twitter_follower_count
            headcount
            funding_total
            headcount_advisor
            headcount_customer_success
            headcount_data
            headcount_design
            headcount_engineering
            headcount_finance
            headcount_legal
            headcount_marketing
            headcount_operations
            headcount_other
            headcount_people
            headcount_product
            headcount_sales
            headcount_support
          }
          updated_at
          people {
            contact
            title
            department
            description
            start_date
            end_date
            is_current_position
            location
            role_type
            person
            person_company_urn
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const harmonicApiPersonsTableQuery = /* GraphQL */ `
  query HarmonicApiPersonsTableQuery($input: AWSJSON) {
    HarmonicApiPersonsTableQuery(input: $input) {
      input
      __typename
    }
  }
`;
export const editableTable = /* GraphQL */ `
  query EditableTable(
    $userid: String
    $method: String
    $columns: [AWSJSON]
    $companylist: [String]
    $companyId: String
    $value: AWSJSON
    $action: AWSJSON
  ) {
    EditableTable(
      userid: $userid
      method: $method
      columns: $columns
      companylist: $companylist
      companyId: $companyId
      value: $value
      action: $action
    ) {
      userid
      columnslist
      companylist
      columns
      companyId
      value
      action
      message
      __typename
    }
  }
`;
export const searchInvestor = /* GraphQL */ `
  query SearchInvestor($search_term: String, $limit: Int, $offset: Int) {
    searchInvestor(search_term: $search_term, limit: $limit, offset: $offset) {
      investor {
        investor
        dealid
        __typename
      }
      __typename
    }
  }
`;
export const editableTable2 = /* GraphQL */ `
  query EditableTable2($userid: String, $method: String, $columns: [String]) {
    EditableTable2(userid: $userid, method: $method, columns: $columns) {
      output {
        userid
        columnslist
        companylist
        columns
        companyId
        value
        action
        message
        __typename
      }
      __typename
    }
  }
`;
export const getPortfolioList = /* GraphQL */ `
  query GetPortfolioList(
    $limit: Int
    $offset: Int
    $sort: AWSJSON
    $filters: AWSJSON
  ) {
    getPortfolioList(
      limit: $limit
      offset: $offset
      sort: $sort
      filters: $filters
    ) {
      result {
        companyname
        initialinvestment
        cost
        fmv
        lastround
        lastrounddate
        coinvestors {
          investor
          dealid
        }
        ceo {
          name
          linkedinUrl
        }
        companyurl
        uuid
        __typename
      }
      count
      message
      __typename
    }
  }
`;
export const getLeadListRejectReasons = /* GraphQL */ `
  query GetLeadListRejectReasons {
    getLeadListRejectReasons {
      id
      title
      __typename
    }
  }
`;
export const getCompanyList = /* GraphQL */ `
  query GetCompanyList($type: String!, $companies: AWSJSON) {
    getCompanyList(type: $type, companies: $companies) {
      company_uuid
      company_name
      company_url
      city
      state
      country
      ticker
      __typename
    }
  }
`;
export const getAllCompanyList = /* GraphQL */ `
  query GetAllCompanyList($type: String!, $nameContains: String) {
    getAllCompanyList(type: $type, nameContains: $nameContains) {
      company_uuid
      company_name
      company_url
      city
      state
      country
      ticker
      __typename
    }
  }
`;
export const getDataSources = /* GraphQL */ `
  query GetDataSources {
    getDataSources {
      sourceName
      bucketName
      __typename
    }
  }
`;
export const getAllCompanies = /* GraphQL */ `
  query GetAllCompanies(
    $limit: Int
    $offset: Int
    $sort: AWSJSON
    $filters: AWSJSON
  ) {
    getAllCompanies(
      limit: $limit
      offset: $offset
      sort: $sort
      filters: $filters
    ) {
      result {
        uuid
        company_name
        website
        seriesmerged
        growthpercentile3evm
        growthpercentile6evm
        growthpercentile12evm
        cihcgpercentile
        reviewgrowthpercentile6
        reviewgrowthpercentile3
        headcount
        company_location {
          address_formatted
          location
          street
          city
          state
          zip
          country
        }
        total_funding
        last_funding_date
        founding_date
        funnel_stage
        total_notes
        socials {
          LINKEDIN {
            url
            follower_count
            username
            status
          }
          TWITTER {
            url
            follower_count
            username
            status
          }
          CRUNCHBASE {
            url
            follower_count
            username
            status
          }
          PITCHBOOK {
            url
            follower_count
            username
            status
          }
          FACEBOOK {
            url
            follower_count
            username
            status
          }
          ANGELLIST {
            url
            follower_count
            username
            status
          }
        }
        created_at
        updated_at
        __typename
      }
      count
      __typename
    }
  }
`;
export const getAllCompaniesUserView = /* GraphQL */ `
  query GetAllCompaniesUserView {
    getAllCompaniesUserView {
      id
      userId
      name
      description
      viewType
      viewData
      createdAt
      updatedAt
      lastViewedAt
      __typename
    }
  }
`;
export const getCompaniesUserView = /* GraphQL */ `
  query GetCompaniesUserView($id: ID!) {
    getCompaniesUserView(id: $id) {
      id
      userId
      name
      description
      viewType
      viewData
      createdAt
      updatedAt
      lastViewedAt
      __typename
    }
  }
`;
export const getAllFunnelStage = /* GraphQL */ `
  query GetAllFunnelStage {
    getAllFunnelStage {
      id
      name
      description
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getFunnelStage = /* GraphQL */ `
  query GetFunnelStage($id: ID!) {
    getFunnelStage(id: $id) {
      id
      name
      description
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getCompaniesFunnelStageHistory = /* GraphQL */ `
  query GetCompaniesFunnelStageHistory($uuid: String!) {
    getCompaniesFunnelStageHistory(uuid: $uuid) {
      id
      userId
      activityType
      params
      activityTime
      __typename
    }
  }
`;
export const getCompanyNotes = /* GraphQL */ `
  query GetCompanyNotes($uuid: String!) {
    getCompanyNotes(uuid: $uuid) {
      id
      userId
      uuid
      title
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getCompanyFavorite = /* GraphQL */ `
  query GetCompanyFavorite($id: ID!) {
    getCompanyFavorite(id: $id) {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanyFavorites = /* GraphQL */ `
  query ListCompanyFavorites(
    $filter: ModelCompanyFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyFavorites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSavedSearch = /* GraphQL */ `
  query GetSavedSearch($id: ID!) {
    getSavedSearch(id: $id) {
      id
      name
      searchJSON
      lastViewed
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSavedSearchs = /* GraphQL */ `
  query ListSavedSearchs(
    $filter: ModelSavedSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavedSearchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        searchJSON
        lastViewed
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorkflow = /* GraphQL */ `
  query GetWorkflow($id: ID!) {
    getWorkflow(id: $id) {
      id
      name
      chartJSON
      creationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWorkflows = /* GraphQL */ `
  query ListWorkflows(
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkflows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        chartJSON
        creationDate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
