import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import DownloadRecords from "./DownloadRecords";
import DragORUpload from "./DragORUpload";
import { gql, useQuery } from "@apollo/client";
import { getDataSources } from "../../graphql/queries";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export type DataSources = { sourceName: string; bucketName: string };

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`upload-csv-content-tabpanel-${index}`} aria-labelledby={`upload-csv-nav-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `upload-csv-nav-tab-${index}`,
    "aria-controls": `upload-csv-content-tabpanel-${index}`
  };
};

const DataSourceManagement = () => {
  const { data } = useQuery<{ getDataSources: DataSources[] }>(gql(getDataSources));
  const [dataSources, setDataSources] = React.useState<DataSources[]>([]);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  React.useEffect(() => {
    const sources = data?.getDataSources;
    if (sources && sources.length > 0) setDataSources(sources);
  }, [data]);

  return (
    <Box
      sx={{
        padding: 2
      }}
    >
      <Box component={Card} sx={{ minHeight: "calc(100vh - 97px)", p: 1 }}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="inherit" variant="fullWidth" aria-label="Upload csv navigation options">
          <Tab label="Download companies list" {...a11yProps(0)} icon={<CloudDownloadIcon />} iconPosition="start" />
          <Tab label="Upload Companies data(.CSV)" {...a11yProps(1)} icon={<CloudUploadIcon />} iconPosition="start" />
        </Tabs>

        <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <DownloadRecords dataSources={dataSources} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <DragORUpload dataSources={dataSources} />
            {/* <FileUploadProcess /> */}
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Box>
  );
};

export default DataSourceManagement;
