import { useAuthenticator } from "@aws-amplify/ui-react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Avatar, Hidden, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { CompaniesByNameSearchBox } from "../../../../components";

interface PropTypes {
  className?: string;
  onSidebarOpen?: () => void;
}

const PAGE_TITLES: any = {
  "/search": "Search Companies",
  "/dashboard": "Dashboard",
  "/companies-list": "All companies",
  "/account": "Account Settings"
};

const Topbar = (props: PropTypes) => {
  const { onSidebarOpen } = props;

  //menu popover state
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const { signOut } = useAuthenticator(context => [context.signOut]);
  const navigate = useNavigate();
  const theme = useTheme();

  const location = useLocation();

  const logOut = () => {
    signOut();
    navigate("/");
  };

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      sx={{
        bgcolor: theme.palette.background.paper,
        color: theme.palette.text.primary
      }}
      //className={clsx(classes.root, className)}
      elevation={1}
    >
      <Toolbar>
        <RouterLink to="/">
          <Avatar variant="square" alt="RE" src="/images/logos/header.png" sizes="" />
        </RouterLink>

        <Typography sx={{ marginLeft: 2 }} variant="h6" component={"h1"}>
          {PAGE_TITLES[location.pathname] ?? ""}
        </Typography>

        <CompaniesByNameSearchBox type="all" sx={{ marginRight: 2, [theme.breakpoints.up("sm")]: { marginLeft: theme.spacing(3) ,  width: "auto" } }} />

        <Box sx={{ marginLeft: "auto" }}>
          <Tooltip title="Account settings">
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
              <Avatar sx={{ width: 32, height: 32 }} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem key="account-settings" onClick={() => navigate("/account")}>
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText> {"Account Settings"}</ListItemText>
            </MenuItem>
            <MenuItem key="account-settings" onClick={() => navigate("/upload")}>
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>
              <ListItemText> {"Upload data files(csv)"}</ListItemText>
            </MenuItem>
            <MenuItem key={"SignOut"} onClick={logOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>{"Sign Out"}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <Hidden lgUp>
          <IconButton color="primary" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
