import { Box, Chip, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { DPbaseProps, _funding } from "../../../../types";
import { withErrorBoundary } from "../../../../components/ErrorBoundary";

interface InvestorsListProps extends DPbaseProps {
  data: _funding;
}

const InvestorsList = ({ data, loading, id }: InvestorsListProps) => {
  return (
    <Box id={id ?? "existing-investors"} sx={{ marginBottom: 1.5 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" sx={{ marginBottom: 0.5, marginRight: 1 }}>
          Existing Investors
        </Typography>
        {/* <ToggleButton size="small" value={isTableViewInvestors} color="primary" selected={isTableViewInvestors} onChange={() => setIsTableView(!isTableViewInvestors)}>
          <TableViewIcon />
        </ToggleButton> */}
      </Box>
      {loading ? (
        <Stack>
          <Skeleton height={30} />
          <Skeleton width={"40%"} height={30} />
        </Stack>
      ) : (
        <Box sx={{ marginTop: 1 }}>
          {data?.investors &&
            data.investors.map((inv, index) => (
              <Chip
                sx={{
                  marginRight: 0.5,
                  marginBottom: 0.5
                }}
                key={`${index}-${inv.entity_urn}`}
                label={inv.name}
                clickable
                color="default"
                variant="outlined"
                size="small"
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default withErrorBoundary(InvestorsList, <div>Having UI error in InvestorsList Component.</div>);
