import facebookRect from "@iconify/icons-il/facebook";
import linkedinFilled from "@iconify/icons-il/linkedin";
import twitterIcon from "@iconify/icons-il/twitter";
import angellistIcon from "@iconify/icons-simple-icons/angellist";
import crunchbaseIcon from "@iconify/icons-simple-icons/crunchbase";
import { Icon } from "@iconify/react";
import React from "react";
import { CommonColorsObject } from "../types";

const commonColors: CommonColorsObject = {
  linkedin: {
    primary: "#0e76a8",
    icon: <Icon height="21px" icon={linkedinFilled} color="#0e76a8" />
  },
  twitter: {
    primary: "#00acee",
    icon: <Icon height="21px" icon={twitterIcon} color="#00acee" />
  },
  facebook: {
    primary: "#3b5998",
    icon: <Icon height="21px" icon={facebookRect} color="#3b5998" />
  },
  crunchbase: {
    primary: "#146aff",
    icon: <Icon height="21px" icon={crunchbaseIcon} color="#146aff" />
  },
  angellist: {
    primary: "#333",
    icon: <Icon height="21px" icon={angellistIcon} color="#333" />
  }
};
export default commonColors;
