import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { _location } from "../../types";
import { Box, Typography } from "@mui/material";


const CompanyLocationRenderCell = (params: GridRenderCellParams) => {
  const location = (params.value as any) as _location;
  return (
    <Box sx={{width:"100%"}}>
      {location ? (
        <Typography variant="body2">{location.address_formatted} </Typography>
      ) : (
        <Typography variant="body2" color={"red"} align="center">
          <b>{"-NA-"}</b>
        </Typography>
      )}
    </Box>
  );
};

export default CompanyLocationRenderCell;
