import { Alert, AlertTitle } from "@mui/material";
import React, { Component, ErrorInfo, ReactNode } from "react";

type ErrorBoundaryState = {
  hasError: boolean;
};

type ErrorBoundaryProps = {
  fallbackUI?: ReactNode;
};
// Hoc for chekiang Error boundary

const withErrorBoundary = <P extends object>(WrappedComponent: React.ComponentType<P>, fallbackUI?: ReactNode) => {
  return class extends Component<P & ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: P & ErrorBoundaryProps) {
      super(props);
      this.state = {
        hasError: false
      };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      // You can customize the error handling logic here
      console.error("Error caught in error boundary:", error);
      console.error("Error info:", errorInfo);
      this.setState({ hasError: true });
    }

    render() {
      const { fallbackUI: fallback } = this.props;
      const { hasError } = this.state;
      if (hasError) {
        // Render the fallback UI if an error occurred
        return (
          fallback || (
            <Alert severity="error">
              <AlertTitle>UI Error</AlertTitle>
              Something wrong with UI component check — <strong>check it out!</strong>
            </Alert>
          )
        );
      }
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withErrorBoundary;
