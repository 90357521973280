import LocationCityIcon from "@mui/icons-material/LocationCity";
import { Avatar, Card, CardContent, CardHeader, Divider, Grid, Typography, styled } from "@mui/material";
import ShowMoreText from "../../../../components/ShowMore";
import React from 'react'

const PREFIX = "EmployeeHeader";

const classes = {
  titleHeading: `${PREFIX}-titleHeading`,
  iconTitleHeading: `${PREFIX}-iconTitleHeading`,
  textIconWrapper: `${PREFIX}-textIconWrapper`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  fullWidth: `${PREFIX}-fullWidth`,
  paperRoot: `${PREFIX}-paperRoot`,
  chipStyle: `${PREFIX}-chipStyle`,
  largeAvatar: `${PREFIX}-largeAvatar`,
  iconClass: `${PREFIX}-iconClass`,
  linkText: `${PREFIX}-linkText`,
  displayRight: `${PREFIX}-displayRight`,
  tagSeperator: `${PREFIX}-tagSeperator`,
  placeholder: `${PREFIX}-placeholder`,
  placeholdertext: `${PREFIX}-placeholdertext`
};

const Root = styled("div")(({ theme }) => ({
  [`&. ${classes.titleHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    marginBottom: "8px"
  },
  [`&. ${classes.iconTitleHeading}`]: {
    color: theme.palette.text.secondary,
    marginBottom: "8px"
  },
  [`&. ${classes.textIconWrapper}`]: {
    display: "inline-block",
    marginRight: "30px"
  },
  [`&. ${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15)
  },
  [`&. ${classes.fullWidth}`]: {
    width: "100%"
  },
  [`&. ${classes.paperRoot}`]: {
    width: "100%",
    marginBottom: theme.spacing(2),
    textAlign: "center"
  },
  [`&. ${classes.linkText}`]: {
    fontSize: theme.typography.pxToRem(14),
    paddingRight: "5px",
    color: theme.palette.info.main
  },
  [`&. ${classes.chipStyle}`]: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: "5px",
    marginBottom: "5px"
  },
  [`&. ${classes.largeAvatar}`]: {
    width: "150px",
    height: "150px",
    display: "inline-block"
  },
  [`&. ${classes.iconClass}`]: {
    paddingRight: "5px",
    color: theme.palette.text.secondary
  },
  [`&. ${classes.displayRight}`]: {
    textAlign: "center"
  },
  [`&. ${classes.tagSeperator}`]: {
    marginTop: "8px"
  },
  [`&. ${classes.placeholder}`]: {
    height: "150px"
  },
  [`&. ${classes.placeholdertext}`]: {
    width: "100%",
    textAlign: "center",
    paddingTop: "60px",
    fontSize: "14px"
  }
}));

const EmployeeHeader = (props: any) => {
  const { data } = props;

  return (
    <Root className={classes.fullWidth}>
      <Grid container spacing={2}>
        <Grid item xs={3} md={2} className={classes.displayRight}>
          <Avatar alt="No Image" className={classes.largeAvatar} src={data.profilepicture && data.profilepicture !== "-" ? data.profilepicture : "/images/avatars/NoProfile.png"}>
            {<img className={classes.largeAvatar} src="/images/avatars/NoProfile.png" alt="NoProfile" />}
          </Avatar>
        </Grid>

        <Grid item xs={9} md={6}>
          {data.personname && data.personname !== "-" ? (
            <Typography gutterBottom variant="h4" component="h2">
              {data.personname}
            </Typography>
          ) : (
            ""
          )}
          {data.liheadline && data.liheadline !== "-" ? <Typography className={classes.titleHeading}>{data.liheadline}</Typography> : ""}
          <Typography className={classes.iconTitleHeading}>
            {data.profilelocation && data.profilelocation !== "-" ? (
              <div className={classes.textIconWrapper}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <LocationCityIcon className={classes.iconClass} />
                  {data.profilelocation}
                </div>
              </div>
            ) : (
              ""
            )}
          </Typography>
          <Typography className={classes.secondaryHeading}>{data.fulltext && data.fulltext !== "-" ? <ShowMoreText data={data.fulltext} trimIndex={200}></ShowMoreText> : ""}</Typography>
          {/*employeeTags*/}
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader title="Profile Signal Score" />
            <Divider />
            <CardContent>
              <div className={classes.placeholder}>
                <Typography className={classes.placeholdertext}>Coming soon...</Typography>
              </div>
              {/* <StrengthMeter height="150px" margin={{ top: 10, right: 10, bottom: 10, left: 10 }} legend={[]} limit={(1)} label="Coming soon..."></StrengthMeter> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={2}></Grid>
        {/*<Grid item xs={12} md={10}>
                {employeeTags}
              </Grid>   */}
      </Grid>
    </Root>
  );
};

export default EmployeeHeader;
