import { Box, Typography } from "@mui/material";
import React from "react";
import { withErrorBoundary } from "../../../../components/ErrorBoundary";
import { DPbaseProps, TractionMetrics, _traction_metrics } from "../../../../types";
import HeadCountGrowth from "./HeadCountGrowth";
import PersonDetails from "./PersonDetails";
import { TeamDetails } from "./TeamDetails";

interface TeamOverViewProps extends DPbaseProps {
  data?: _traction_metrics;
  routeRefs: any;
  uuid?: string;
}

const processDataForHeadCount = (data?: _traction_metrics) => {
  if (data) {
    const headcount = JSON.parse(data.headcount) as TractionMetrics;
    const departments = Object.keys(data)
      .filter(d => d.startsWith("headcount_"))
      .map(k => {
        //@ts-ignore
        const d = JSON.parse(data[k]) as TractionMetrics;
        return { [k.split("_")[1]]: d };
      }).reduce((acc, obj) => {
        return {...acc, ...obj};
      }, {});;
    return { headcount, ...departments };
  }
  return null;
};

const TeamOverview = ({ id, loading, data, routeRefs, uuid }: TeamOverViewProps) => {


  const [pageController, setPageController] = React.useState({
    page: 0,
    rowsPerPage: 10
  });

  /*allEmployeeQuery
   * @description  Page change handler for pagination
   */
  // const handleChangePage = <T extends HTMLElement>(event: React.MouseEvent<T> | null, newPage: number) => {
  //   console.log(newPage);
  //   setPageController(prev => ({ ...prev, page: newPage < 0 ? 0 : newPage }));
  // };

  /*
   * @description  Page size change handler.
   */
  // const handleChangeRowsPerPage = (pageSize: number) => {
  //   setPageController({
  //     page: 0,
  //     rowsPerPage: pageSize
  //   });
  // };

  return (
    <Box sx={{ marginTop: 2 }} id={id ?? "teamOverview"}>
      <Typography variant="h3" sx={{ marginBottom: 0.5 }}>
        Team Overview
      </Typography>

      {/* HeadCount Section starts */}
      <HeadCountGrowth routeRefs={routeRefs} loading={loading!} data={processDataForHeadCount(data)} />

      <TeamDetails pageController={pageController} setPageController={setPageController} />
      {/* Team details section ends */}

      <PersonDetails uuid={uuid!} />

      {/* <Box id={"teamOverview-recent-team-changes"}>
        <Typography variant="h5" sx={{ marginTop: 1.5, marginBottom: 1.5 }}>
          Recent Team changes
        </Typography>
        <Box sx={{ paddingLeft: 2 }}>
          <Typography variant="subtitle1">{"New hires"}</Typography>
          <GridViewOfTeam data={teamData} page={pageController.page} count={6} rowsPerPage={pageController.rowsPerPage} onChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} forCollection="recentChanges" />
        </Box>
        <Box sx={{ marginBottom: 1, paddingLeft: 2 }}>
          <Typography variant="subtitle1">{"Notable company alumni"}</Typography>
          <GridViewOfTeam
            data={dataAlumni?.AllEmployeeQuery.result!}
            page={pageController.page}
            total={dataAlumni?.AllEmployeeQuery.count!}
            rowsPerPage={pageController.rowsPerPage}
            onChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            forCollection="recentChanges"
            loading={alumniLoading}
          />
        </Box> 
      </Box>*/}
      {/* <Box id={"teamOverview-headcountChanges"}>
        <Typography variant="h5" sx={{ marginTop: 1.5, marginBottom: 1.5 }}>
          Headcount changes
        </Typography>
      </Box> */}
      {/* <Box id={"teamOverview-jobOpenings"}>
        <Typography variant="h5" sx={{ marginTop: 1.5, marginBottom: 1.5 }}>
          Job Openings
        </Typography>
      </Box> */}
    </Box>
  );
};

export default withErrorBoundary(TeamOverview)