import AppsIcon from "@mui/icons-material/Apps";
import TableViewIcon from "@mui/icons-material/TableView";
import { Avatar, Box, Divider, Grid, Link, Skeleton, Stack, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import TablePagination from "@mui/material/TablePagination";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import Moment from "react-moment";
import { NotFoundSvg } from "../../../../assets";
import { EmployeeShort } from "../../../../types";

type teamViewType = "tableView" | "gridView";

interface TeamDetailsProps{
  pageController:{
    page:number,
    rowsPerPage:number
  }
  setPageController:React.Dispatch<React.SetStateAction<{
    page: number;
    rowsPerPage: number;
}>>;
}

interface TeamDetailsBase<T> {
  data: T[];
  total: number;
  page: number;
  rowsPerPage: number;
  loading: boolean;
  onChange: Function;
  onRowsPerPageChange?: Function;
}



// cols of the table
const columns: GridColDef[] = [
  {
    field: "personname",
    headerName: "Name",
    minWidth: 200,
    flex: 0.55,
    renderCell: params => (
      <Stack direction={"row"} alignItems="center">
        <Avatar sx={{ width: 24, height: 24 }} src={params.row.profilepicture} />
        <Typography component={Link} target="_blank" href={params.row.linkedinurl} sx={{ marginLeft: 1 }}>
          {params.row.personname}
        </Typography>
      </Stack>
    )
  },
  {
    field: "companyrole",
    headerName: "Job Title",
    flex: 0.45,
    minWidth: 220,
    renderCell: params => <Typography>{params.row.companyrole}</Typography>
  },
  {
    field: "liheadline",
    headerName: "Job Type",
    minWidth: 250,
    flex: 1,
    renderCell: params => <Typography>{params.row.liheadline}</Typography>
  },
  {
    field: "startdate",
    headerName: "Joining Date",
    flex: 0.3,
    minWidth: 80,
    // align:"center",
    renderCell: params => (
      <Typography>
        <Moment format="L">{parseInt(params.row.startepoch) * 1000}</Moment>
      </Typography>
    )
  },
  {
    field: "enddate",
    headerName: "End Date",
    // align:"center",
    width: 100,
    renderCell: params => <Typography>{params.row.enddate}</Typography>
  }
];

export const TeamDetails = ({ pageController , setPageController}:TeamDetailsProps) => {
    //team section states;
    const [teamView, setTeamAlignment] = React.useState<teamViewType>("gridView");

      // query for get all employee
  // const [getAllEmployee, { data: allEMpData, loading: allEmpLoading }] = useLazyQuery<{
  //   AllEmployeeQuery: {
  //     count: number;
  //     result: EmployeeShort[];
  //   };
  // }>(gql(allEmployeeQuery));


  // React.useEffect(() => {
  //   const { page, rowsPerPage } = pageController;
  //   getAllEmployee({
  //     variables: {
  //       CompanyUUID: companyUuid,
  //       Seniority: "All",
  //       Offset: page < 1 ? 0 : page * rowsPerPage,
  //       Limit: 10,
  //       onlyCurrent: true
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pageController, companyUuid]);

    /*
   * @description  Team view toggler
   */
    const handleTeamView = (event: React.MouseEvent<HTMLElement>, newAlignment: teamViewType) => {
      setTeamAlignment(newAlignment);
    };


  /*allEmployeeQuery
   * @description  Page change handler for pagination
   */
  // const handleChangePage = <T extends HTMLElement>(event: React.MouseEvent<T> | null, newPage: number) => {
  //   console.log(newPage);
  //   setPageController(prev => ({ ...prev, page: newPage < 0 ? 0 : newPage }));
  // };




  /*
   * @description  Page size change handler.
   */
  // const handleChangeRowsPerPage = (pageSize: number) => {
  //   setPageController({
  //     page: 0,
  //     rowsPerPage: pageSize
  //   });
  // };


      //Team details view object....
  // const teamDetailsViews = {
  //   tableView: <TableViewOfTeam data={allEMpData?.AllEmployeeQuery.result!} page={pageController.page} total={allEMpData?.AllEmployeeQuery.count!} rowsPerPage={pageController.rowsPerPage} onChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} loading={allEmpLoading} />,
  //   gridView: (
  //     <GridViewOfTeam
  //       data={allEMpData?.AllEmployeeQuery.result!}
  //       page={pageController.page}
  //       total={allEMpData?.AllEmployeeQuery.count!}
  //       rowsPerPage={pageController.rowsPerPage}
  //       onChange={handleChangePage}
  //       onRowsPerPageChange={handleChangeRowsPerPage}
  //       forCollection="teamDetails"
  //       loading={allEmpLoading}
  //     />
  //   )
  // };


  return (
    <Box id={"teamOverview-team"}>
    <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: 2 }}>
      <Typography variant="h5" sx={{ marginTop: 1.5, marginBottom: 1.5 }}>
        Team
      </Typography>
      <ToggleButtonGroup value={teamView} exclusive onChange={handleTeamView} aria-label="Team member's view" color="primary">
        <ToggleButton size="small" value="tableView" aria-label="Table View">
          <TableViewIcon />
        </ToggleButton>
        <ToggleButton size="small" value="gridView" aria-label="Grid View">
          <AppsIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>

    <Divider />
    {/* {teamDetailsViews[teamView]} */}
  </Box>
  )
}


export const TableViewOfTeam = <T extends object>({
  data,
  onChange,
  onRowsPerPageChange,
  loading,
  page,
  rowsPerPage,
  total
}: TeamDetailsBase<T> & {
  count?: number;
}) => {
  const [pageState, setPageState] = React.useState<{
    isLoading: boolean;
    data: T[];
    total: number;
    page: number;
    pageSize: number;
  }>({
    isLoading: false,
    data: [],
    total: 0,
    page: page,
    pageSize: rowsPerPage
  });

  React.useEffect(() => {
    if (!isEmpty(data))
      setPageState(old => ({
        ...old,
        data: [...data],
        total: total
      }));
  }, [data, total]);

  React.useEffect(() => {
    setPageState(old => ({
      ...old,
      page,
      pageSize: rowsPerPage
    }));
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    setPageState(old => ({
      ...old,
      isLoading: loading
    }));
  }, [loading]);

  return (
    <Box sx={{ height: "450px", maxHeight: "400px" }}>
      <DataGrid
        columns={columns}
        rowCount={pageState.total ?? 30}
        rows={pageState.data}
        loading={pageState.isLoading}
        getRowId={d => d.employeeuuid}
        pageSize={pageState.pageSize}
        page={pageState.page}
        getRowHeight={() => "auto"}
        rowsPerPageOptions={[10]}
        density="compact"
        paginationMode="server"
        pagination
        onPageChange={(page, details) => onChange(null, page)}
        onPageSizeChange={(pageSize, details) => {
          if (onRowsPerPageChange) onRowsPerPageChange(undefined, pageSize);
        }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" }
        }}
      />
    </Box>
  );
};

export const GridViewOfTeam = <T extends object>({
  data,
  total,
  page,
  rowsPerPage,
  loading,
  onChange,
  onRowsPerPageChange,
  forCollection
}: TeamDetailsBase<T> & {
  forCollection: "teamDetails" | "recentChanges";
}) => {
  const secondaryTexts = {
    teamDetails: (tm: EmployeeShort) => (
      <React.Fragment>
        <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
          {tm.companyrole}
        </Typography>
        {` — ${tm.liheadline}`}
      </React.Fragment>
    ),
    recentChanges: (tm: EmployeeShort) => (
      <React.Fragment>
        <Typography variant="body2" color="text.primary">
          {tm.companyrole}
        </Typography>
        <Typography variant="subtitle2" color="text.primary">
          {moment(tm.startdate).format("MMMM YYYY")}
        </Typography>
      </React.Fragment>
    )
  };
  if (loading)
    return (
      <Grid container spacing={2}>
        {[...new Array(10)].map((d, i) => (
          <Grid item md={4} key={`grid-view-${i}`}>
            <Skeleton key={`skeloton-${i}`} sx={{ height: "60px" }} variant="rounded" />
          </Grid>
        ))}
      </Grid>
    );
  if (isEmpty(data)) return <NotFoundSvg />;
  // return null;
  return (
    <Box>
      <List sx={{ bgcolor: "background.paper" }} component={Grid} container>
        {data.map((tm: any) => (
          <ListItem alignItems="flex-start" component={Grid} item md={4}>
            <ListItemAvatar>
              <Avatar alt={tm.personname} src={tm.profilepicture} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle2" component={Link} href={`${tm.linkedinurl}`} target="_blank">
                  {tm.personname}
                </Typography>
              }
              secondary={secondaryTexts[forCollection](tm)}
            />
            {/* <Divider variant="inset" /> */}
          </ListItem>
        ))}
      </List>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={(event, page) => onChange(null, page)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={event => {
          if (onRowsPerPageChange) onRowsPerPageChange(parseInt(event.target.value));
        }}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Box>
  );
};

// export default TeamDetails