import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Box, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { GridColDef, GridInitialState, GridRenderCellParams, GridRowModel, GridSingleSelectColDef, GridTreeNodeWithRender, getGridNumericOperators } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CompanyNameCell, RenderSocialsCell } from "../../components";
import CompanyLocationRenderCell from "../../components/TableRenderCells/CompanyLocationRenderCell";
import PercentileDataRenderCell from "../../components/TableRenderCells/PercentileDataRenderCell";
import { createFunnelStage, deleteFunnelStage, updateFunnelStage, updateFunnelStageOrder } from "../../graphql/mutations";
import { getAllCompanies, getAllCompaniesUserView, getAllFunnelStage } from "../../graphql/queries";
import { LAST_ROUND_OPTIONS } from "../../helpers/DataGridHelpers";
import { CompaniesUserView, FunnelStage, GetAllCompaniesListData, GetAllCompaniesListDataResponse, NotesData, ViewsListItem } from "../../types";
import { buildFinalQuery } from "../Dashboard/components/utils";
import CompaniesListTable from "./CompaniesListTable";
import { viewsListBuilder } from "./CompaniesViewsList";
import NotesRenderCell from "./NotesRenderCell";
import SingleSelectColsManageOptsView from "./SingleSelectColsManageOptsView";
import UserNotesManagementView from "./UserNotesManagementView";
import { loadGridInitialState } from "./utils";
import { JSONContent } from "@tiptap/react";

/***
 * !! Load saved view algo
 * 1. Data needed from the network ( Passed as props )
 *    - Build columns from network data
 *    - Load data for saved view means rows
 *    - Then pass initial state and and based on that alo set the pagination and other details correctly.
 * 2. Manage event handler ( Manage in the component itself)
 *    - On pagination change
 *    - On Filter modal change
 *    - On sorting modal change
 */

const COMPANIES_LIST_CELLS: GridColDef<GetAllCompaniesListData>[] = [
  /** company's demographic cols */
  { headerName: "Company", hideable: false, field: "company_name", pinnable: true, width: 225, renderCell: params => <CompanyNameCell params={params} keys={{ url: "website", name: "company_name" }} /> },
  { headerName: "Socials", filterable: false, sortable: false, field: "socials", renderCell: params => <RenderSocialsCell {...params} /> },
  { headerName: "Location", field: "company_location", minWidth: 150, renderCell: params => <CompanyLocationRenderCell {...params} /> },
  /** funding related cols */
  { headerName: "Funding Series", field: "seriesmerged", type: "singleSelect", valueOptions: LAST_ROUND_OPTIONS },
  { headerName: "Total Funding (In $)", minWidth: 150, align: "right", field: "total_funding", type: "number", valueFormatter: params => (params.value ? `$ ${parseInt(params.value).toLocaleString()}` : "$ 0") },
  { headerName: "Last Funding Date", align: "center", field: "last_funding_date", type: "date", valueFormatter: params => params.value && dayjs(params.value).format("DD/MM/YYYY") },
  { headerName: "Foundation Year", align: "center", field: "founding_date", type: "date", valueFormatter: params => params.value && dayjs(params.value).year() },
  { headerName: "Total Employee", align: "center", field: "headcount", type: "number" },
  { headerName: "Industry Tag", field: "industry_tags" },
  { headerName: "Funnel Stage", field: "funnel_stage" },

  /** growth related cols */
  { headerName: "Review Growth (6m)", field: "reviewgrowthpercentile6", type: "number", valueFormatter: params => params.value && parseFloat((parseFloat(params.value) * 100).toFixed(2)), renderCell: params => <PercentileDataRenderCell {...params} /> },
  { headerName: "Review Growth (3m)", field: "reviewgrowthpercentile3", type: "number", valueFormatter: params => params.value && parseFloat((parseFloat(params.value) * 100).toFixed(2)), renderCell: params => <PercentileDataRenderCell {...params} /> },
  { headerName: "Invariant Headcount", field: "cihcgpercentile", type: "number", valueFormatter: params => params.value && parseFloat((parseFloat(params.value) * 100).toFixed(2)), renderCell: params => <PercentileDataRenderCell {...params} /> },
  { headerName: "Traffic Growth(3m EVM)", field: "growthpercentile3evm", type: "number", valueFormatter: params => params.value && parseFloat((parseFloat(params.value) * 100).toFixed(2)), renderCell: params => <PercentileDataRenderCell {...params} /> },
  { headerName: "Traffic Growth(6m EVM)", field: "growthpercentile6evm", type: "number", valueFormatter: params => params.value && parseFloat((parseFloat(params.value) * 100).toFixed(2)), renderCell: params => <PercentileDataRenderCell {...params} /> },
  { headerName: "Traffic Growth(12m EVM)", field: "growthpercentile12evm", type: "number", valueFormatter: params => params.value && parseFloat((parseFloat(params.value) * 100).toFixed(2)), renderCell: params => <PercentileDataRenderCell {...params} /> },

  /** user defined cols */
  { headerName: "Source", field: "source" },
  { headerName: "Source Type", field: "source_type" },
  { headerName: "Live Deal", field: "live_deal" },
  { headerName: "Discuss", field: "discuss" },
  {
    headerName: "Notes",
    field: "notes",
    hideable: false,
    align: "center",
    width: 60
    // renderCell: params =>
    //   params.value && (
    //     <Badge color="secondary" badgeContent={params.value.length} max={10}>
    //       <NoteIcon />
    //     </Badge>
    //   ),
    // renderEditCell: params => <NotesEditRenderCell {...params} />
  }
  // { headerName: "Description", field: "description" },
  // { headerName: "Investors", field: "investors" },
  // { headerName: "Date Added", field: "date_added" },
  // { headerName: "Point Person", field: "point_person" },

  /** Address */
];

interface GetAllCompVariables {
  limit: number;
  offset: number;
  sort?: string;
  filters?: string;
}

type requestDataParams = { offset?: number; limit?: number; filters?: string; sort?: string };

const CompaniesList = () => {
  /** Get query params from the routes */
  const { id } = useParams<{ id: string }>();
  /** router-dom-location  data parse*/
  const location = useLocation();
  /** navigation api */
  const navigate = useNavigate();

  // Queries
  /** Query for getting the companies */
  const [getCompanies] = useLazyQuery<{ getAllCompanies: GetAllCompaniesListDataResponse }, GetAllCompVariables>(gql(getAllCompanies), {
    fetchPolicy: "network-only"
  });
  /** Query for the funnel_stages list from DB */
  const [getAllFunnelStages] = useLazyQuery<{ getAllFunnelStage: FunnelStage[] }>(gql(getAllFunnelStage), {
    fetchPolicy: "network-only"
  });

  //Mutations

  /** CREATE Funnel stage */
  const [addFunnelStageOpt, { loading: createFunnelStageLoading }] = useMutation<{ createFunnelStage: FunnelStage }, { input: Partial<FunnelStage> }>(gql(createFunnelStage));
  /** Update funnel stage  */
  const [updateFunnelState, { loading: funnelStageLoading }] = useMutation<{ updateFunnelStage: FunnelStage }, { input: Partial<FunnelStage> & { id: string } }>(gql(updateFunnelStage));
  /** delete funnel stage option */
  const [deleFunnelSageOpt, { loading: funnelStgeDelLoading }] = useMutation<{ deleteFunnelStage: FunnelStage[] }, { id: string }>(gql(deleteFunnelStage));
  /** Update sorting order of the options list */
  const [updateSortOrder] = useMutation<{ updateFunnelStageOrder: FunnelStage[] }, { input: { data: string } }>(gql(updateFunnelStageOrder));

  /** Query for fetching all the saved views */
  const [getAllSavedViews] = useLazyQuery<{ getAllCompaniesUserView: CompaniesUserView<string>[] }>(gql(getAllCompaniesUserView), {
    fetchPolicy: "network-only"
  });
  // States
  /** View list */
  const [viewsList, setViewList] = React.useState<Array<ViewsListItem>>([]);
  /** State for the storing of the columns */
  const [columns, setColumns] = React.useState<GridColDef[]>([]);
  /** Saved initial data */
  const [currentView, setCurrentView] = React.useState<ViewsListItem>();
  /** state for the rows GridRowsModal */
  const [rows, setRows] = React.useState<GetAllCompaniesListData[]>([]);
  /** Total roes count for the saved view */
  const [rowsCount, setRowsCount] = React.useState(0);
  /** FunnelStages Options list */
  const [funnelStagesOpts, setFunnelStagesOpts] = React.useState<FunnelStage[]>([]);
  /** Loader state  */
  const [dataLoader, setDataLoader] = useState(false);

  /** manage select options for singleSelect headers */
  const [manageOptCol, setManageOptCols] = React.useState<GridSingleSelectColDef | null>(null);
  /** Selected */
  const [selectedNotesRow, setSelectedRow] = React.useState<GridRowModel | GetAllCompaniesListData | null>(null);

  /** Notes icon click handler */
  const onNotesClick = (row: any) => {
    setSelectedRow(row);
  };
  /** Prepare columns function */
  const prepareColumn = React.useCallback((f_s: FunnelStage[]) => {
    const cols = COMPANIES_LIST_CELLS.map(col => {
      if (col.field === "funnel_stage") {
        return {
          ...col,
          type: "singleSelect",
          minWidth: 120,
          headerClassName: "companies-list-header",
          editable: true,
          valueOptions: f_s.map(v => ({ value: v.id, label: v.name }))
          // getOptionValue: (val: FunnelStage) => val.id,
          // getOptionLabel: (val: FunnelStage) => val.name,
        };
      }

      if (col.field === "notes") {
        // console.log("RenderCellLoaded::")
        return { ...col, headerClassName: "companies-list-header", renderCell: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => <NotesRenderCell params={params} onNotesClick={onNotesClick} /> };
      }

      if (col.type !== "number") {
        return { ...col, headerClassName: "companies-list-header", minWidth: 120 };
      }

      return {
        ...col,
        headerClassName: "companies-list-header",
        minWidth: 120,
        filterOperators: getGridNumericOperators().filter(
          // operator => !["isAnyOf", "isEmpty", "isNotEmpty"].includes(operator.value)
          operator => operator.value !== "isAnyOf"
          // (operator) => operator.value === '=' || operator.value === '!=' || operator.value === ">" || operator.value === ">=" || operator.value === "<" || operator.value === "<=" || operator.value === "isEmpty" || operator.value === "isNotEmpty",
        )
      };
    });
    return cols;
  }, []);

  const getForTheCurrentInitialState = useCallback(
    async (initialState: GridInitialState, cols: GridColDef[]) => {
      const paginationModal = initialState.pagination?.paginationModel;
      const filterModal = initialState.filter?.filterModel;
      const sortingModal = initialState.sorting?.sortModel;

      const vars: requestDataParams = {
        limit: paginationModal?.pageSize ?? 50,
        offset: (paginationModal?.page ?? 0) * (paginationModal?.pageSize ?? 50)
      };

      if (filterModal) vars.filters = buildFinalQuery(filterModal, cols);
      if (sortingModal) vars.sort = JSON.stringify(sortingModal);

      const { data } = await getCompanies({
        //@ts-ignore
        variables: vars
      });
      const rows = data?.getAllCompanies.result;
      const count = data?.getAllCompanies.count;
      return { rows, count };
    },
    [getCompanies]
  );

  const getSavedViewAndLoadViewsData = React.useCallback(async () => {
    //Init:: setDataLoader to true
    setDataLoader(true);

    // Step 1 :: Get all the saved view
    const [requestAllViews, requestFunnelStages] = await Promise.all([getAllSavedViews(), getAllFunnelStages()]);
    const _viewsList = viewsListBuilder(requestAllViews.data?.getAllCompaniesUserView ?? []);

    // Step 2 :: Current View
    const _currentView = _viewsList.find(d => d.id === id);

    if (!_currentView) {
      toast.warn("Requested view is not available for now. Select view form available views list");
      navigate("/companies-list");
    }

    //Step 3 :: Initial state for the current view
    const _initialState = _currentView?.viewData.state ?? loadGridInitialState();

    //Step 4 :: Prepare column for the current view;
    const _f_st = requestFunnelStages.data?.getAllFunnelStage ?? [];
    const _columns = prepareColumn(_f_st);

    // Step 5 :: Fetch rows data by initial state
    const { rows, count } = await getForTheCurrentInitialState(_initialState, _columns);

    //Step 6 ::  Update the state here
    unstable_batchedUpdates(() => {
      setViewList(_viewsList);
      setColumns(_columns);
      setCurrentView(_currentView);
      setFunnelStagesOpts(_f_st);
      setRows(rows ?? []);
      setRowsCount(count ?? 0);
    });

    //Finally:: setDataLoader to false
    setDataLoader(false);
  }, [getAllFunnelStages, getAllSavedViews, getForTheCurrentInitialState, id, navigate, prepareColumn]);

  const manageOptionsClickHandler = (colDef: GridColDef) => {
    setManageOptCols(colDef as GridSingleSelectColDef);
  };

  /** Handle options change */
  const handleOptionsChange = async (option: { id?: string | number; name?: string; description?: string }, reason: "create" | "update" | "delete") => {
    const { id, name, description } = option;
    let updateOpt: FunnelStage | FunnelStage[] | undefined;

    if (id && reason === "update") {
      const updateRequest = await updateFunnelState({
        variables: {
          input: {
            id: id + "",
            name,
            description
          }
        }
      });
      updateOpt = updateRequest.data?.updateFunnelStage;
    } else if (id && reason === "delete") {
      // Operation delete
      const deleteReq = await deleFunnelSageOpt({ variables: { id: id + "" } });
      updateOpt = deleteReq.data?.deleteFunnelStage ?? [];
    } else {
      const addNewRequest = await addFunnelStageOpt({ variables: { input: { name, description } } });
      updateOpt = addNewRequest.data?.createFunnelStage;
    }

    if (updateOpt) {
      const updatedOpts =
        reason === "delete"
          ? (updateOpt as FunnelStage[])
          : reason === "update"
          ? funnelStagesOpts.map(item => {
              if (item.id === (updateOpt as FunnelStage)?.id) return updateOpt as FunnelStage;
              return item;
            })
          : [...funnelStagesOpts, { ...(updateOpt as FunnelStage) }];
      const updatedColumns = prepareColumn(updatedOpts);
      unstable_batchedUpdates(() => {
        setFunnelStagesOpts(updatedOpts);
        setColumns(updatedColumns);
      });

      return updateOpt;
    }

    return;
  };

  /** Reorder change handler */
  const reorderChangeHandler = async (items: FunnelStage[]) => {
    const indexOrderArray = items.map((d, index) => ({ id: d.id, sortOrder: index }));

    const sortRequest = await updateSortOrder({ variables: { input: { data: JSON.stringify(indexOrderArray) } } });
    const funnel_stages = sortRequest.data?.updateFunnelStageOrder;
    // console.log("indexOrderArray::", indexOrderArray);
    if (funnel_stages) items = funnel_stages;
    const updatedColumns = prepareColumn(items);
    unstable_batchedUpdates(() => {
      setFunnelStagesOpts(items);
      setColumns(updatedColumns);
    });
  };

  /** Set the new notes to the respective row */
  const handleSaveNewNotesData = (data: NotesData<JSONContent>) => {
    const _rows = [...rows];
    const selectedRowIndex = _rows.findIndex(i => i.uuid === data.uuid);
    const prevNotes = _rows[selectedRowIndex].notes;

    if (prevNotes && prevNotes.length > 0) _rows[selectedRowIndex].notes = [...prevNotes, { ...data }];
    else _rows[selectedRowIndex].notes = [{ ...data }];

    unstable_batchedUpdates(() => {
      setRows(_rows);
      setSelectedRow(_rows.find(d => d.uuid === data.uuid) ?? null);
    });
  };

  /** handler update existing notes */
  const updateNotedHandler = (data: NotesData<JSONContent>) => {
    const _rows = [...rows];
    const selectedRowIndex = _rows.findIndex(i => i.uuid === data.uuid);
    const prevNotes = _rows[selectedRowIndex].notes;

    if (prevNotes && prevNotes.length > 0) {
      const editedNotesIndex = prevNotes?.findIndex(item => item.id === data.id);
      prevNotes[editedNotesIndex] = { ...data };
      _rows[selectedRowIndex].notes = [...prevNotes];
    }

    unstable_batchedUpdates(() => {
      setRows(_rows);
      setSelectedRow(_rows.find(d => d.uuid === data.uuid) ?? null);
    });
  };

  /** Update New Notes State */

  const updateNewNotesState = (prevSelectedRow: GetAllCompaniesListData, newNotes: NotesData<JSONContent>[]): GetAllCompaniesListData => {
    let updateRow: GetAllCompaniesListData;
    prevSelectedRow.notes = [...newNotes];
    updateRow = { ...prevSelectedRow };
    return updateRow;
  };

  useEffect(() => {
    getSavedViewAndLoadViewsData();
  }, [getSavedViewAndLoadViewsData, location]);

  const isManageOptOpen = Boolean(manageOptCol);
  const isUserNotesOpen = Boolean(selectedNotesRow);

  if (currentView && !dataLoader)
    return (
      <Box sx={{ p: 1 }}>
        <CompaniesListTable
          //props
          rows={rows}
          columns={columns}
          rowsCount={rowsCount}
          currentView={currentView}
          viewList={viewsList}
          manageOptionsClickHandler={manageOptionsClickHandler}
        />
        <SingleSelectColsManageOptsView
          //props
          open={isManageOptOpen}
          closeHandler={() => setManageOptCols(null)}
          label={`Manage Options for ${manageOptCol?.headerName}`}
          optionsList={funnelStagesOpts}
          getSortingKey={item => item.sortOrder}
          getOptionId={opt => opt.id}
          onOptionChange={handleOptionsChange}
          dataLoading={funnelStageLoading || createFunnelStageLoading || funnelStgeDelLoading}
          reorderChangeHandler={reorderChangeHandler}
        />
        <UserNotesManagementView
          //props
          open={isUserNotesOpen}
          selectedRow={selectedNotesRow as GetAllCompaniesListData}
          label="Users notes by the user."
          setSelectedRow={setSelectedRow}
          getCompanyPageLink={row => `/company-details/${row?.uuid ?? ""}?fromPage=companies-list`}
          getCompanYWebUrl={row => row?.website ?? ""}
          getCompanYName={row => row?.company_name ?? ""}
          getNotesList={row => row?.notes ?? []}
          getUuid={row => row?.uuid ?? ""}
          saveNewNotes={handleSaveNewNotesData}
          updateNotes={updateNotedHandler}
          updateNewNotesState={updateNewNotesState}
        />
      </Box>
    );
  return (
    <Stack justifyContent={"center"} alignItems={"center"} sx={{ height: "calc(100vh - 64px)" }}>
      <CircularProgress />
    </Stack>
  );
};

export default CompaniesList;
