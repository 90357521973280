import { useMutation , gql } from "@apollo/client";
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, styled } from "@mui/material";
// import gql from "graphql-tag";
import { updateSavedSearch } from "../../../../../../graphql/mutations";
import { listSavedSearchs } from "../../../../../../graphql/queries";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import ActionIcon from "../ActionIcon";
import NewResultsText from "../NewResultsText";

const PREFIX = "SearchTable1";
const classes = {
  table: `${PREFIX}-table`,
  tableCell: `${PREFIX}-tableCell`,
  tableCell1: `${PREFIX}-tableCell1`,
  tableHeaderCell: `${PREFIX}-tableHeaderCell`,
  tableHeaderCell1: `${PREFIX}-tableHeaderCell1`,
  root: `${PREFIX}-root`,
  nothingFoundText: `${PREFIX}-nothingFoundText`
};

const RootPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.table}`]: {
    minWidth: 650
  },
  [`& .${classes.tableCell}`]: {
    fontSize: "15px",
    textAlign: "center"
  },
  [`& .${classes.tableCell1}`]: {
    fontSize: "15px",
    marginLeft: "10px",
    color: theme.palette.info.main
  },
  [`& .${classes.tableHeaderCell}`]: {
    fontSize: "15px",
    fontWeight: "500"
    //textAlign:"center"
  },
  [`& .${classes.tableHeaderCell1}`]: {
    fontSize: "15px",
    fontWeight: "500",
    marginLeft: "10px"
    //textAlign:"center"
  },
  [`&.${classes.root}`]: {
    width: "100%"
  },
  [`& .${classes.nothingFoundText}`]: {
    width: "100%",
    textAlign: "center",
    padding: "20px"
  }
}));

const queryString = require("qs");

const SearchTable = props => {
  const { data, page, handleChangePage, tableLength } = props;
  // const history = useHistory()
  const navigate = useNavigate();
  const onError = error => {
    console.log(error);
  };

  const updateCache = (cache, { data }) => {
    const existingSaved = cache.readQuery({
      query: gql(listSavedSearchs),
      variables: {
        limit: 1000
      }
    });
    const oldItems = existingSaved.listSavedSearchs.items;
    const nextToken = existingSaved.listSavedSearchs.nextToken;

    const newItems = oldItems.map(t => {
      if (t.id === data.updateSavedSearch.id) {
        const newItem = Object.assign({}, t);
        newItem.lastViewed = data.updateSavedSearch.lastViewed;
        return newItem;
      } else {
        return t;
      }
    });
    cache.writeQuery({
      query: gql(listSavedSearchs),
      variables: {
        limit: 1000
      },
      data: {
        listSavedSearchs: {
          __typename: "ModelSavedSearchConnection",
          items: newItems,
          nextToken: nextToken
        }
      }
    });
  };
  const [updateSearch] = useMutation(gql(updateSavedSearch), {
    update: updateCache,
    onError: onError
  });
  const onViewSearchLink = (searchString, lastUpdated) => {
    var newSearchString = searchString;
    if (lastUpdated > 0) {
      var searchInput = queryString.parse(newSearchString, { arrayFormat: "indices", strictNullHandling: true, ignoreQueryPrefix: true });
      if (searchInput.pageNum) searchInput.pageNum = parseInt(searchInput.pageNum);
      if (searchInput.DepartmentStrengthList) searchInput.pageNum = parseInt(searchInput.pageNum);
      searchInput["RecordCreated"] = new Date(parseInt(lastUpdated) * 1000).toJSON();
      newSearchString = queryString.stringify(searchInput, { arrayFormat: "indices", strictNullHandling: true });
    }
    const ViewAllLink = props => (
      <ReactLink
        to={{
          pathname: "/search",
          search: newSearchString
        }}
        {...props}
      />
    );

    return ViewAllLink;
  };
  const onViewSearch = (e, id, searchString, lastUpdated) => {
    e.preventDefault();
    e.stopPropagation();
    const now = new Date();
    const currentEpoch = Math.round(now.getTime() / 1000);
    updateSearch({
      variables: {
        input: {
          id: id,
          lastViewed: currentEpoch
        }
      }
    });
    var newSearchString = searchString;
    if (lastUpdated > 0) {
      var searchInput = queryString.parse(newSearchString, { arrayFormat: "indices", strictNullHandling: true, ignoreQueryPrefix: true });
      if (searchInput.pageNum) searchInput.pageNum = parseInt(searchInput.pageNum);
      if (searchInput.DepartmentStrengthList) searchInput.pageNum = parseInt(searchInput.pageNum);
      searchInput["RecordCreated"] = new Date(parseInt(lastUpdated) * 1000).toJSON();
      newSearchString = queryString.stringify(searchInput, { arrayFormat: "indices", strictNullHandling: true });
    }

    navigate("/search", { state: { searchString } });
  };

  return (
    <RootPaper elevation={2} className={classes.root}>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.tableHeaderCell1} variant="body1">
                  Search Name
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.tableHeaderCell} variant="body1">
                  Last Viewed
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.tableHeaderCell} variant="body1">
                  New Results
                </Typography>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          {data.length > 0 ? (
            <TableBody>
              {data.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" align="left">
                    <Link className={classes.tableCell1} variant="body1" color="inherit" href="#" component={onViewSearchLink(row.searchJSON, 0)}>
                      {" "}
                      {row.name}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className={classes.tableCell} variant="body1">
                      {new Date(parseInt(row.lastViewed) * 1000).toJSON().split("T")[0]}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <NewResultsText color="inherit" href="#" onClick={e => onViewSearch(e, row.id, row.searchJSON, row.lastViewed)} lastViewed={row.lastViewed} component={onViewSearchLink(row.searchJSON, row.lastViewed)} className={classes.tableCell} searchString={row.searchJSON} variant="body1" />
                  </TableCell>
                  <TableCell align="right">
                    <ActionIcon id={row.id} lastViewed={row.lastViewed} searchString={row.searchJSON} onViewSearch={onViewSearch} onViewSearchLink={onViewSearchLink} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
          {data.length > 0 ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={tableLength}
                  rowsPerPageOptions={[]}
                  rowsPerPage={10}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
      {data.length === 0 ? (
        <Typography variant="h4" className={classes.nothingFoundText}>
          No Saved Searches Found
        </Typography>
      ) : null}
    </RootPaper>
  );
};


export default SearchTable;
