import { Avatar, Tooltip, Typography, styled } from "@mui/material";
import clsx from "clsx";
import { useUserContextAPI } from "../../../../../../components/UserContext";
import isEmpty from "lodash/isEmpty";
import { Link as RouterLink } from "react-router-dom";
import React from 'react'

interface PropTypes {
  className?: string;
}
const PREFIX = "Header1";
const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  name: `${PREFIX}-name`,
  textColor: `${PREFIX}-textColor`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  [`& .${classes.avatar}`]: {
    width: 60,
    height: 60
  },
  [`& .${classes.name}`]: {
    marginTop: theme.spacing(1)
  },
  [`& .${classes.textColor}`]: {
    color: "white",
    textAlign: "center"
  }
}));

const Profile = (props: PropTypes) => {
  const { className } = props;

  const {
    values,
    avatar
    // setAccountInfo,
    // setProfilePicture
  } = useUserContextAPI();

  return (
    <Root className={clsx(classes.root, className)}>
      <Tooltip
        title={
          <div>
            <Typography className={`${classes.name} ${classes.textColor}`} variant="body1">
              {(!isEmpty(values.firstName) ? values.firstName : "") + " " + (!isEmpty(values.lastName) ? values.lastName : "")}
            </Typography>
            <Typography variant="body2" className={classes.textColor}>
              {values.title}
            </Typography>
          </div>
        }
        aria-label="add"
        placement="right"
      >
        <Avatar alt="Person" className={classes.avatar} component={RouterLink} src={avatar} to="/account" />
      </Tooltip>
    </Root>
  );
};

export default Profile;
