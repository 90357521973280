import { Autocomplete, Checkbox, FormControlLabel, Grid, Slider, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import MultipartItem from "../MultipartItem";

const PREFIX = "SearchSideBarBlocks";

const classes = {
  root: `${PREFIX}-root`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  details: `${PREFIX}-details`,
  textField: `${PREFIX}-textField`,
  textFieldSmall: `${PREFIX}-textFieldSmall`,
  labelRoot: `${PREFIX}-labelRoot`,
  labelRootExternal: `${PREFIX}-labelRootExternal`,
  labelRootSmall: `${PREFIX}-labelRootSmall`,
  labelRootExternalSmall: `${PREFIX}-labelRootExternalSmall`,
  divider: `${PREFIX}-divider`,
  expansionPanelStyle: `${PREFIX}-expansionPanelStyle`,
  indentItems: `${PREFIX}-indentItems`,
  chipStyle: `${PREFIX}-chipStyle`,
  buttonStyle: `${PREFIX}-buttonStyle`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%"
  },
  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  [`& .${classes.details}`]: {
    alignItems: "center",
    width: "100%"
  },
  [`& .${classes.textField}`]: {
    width: "100%",
    marginTop: "16px"
  },
  [`& .${classes.textFieldSmall}`]: {
    width: "100%",
    marginTop: "8px"
  },
  [`& .${classes.labelRoot}`]: {
    fontSize: "19px"
  },
  [`& .${classes.labelRootExternal}`]: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    fontSize: "19px",
    marginTop: "16px"
  },
  [`& .${classes.labelRootSmall}`]: {
    fontSize: "17px"
  },
  [`& .${classes.labelRootExternalSmall}`]: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    fontSize: "17px",
    marginTop: "8px",
    textAlign: "left"
  },
  [`& .${classes.expansionPanelStyle}`]: {
    root: {
      boxShadow: "none"
    }
  },
  [`& .${classes.indentItems}`]: {
    marginLeft: "40px",
    marginRight: "40px",
    textAlign: "center"
  },
  [`& .${classes.divider}`]: {
    backgroundColor: "#494A4E",
    marginTop: "8px",
    marginBottom: "8px"
  },
  [`& .${classes.buttonStyle}`]: {
    marginBottom: "8px"
  },
  [`& .${classes.chipStyle}`]: {
    fontSize: theme.typography.pxToRem(12),
    marginRight: "5px",
    marginBottom: "5px"
  }
}));
const marks = [
  {
    value: 0,
    label: "0"
  },
  {
    value: 25,
    label: "25"
  },
  {
    value: 50,
    label: "50"
  },
  {
    value: 75,
    label: "75"
  },
  {
    value: 100,
    label: "100"
  }
];

const marksShort = [
  {
    value: 0,
    label: "0"
  },
  {
    value: 5,
    label: "5"
  },
  {
    value: 10,
    label: "10"
  },
  {
    value: 15,
    label: "15"
  },
  {
    value: 20,
    label: "20"
  }
];

interface PropTypes {
  className?: string;
  onChange: Function;
  title?: string;
  items: any[];
  values?: any;
  isOpen?: boolean;
  onAddItem?: Function;
  errorsStates?: Function;
  tags?: [];
  onGenerateOutputWrapper?: Function;
  errorStates?: any;
}

const SearchSideBar = (props: PropTypes) => {
  const { onChange, items, values } = props;
  return (
    <Root className={classes.root}>
      <Grid container spacing={1}>
        {items.map(item => (
          <Grid item xl={3} xs={12}>
            {item.type === "MULTI" ? (
              <Autocomplete
                multiple={item.isMultiple}
                options={item.data}
                onChange={(e, v) => onChange(item.id, v)}
                id={item.id}
                value={values[item.id]}
                getOptionLabel={option => option}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    value={item.autoCompleteValue ? item.autoCompleteValue : undefined}
                    onChange={item.onAutoCompleteChange ? e => item.onAutoCompleteChange(e.target.value) : undefined}
                    variant="standard"
                    label={item.label}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot
                      }
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password"
                    }}
                  />
                )}
              />
            ) : item.type === "FREE" ? (
              <Autocomplete
                multiple
                freeSolo
                autoSelect
                options={[]}
                onChange={(e, v) => onChange(item.id, v)}
                id={item.id}
                value={values[item.id]}
                getOptionLabel={option => option}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    variant="standard"
                    label={item.label}
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.labelRoot
                      }
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password"
                    }}
                  />
                )}
              />
            ) : item.type === "SLIDER" ? (
              <React.Fragment>
                <Typography className={classes.labelRootExternal}>{item.label}</Typography>
                <Slider
                  //value={values[item.id]}
                  //onChange={(e, v) => onChange(item.id, v)}
                  onChangeCommitted={(e, v) => onChange(item.id, v)}
                  defaultValue={values[item.id]}
                  valueLabelDisplay="auto"
                  marks={item.short ? marksShort : marks}
                  max={item.short ? 20 : 100}
                />
              </React.Fragment>
            ) : item.type === "SLIDER_SELECT" ? (
              <MultipartItem id={item.id} label={item.label} Select1={item.Select} Select2={item.Select2} Select3={item.Select3} Slide={item.Slide} generateOutput={item.generateOutput} generateTags={item.generateTags} value={values[item.id]} onValueChange={onChange} />
            ) : item.type === "CHECKBOX" ? (
              <FormControlLabel control={<Checkbox checked={values[item.id]} onChange={event => onChange(item.id, event.target.checked)} name="checkedB" color="primary" />} label={item.label} className={classes.labelRoot} />
            ) : (
              <TextField
                className={classes.textField}
                variant="standard"
                id={item.id}
                value={values[item.id]}
                onChange={e => onChange(e.target.id, e.target.value)}
                label={item.label}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.labelRoot
                  }
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Root>
  );
};

SearchSideBar.defaultProps = {
  tags: []
};
export default SearchSideBar;
