import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Avatar, Box, Card, Chip, Divider, Grid, Link, Skeleton, Typography, styled } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CrunchBaseIcon, PitchBookIcon } from "../../assets";
import { CSCompanyData, _funding } from "../../types";

const CompanyCard = ({ data, loadingStatus }: { data: CSCompanyData; loadingStatus: boolean }) => {
  const navigate = useNavigate();

  const onCompanyCardDetails = () => {
    // navigate(`/companies/${data.uuid}`);
    navigate(`/company-details/${data.uuid}`);
  };

  return (
    <Card sx={{ padding: 1}} 
    //onClick={onCompanyCardDetails}
    >
      {/*Company profile logo section**/}
      <CompanyAvatar onClickNavigate={onCompanyCardDetails} namefinal={data.namefinal} websitefinal={data.websitefinal ?? "https://example.com"} locationfinal={data.locationfinal} loading={loadingStatus} />
      <Divider sx={{ marginTop: 0.5, marginBottom: 0.5 }} />
      {/*This component is for representing short data in tabular form**/}
      <OverviewShort data={data} loading={loadingStatus} />
      <Divider />
      {/*Industry tags for the current company**/}
      <ExistingInvestors data={data.company_data?.funding} />
    </Card>
  );
};

interface CompanyAvatarPops {
  websitefinal: string;
  locationfinal: string | null;
  loading: boolean;
  namefinal: string | null;
  onClickNavigate:() => void;
}

const CompanyAvatar = ({ websitefinal, locationfinal, loading, namefinal , onClickNavigate }: CompanyAvatarPops) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs="auto">
        {loading ? (
          <Skeleton variant="rounded" sx={{ width: "60px", height: "60px" }} />
        ) : (
          <Avatar
            sx={{ width: "60px", height: "60px", border: "1px solid #f2f2f2" , cursor:"pointer" }}
            src={"//logo.clearbit.com/" + websitefinal}
            variant="rounded"
            sizes="small"
            imgProps={{
              onClick:onClickNavigate,
              onError: (e: React.SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = "/images/default-corporate-image.jpg")
            }}
          />
        )}
      </Grid>
      <Grid item xs>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Stack>
            <Typography variant="h5" onClick={onClickNavigate} sx={{cursor:"pointer"}}>{namefinal ?? ""}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LanguageTwoToneIcon fontSize="small" sx={{ marginRight: 0.5 }} />{" "}
              {loading ? (
                <Skeleton variant="rounded" sx={{ width: "120px", height: "20px" }} />
              ) : (
                <Link href={`https://www.${websitefinal}`} target="_blank">
                  {websitefinal}
                </Link>
              )}
            </Box>
          </Stack>
          <Divider sx={{ marginTop: 0.25, marginBottom: 0.25 }} />

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocationOnTwoToneIcon fontSize="small" sx={{ marginRight: 0.5 }} />
            {loading ? <Skeleton variant="rounded" sx={{ width: "120px", height: "20px" }} /> : <Typography variant="overline">{locationfinal ?? "Unknown Location"}</Typography>}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const OverviewShort = ({ data, loading }: { data: CSCompanyData; loading: boolean }) => {
  const fundingData = data.company_data?.funding;
  const socials = data.company_data?.socials;
  return (
    <Box>
      <OverviewTable>
        {loading ? (
          <tbody>
            {[...new Array(6)].map((d, i) => (
              <tr key={i}>
                <td>
                  <Skeleton variant="rounded" sx={{ width: "110px" }} />
                </td>
                <td>
                  <Skeleton variant="rounded" sx={{ width: "60px" }} />
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>
                <Typography variant="body2">Last Series</Typography>
              </td>
              <td>
                <Typography variant="body2">{data?.seriesmerged ?? "-NA-"}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2">Total Funding</Typography>
              </td>
              <td>
                <Typography variant="body2">{fundingData?.funding_total ? `${parseInt(fundingData.funding_total) / 1e6} million` : `$ ${data.totalfunding} million`}</Typography>
              </td>
            </tr>

            <tr>
              <td>
                <Typography variant="body2">Founding Year</Typography>
              </td>
              <td>
                <Typography variant="body2"> {data.founding_date ? moment(data.founding_date).year() : "Not avilable"}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="body2">Employee Count</Typography>
              </td>
              <td>
                <Typography variant="body2">{data?.headcount ?? "-NA-"}</Typography>
              </td>
            </tr>
            {fundingData ? (
              <tr>
                <td>
                  <Typography variant="body2">Last Fundraising Date</Typography>
                </td>
                <td>
                  <Typography variant="body2"> {fundingData?.last_funding_at ? moment(fundingData?.last_funding_at).format("L") : "-NA-"}</Typography>
                </td>
              </tr>
            ) : null}

            {socials ? (
              <tr>
                <td>
                  <Typography variant="body2">Social Links</Typography>
                </td>
                <td>
                  <Stack spacing={0.75} direction="row" justifyContent={"flex-end"}>
                    {socials?.LINKEDIN?.url ? (
                      <a target={"_blank"} href={socials?.LINKEDIN?.url} rel="noreferrer">
                        <LinkedInIcon sx={{ height: "20px" }} />
                      </a>
                    ) : null}
                    {socials?.FACEBOOK?.url ? (
                      <a target={"_blank"} href={socials?.FACEBOOK?.url} rel="noreferrer">
                        <FacebookIcon sx={{ height: "20px" }} />
                      </a>
                    ) : null}
                    {socials?.TWITTER?.url ? (
                      <a target={"_blank"} href={socials?.TWITTER?.url} rel="noreferrer">
                        <TwitterIcon sx={{ height: "20px" }} />
                      </a>
                    ) : null}
                    {socials?.PITCHBOOK?.url ? (
                      <a target={"_blank"} href={socials?.PITCHBOOK?.url} rel="noreferrer">
                        <PitchBookIcon height={20} />
                      </a>
                    ) : null}
                    {socials?.CRUNCHBASE?.url ? (
                      <a target={"_blank"} href={socials?.CRUNCHBASE?.url} rel="noreferrer">
                        <CrunchBaseIcon height={20} />
                      </a>
                    ) : null}
                  </Stack>
                </td>
              </tr>
            ) : null}
          </tbody>
        )}
      </OverviewTable>
    </Box>
  );
};

const ExistingInvestors = ({ data }: { data?: _funding }) => {
  const investors = data?.investors;

  return (
    <Box id="industry-tags" sx={{ marginBottom: 1.5, marginTop: 1.5 }}>
      <Typography variant="h5" sx={{ marginBottom: 0.5 }}>
        Existing Investors
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", maxHeight: 60, overflowY: "auto" }} component={"div"}>
        {investors && investors.length > 0 ? (
          investors.map((investor, index) => (
            <Chip
              key={`investor-${investor.entity_urn}r-${index}`}
              label={investor.name ?? ""}
              clickable
              color="default"
              variant="outlined"
              size="small"
              sx={{
                marginRight: 0.5,
                marginBottom: 0.5
              }}
            />
          ))
        ) : (
          <Typography variant="overline" color={"red"}>
            List not avilable
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const OverviewTable = styled("table")`
  border: none;
  border-collapse: collapse;
  width: 100%;
  tr {
    td {
      padding: 2px 0;
      &:first-child {
        font-weight: 500;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
`;

export default CompanyCard;
