import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";

const bgColor = (val: number, alpha: number) => {
  if (val > 0 && val <= 25) {
    return `rgba(255, 0, 0 ,${alpha}) `;
  } else if (val > 25 && val <= 50) {
    return `rgba(241, 196, 15 , ${alpha})`;
  } else if (val > 50 && val <= 80) {
    return `rgba(52, 152, 219,${alpha})`;
  } else if (val <= 100) {
    return `rgba(7, 188, 12 , ${alpha})`;
  } else {
    return "#fff";
  }
};

const PercentileDataRenderCell = (params: GridRenderCellParams) => {
  const val = (params.formattedValue as any) as number;
  return (
    <Typography
      variant="caption"
      sx={{ p: 0.25, border: 1, borderColor: "#ccc", borderRadius: 1.5, width: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: `linear-gradient(90deg , ${bgColor(val ?? 0, 0.3)} 0% ,${bgColor(val ?? 0, 0.5)} ${val ?? 0}% , #fff ${val ?? 0}% , #fff 100%)` }}
    >
      <b> {(val ?? "0") + "%"}</b>
    </Typography>
  );
};

export default PercentileDataRenderCell;
