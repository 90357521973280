var departmentMap = {
    1: "Accounting",
    2: "Administrative",
    3: "Art and Design",
    4: "Business Development",
    5: "Community and Social Services",
    6: "Consulting",
    7: "Education",
    8: "Engineering",
    9: "Entrepreneurship",
    10: "Finance",
    11: "Healthcare Services",
    12: "Human Resources",
    13: "Information Technology",
    14: "Legal",
    15: "Marketing",
    16: "Media and Communication",
    17: "Military and Protective Services",
    18: "Operations",
    19: "Product Management",
    20: "Program and Project Manager",
    21: "Purchasing",
    22: "Quality Assurance",
    23: "Investment",
    24: "Research",
    25: "Sales",
    26: "Support",
}

var reverseDepartmentMap = {
    "Any Department": "*",
    "Accounting": 1,
    "Administrative": 2,
    "Art and Design": 3,
    "Business Development": 4,
    "Community and Social Services": 5,
    "Consulting": 6,
    "Education": 7,
    "Engineering": 8,
    "Entrepreneurship": 9,
    "Finance": 10,
    "Healthcare Services": 11,
    "Human Resources": 12,
    "Information Technology": 13,
    "Legal": 14,
    "Marketing": 15,
    "Media and Communication": 16,
    "Military and Protective Services": 17,
    "Operations": 18,
    "Product Management": 19,
    "Program and Project Manager": 20,
    "Purchasing": 21,
    "Quality Assurance": 22,
    "Investment": 23,
    "Research": 24,
    "Sales": 25,
    "Support": 26
}
var seniorityMap = {
    1: "Employee",
    2: "Employee",
    3: "Employee",
    4: "Employee",
    5: "Manager",
    6: "Manager",
    7: "Executive",
    8: "Executive",
    9: "Investor",
    10: "Founder",
}
const seniorityValueMap ={
    "Any Seniority" :  "*",
    "Founders" : "Founder",
    "Investors" : "Investor",
    "Executives": "Executive",
    "Managers": "Manager"
}
const seniorityDisplayMap ={
    "Any Seniority" :  "Any Seniority",
    "Founder" : "Founders",
    "Investor" : "Investors",
    "Executive": "Executives",
    "Manager": "Managers"
}
const tags = {
    "Acquired": "Experience at Acquired Company",
    "Venture Financing": "Experience at Venture Backed Company",
    "Fortune 500":"Fortune 500 Experience",
    "Big 3: Consulting": "Big 3: Consulting Experience",
    "Big 4: Accounting": "Big 4: Accounting Experience",
    "FAANG": "FAANG Experience",
    "PhD": "PhD",
    "MBA": "MBA",
    "Master": "Master's Degree",
    "JD": "JD",
    "MD": "MD",
    "Industry or Academic Publications": "Industry or Academic Publications",
    "Forbes": "Forbes Feature" ,
    "Fortune": "Fortune Feature",
    "5 Years+ Experience": "5 Years+ Experience" ,
    "10 Years+ Experience":  "10 Years+ Experience" ,
    "15 Years+ Experience":  "15 Years+ Experience",
    "20 Years+ Experience": "20 Years+ Experience",
    "25 Years+ Experience": "25 Years+ Experience",
    "Ivy": "Degree From Ivy League",
    "Top School": "Degree From Top School"


}
function getSeniorityFromInt(seniority){
    return seniorityMap[parseInt(seniority)] 
}
function getIntFromDepartment(departmentString) {
    return reverseDepartmentMap[departmentString]
}

function getDepartmentFromInt(departmentInt) {
    return departmentMap[parseInt(departmentInt)]
}

function getSeniorityFromDisplay(seniority){
    return seniorityValueMap[seniority]
}
function convertSeniorityToDisplay(seniority){
    return seniorityDisplayMap[seniority]
}
function convertTagToDisplay(tag){
    return tags[tag]
}
function getAllDisplayTags(){
    return Object.values(tags)
}
function getAllDisplaySeniorities(){
    return Object.keys(seniorityValueMap)
}
function getAllDepartments(){
    return Object.keys(reverseDepartmentMap)
}
//eslint-disable-next-line
export default {
    getSeniorityFromInt,
    getDepartmentFromInt,
    getIntFromDepartment,
    getSeniorityFromDisplay,
    convertSeniorityToDisplay,
    convertTagToDisplay,
    getAllDisplayTags,
    getAllDisplaySeniorities,
    getAllDepartments
    
  };