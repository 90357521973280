import { Box, Link } from "@mui/material";
import ShowMore from "../../../../components/ShowMore";
import { EmployeeData } from "../../../../types";
import ExpandingSection from "../ExpandingSection";
import ExperienceCard from "../ExperienceCard";
import React from 'react'

interface PropTypes {
  className?: string;
  data: EmployeeData;
}

const Projects = (props: PropTypes) => {
  const { data } = props;

  const projectData = (
    <ShowMore
      data={data["projects"].map(project => {
        var date = project.projectstart && project.projectstart !== "-" && project.projectend && project.projectend !== "-" ? " - " : "";
        if (project.projectstart && project.projectstart !== "-") {
          date = project.projectstart + date;
        }
        if (project.projectend && project.projectend !== "-") {
          date += project.projectend;
        }
        return (
          <ExperienceCard
            data={{
              header:
                project.projectlink && project.projectlink !== "-" ? (
                  <Link color="inherit" href={project.projectlink}>
                    {project.projecttitle}
                  </Link>
                ) : (
                  project.projecttitle
                ),
              subTitle1: date,
              description: project.description
            }}
          />
        );
      })}
      trimIndex={3}
    />
  );
  return (
    <Box sx={{ width: "100%" }}>
      <ExpandingSection title="Projects" itemIndex={"projecttitle"} data={data["projects"]} fullBody={projectData} />
    </Box>
  );
};

export default Projects;
