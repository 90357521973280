import { Box, Typography } from "@mui/material";
import { EmployeeData } from "../../../../types";
import ExperienceCard from "../ExperienceCard";
import React from 'react'

interface PropTypes {
  className?: string;
  data: EmployeeData;
}

const Skills = (props: PropTypes) => {
  const { data } = props;
  // const history = useHistory();
  const tags = data.skills.map(value => {
    return value.skill;
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h4" component="h2">
        {"Skills (" + data.skills.length + ")"}
      </Typography>
      <ExperienceCard
        data={{
          tags: tags
        }}
      />
    </Box>
  );
};

export default Skills;
