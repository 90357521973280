import {colors} from '@mui/material';
import { ThemePaletteOptions} from "../types"


const white = '#FFFFFF';
const black = '#000000';

const themePalette:ThemePaletteOptions = {
  common:{
    black,
    white
  },
  primary: {
    contrastText: "#FFFFFF",
    dark: "#FA2217",
    main: "#FA2217",
    light: "#FA2217"
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: "#494A4E",
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};


export default themePalette