import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NavigationIcon from "@mui/icons-material/Navigation";
import { Skeleton } from "@mui/lab";
import { Divider, Fab, Grid, Pagination, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import DataNotFound from "../../assets/Svgs/DataNotFound";
import { CompanyCard } from "../../components";
import { CSCompanyData, CompanySearchResponse } from "../../types";

interface PropType {
  loading: boolean;
  data?: CompanySearchResponse;
  page: number;
  onChange: (type: string, page: number) => void;
}
type LIST_VIEWS = "GRID" | "LIST";

const CompanyListBody = (props: PropType) => {
  const theme = useTheme();
  const { loading, data, page, onChange } = props;
  const [showScrollTop, setShowScrollTop] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewType, setVieType] = useState<LIST_VIEWS>("GRID");

  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

  // Effect for the showing and hiding of the scroll top buttn state
  React.useEffect(() => {
    const handleScroll = (event: Event) => {
      const scrollY = window.scrollY;
      if (scrollY > 200) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // defining view object for the multiple view
  const DATA_VIEWS = {
    GRID: (data: CSCompanyData[]) => (
      <Grid container spacing={2}>
        {data.map(company => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={company.uuid}>
            <CompanyCard data={company} loadingStatus={loading} />
          </Grid>
        ))}{" "}
      </Grid>
    ),
    LIST: (data: CSCompanyData[]) => null
  };

  return (
    <Box>
      {data ? (
        <Stack alignItems={"center"} justifyContent={"space-between"} direction={"row"} sx={{ marginBottom: 2 }}>
          <ToggleButtonGroup size="small" value={viewType} exclusive aria-label="reminder-preview-type" color="primary">
            <ToggleButton value={"GRID"} aria-label="count-notes">
              <CalendarTodayIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="LIST" aria-label="2-at-once">
              <AutoAwesomeMosaicIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>

          {/* show top pagination while screen size is larger the md breakpoint */}
          {!isBelowMd ? <Pagination color="primary" count={Math.max(Math.min(Math.ceil(data?.count[0].count / 25), 20), 1)} page={page} onChange={(e, v) => onChange("pageNum", v)} /> : null}

          {/* total count for the search results */}
          <Typography variant="body1" color="textSecondary" component="p" sx={{ textAlign: "right" }}>
            {"Showing " + (data.count[0].count < 500 ? data.count[0].count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "500+") + " results"}
          </Typography>
        </Stack>
      ) : null}

      {/* Loading skeleton */}
      {loading && viewType === "GRID" ? <SkeltonListBody /> : null}

      {/* Mapping list with the API output GridView; */}
      {data ? (
        data.company && data.company.length > 0 ? (
          DATA_VIEWS[viewType](data.company)
        ) : (
          <Box>
            <DataNotFound />
          </Box>
        )
      ) : null}

      {/* show bottom pagination while data is avilable to show */}
      {!loading && data ? <Pagination sx={{ marginTop: 2, display: "flex", justifyContent: "center" }} color="primary" count={Math.max(Math.min(Math.ceil(data.count[0].count / 25), 20), 1)} page={page} onChange={(e, v) => onChange("pageNum", v)} /> : null}

      {/* conditional rendering for the scroll top button */}
      {showScrollTop ? (
        <Fab variant="extended" color="primary" sx={{ position: "fixed", bottom: 16, right: 16 }} component={NavHashLink} smooth to="#">
          <NavigationIcon sx={{ mr: 1 }} />
          Navigate to top
        </Fab>
      ) : null}
    </Box>
  );
};

const SkeltonListBody = () => {
  return (
    <Grid container spacing={2}>
      {[...new Array(10)].map((d, i) => (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={`se_skl_${i}`}>
          <Box component={Paper} elevation={1} sx={{ padding: 1.25 }}>
            <Stack direction={"row"} spacing={1.25}>
              <Skeleton variant="rounded" sx={{ width: 60, height: 60 }} />

              <Box sx={{ flexGrow: 1, flexBasis: 0, maxWidth: "100%" }}>
                <Skeleton variant="text" sx={{ height: "25px", marginBottom: 0.25 }} />
                <Skeleton variant="text" sx={{ height: "10px" }} />
                <Divider sx={{ marginTop: 0.25, marginBottom: 0.25 }} />
                <Skeleton variant="text" sx={{ height: "10px" }} />
              </Box>
            </Stack>
            <Divider sx={{ marginTop: 0.5, marginBottom: 0.5 }} />
            <Box>
              <Skeleton variant="text" sx={{ height: "10px", marginBottom: 0.35 }} />
              <Skeleton variant="text" sx={{ height: "10px", marginBottom: 0.35 }} />
              <Skeleton variant="text" sx={{ height: "10px", marginBottom: 0.35 }} />
              <Skeleton variant="text" sx={{ height: "10px", marginBottom: 0.35 }} />
            </Box>
            <Divider sx={{ marginTop: 0.5, marginBottom: 0.5 }} />
            <Box>
              <Skeleton variant="text" sx={{ height: "20px", marginBottom: 0.25 }} width={100} />
              <Stack direction={"row"} spacing={1}>
                {[...new Array(5)].map((d, j) => (
                  <Skeleton key={`se_chip_${i}-${j}`} variant="rounded" width={50} sx={{ marginRight: 0.25 }} />
                ))}
              </Stack>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CompanyListBody;
