import Delimiter from "@editorjs/delimiter";
import Header from "@editorjs/header";
import LinkTool from "@editorjs/link";
import List from "@editorjs/list";
import Underline from "@editorjs/underline";

export const EDITOR_JS_TOOLS = {
  list: List,
  linkTool: LinkTool,
  header: Header,
  delimiter: Delimiter,
  underline: Underline
};

export const DEMO_DATA = {
  title: "This is title for notes",
  data: {
    time: 1650892937406,
    blocks: [
      {
        id: "XxpUj_Q5Vd",
        type: "paragraph",
        data: {
          text:
            "Hello this is&nbsp;Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
      },
      {
        id: "JhF6rpbhno",
        type: "list",
        data: {
          style: "unordered",
          items: ["This is hello&nbsp;", "list item 2", "list item 3", "list item 4"]
        }
      },
      {
        id: "tR5OdpnpC4",
        type: "paragraph",
        data: {
          text:
            "Thuis is&nbsp; Ipsum&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
      }
    ],
    version: "2.23.2"
  }
};
