import { useMutation, useQuery  , gql} from "@apollo/client";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Checkbox, Chip, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { withErrorBoundary } from "../../../../components/ErrorBoundary";
import { interestedWeeklyLead } from "../../../../graphql/mutations";
import { getLeadListRejectReasons } from "../../../../graphql/queries";
import { DPbaseProps } from "../../../../types";

interface HeadingProps extends DPbaseProps {
  namefinal: string;
  finaldescription: string;
  interesting?: boolean;
  dategenerated?: string;
  uuid: string;
}

const CompanyHeading = ({ namefinal, finaldescription, loading, id, dategenerated, interesting, uuid }: HeadingProps) => {
  const [interested, setInterested] = useState(false);
  const [updateInteresting, { loading: interestingMutationLoading }] = useMutation(gql(interestedWeeklyLead), { ignoreResults: true });
  const { data, loading: queryLoading } = useQuery<{ getLeadListRejectReasons: { id: number; title: string }[] }>(gql(getLeadListRejectReasons));
  //state for the storing anchor element for the showing popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  //state for the list of check reasons.
  const [checked, setChecked] = React.useState<{ id: number; title: string }[]>([]);

  React.useEffect(() => {
    setInterested(Boolean(interesting));
  }, [interesting]);

  const handleCheckedClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    if (interested) setAnchorEl(event.currentTarget);
    // const response = await updateInteresting({ variables: { input: { uuid: id, interested: interesting, comment: checked } } });
  };

  // checkbox toggling
  const handleToggle = (value: { id: number; title: string }) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClose = async () => {
    if (checked.length > 0) {
      const response = await updateInteresting({ variables: { input: { uuid, interested: !interested, comment: checked.map(cmt => cmt.id) } } });
      setInterested(response.data.interestedWeeklyLead.interesting);
    }
    setAnchorEl(null);
  };

  const checkBoxPopoverOpen = Boolean(anchorEl);
  const popoverId = checkBoxPopoverOpen ? `rejectReasonChangePopper-${id}` : undefined;

  return (
    <Box id={id ?? ""}>
      <Box id="company-header" sx={{ marginBottom: 1.5 }}>
        {queryLoading ? (
          <Skeleton sx={{ width: "80px", height: "32px" }} />
        ) : (
          <Stack direction={"row"} alignItems="center">
            <Typography variant="h2" component={"h1"} sx={{ marginBottom: 0.5, marginRight: 3 }}>
              {namefinal}
            </Typography>
            {dategenerated ? <Chip size="small" icon={<AddTaskIcon />} label={`Suggested on ${moment(dategenerated).format("L")}`} variant="outlined" color="info" sx={{ marginRight: 1 }} /> : null}
            {interested !== null ? <Chip size="small" icon={interested ? <CheckIcon /> : <CloseIcon />} label={interested ? "Interested" : "Not Interested"} variant="outlined" color={interested ? "success" : "error"} onClick={handleCheckedClick} /> : null}
            <Popover
              id={popoverId}
              open={checkBoxPopoverOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
            >
              <Box sx={{ maxWidth: 500 }}>
                <Box sx={{ p:2, paddingBottom: 2.25 }}>
                  <Typography variant="h5">
                    Mark this lead as not interesting?<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                  <Typography variant="subtitle1">Please select at least one reason why ? </Typography>
                </Box>
                <Divider />

                <List sx={{ width: "100%", maxWidth: 360, maxHeight: 320, overflowY: "auto", bgcolor: "background.paper" }} dense>
                  {queryLoading &&
                    [0, 1, 2, 3, 4, 5].map(val => (
                      <ListItem key={`skeleton-${val}`} disablePadding dense>
                        <Skeleton sx={{ width: 300, height: 36, marginLeft: 1, marginRight: 1 }} />
                      </ListItem>
                    ))}
                  {data &&
                    data?.getLeadListRejectReasons.map(reason => {
                      const labelId = `reject-reason-list-${reason.id}`;

                      return (
                        <ListItem key={`reasonListItem-${reason.id}`} disablePadding dense>
                          <ListItemButton disabled={interestingMutationLoading} role={undefined} onClick={handleToggle(reason)} dense>
                            <ListItemIcon>
                              <Checkbox edge="start" checked={checked.indexOf(reason) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={reason.title} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </List>
              </Box>
            </Popover>
          </Stack>
        )}
        <Typography variant="body2">{/* <b>CEO:-</b> {"Someone ceo"} */}</Typography>
      </Box>
      <Box id="description" sx={{ marginBottom: 1.5 }}>
        <Typography variant="h5" sx={{ marginBottom: 0.5 }}>
          About
        </Typography>
        {loading ? (
          <>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} width="30%" />
          </>
        ) : (
          <Typography variant="body1">{finaldescription}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default withErrorBoundary(CompanyHeading);
