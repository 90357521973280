import { useLazyQuery, useMutation , gql } from "@apollo/client";
import NavigationIcon from "@mui/icons-material/Navigation";
import { Box, Card, Divider, Grid } from "@mui/material";
import Fab from "@mui/material/Fab";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { userActivityLog } from "../../graphql/mutations";
import { companyPersonDataByUUIDQuery } from "../../graphql/queries";
import { HarmonicData, PersonData } from "../../types";
import { OverviewShort } from "./Components/CompanyOverViewShort";
import { Verticals } from "./Components/IndustryTags";
import { CompanyInvestors } from "./Components/InvestorsList";
import TeamOverview from "./Components/TeamOverview";
import { CompanyLogo, Heading } from "./Components/Utils";
import { withErrorBoundary } from "../../components/ErrorBoundary";


// const MENU_LIST = [
//   {
//     id: "teamOverview",
//     title: "Team Overview",
//     submenu: [
//       {
//         id: "headCount",
//         title: "Head Count"
//       },
//       {
//         id: "teamOverview-team",
//         title: "Team"
//       },
//       {
//         id: "teamOverview-recent-team-changes",
//         title: "Recent Team changes"
//       },
//       {
//         id: "teamOverview-headcountChanges",
//         title: "Headcount  Changes"
//       },
//       {
//         id: "teamOverview-jobOpenings",
//         title: "Job Opening"
//       }
//     ]
//   },
//   {
//     id: "growthSignals",
//     title: "Growth Signals",
//     submenu: [
//       {
//         id: "growth-web-traffic",
//         title: "Web Traffic"
//       },
//       // {
//       //   id: "growthS-app-downloads",
//       //   title: "App downloads"
//       // },
//       // {
//       //   id: "growthS-github-stars",
//       //   title: "Github stars"
//       // },
//       // {
//       //   id: "githubCommits",
//       //   title: "Github commits"
//       // },
//       // {
//       //   id: "g2-review-growth",
//       //   title: "G2 Review growth"
//       // },
//       // {
//       //   id: "growth-backlinks",
//       //   title: "Backlinks"
//       // },
//       {
//         id: "growth-signals-analysis",
//         title: "Growth Analysis"
//       },
//       {
//         id: "growth-signals-followers",
//         title: "Followers"
//       }
//     ]
//   },
//   {
//     id: "company-review",
//     title: "Reviews",
//     submenu: [
//       {
//         id: "cr-g2-review",
//         title: "G2 Review"
//       },
//       {
//         title: "Capterra Reviews",
//         id: "cr-capterra-reviews"
//       }
//     ]
//   },
//   {
//     id: "details-competitors",
//     title: "Competitors"
//   },
//   {
//     id: "newsSection",
//     title: "News",
//     submenu: [
//       {
//         id: "news-submenu",
//         title: "News"
//       },
//       {
//         id: "websiteChanges",
//         title: "Website Changes"
//       },
//       {
//         id: "intellectualProperties",
//         title: "Intellectual Properties"
//       }
//     ]
//   },
//   {
//     id: "contact",
//     title: "Contact",
//     submenu: [
//       {
//         id: "contactHistory",
//         title: "Contact History"
//       },
//       {
//         id: "emails",
//         title: "Emails"
//       },
//       {
//         id: "connect-to-renegade",
//         title: "Connect to Renegade"
//       }
//     ]
//   },
//   {
//     id: "company-data",
//     title: "Company Data",
//     submenu: [
//       {
//         id: "files",
//         title: "Files"
//       },
//       {
//         id: "financial",
//         title: "Financial"
//       }
//     ]
//   },
//   {
//     id: "ranegade-data",
//     title: "Renegade Data",
//     submenu: [
//       {
//         id: "notes",
//         title: "Notes"
//       },
//       {
//         id: "remainders",
//         title: "Remainders"
//       },
//       {
//         id: "scoreCards",
//         title: "Score Cards"
//       },
//       {
//         id: "codaDocuments",
//         title: "Cods Documents"
//       },
//       {
//         id: "loomVideos",
//         title: "Loom Videos"
//       }
//     ]
//   },
//   {
//     id: "crmData",
//     title: "CRM"
//   },
//   {
//     id: "research",
//     title: "Research",
//     submenu: [
//       {
//         id: "productOvreview",
//         title: "Product Overview"
//       },
//       {
//         id: "marketOvreview",
//         title: "Market Overview"
//       },
//       {
//         id: "keyQuestion",
//         title: "Key Question For diligence"
//       },
//       {
//         id: "traction",
//         title: "Traction"
//       },
//       {
//         id: "knownCustomers",
//         title: "Known Customers"
//       }
//     ]
//   }
// ];

interface HarmonicCompanyData {
  CompanyPersonDataByUUIDQuery: {
    result: {
      company_data: HarmonicData;
      company_name: string;
      company_url: any;
      company_uuid: string;
      company_date: string;
      person_data: PersonData[];
      dategenerated: string;
      interesting: boolean;
    };
  };
}

// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CompanyDetails = () => {
  const { id } = useParams<{ id: string }>();
  let routeRefs = useRef([]);
  // const { state } = useLocation();
  let query = useQuery();

  const useMutationRef = useRef<any>(null);

  const [willCollapse, setWillCollapse] = useState(false);

  const [getCompanyData, { data: harmonicData, loading: harmonicDataLoading }] = useLazyQuery<HarmonicCompanyData>(gql(companyPersonDataByUUIDQuery), {
    variables: {
      uuid: id
    }
  });
  const [updateActivity, { loading }] = useMutation(gql(userActivityLog), { ignoreResults: true });

  // React.useEffect(() => {
  //   // if (harmonicData) console.log("harmonicData::", harmonicData);
  // }, [harmonicData]);

  React.useLayoutEffect(() => {
    const handleScroll = (event: Event) => {
      const scrollY = window.scrollY;
      if (scrollY > 200) {
        setWillCollapse(true);
      } else {
        setWillCollapse(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    const fromPage = query.get("fromPage");
    if (fromPage && !loading) {
      if (!useMutationRef.current)
        useMutationRef.current = updateActivity({
          variables: {
            input: {
              activityType: "PAGE_VIEW",
              params: JSON.stringify({ fromPage: fromPage ?? "WeeklyLeadList", toPage: "CompanyDetail", url: "/company-details/" + id, uuid: id })
            }
          }
        });
    }
    // return () => {

    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box sx={{ padding: 1 }} component="div">
      <Grid container spacing={1}>
        <Grid item md={3} component={"aside"}>
          <Card
            sx={{ position: "sticky", padding: 1, top: 70 }}
            className={clsx({
              willCollapse: willCollapse
            })}
          >
            {/*Company profile logo section**/}
            <CompanyLogo website={harmonicData?.CompanyPersonDataByUUIDQuery.result.company_data.website!} location={harmonicData?.CompanyPersonDataByUUIDQuery.result.company_data.location!} loading={harmonicDataLoading} />
            {!willCollapse ? <Divider sx={{ marginTop: 0.5, marginBottom: 0.5 }} /> : null}
            {/*This component is for representing short data in tabular form**/}
            {!willCollapse ? <OverviewShort data={harmonicData?.CompanyPersonDataByUUIDQuery.result.company_data!} loading={harmonicDataLoading} /> : null}
            {!willCollapse ? <Divider /> : null}
            {/*Industry tags for the current company**/}
            {!willCollapse ? <Verticals data={harmonicData?.CompanyPersonDataByUUIDQuery.result.company_data.tags} loading={harmonicDataLoading} /> : null}
          </Card>
          <MenusList />
        </Grid>
        <Grid item md={9} component={"section"} className="details">
          <Card sx={{ padding: 1 }}>
            <Heading
              uuid={id!}
              interesting={harmonicData?.CompanyPersonDataByUUIDQuery.result.interesting}
              dategenerated={harmonicData?.CompanyPersonDataByUUIDQuery.result.dategenerated}
              loading={harmonicDataLoading}
              namefinal={harmonicData?.CompanyPersonDataByUUIDQuery?.result?.company_name!}
              finaldescription={harmonicData?.CompanyPersonDataByUUIDQuery?.result?.company_data?.description!}
            />

            <CompanyInvestors id="companyInvestors" data={harmonicData?.CompanyPersonDataByUUIDQuery.result.company_data.funding!} loading={harmonicDataLoading} />
            <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
            <TeamOverview id="teamOverview" loading={harmonicDataLoading} data={harmonicData?.CompanyPersonDataByUUIDQuery.result.company_data.traction_metrics} routeRefs={routeRefs} uuid={id} />

            {/* <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} /> */}
            {/* <GrowthSignals id="growthSignals" data={data?.CompanyUUIDQuery!} routeRefs={routeRefs} /> */}

            {/* <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
            {data?.CompanyUUIDQuery && <Reviews data={data?.CompanyUUIDQuery!} id={"company-review"} />}

            <Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />
            <Competitors id="details-competitors" />
            <NewsSection id={"newsSection"} />
            <RenegadeData id={"ranegade-data"} />
            <Crm data={data?.CompanyUUIDQuery} id={"crm"} />
            <Research /> */}
          </Card>
        </Grid>
      </Grid>
      {/* action button to navigate to Top */}
      {willCollapse ? (
        <Fab variant="extended" color="primary" sx={{ position: "fixed", bottom: 16, right: 16 }} component={NavHashLink} smooth to="#">
          <NavigationIcon sx={{ mr: 1 }} />
          Navigate to top
        </Fab>
      ) : null}
    </Box>
  );
};

const MenusList = () => {
  // const scrollWithOffset = (el: HTMLElement) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = -65;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  // };
  // const [expanded, setExpanded] = useState("");
  return null;
  // return (
  //   <Card sx={{ marginTop: 1, position: "sticky", top: 180, maxWidth: "100%" }}>
  //     <MenuList sx={{ maxHeight: 560, overflowY: "auto" }}>
  //       {MENU_LIST.map(menu => (
  //         <MenuItem sx={{ padding: 0 }} key={menu.id}>
  //           <Accordion expanded={expanded === menu.id} sx={{ width: "100%", boxShadow: "none", padding: "0px" }} onClick={() => setExpanded(menu.id)}>
  //             <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls={`menu-${menu.id}`} id={`menu-id-${menu.id}`}>
  //               <Typography component={NavHashLink} smooth scroll={el => scrollWithOffset(el)} to={`#${menu.id}`}>
  //                 {menu.title}
  //               </Typography>
  //             </AccordionSummary>
  //             <AccordionDetails sx={{ paddingLeft: "15px" }}>
  //               {menu?.submenu &&
  //                 menu.submenu.map(submenu => (
  //                   <MenuItem component={NavHashLink} smooth scroll={el => scrollWithOffset(el)} to={`#${submenu.id}`} key={submenu.id}>
  //                     {submenu.title}
  //                   </MenuItem>
  //                 ))}
  //             </AccordionDetails>
  //           </Accordion>
  //         </MenuItem>
  //       ))}
  //     </MenuList>
  //   </Card>
  // );
};

// export default withErrorBoundary(CompanyDetails, <div>Something Wrong in CompanyDetails Component </div>);
export default withErrorBoundary(CompanyDetails , <div>Something Wrong in CompanyDetails Component </div>);
