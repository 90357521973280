import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Stack } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CrunchBaseIcon, PitchBookIcon } from "../../assets";


/**
 * @description Render Socials link cell components
 * @param props GridRenderEditCellParams
 * @returns JSX
 */
const SocialsRenderCell = (params: GridRenderCellParams) => {
  const socials = params?.value;
  return (
    <Stack spacing={0.75} direction="row" justifyContent={"flex-end"}>
      {socials?.LINKEDIN?.url ? (
        <a target={"_blank"} href={socials?.LINKEDIN?.url} rel="noreferrer">
          <LinkedInIcon sx={{ height: "20px" }} />
        </a>
      ) : null}
      {/* {socials?.FACEBOOK?.url ? (
        <a target={"_blank"} href={socials?.FACEBOOK?.url} rel="noreferrer">
          <FacebookIcon sx={{ height: "20px" }} />
        </a>
      ) : null}
      {socials?.TWITTER?.url ? (
        <a target={"_blank"} href={socials?.TWITTER?.url} rel="noreferrer">
          <TwitterIcon sx={{ height: "20px" }} />
        </a>
      ) : null} */}
      {socials?.PITCHBOOK?.url ? (
        <a target={"_blank"} href={socials?.PITCHBOOK?.url} rel="noreferrer">
          <PitchBookIcon height={20} />
        </a>
      ) : null}
      {socials?.CRUNCHBASE?.url ? (
        <a target={"_blank"} href={socials?.CRUNCHBASE?.url} rel="noreferrer">
          <CrunchBaseIcon height={20} />
        </a>
      ) : null}
    </Stack>
  );
};


export default SocialsRenderCell;