import { Box, Card, TextField, Button } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { toast } from "react-toastify";

//https://docs.google.com/forms/d/e/1FAIpQLSccy9txrsARbsNarH0FXxOXIGDfU22mePlxIVQ75bQDCRzB6g/viewform?embedded=true

const CompanyAnalytics = () => {
  const [companyAnalyticsSrc, setCASrc] = useState("");
  const [url, setUrl] = React.useState("");
  const [isPreview, setIsPreview] = React.useState(false);

  const previewHandler = () => {
    if (url && url.includes("https://")) {
      setCASrc(url);
      setIsPreview(true);
    } else toast.warn("Please enter a valid url");
  };

  const saveHandler = () => {
    setIsPreview(false);
    localStorage.setItem("ifSrcCA", url);
  };

  const cancelPreview = () => {
    unstable_batchedUpdates(() => {
      setUrl("");
      setIsPreview(false);
      setCASrc("");
    });
  };

  const removeIframeLink = () => {
    cancelPreview();
    localStorage.removeItem("ifSrcCA");
  };

  React.useEffect(() => {
    const _localSrc = localStorage.getItem("ifSrcCA");
    if (_localSrc && _localSrc !== "undefined") {
      setCASrc(_localSrc);
    }
  }, []);

  if (!companyAnalyticsSrc)
    return (
      <Stack justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", height: "100%" }} spacing={2}>
        <Box component={Card} elevation={1} sx={{ minWidth: 500, width: "60vw", p: 10, textAlign: "center" }}>
          <TextField title="iframe url" placeholder="Write here url of the iframe" type="url" name="companyAnalyticsSrc" value={url} onChange={e => setUrl(e.target.value)} fullWidth required sx={{ marginBottom: 4 }} />

          <Button type="button" onClick={previewHandler}>
            Preview
          </Button>
        </Box>
      </Stack>
    );

  return (
    <Box
      sx={{
        position: "relative",
        "& .iframeRoot": {
          width: "100%",
          height: "calc(100vh - 72px)",
          borderWidth: "0px"
        }
      }}
      //   component={Card}
    >
      {companyAnalyticsSrc ? (
        <Box sx={{ position: "absolute", top: 10, left: 15 }}>
          <Button type="button" onClick={removeIframeLink}>
            Remove current url
          </Button>
        </Box>
      ) : null}
      {isPreview ? (
        <Stack direction={"row"} justifyContent={"space-between"} component={Card} elevation={2} sx={{ p: 2, margin: "0 auto", marginTop: 3, maxWidth: 600, textAlign: "center" }}>
          <Button onClick={saveHandler}>Save locally the current iframe url</Button>

          <Button variant="outlined" type="button" onClick={cancelPreview}>
            Cancel
          </Button>
        </Stack>
      ) : null}
      <iframe title="Company analytics visualization" sandbox="allow-forms allow-scripts allow-same-origin allow-popups" className={"iframeRoot"} src={isPreview ? url : companyAnalyticsSrc}></iframe>
    </Box>
  );
};

export default CompanyAnalytics;
