import { Link, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

const CeoRenderCell = (params : GridRenderCellParams) => {
    if (params.value?.linkedinUrl)
      return (
        <Typography component={Link} href={params.value?.linkedinUrl} target="blank">
          {params.value?.name}
        </Typography>
      );
    return <Typography variant="subtitle1">{params.value?.name}</Typography>;
  }

export default CeoRenderCell