import { Box, Link, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

interface PropTypes {
  data: string;
  trimIndex: number;
  isInline?: boolean;
}

function ShowMoreText(props: PropTypes) {
  const { data, trimIndex } = props;
  const theme = useTheme();
  const [shown, setShown] = useState(false);
  const shortData = data.length > trimIndex ? data.substring(0, trimIndex) : data;

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setShown(!shown);
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Box sx={{ width: "100%" , minHeight:150}}>
      <Typography variant="body1" >
        {!shown ? shortData : data}
      </Typography>

      {data.length > trimIndex && (
        <Link href="#" sx={{ fontSize: theme.typography.pxToRem(15), color: theme.palette.info.main, marginLeft: "5px" }} onClick={handleClick} variant="body2">
          {shown ? "Show Less" : "Show More"}
        </Link>
      )}
    </Box>
  );
}

export default ShowMoreText;
