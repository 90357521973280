import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Card, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import Document from "@tiptap/extension-document";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorContent, EditorOptions, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import "./editorStyle.scss";
import React from "react";
import { withErrorBoundary } from "../ErrorBoundary";

interface NotesEditorProps {
  onSaveNotes: (data: JSONContent) => void;
  content?: JSONContent;
  editable?: boolean;
  loading?: boolean;
  newNotes?: boolean;
  cancelNotesEditView?: () => void;
}

const CustomDocument = Document.extend({
  content: "heading block*"
});

const NotesEditor = ({ onSaveNotes, cancelNotesEditView, content, editable, loading }: NotesEditorProps) => {
  const editorOptions: Partial<EditorOptions> = {
    extensions: [
      CustomDocument,
      Underline,
      StarterKit.configure({
        document: false
      }),
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === "heading") {
            return "What’s the title?";
          }

          return "Can you add some further context?";
        }
      })
    ],
    editorProps: {
      attributes: {
        class: "notedEditorContainer"
      }
    }
    // content: htmlContent,
    // editable: editable
  };

  if (content) editorOptions.content = content;
  if (editable) editorOptions.editable = editable;

  const editor = useEditor(editorOptions);

  // React.useEffect(() => {
  //   setMenusOpen(false);
  //   if (editor) setMenusOpen(true);
  // }, [editor]);

  const exportNotesData = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const content = editor?.getJSON();
    if (content) onSaveNotes(content);
  };

  /** Close notes view */
  const closeNotesView = () => {
    if (cancelNotesEditView) cancelNotesEditView();
  };

  return (
    <>
      {/* {openMenus && (
        <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor ?? undefined}>
          <ToggleButtonGroup sx={{ background: "#fff" }} value={[editor?.isActive("bold") ? "bold" : "", editor?.isActive("italic") ? "italic" : "", editor?.isActive("underline") ? "underline" : ""]} color="primary" size="small">
            <ToggleButton
              value="bold"
              aria-label="bold"
              onClick={() =>
                editor
                  ?.chain()
                  .focus()
                  .toggleBold()
                  .run()
              }
            >
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton
              value="italic"
              aria-label="italic"
              onClick={() =>
                editor
                  ?.chain()
                  .focus()
                  .toggleItalic()
                  .run()
              }
            >
              <FormatItalicIcon />
            </ToggleButton>
            <ToggleButton
              value="underline"
              aria-label="underline"
              onClick={() =>
                editor
                  ?.chain()
                  .focus()
                  .toggleUnderline()
                  .run()
              }
            >
              <FormatUnderlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </BubbleMenu>
      )} */}

      {/* {openMenus && <FloatingMenuOptions editor={editor ?? undefined} />} */}
      <Stack spacing={2}>
        <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: "100%" }}>
          <Typography variant="h6">{"Add new note"}</Typography>
          <Stack direction={"row"} spacing={1}>
            <IconButton color="primary" size="small" disabled={loading} onClick={exportNotesData} title="Save current note">
              {loading ? <CircularProgress size={24} /> : <SaveIcon fontSize="inherit" />}
            </IconButton>
            <IconButton color="secondary" size="small" title="Cancel this note" onClick={closeNotesView}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </Stack>
        <Box component={Card} elevation={0} sx={{ p: 2, minHeight: 300, maxHeight: 500, overflowY: "auto", backgroundColor: "#f1f1f1!important" }}>
          <EditorContent editor={editor} readOnly={Boolean(loading)} />
        </Box>
      </Stack>
    </>
  );
};

export default withErrorBoundary(NotesEditor, <div>Error in notes editor</div>);
