import { gql, useQuery } from "@apollo/client";
import { Box, Grid, Typography } from "@mui/material";
import { growthDataQuery } from "../../../../graphql/queries";
import React from "react";
import { CompanyData, GrowthDataType, RelationshipObject } from "../../../../types";
import RelationshipWrapper from "../RelationshipWrapper";



const parseRelationships = (data: GrowthDataType, type: "vendor" | "partner" | "integration" ) => {
  if (data[type] != null && data[type].length > 0) {
    var outList:RelationshipObject[] = [];
    for (const elem  of data[type]) {
      outList.push({
        relationshipName: elem.companyname,
        relationshipURL: elem.counterpartyurl,
        relationshipStartDate: elem.firstdate,
        relationshipSource: elem.sourceurl
      });
    }
    return outList;
  } else {
    return [];
  }
};
type partnershipOverviewProps = {
  data: CompanyData;
};

function PartnershipOverview(props: partnershipOverviewProps) {
  const { data } = props;

  const [vendorOffset, setVendorOffset] = React.useState(0);
  const [partnershipOffset, setPartnershipOffset] = React.useState(0);
  const [integrationOffset, setIntegrationOffset] = React.useState(0);

  const {  loading: vendorLoading, data: vendorData } = useQuery(gql(growthDataQuery), {
    variables: {
      CompanyUUID: data.uuid,
      QueryType: "vendor",
      Offset: vendorOffset
    }
  });

  const {  loading: partnershipLoading, data: partnershipData } = useQuery(gql(growthDataQuery), {
    variables: {
      CompanyUUID: data.uuid,
      QueryType: "partnership",
      Offset: partnershipOffset
    }
  });

  const { loading: integrationLoading, data: integrationData } = useQuery(gql(growthDataQuery), {
    variables: {
      CompanyUUID: data.uuid,
      QueryType: "integration",
      Offset: integrationOffset
    }
  });


  const handleButtonChange = (type: any, buttonType: any) => {
    if (type === "Vendors") {
      if (buttonType === "Next") {
        setVendorOffset(vendorOffset + 25);
      } else {
        setVendorOffset(vendorOffset - 25);
      }
    } else if (type === "Partnerships") {
      if (buttonType === "Next") {
        setPartnershipOffset(partnershipOffset + 25);
      } else {
        setPartnershipOffset(partnershipOffset - 25);
      }
    } else if (type === "Integrations") {
      if (buttonType === "Next") {
        setIntegrationOffset(integrationOffset + 25);
      } else {
        setIntegrationOffset(integrationOffset - 25);
      }
    }
  };
  var partnershipDataOut = partnershipData ? parseRelationships(partnershipData.GrowthDataQuery, "partner") : [];
  var vendorDataOut = vendorData ? parseRelationships(vendorData.GrowthDataQuery, "vendor") : [];
  var integrationDataOut = integrationData ? parseRelationships(integrationData.GrowthDataQuery, "integration") : [];

  return (
    <Box sx={{width:"100%"}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4" component="h2">
            {"Customers & Partnerships"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RelationshipWrapper
            cardData={vendorDataOut ? vendorDataOut.slice(0, 24) : vendorDataOut}
            loading={vendorLoading}
            cardTitle={"Customers"}
            handleChange={(buttonType: any) => handleButtonChange("Vendors", buttonType)}
            prevDisabled={vendorOffset === 0}
            errorMessage={"No Customers Found"}
            nextDisabled={vendorDataOut.length < 24}
          />
        </Grid>
        <Grid item xs={12}>
          <RelationshipWrapper
            cardData={partnershipDataOut.slice(0, 24)}
            loading={partnershipLoading}
            cardTitle={"Partnerships"}
            handleChange={(buttonType: any) => handleButtonChange("Partnerships", buttonType)}
            prevDisabled={partnershipOffset === 0}
            errorMessage={"No Partnerships Found"}
            nextDisabled={partnershipDataOut.length < 24}
          />
        </Grid>

        <Grid item xs={12}>
          <RelationshipWrapper
            cardData={integrationDataOut.slice(0, 24)}
            loading={integrationLoading}
            cardTitle={"Integrations"}
            handleChange={(buttonType: any) => handleButtonChange("Integrations", buttonType)}
            prevDisabled={integrationOffset === 0}
            errorMessage={"No Integrations Found"}
            nextDisabled={integrationDataOut.length < 24}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PartnershipOverview;
