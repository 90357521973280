import { GridInitialState } from "@mui/x-data-grid-pro";

/** get initial state from session store */
export const loadGridInitialState = (): GridInitialState => {
  let initialState: GridInitialState = {
    pinnedColumns: { left: ["company_name"], right: ["notes"] },
    columns: {
      columnVisibilityModel: {
        industry_tags: false,
        funnel_stage: false,
        source: false,
        source_type: false,
        live_deal: false,
        discuss: false
      }
    }
  };

  return initialState;
};
export const DEFAULT_VIEW_ID = "default-view";
export const INITIAL_STATE = {
  pinnedColumns: {
    left: ["company_name"],
    right: ["notes"]
  },
  columns: {
    columnVisibilityModel: {
      industry_tags: false,
      // funnel_stage: false,
      source: false,
      source_type: false,
      live_deal: false,
      discuss: false
    },
    orderedFields: [
      "company_name",
      "socials",
      "company_location",
      "seriesmerged",
      "total_funding",
      "last_funding_date",
      "founding_date",
      "headcount",
      "industry_tags",
      "funnel_stage",
      "reviewgrowthpercentile6",
      "reviewgrowthpercentile3",
      "cihcgpercentile",
      "growthpercentile3evm",
      "growthpercentile6evm",
      "growthpercentile12evm",
      "source",
      "source_type",
      "live_deal",
      "discuss",
      "notes"
    ],
    dimensions: {
      company_name: {
        maxWidth: -1,
        minWidth: 120,
        width: 225
      },
      socials: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      company_location: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      seriesmerged: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      total_funding: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      last_funding_date: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      founding_date: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      headcount: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      industry_tags: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      funnel_stage: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      reviewgrowthpercentile6: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      reviewgrowthpercentile3: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      cihcgpercentile: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      growthpercentile3evm: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      growthpercentile6evm: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      growthpercentile12evm: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      source: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      source_type: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      live_deal: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      discuss: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      },
      notes: {
        maxWidth: -1,
        minWidth: 120,
        width: 100
      }
    }
  },
  preferencePanel: {
    open: false
  },
  filter: {
    filterModel: {
      items: [],
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and"
    }
  },
  sorting: {
    sortModel: []
  },
  pagination: {
    paginationModel: {
      page: 0,
      pageSize: 50
    }
  }
};
