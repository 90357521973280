import { Avatar, Card, CardActionArea, CardHeader, Typography } from "@mui/material";
import { RelationshipObject } from "../../../../types";
import React from 'react'

interface PropType extends RelationshipObject {}

function RelationshipCard(props: PropType) {
  const { relationshipName, relationshipURL, relationshipStartDate, relationshipSource } = props;

  return (
    <Card sx={{ width: "100%" }} elevation={2}>
      <CardActionArea href={relationshipSource ?? "/#"}>
        <CardHeader
          avatar={
            <Avatar alt="No Image" src={"//logo.clearbit.com/" + relationshipURL}>
              {<img style={{ height: "40px", width: "40px" }} src="/images/default-corporate-image.jpg" alt="default-corporate" />}
            </Avatar>
          }
          title={
            <Typography variant="h5" component="h2">
              {relationshipName}
            </Typography>
          }
          subheader={
            <div>
              <Typography variant="body2" color="textSecondary" component="p">
                {relationshipStartDate ? "Start Date: " + relationshipStartDate : ""}
              </Typography>
            </div>
          }
        />
      </CardActionArea>
    </Card>
  );
}

export default RelationshipCard;
