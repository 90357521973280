import { Chip, Grid, Paper, TableCell, styled } from "@mui/material";
import { TableBody, TableHeader } from "../../../../components";
import ShowMore from "../../../../components/ShowMore";
import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { CompanyData, CompanyTableColumns, HeadcountGrowth, Vertical } from "../../../../types";

const PREFIX = "CompanyHeader1";
const classes = {
  fullWidth:`${PREFIX}-fullWidth`,
  paperRoot:`${PREFIX}-paperRoot`,
 
  chipStyle: `${PREFIX}-chipStyle`,
  imageStyle: `${PREFIX}-imageStyle`,
};


const Root = styled("div")(({ theme }) => ({
 
  [`&.${classes.fullWidth}`]: {
      width: "100%"
    },
   [`& .${classes.paperRoot}`] : {
      width: "100%",
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
   
    [`& .${classes.chipStyle}`]: {
      fontSize: theme.typography.pxToRem(14),
      marginRight: "5px",
      marginBottom: "5px",
      color: theme.palette.info.main,
      borderColor: theme.palette.info.main
    },
    [`& .${classes.imageStyle}`]: {
      maxWidth: "200px",
      height: "auto"
    },
 
  })
);


interface PropTypes {
  data: CompanyData;
}

const CompanyHeader = (props: PropTypes) => {


  const { data } = props;

  const columns: CompanyTableColumns<CompanyData>[] = [
    {
      id: "namefinal",
      label: "Company Name",
      minWidth: 110
    },
    {
      id: "totalstrength_nn",
      label: "Overall Company Signal",
      minWidth: 110,
      align: "center",
      format: () => "Coming soon..."
    },

    {
      id: "headcountgrowth",
      label: "Employee Count",
      minWidth: 110,
      align: "center",
      format: value => {
        const _value = value as HeadcountGrowth[];
        if (_value) {
          for (const headcount of _value) {
            if (headcount.industryname === "All") {
              return headcount.departmentcount;
            }
          }
        } else {
          return "No Data";
        }
      }
    },
    {
      id: "locationfinal",
      label: "Location",
      minWidth: 110
    }
  ];
  const columns2: CompanyTableColumns<{ verticals: Vertical[] }>[] = [
    {
      id: "verticals",
      label: "Industries",
      width: "50%",
      format: values =>
        values && typeof values === "object" && values.length > 0 ? (
          <ShowMore
            isInline={true}
            data={values.map(valueOg => {
              const { vertical } = valueOg as Vertical;
              return (
                <Chip
                  label={vertical}
                  className={classes.chipStyle}
                  clickable
                  color="default"
                  variant="outlined"
                  onClick={(event: React.MouseEvent) => event.preventDefault()}
                  component={ReactLink}
                  to={{
                    pathname: "/search"
                  }}
                />
              );
            })}
            trimIndex={3}
          ></ShowMore>
        ) : (
          "No Industries"
        )
    }
  ];

  return (
    <Root className={classes.fullWidth}>
      <Grid container spacing={2}>
        <Grid item xs={4} lg={3} xl={2}>
          <Paper className={classes.paperRoot}>
            <img
              className={classes.imageStyle}
              src={"//logo.clearbit.com/" + data.websitefinal}
              alt = "logo"
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.src = "/images/default-corporate-image.jpg"; // some replacement image
                //e.currentTarget.style = 'padding-top: 25px; padding-bottom: 25px;' // inline styles in html format
              }}
            ></img>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8} lg={9} xl={10}>
          <Grid container>
            <div className={classes.fullWidth}>
              <Grid item xs={12}>
                <Paper className={classes.paperRoot}>
                  <TableHeader
                    columns={columns}
                    renderColumns={column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          width: column.width ? column.width : ""
                        }}
                      >
                        {column.label}
                      </TableCell>
                    )}
                    body={
                      <TableBody
                        data={[data]}
                        columns={columns}
                        renderRecords={(d, column) => (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(d[column.id]):null}
                          </TableCell>
                        )}
                      />
                    }
                  />
                </Paper>
                <Grid item xs={12}>
                  <Paper className={classes.paperRoot}>
                    <TableHeader
                      columns={columns2}
                      renderColumns={column => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                            width: column.width ? column.width : ""
                          }}
                        >
                          {column.label}
                        </TableCell>
                      )}
                      body={
                        <TableBody
                          data={[{ verticals: data.verticals ?? [] }]}
                          columns={columns2}
                          renderRecords={(d, column) => (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(d[column.id]):null}
                            </TableCell>
                          )}
                        />
                      }
                    />
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

export default CompanyHeader;
