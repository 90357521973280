import React , { HTMLAttributes } from "react";



interface PropTypes extends HTMLAttributes<HTMLDivElement>{
    className?:string,
    links:Object,
    linkedInName:string
}


const ContactInfoButton = (props:PropTypes) => {
 
  return <div></div>;
};

export default ContactInfoButton;
