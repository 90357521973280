import { GridFilterModel, GridColDef } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { groupBy, isEmpty } from "lodash";
import { portfolioData } from "../../../types";

/**
 * @description This will check for the isEmpty and isNotEmpty condition.
 * @param filter GridFilterModel
 * @returns boolean
 */
export const emptyConditionCheck = (filters: GridFilterModel): boolean => {
  let checked = true;
  const items = filters.items;

  // grouping filter items WRT field
  const groupedItems = groupBy(items, d => d.field);

  Object.keys(groupedItems).forEach(field => {
    //items for a felid
    const itemsOfField = groupedItems[field];
    // if the is empty and is not empty is applied for the same field then checked will be false.
    if (itemsOfField.find(d => d.operator === "isEmpty" && itemsOfField.find(d => d.operator === "isNotEmpty"))) checked = false;
  });
  return checked;
};

/**
 * @description This will build final query
 */

export const buildFinalQuery = (filters: GridFilterModel, cols: GridColDef[]) => {
  const dataTypeDateFieldName = cols.filter(col => col.type === "date").map(col => col.field);

  const items = filters.items.map(item => {
    if (item.field.toLowerCase().includes("percent")) {
      return { ...item, value: (item.value / 100).toFixed(4) };
    }

    if (dataTypeDateFieldName.includes(item.field)) {
      return { ...item, value: dayjs(item.value).format("YYYY-MM-DD") };
    }
    return item;
  });
  return JSON.stringify({ ...filters, items });
};

// export const formatFloatNum = (num:number , decimalCount = 4):string => {
//   const decimalMatchRegex =  new RegExp(`/^-?\d+(?:\.\d{0,${decimalCount}})?/`);
//   const match =  num.toString().match(decimalMatchRegex);
//   return match && match.length> 0 ? match[0] : num.toString().slice(0,5)
// }
export const formatFloatNum = (num: number, decimalPoints: number = 4) => {
  const numbers = num.toString().split(".");
  if (numbers.length === 1) return num;
  if (numbers.length === 2) return numbers[0] + "." + numbers[1].slice(0, decimalPoints);
  return num;
};

/**
 * @description This function is for validating from details.
 */

export const validatePortfolioForm = (data: portfolioData): { isValid: boolean; message: string } => {
  // console.log("validatePortfolioForm")
  // console.log("data::", data);
  let isValid = true;
  const { companyname, coinvestors, initialinvestment, cost, fmv, lastround, companyurl, ceo } = data;
  if (isEmpty(companyname.trim())) {
    return { isValid: false, message: "Company name field is empty" };
  }
  if (isEmpty(companyurl.trim())) {
    return { isValid: false, message: "Company url is empty" };
  }
  if (!cost || cost < 0) {
    return { isValid: false, message: "Cost value should be greater than zero" };
  }
  if (!fmv || fmv < 0) {
    return { isValid: false, message: "FMV value should be greater than zero" };
  }

  if (!dayjs(initialinvestment).isValid()) {
    return { isValid: false, message: "Format of initial investment is not correct" };
  }
  if (coinvestors && coinvestors.length === 0) {
    return { isValid: false, message: "At minimum one Investor is required felid here." };
  }
  if (isEmpty(lastround)) {
    return { isValid: false, message: "Select last round." };
  }
  if (isEmpty(ceo?.name)) {
    return { isValid: false, message: "Valid ceo name is required felid" };
  }

  return { isValid, message: "ok" };
};
