import { gql, useLazyQuery } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, Box, CircularProgress, ListItemAvatar, ListItemText, MenuItem, MenuList, Popover, SxProps, Theme, Typography, useTheme } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import React, { useState } from "react";
import { getAllCompanyList } from "../../graphql/queries";
import { GetCompaniesListData } from "../../types";

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch"
    }
  }
}));

interface TopbarSearchProps {
  sx?: SxProps<Theme>;
  type: "all" | "pitchbook";
  onSelect?: (selectedCompany: GetCompaniesListData) => void;
  placeholder?: string;
  icon?: React.ReactNode;
}

const CompaniesByNameSearchBox = ({ sx, type, onSelect, placeholder, icon }: TopbarSearchProps) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [getCompanies, { loading }] = useLazyQuery<{ getAllCompanyList: GetCompaniesListData[] }>(gql(getAllCompanyList));
  const [itemsList, setItemList] = useState<GetCompaniesListData[]>([]);
  const theme = useTheme();
  const boxref = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  // Define the debounced search function using lodash's debounce
  // debouncing for searchTerm
  const debouncedFetch = React.useMemo(
    () =>
      debounce((input: string) => {
        getCompaniesList(input);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getCompaniesList = (queryText: string) => {
    getCompanies({ variables: { type, nameContains: queryText } }).then(res => {
      const itemList = res.data?.getAllCompanyList;
      if (itemList) setItemList([...itemList]);
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);

    // Call the debounced search function with the latest input value
    if (value.length > 2) debouncedFetch(value);
  };

  const handleCloseCompaniesList = () => {
    setItemList([]);
  };

  const handleListItemClick = (data: GetCompaniesListData, type: "all" | "pitchbook", index: number) => {
    if (type === "pitchbook") {
      if (onSelect) onSelect(data);
      itemsList.splice(index, 1);
      setItemList([...itemsList]);
      // handleCloseCompaniesList();
    }

    if (type === "all") {
      navigate(`/company-details/${data.company_uuid}?fromPage='topbarSearch'`);
      handleCloseCompaniesList();
    }
  };

  return (
    <Box
      ref={boxref}
      sx={{
        position: "relative",
        borderRadius: 1,
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        "&:hover": {
          backgroundColor: alpha(theme.palette.common.black, 0.25)
        },
        //marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          // marginLeft: theme.spacing(3),
          width: "auto"
        },
        ...sx
      }}
    >
      <SearchIconWrapper>{icon ?? <SearchIcon />}</SearchIconWrapper>
      <StyledInputBase type="search" placeholder={placeholder ?? "Search companies by name…"} inputProps={{ "aria-label": "search companies" }} name="searchTerm" value={searchTerm} onChange={handleInputChange} />
      <Popover
        id={"companiesList"}
        open={Boolean(itemsList.length || loading)}
        anchorEl={boxref.current}
        onClose={handleCloseCompaniesList}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        PaperProps={{
          sx: {
            minWidth: 290,
            maxWidth: 350,
            maxHeight: 450
          }
        }}
      >
        {loading ? (
          <Stack sx={{ p: 2 }} direction={"row"} justifyContent={"space-between"}>
            <Typography>Loading...</Typography>
            <CircularProgress size={24} color="primary" />
          </Stack>
        ) : itemsList.length > 0 ? (
          itemsList.map((data, index) => (
            <MenuList key={`listItemofsearch-${index}`}>
              <MenuItem onClick={() => handleListItemClick(data, type, index)}>
                {type === "all" ? (
                  <ListItemAvatar>
                    <Avatar
                      sx={{ width: "24px", height: "24px" }}
                      src={"//logo.clearbit.com/" + data.company_url}
                      variant="rounded"
                      sizes="small"
                      imgProps={{
                        onError: (e: React.SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = "/images/default-corporate-image.jpg")
                      }}
                    />
                  </ListItemAvatar>
                ) : null}
                <ListItemText>{data.company_name}</ListItemText>
              </MenuItem>
            </MenuList>
          ))
        ) : (
          <Box sx={{ p: 2 }}>No result found</Box>
        )}
      </Popover>
    </Box>
  );
};

export default CompaniesByNameSearchBox;
