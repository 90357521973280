import { useLazyQuery, useMutation } from "@apollo/client";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertProps, Box, Snackbar, Typography } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridEventListener, GridFilterModel, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridSortModel, MuiEvent, getGridNumericOperators, getGridStringOperators, gridClasses } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import gql from "graphql-tag";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { getPortfolioList } from "../../../../graphql/queries";
import { portfolioData } from "../../../../types";
import CoInvestorsRenderCell from "./CoInvestorsRenderCell";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from "@mui/material";
import { debounce, isEmpty } from "lodash";
import { createPortfolio, updatePortfolio } from "../../../../graphql/mutations";
import { buildFinalQuery, formatFloatNum, validatePortfolioForm } from "../utils";
import CeoEditCell from "./CeoEditCell";
import CeoRenderCell from "./CeoRenderCell";
import CoInvestorsEditCell from "./CoInvestorsEditCell";
import CompanyNameEditCell from "./CompanyNameEditCell";
import CompanyNameRenderCell from "./CompanyNameRenderCell";
import EditToolbar from "./EditToolbar";
import CompanyLastRoundEditCell from "./LastRoundEditCell";
import { emptyConditionCheck } from "../../../../helpers/DataGridHelpers";

/** Page size limit size. */
const PAGE_SIZE = 10;

/**
 * @description Portfolio table column definition list
 */
const portfolioListCols: GridColDef[] = [
  {
    field: "companyname",
    headerName: "Company Name",
    width: 200,
    type: "string",
    editable: true,
    renderCell: params => <CompanyNameRenderCell {...params} />,
    renderEditCell: params => <CompanyNameEditCell {...params} />
  },
  {
    field: "initialinvestment",
    headerName: "Initial Investment",
    minWidth: 120,
    flex: 0.2,
    type: "date",
    editable: true,
    valueFormatter: params => dayjs(params.value).format("DD/MM/YYYY"),
    // renderCell: params => <Moment format="L">{params.value}</Moment>
    renderCell: params => (
      <Typography>
        <Moment format="L">{params.value}</Moment>
      </Typography>
    )
  },
  {
    field: "cost",
    headerName: "Cost (In $ million)",
    width: 130,
    editable: true,
    type: "number",
    align: "center",
    valueFormatter: params => formatFloatNum(params.value, 2),
    renderCell: params => <Typography>{params.formattedValue}</Typography>
    // valueFormatter:params => parseFloat(params.value).toFixed(4)
    // valueGetter: (params: GridValueGetterParams) => `$ ${params.row.cost} million`
  },
  {
    field: "fmv",
    headerName: "FMV(in $ million)",
    editable: true,
    type: "number",
    width: 150,
    align: "center",
    valueFormatter: params => formatFloatNum(params.value, 2),
    renderCell: params => <Typography>{params.formattedValue} </Typography>
    // valueGetter: (params: GridValueGetterParams) => `$ ${params.row.fmv} million`
  },
  {
    field: "lastround",
    editable: true,
    headerName: "Last Round",
    type: "string",
    width: 120,
    renderCell: params => <Typography>{params.value}</Typography>,
    renderEditCell: params => <CompanyLastRoundEditCell {...params} />
  },
  {
    field: "coinvestors",
    headerName: "Co-Investor",
    type: "string",
    sortable: false,
    // filterable: false,
    editable: true,
    renderCell: params => <CoInvestorsRenderCell {...params} />,
    minWidth: 370,
    flex: 0.85,
    renderEditCell: params => <CoInvestorsEditCell {...params} />
  },
  {
    field: "ceo",
    headerName: "CEO",
    // align:"center",
    editable: true,
    sortable: false,
    type: "string",
    width: 140,
    renderCell: params => <CeoRenderCell {...params} />,
    renderEditCell: params => <CeoEditCell {...params} />
  }
];

// Additional components for utilizing in the table

const PortfolioListPro = () => {
  //Query for the portfolio list.
  const [getPortfolio, { data, loading, error, refetch }] = useLazyQuery<{ getPortfolioList: { result: portfolioData[]; count: number; message: string } }>(gql(getPortfolioList));
  const [addRecord, { loading: addRecordLoading }] = useMutation(gql(createPortfolio));
  const [updateRecord, { loading: updateRecordLoading }] = useMutation(gql(updatePortfolio));
  // state for row count and data , this us because for the prevention when data will be undefined.
  // const [rowState, setRowState] = React.useState<{ count: number; data: portfolioData[] }>({ data: [], count: 0 });
  const [rows, setRows] = React.useState<portfolioData[]>([]);
  const [rowCount, setRowCount] = React.useState<number>(0);
  // taking pagination modal as state because of the server side pagination implementation
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE
  });

  //state for the snackbar and settings
  const [snackbar, setSnackbar] = React.useState<Pick<AlertProps, "children" | "severity"> | null>(null);

  //Row update state
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  // query state for server side filter option
  const [filters, setFilters] = React.useState<GridFilterModel | null>(null);
  //Sorting state
  const [sort, setSort] = React.useState<GridSortModel | null>(null);

  const handleEditClick = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = React.useCallback(
    (id: GridRowId) => () => {
      // console.log("handleSaveClick");
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  // const handleDeleteClick = (id: GridRowId) => () => {
  //   setRows(rows.filter(row => row.id !== id));
  // };

  const handleCancelClick = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true }
      });

      const editedRow = rows.find(row => row.id === id);
      if (editedRow!.isNew) {
        setRows(rows.filter(row => row.id !== id));
      }
    },
    [rowModesModel, rows]
  );

  //Attaching action buttons to the col array
  let columns: GridColDef[] = React.useMemo(
    () => [
      ...portfolioListCols,
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,

        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [<GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />, <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />];
          }
          return [<GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />];
        }
      }
    ],
    [handleCancelClick, handleEditClick, handleSaveClick, rowModesModel]
  );
  columns = React.useMemo(
    () =>
      columns.map(col => {
        switch (col.type) {
          case "number":
            return {
              ...col,
              //@ts-ignore
              filterOperators: getGridNumericOperators().filter(
                // @ts-ignore
                operator => operator.value !== "isAnyOf"
              )
            };

          case "string":
            return {
              ...col,
              //@ts-ignore
              filterOperators: getGridStringOperators().filter(
                // @ts-ignore
                operator => operator.value !== "isAnyOf"
              )
            };
          default:
            return col;
        }
      }),
    [columns]
  );

  /***
   * @description Handler for the filter option change.
   */
  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    // As per the discussion with backend team for these two cases value should be the name of the operator itself.
    const items = filterModel?.items
      .map(item => {
        if (item.operator === "isNotEmpty" || item.operator === "isEmpty") return { ...item, value: item.operator };
        return item;
      })
      //Removing empty value object from thee items array. so filter will occur on valid object only
      .filter(item => !isEmpty(item.value));

    const _filters = { ...filterModel, items };

    if (!emptyConditionCheck(_filters)) toast.error("Please revisit the combination of filter options.");
    // isEmpty and isNotEmpty should not be applied for the same felid.

    setFilters(_filters);
  }, []);

  //On sorting change handler
  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setSort(sortModel);
  }, []);

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel, oldRow: GridRowModel) => {
    // console.log("ProcessRowUpdate")
    let updatedRow = { ...newRow, isNew: false, initialinvestment: dayjs(newRow.initialinvestment).toISOString() };
    //@ts-ignore
    if (typeof updatedRow.companyname !== "string") updatedRow = { ...updatedRow, companyname: newRow.companyname.companyname, companyurl: newRow.companyname.companyurl };
    //@ts-ignore
    const validation = validatePortfolioForm(updatedRow);
    if (validation.isValid) {
      // console.log("valid data");
      //@ts-ignore
      const { companyname, initialinvestment, cost, fmv, lastround, coinvestors, ceo, companyurl, uuid } = updatedRow;
      if (newRow.isNew) {
        //if new record then add record
        const response = await addRecord({
          variables: {
            input: {
              companyname,
              initialinvestment,
              cost,
              fmv,
              lastround,
              coinvestors: coinvestors.map((d: any) => ({ dealid: d.dealid, investor: d.investor })),
              ceo: {
                linkedinUrl: ceo.linkedinUrl,
                name: ceo.name
              },
              companyurl
            }
          }
        });

        //@ts-ignore
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
        return { ...response.data.createPortfolio.data, id: newRow.id };
      } else {
        // update the record
        const updateResponse = await updateRecord({
          variables: {
            input: {
              companyname,
              initialinvestment,
              cost,
              fmv,
              lastround,
              coinvestors: coinvestors.map((d: any) => ({ dealid: d.dealid, investor: d.investor })),
              ceo: {
                linkedinUrl: ceo.linkedinUrl,
                name: ceo.name
              },
              companyurl,
              uuid
            }
          }
        });
        //@ts-ignore
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
        return { ...updateResponse.data.updatePortfolio.data, id: newRow.id };
      }
    } else {
      // console.log("Invalid data")
      throw new Error(validation.message);
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  //handle close Snackbar for the response of the mutation
  const handleCloseSnackbar = () => setSnackbar(null);

  // Define the debounced state change handler
  const filterQueryWithDebounce = debounce((filters: GridFilterModel) => {
    const { pageSize } = paginationModel;

    const vars: any = {
      limit: pageSize,
      offset: pageSize * paginationModel.page,
      filters: buildFinalQuery(filters, columns)
    };
    if (sort) vars.sort = JSON.stringify(sort);

    getPortfolio({
      variables: vars
    });
  }, 1000);

  // Define the debounced state change handler
  const sortingWithDebounce = debounce((sort: GridSortModel) => {
    const { pageSize } = paginationModel;

    const vars: any = {
      limit: pageSize,
      offset: pageSize * paginationModel.page,
      sort: JSON.stringify(sort)
    };
    if (filters?.items && filters.items.length > 0) vars.filters = buildFinalQuery(filters, columns);

    getPortfolio({
      variables: vars
    });
  }, 1000); // Set the debounce delay to 500 milliseconds

  //fetch data on render || or on pagination change
  React.useEffect(() => {
    const { pageSize } = paginationModel;
    const vars: any = {
      limit: pageSize,
      offset: pageSize * paginationModel.page
    };
    if (filters) vars.filters = buildFinalQuery(filters, columns);
    if (sort) vars.sort = JSON.stringify(sort);
    getPortfolio({
      variables: vars
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  //effect for the debouncing query for the filter query
  React.useEffect(() => {
    const { pageSize } = paginationModel;

    // call the filter query debounce here
    if (filters?.items && filters.items.length > 0) filterQueryWithDebounce(filters);

    //this action will occur when all filter will be  removed
    if (filters?.items.length === 0) {
      // refetching the data for the current page.
      getPortfolio({
        variables: {
          limit: pageSize,
          offset: pageSize * paginationModel.page
        }
      });
    }
    return () => {
      filterQueryWithDebounce.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  //effect for the debouncing query for the sorting query
  React.useEffect(() => {
    // call the sorting query debounce here
    if (sort) sortingWithDebounce(sort);

    return () => {
      filterQueryWithDebounce.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  //Handle Query result.
  React.useEffect(() => {
    unstable_batchedUpdates(() => {
      setRows(data?.getPortfolioList?.result ? data.getPortfolioList.result.map(d => ({ ...d, id: d.uuid })) : []);
      setRowCount(data?.getPortfolioList?.count ?? 0);
    });
  }, [data]);

  //Error handlers for the query
  React.useEffect(() => {
    if (error) toast.error(error.message);
  }, [error]);

  return (
    <Accordion sx={{marginBottom:2}}>
      <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="portfolioList-content"
          id="portfolioList-header"
      >
        <Stack direction={"row"} alignItems="center" spacing={2} alignContent={"center"}>
          <FormatListBulletedIcon fontSize="large" />
          <Typography variant="h5">{"Portfolio List"}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails id="portfolioList-content">
        <Box
          //component={Card} elevation={1}
          sx={{
            //paddingLeft: 1, paddingRight: 1,
            // marginBottom: 2
            borderRadius:4
          }}
          height={640}
        >
          <DataGridPro
            columns={columns}
            rows={rows}
            loading={loading || addRecordLoading || updateRecordLoading}
            pagination
            pageSizeOptions={[10, 20, 50]}
            rowCount={rowCount}
            getRowHeight={() => "auto"}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            paginationModel={paginationModel}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={error => {
              toast.error(error.message);
            }}
            slots={{
              toolbar: EditToolbar
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, refetch, loading }
            }}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1.5
              }
            }}
            initialState={{ pinnedColumns: { right: ["actions"] } }}
          />

          {!!snackbar && (
            <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PortfolioListPro;
