import React from "react";
import { useEffect } from "react";

import { Authenticator, useAuthenticator, View, Image, Text, Heading, useTheme, Button, Theme ,ThemeProvider  } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { useNavigate, useLocation } from "react-router";

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Renegade logo" src="/images/logos/header.png" width={150} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
            Reset Password
          </Button>
        </View>
      );
    }
  }
};

export function Login() {
  const { route } = useAuthenticator(context => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/dashboard";
  const { tokens } = useTheme();

  //theming the connected components
  const theme: Theme = {
    name: "Renegade Theme",
    tokens: {
      colors: {
        brand: {
          primary: {
            "10": tokens.colors.red["100"],
            "80": tokens.colors.red["40"],
            "90": tokens.colors.red["20"],
            "100": tokens.colors.red["10"]
          }
        }
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: tokens.colors.white.value
              }
            },
            _hover: {
              color: {
                value: tokens.colors.yellow["80"].value
              }
            },
            _active: {
              color: {
                value: tokens.colors.white.value
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <View className="auth-wrapper">
      <ThemeProvider theme={theme}>
        <Authenticator loginMechanisms={["email"]} hideSignUp={true} components={components} />
      </ThemeProvider>
    </View>
  );
}
