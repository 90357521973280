import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import { Sidebar, Topbar } from "./components";
import SidebarMini from "./components/SidebarMini";

const SIDEBAR_WIDTH = 200;
const PREFIX = "MainContent";
const classes = {
  root: `${PREFIX}-root`,
  shiftContentSmall: `${PREFIX}-shiftContentSmall`,
  shiftContent: `${PREFIX}-shiftContent`,
  belowLgSidebar: `${PREFIX}-belowLgSidebar`,
  content: `${PREFIX}-content`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64
    }
  },
  [`&.${classes.shiftContentSmall}`]: {
    paddingLeft: 80
  },
  [`&.${classes.shiftContent}`]: {
    paddingLeft: `${SIDEBAR_WIDTH}px`
  },
  [`& .${classes.content}`]: {
    height: "100%"
  }
}));

const Main = () => {
  const theme = useTheme();
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"), {
    defaultMatches: true
  });
  const isLgScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isBelowLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(!openSidebar);
  };
  return (
    <Root
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isXlScreen,
        [classes.shiftContentSmall]: isLgScreen,
        [classes.belowLgSidebar]: isBelowLg
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      {/* <div>{!isDesktop ? <Sidebar onClose={handleSidebarClose} open={shouldOpenSidebar} variant={isDesktop ? "persistent" : "temporary"} handleSidebarOpen={handleSidebarOpen} /> }</div> */}

      <Box>
        {isXlScreen ? <Sidebar sidebarWidth={SIDEBAR_WIDTH} onClose={handleSidebarClose} open={true} variant={"persistent"} handleSidebarOpen={handleSidebarOpen} /> : null}
        {isLgScreen ? <SidebarMini onClose={handleSidebarClose} open={true} variant="persistent" /> : null}
        {isBelowLg ? <Sidebar sidebarWidth={SIDEBAR_WIDTH} onClose={handleSidebarClose} open={openSidebar} variant={"temporary"} handleSidebarOpen={handleSidebarOpen} /> : null}
      </Box>
      <main className={classes.content}>
        <Outlet />
      </main>
    </Root>
  );
};

export default Main;
