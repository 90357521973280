import { Box } from "@mui/material";
import ShowMore from "../../../../components/ShowMore";
import { EmployeeData } from "../../../../types";
import ExpandingSection from "../ExpandingSection";
import ExperienceCard from "../ExperienceCard";
import React from 'react';


interface PropTypes {
  data: EmployeeData;
}

const Languages = (props: PropTypes) => {
  const { data } = props;
 
  const languageData = (
    <ShowMore
      data={data["languages"].map(language => {
        return (
          <ExperienceCard
            data={{
              header: language.profilelanguage,
              description: language.proficiency
            }}
          />
        );
      })}
      trimIndex={3}
    />
  );
  return (
    <Box sx={{width:"100%"}}>
      <ExpandingSection title="Languages" itemIndex={"profilelanguage"} data={data["languages"]} fullBody={languageData} />
    </Box>
  );
};

export default Languages;
