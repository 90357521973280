import React from 'react';

interface Props {
	className?: string;
}

const DataNotFound = (props: Props) => {
	return (
		<svg height="496.81332" id="svg2" width="666.66669" version="1.1" viewBox="0 0 666.66669 496.81332" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve">
	<defs id="defs6"/>
	<g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,496.81333)">
		<g id="g10" transform="scale(0.1)">
			<path id="path12" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 0,449.129 h 5000 v 2.5 H 0 Z"/>
			<path id="path14" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4167.79,290.672 h 331.22 v -2.5 h -331.22 v 2.5"/>
			<path id="path16" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3225.27,263.539 h 86.93 v -2.5 h -86.93 v 2.5"/>
			<path id="path18" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3965.86,383.531 h 191.92 v -2.5 h -191.92 v 2.5"/>
			<path id="path20" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 524.59,366.73 h 431.933 v -2.5 H 524.59 v 2.5"/>
			<path id="path22" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1045.56,366.73 h 63.33 v -2.5 h -63.33 v 2.5"/>
			<path id="path24" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1314.71,324.512 h 936.76 v -2.5 h -936.76 v 2.5"/>
			<path id="path26" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 439.152,3723.59 c -30.101,0 -54.582,-24.48 -54.582,-54.57 V 954.699 c 0,-30.097 24.481,-54.578 54.582,-54.578 H 2370.14 c 30.09,0 54.57,24.481 54.57,54.578 V 3669.02 c 0,30.09 -24.48,54.57 -54.57,54.57 H 439.152 M 2370.14,897.621 H 439.152 c -31.476,0 -57.082,25.598 -57.082,57.078 V 3669.02 c 0,31.46 25.606,57.07 57.082,57.07 H 2370.14 c 31.46,0 57.07,-25.61 57.07,-57.07 V 954.699 c 0,-31.48 -25.61,-57.078 -57.07,-57.078"/>
			<path id="path28" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2602.12,3723.59 c -30.09,0 -54.57,-24.48 -54.57,-54.57 V 954.699 c 0,-30.097 24.48,-54.578 54.57,-54.578 H 4533.1 c 30.1,0 54.59,24.481 54.59,54.578 V 3669.02 c 0,30.09 -24.49,54.57 -54.59,54.57 H 2602.12 M 4533.1,897.621 H 2602.12 c -31.47,0 -57.07,25.598 -57.07,57.078 V 3669.02 c 0,31.46 25.6,57.07 57.07,57.07 H 4533.1 c 31.48,0 57.09,-25.61 57.09,-57.07 V 954.699 c 0,-31.48 -25.61,-57.078 -57.09,-57.078"/>
			<path id="path30" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2896.91,2535.03 h 1377.81 v 902.266 H 2896.91 Z"/>
			<path id="path32" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,2535.03 h 1400.2 v 902.266 h -1400.2 z"/>
			<path id="path34" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2896.91,2357.95 h 1377.81 v 177.082 H 2896.91 Z"/>
			<path id="path36" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2784.83,2357.95 h 1400.2 v 177.082 h -1400.2 z"/>
			<path id="path38" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2913.72,2593.89 h 1282.5 v 784.555 h -1282.5 z"/>
			<path id="path40" style={{"fill":"#ffffff","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3907.02,2593.88 -163.8,784.56 h -255.61 l 163.8,-784.56 h 255.61"/>
			<path id="path42" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4168.97,2652.46 c 2.32,0 4.2,1.89 4.2,4.21 v 683.48 c 0,2.32 -1.88,4.21 -4.2,4.21 -2.32,0 -4.21,-1.89 -4.21,-4.21 v -683.48 c 0,-2.32 1.89,-4.21 4.21,-4.21"/>
			<path id="path44" style={{"fill":"#ffffff","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3596.5,2593.88 -163.8,784.56 h -99.66 l 163.81,-784.56 h 99.65"/>
			<path id="path46" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2913.72,2593.89 h 7.4805 v 784.555 h -7.4805 z"/>
			<path id="path48" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4218.76,3289.7 h -22.54 v 65.87 h 27.95 l -5.41,-65.87"/>
			<path id="path50" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4196.22,3289.7 h -23.05 v 50.45 c 0,2.32 -1.88,4.21 -4.2,4.21 -2.32,0 -4.21,-1.89 -4.21,-4.21 v -50.45 h -403.02 l -13.75,65.87 h 448.23 v -65.87 m -690.09,0 h -54.91 l -13.75,65.87 h 54.91 l 13.75,-65.87"/>
			<path id="path52" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3761.74,3289.7 h -255.61 l -13.75,65.87 h 255.61 l 13.75,-65.87"/>
			<path id="path54" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 3351.57,3289.7 H 2921.2 v 65.87 h 416.62 l 13.75,-65.87"/>
			<path id="path56" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,3289.7 h -13.92 l 5.41,65.87 h 8.51 v -65.87"/>
			<path id="path58" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,3289.7 h 58.8477 v 65.8633 H 2854.87 Z"/>
			<path id="path60" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4173.17,3289.7 h -8.41 v 50.45 c 0,2.32 1.89,4.21 4.21,4.21 2.32,0 4.2,-1.89 4.2,-4.21 v -50.45"/>
			<path id="path62" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3451.22,3289.7 h -99.65 l -13.75,65.87 h 99.65 l 13.75,-65.87"/>
			<path id="path64" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2913.72,3289.7 h 7.4805 v 65.8633 h -7.4805 z"/>
			<path id="path66" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4218.76,3181.68 h -22.54 v 65.87 h 27.95 l -5.41,-65.87"/>
			<path id="path68" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4196.22,3181.68 h -23.05 v 65.87 h 23.05 v -65.87 m -31.46,0 H 3784.3 l -13.76,65.87 h 394.22 v -65.87 m -636.07,0 h -54.91 l -13.76,65.87 h 54.91 l 13.76,-65.87"/>
			<path id="path70" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3784.3,3181.68 h -255.61 l -13.76,65.87 h 255.61 l 13.76,-65.87"/>
			<path id="path72" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 3374.12,3181.68 H 2921.2 v 65.87 h 439.17 l 13.75,-65.87"/>
			<path id="path74" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,3181.68 h -13.92 l 5.41,65.87 h 8.51 v -65.87"/>
			<path id="path76" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,3181.68 h 58.8477 v 65.8633 H 2854.87 Z"/>
			<path id="path78" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4164.76,3181.68 h 8.4102 v 65.8633 h -8.4102 z"/>
			<path id="path80" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3473.78,3181.68 h -99.66 l -13.75,65.87 h 99.65 l 13.76,-65.87"/>
			<path id="path82" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2913.72,3181.68 h 7.4805 v 65.8633 h -7.4805 z"/>
			<path id="path84" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4218.76,3073.66 h -22.54 v 65.86 h 27.95 l -5.41,-65.86"/>
			<path id="path86" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4196.22,3073.66 h -23.05 v 65.86 h 23.05 v -65.86 m -31.46,0 h -357.91 l -13.75,65.86 h 371.66 v -65.86 m -613.52,0 h -54.91 l -13.75,65.86 h 54.91 l 13.75,-65.86"/>
			<path id="path88" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3806.85,3073.66 h -255.61 l -13.75,65.86 h 255.61 l 13.75,-65.86"/>
			<path id="path90" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 3396.68,3073.66 H 2921.2 v 65.86 h 461.73 l 13.75,-65.86"/>
			<path id="path92" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,3073.66 h -13.92 l 5.41,65.86 h 8.51 v -65.86"/>
			<path id="path94" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,3073.66 h 58.8477 v 65.8594 H 2854.87 Z"/>
			<path id="path96" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4164.76,3073.66 h 8.4102 v 65.8594 h -8.4102 z"/>
			<path id="path98" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3496.33,3073.66 h -99.65 l -13.75,65.86 h 99.65 l 13.75,-65.86"/>
			<path id="path100" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2913.72,3073.66 h 7.4805 v 65.8594 h -7.4805 z"/>
			<path id="path102" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4218.76,2965.64 h -22.54 v 65.86 h 27.95 l -5.41,-65.86"/>
			<path id="path104" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4196.22,2965.64 h -23.05 v 65.86 h 23.05 v -65.86 m -31.46,0 H 3829.4 l -13.75,65.86 h 349.11 v -65.86 m -590.97,0 h -54.91 l -13.75,65.86 h 54.91 l 13.75,-65.86"/>
			<path id="path106" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3829.4,2965.64 h -255.61 l -13.75,65.86 h 255.61 l 13.75,-65.86"/>
			<path id="path108" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 3419.23,2965.64 H 2921.2 v 65.86 h 484.28 l 13.75,-65.86"/>
			<path id="path110" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,2965.64 h -13.92 l 5.41,65.86 h 8.51 v -65.86"/>
			<path id="path112" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,2965.64 h 58.8477 v 65.8633 H 2854.87 Z"/>
			<path id="path114" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4164.76,2965.64 h 8.4102 v 65.8633 h -8.4102 z"/>
			<path id="path116" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3518.88,2965.64 h -99.65 l -13.75,65.86 h 99.65 l 13.75,-65.86"/>
			<path id="path118" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2913.72,2965.64 h 7.4805 v 65.8633 h -7.4805 z"/>
			<path id="path120" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,2857.63 h -13.92 l 5.41,65.85 h 8.51 v -65.85"/>
			<path id="path122" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4218.76,2857.63 h -22.54 v 65.85 h 27.95 l -5.41,-65.85 m -1305.04,0 h -58.85 v 65.85 h 58.85 v -65.85"/>
			<path id="path124" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4196.22,2857.63 h -23.05 v 65.85 h 23.05 v -65.85 m -31.46,0 h -312.8 l -13.76,65.85 h 326.56 v -65.85 m -568.41,0 h -54.92 l -13.75,65.85 h 54.91 l 13.76,-65.85 m -154.57,0 h -376.51 c 9.9,6.52 19.88,12.9 29.77,19.14 l -17.75,38.53 c -13.65,-4.7 -26.2,-9.85 -39.06,-15.41 -12.8,-5.46 -25.44,-11.39 -37.84,-17.97 -14.18,-7.53 -28.34,-15.35 -42.25,-24.29 h -36.94 v 65.85 h 506.83 l 13.75,-65.85"/>
			<path id="path126" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3851.96,2857.63 h -28.12 c -9.15,22.53 -19.23,44.38 -30.26,65.85 h 44.62 l 13.76,-65.85 m -90.58,0 h -165.03 l -13.76,65.85 h 160.23 c 6.42,-21.86 12.78,-43.89 18.56,-65.85"/>
			<path id="path128" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4164.76,2857.63 h 8.4102 v 65.8594 h -8.4102 z"/>
			<path id="path130" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3541.43,2857.63 h -99.65 l -13.75,65.85 h 99.65 l 13.75,-65.85"/>
			<path id="path132" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2913.72,2857.63 h 7.4805 v 65.8594 h -7.4805 z"/>
			<path id="path134" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2854.87,2761.44 h -12.95 l 4.44,54.02 h 8.51 v -54.02"/>
			<path id="path136" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4218.76,2749.6 h -22.54 v 65.86 h 27.95 l -5.41,-65.86 m -1342.98,11.84 h -20.91 v 54.02 h 51.22 c -4.34,-4.67 -8.56,-9.75 -12.62,-15.41 -5.24,-7.31 -10.25,-15.74 -14.07,-26.07 -1.46,-3.85 -2.7,-8.06 -3.62,-12.54"/>
			<path id="path138" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4196.22,2749.6 h -23.05 v 65.86 h 23.05 v -65.86 m -31.46,0 h -290.25 l -13.75,65.86 h 304 v -65.86 m -545.86,0 h -54.91 l -13.75,65.86 h 54.91 l 13.75,-65.86 m -154.56,0 h -10.46 c 1.54,9.56 3.21,18.7 4.8,27.09 l 5.66,-27.09 m -55,0 h -97.11 c -13.5,7.53 -28.84,11.84 -44.82,11.84 h -8.21 v 0 h -305.06 c 4.47,7.44 11.22,15.77 18.89,23.74 10.22,10.43 21.59,20.52 33.69,30.28 h 443.87 l 5.4,-25.87 -46.65,-39.99"/>
			<path id="path140" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3874.51,2749.6 h -19.09 c -2.96,24.31 -9.15,45.14 -15.82,65.86 h 21.16 l 13.75,-65.86 m -92.29,0 H 3618.9 l -13.75,65.86 h 166.5 c 1.23,-5.6 2.41,-11.19 3.52,-16.76 3.28,-16.72 6.13,-33.88 7.05,-49.1"/>
			<path id="path142" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4164.76,2749.6 h 8.4102 v 65.8633 h -8.4102 z"/>
			<path id="path144" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3563.99,2749.6 h -99.65 l -5.66,27.09 c 1.26,6.61 2.47,12.74 3.55,18.26 l -6.24,-5.36 -5.4,25.87 h 99.65 l 13.75,-65.86"/>
			<path id="path146" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4076.88,1050.85 h -288.89 v 56.95 h 288.89 v -56.95"/>
			<path id="path148" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3243.08,451.629 h 53.3281 v 1310.65 H 3243.08 Z"/>
			<path id="path150" style={{"fill":"#f3f2f1","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3058.36,1050.85 h 729.633 v 56.9492 H 3058.36 Z"/>
			<path id="path152" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4076.88,739.18 h -288.89 v 56.961 h 288.89 V 739.18"/>
			<path id="path154" style={{"fill":"#f3f2f1","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3058.36,739.18 h 729.633 v 56.9609 H 3058.36 Z"/>
			<path id="path156" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4076.88,1674.18 h -288.89 v 56.96 h 288.89 v -56.96"/>
			<path id="path158" style={{"fill":"#f3f2f1","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3058.36,1674.18 h 729.633 v 56.9609 H 3058.36 Z"/>
			<path id="path160" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 4076.88,1362.51 h -288.89 v 56.96 h 288.89 v -56.96"/>
			<path id="path162" style={{"fill":"#f3f2f1","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3058.36,1362.51 h 729.633 v 56.957 H 3058.36 Z"/>
			<path id="path164" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3972.71,451.629 h 53.33 V 1762.28 h -53.33 V 451.629"/>
			<path id="path166" style={{"fill":"#f3f2f1","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3734.66,451.629 h 53.33 V 1762.28 h -53.33 V 451.629"/>
			<path id="path168" style={{"fill":"#f3f2f1","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3058.36,451.629 h 53.332 v 1310.65 h -53.332 z"/>
			<path id="path170" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 653.738,451.621 h 545.781 v 1058.93 H 653.738 Z"/>
			<path id="path172" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 799.477,451.621 H 653.738 v 146.18 H 952.281 L 799.477,451.621"/>
			<path id="path174" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2141.83,451.621 h 545.777 v 1058.93 H 2141.83 Z"/>
			<path id="path176" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 653.738,501.941 h 1615.29 v 1008.61 H 653.738 Z"/>
			<path id="path178" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2123.29,451.621 h 145.74 v 146.18 h -298.55 l 152.81,-146.18"/>
			<path id="path180" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 766.797,882.301 h 1389.17 v 252.41 H 766.797 Z"/>
			<path id="path182" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 766.797,575.941 H 2155.97 V 828.352 H 766.797 V 575.941"/>
			<path id="path184" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1030.84,1107.72 h 861.08 c 25.28,0 45.77,20.49 45.77,45.76 v 3.09 H 985.074 v -3.09 c 0,-25.27 20.496,-45.76 45.766,-45.76"/>
			<path id="path186" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 766.797,1188.66 h 1389.17 v 252.41 H 766.797 Z"/>
			<path id="path188" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1030.84,1414.08 h 861.08 c 25.28,0 45.77,20.49 45.77,45.76 v 3.09 H 985.074 v -3.09 c 0,-25.27 20.496,-45.76 45.766,-45.76"/>
			<path id="path190" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1030.84,801.359 h 861.08 c 25.28,0 45.77,20.493 45.77,45.77 v 3.082 H 985.074 v -3.082 c 0,-25.277 20.486,-45.77 45.766,-45.77"/>
			<path id="path192" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 740.68,2535.03 h 1377.81 v 902.266 H 740.68 Z"/>
			<path id="path194" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 698.641,2535.03 h 1400.21 v 902.266 H 698.641 Z"/>
			<path id="path196" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 740.68,2357.95 h 1377.81 v 177.082 H 740.68 Z"/>
			<path id="path198" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 628.605,2357.95 h 1400.2 v 177.082 h -1400.2 z"/>
			<path id="path200" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,2593.89 h 1282.49 v 784.555 H 757.496 Z"/>
			<path id="path202" style={{"fill":"#ffffff","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1750.8,2593.88 -163.81,784.56 h -255.61 l 163.81,-784.56 h 255.61"/>
			<path id="path204" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2012.74,2652.46 c 2.32,0 4.21,1.89 4.21,4.21 v 683.48 c 0,2.32 -1.89,4.21 -4.21,4.21 -2.32,0 -4.2,-1.89 -4.2,-4.21 v -683.48 c 0,-2.32 1.88,-4.21 4.2,-4.21"/>
			<path id="path206" style={{"fill":"#ffffff","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1440.28,2593.88 -163.81,784.56 h -99.65 l 163.81,-784.56 h 99.65"/>
			<path id="path208" style={{"fill":"#e2e2e0","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,2593.89 h 7.47656 v 784.555 H 757.496 Z"/>
			<path id="path210" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 687.777,3289.7 h -3.054 l 5.418,65.87 h 8.5 v -37.16 h -8.5 l -2.364,-28.71"/>
			<path id="path212" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2064.89,3318.41 h -24.9 v 37.16 h 27.96 l -3.06,-37.16 m -1307.394,0 h -58.855 v 37.16 h 58.855 v -37.16"/>
			<path id="path214" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3318.41 h -23.04 v 21.74 c 0,2.32 -1.89,4.21 -4.21,4.21 -2.32,0 -4.2,-1.89 -4.2,-4.21 v -21.74 h -409.02 l -7.75,37.16 h 448.22 v -37.16 m -696.08,0 H 1289 l -7.75,37.16 h 54.91 l 7.75,-37.16 m -154.56,0 H 764.973 v 37.16 h 416.617 l 7.76,-37.16"/>
			<path id="path216" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1599.52,3318.41 h -255.61 l -7.75,37.16 h 255.61 l 7.75,-37.16"/>
			<path id="path218" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2016.95,3318.41 h -8.41 v 21.74 c 0,2.32 1.88,4.21 4.2,4.21 2.32,0 4.21,-1.89 4.21,-4.21 v -21.74"/>
			<path id="path220" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1289,3318.41 h -99.65 l -7.76,37.16 h 99.66 l 7.75,-37.16"/>
			<path id="path222" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3318.41 h 7.47656 v 37.1563 H 757.496 Z"/>
			<path id="path224" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 687.777,3252.55 h -3.054 l 3.054,37.15 h 10.864 v -8.44 h -8.5 l -2.364,-28.71"/>
			<path id="path226" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2064.89,3281.26 h -24.9 v 8.44 h 22.54 l 2.36,28.71 h 3.06 l -3.06,-37.15 m -1307.394,0 h -58.855 v 8.44 h 58.855 v -8.44"/>
			<path id="path228" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3281.26 h -23.04 v 8.44 h 23.04 v -8.44 m -31.45,0 h -401.26 l -1.76,8.44 h 403.02 v -8.44 m -656.87,0 h -54.91 l -1.76,8.44 h 54.91 l 1.76,-8.44 m -154.56,0 H 764.973 v 8.44 h 430.377 l 1.76,-8.44"/>
			<path id="path230" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1607.28,3281.26 h -255.61 l -1.76,8.44 h 255.61 l 1.76,-8.44"/>
			<path id="path232" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3281.26 h 8.4102 v 8.4453 h -8.4102 z"/>
			<path id="path234" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1296.76,3281.26 h -99.65 l -1.76,8.44 H 1295 l 1.76,-8.44"/>
			<path id="path236" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3281.26 h 7.47656 v 8.4453 H 757.496 Z"/>
			<path id="path238" style={{"fill":"#ebebe9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 698.641,3289.7 h -10.864 l 2.364,28.71 h 8.5 v -28.71"/>
			<path id="path240" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2062.53,3289.7 h -22.54 v 28.71 h 24.9 l -2.36,-28.71 m -1305.034,0 h -58.855 v 28.71 h 58.855 v -28.71"/>
			<path id="path242" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3289.7 h -23.04 v 28.71 h 23.04 v -28.71 m -31.45,0 h -403.02 l -6,28.71 h 409.02 v -28.71 m -658.63,0 H 1295 l -6,28.71 h 54.91 l 6,-28.71 m -154.56,0 H 764.973 v 28.71 h 424.377 l 6,-28.71"/>
			<path id="path244" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1605.52,3289.7 h -255.61 l -6,28.71 h 255.61 l 6,-28.71"/>
			<path id="path246" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3289.7 h 8.4102 v 28.707 h -8.4102 z"/>
			<path id="path248" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1295,3289.7 h -99.65 l -6,28.71 H 1289 l 6,-28.71"/>
			<path id="path250" style={{"fill":"#e6e6e4","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3289.7 h 7.47656 v 28.707 H 757.496 Z"/>
			<path id="path252" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 687.777,3215.39 h -3.054 l 3.054,37.16 h 10.864 v -8.45 h -8.5 l -2.364,-28.71"/>
			<path id="path254" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2064.89,3244.1 h -24.9 v 8.45 h 22.54 l 2.36,28.71 h 3.06 l -3.06,-37.16 m -1307.394,0 h -58.855 v 8.45 h 58.855 v -8.45"/>
			<path id="path256" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3244.1 h -23.04 v 8.45 h 23.04 v -8.45 m -31.45,0 h -393.5 l -1.77,8.45 h 395.27 v -8.45 m -649.11,0 h -54.91 l -1.77,8.45 h 54.91 l 1.77,-8.45 m -154.56,0 H 764.973 v 8.45 H 1203.1 l 1.77,-8.45"/>
			<path id="path258" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1615.04,3244.1 h -255.61 l -1.77,8.45 h 255.61 l 1.77,-8.45"/>
			<path id="path260" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3244.1 h 8.4102 v 8.4492 h -8.4102 z"/>
			<path id="path262" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1304.52,3244.1 h -99.65 l -1.77,8.45 h 99.65 l 1.77,-8.45"/>
			<path id="path264" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3244.1 h 7.47656 v 8.4492 H 757.496 Z"/>
			<path id="path266" style={{"fill":"#ebebe9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 698.641,3252.55 h -10.864 l 2.364,28.71 h 8.5 v -28.71"/>
			<path id="path268" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2062.53,3252.55 h -22.54 v 28.71 h 24.9 l -2.36,-28.71 m -1305.034,0 h -58.855 v 28.71 h 58.855 v -28.71"/>
			<path id="path270" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3252.55 h -23.04 v 28.71 h 23.04 v -28.71 m -31.45,0 h -395.27 l -5.99,28.71 h 401.26 v -28.71 m -650.88,0 h -54.91 l -5.99,28.71 h 54.91 l 5.99,-28.71 m -154.56,0 H 764.973 v 28.71 h 432.137 l 5.99,-28.71"/>
			<path id="path272" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1613.27,3252.55 h -255.61 l -5.99,28.71 h 255.61 l 5.99,-28.71"/>
			<path id="path274" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3252.55 h 8.4102 v 28.707 h -8.4102 z"/>
			<path id="path276" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1302.75,3252.55 h -99.65 l -5.99,28.71 h 99.65 l 5.99,-28.71"/>
			<path id="path278" style={{"fill":"#e6e6e4","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3252.55 h 7.47656 v 28.707 H 757.496 Z"/>
			<path id="path280" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 687.777,3178.24 h -3.054 l 3.054,37.15 h 10.864 v -8.44 h -8.5 l -2.364,-28.71"/>
			<path id="path282" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2064.89,3206.95 h -24.9 v 8.44 h 22.54 l 2.36,28.71 h 3.06 l -3.06,-37.15 m -1307.394,0 h -58.855 v 8.44 h 58.855 v -8.44"/>
			<path id="path284" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3206.95 h -23.04 v 8.44 h 23.04 v -8.44 m -31.45,0 H 1622.8 l -1.77,8.44 h 387.51 v -8.44 m -641.35,0 h -54.92 l -1.76,8.44 h 54.91 l 1.77,-8.44 m -154.57,0 H 764.973 v 8.44 h 445.887 l 1.76,-8.44"/>
			<path id="path286" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1622.8,3206.95 h -255.61 l -1.77,8.44 h 255.61 l 1.77,-8.44"/>
			<path id="path288" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3206.95 h 8.4102 v 8.4453 h -8.4102 z"/>
			<path id="path290" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1312.27,3206.95 h -99.65 l -1.76,8.44 h 99.65 l 1.76,-8.44"/>
			<path id="path292" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3206.95 h 7.47656 v 8.4453 H 757.496 Z"/>
			<path id="path294" style={{"fill":"#ebebe9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 698.641,3215.39 h -10.864 l 2.364,28.71 h 8.5 v -28.71"/>
			<path id="path296" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2062.53,3215.39 h -22.54 v 28.71 h 24.9 l -2.36,-28.71 m -1305.034,0 h -58.855 v 28.71 h 58.855 v -28.71"/>
			<path id="path298" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3215.39 h -23.04 v 28.71 h 23.04 v -28.71 m -31.45,0 h -387.51 l -5.99,28.71 h 393.5 v -28.71 m -643.12,0 h -54.91 l -5.99,28.71 h 54.91 l 5.99,-28.71 m -154.56,0 H 764.973 v 28.71 h 439.897 l 5.99,-28.71"/>
			<path id="path300" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1621.03,3215.39 h -255.61 l -5.99,28.71 h 255.61 l 5.99,-28.71"/>
			<path id="path302" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3215.39 h 8.4102 v 28.707 h -8.4102 z"/>
			<path id="path304" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1310.51,3215.39 h -99.65 l -5.99,28.71 h 99.65 l 5.99,-28.71"/>
			<path id="path306" style={{"fill":"#e6e6e4","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3215.39 h 7.47656 v 28.707 H 757.496 Z"/>
			<path id="path308" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 687.777,3141.09 h -3.054 l 3.054,37.15 h 10.864 v -8.44 h -8.5 l -2.364,-28.71"/>
			<path id="path310" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2064.89,3169.8 h -24.9 v 8.44 h 22.54 l 2.36,28.71 h 3.06 l -3.06,-37.15 m -1307.394,0 h -58.855 v 8.44 h 58.855 v -8.44"/>
			<path id="path312" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3169.8 h -23.04 v 8.44 h 23.04 v -8.44 m -31.45,0 h -377.99 l -1.76,8.44 h 379.75 v -8.44 m -633.6,0 h -54.91 l -1.76,8.44 h 54.91 l 1.76,-8.44 m -154.56,0 H 764.973 v 8.44 h 453.647 l 1.76,-8.44"/>
			<path id="path314" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1630.55,3169.8 h -255.61 l -1.76,8.44 h 255.61 l 1.76,-8.44"/>
			<path id="path316" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3169.8 h 8.4102 v 8.4453 h -8.4102 z"/>
			<path id="path318" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1320.03,3169.8 h -99.65 l -1.76,8.44 h 99.65 l 1.76,-8.44"/>
			<path id="path320" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3169.8 h 7.47656 v 8.4453 H 757.496 Z"/>
			<path id="path322" style={{"fill":"#ebebe9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 698.641,3178.24 h -10.864 l 2.364,28.71 h 8.5 v -28.71"/>
			<path id="path324" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2062.53,3178.24 h -22.54 v 28.71 h 24.9 l -2.36,-28.71 m -1305.034,0 h -58.855 v 28.71 h 58.855 v -28.71"/>
			<path id="path326" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3178.24 h -23.04 v 28.71 h 23.04 v -28.71 m -31.45,0 h -379.75 l -5.99,28.71 h 385.74 v -28.71 m -635.36,0 h -54.91 l -6,28.71 h 54.92 l 5.99,-28.71 m -154.56,0 H 764.973 v 28.71 h 447.647 l 6,-28.71"/>
			<path id="path328" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1628.79,3178.24 h -255.61 l -5.99,28.71 h 255.61 l 5.99,-28.71"/>
			<path id="path330" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3178.24 h 8.4102 v 28.707 h -8.4102 z"/>
			<path id="path332" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1318.27,3178.24 h -99.65 l -6,28.71 h 99.65 l 6,-28.71"/>
			<path id="path334" style={{"fill":"#e6e6e4","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3178.24 h 7.47656 v 28.707 H 757.496 Z"/>
			<path id="path336" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 698.641,3103.93 h -13.918 l 3.054,37.16 h 10.864 v -37.16"/>
			<path id="path338" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2062.53,3103.93 h -22.54 v 37.16 h 22.54 l 2.36,28.71 h 3.06 l -5.42,-65.87 m -1305.034,0 h -58.855 v 37.16 h 58.855 v -37.16"/>
			<path id="path340" style={{"fill":"#eeeeec","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3103.93 h -23.04 v 37.16 h 23.04 v -37.16 m -31.45,0 H 1644.3 l -7.75,37.16 h 371.99 v -37.16 m -619.85,0 h -54.91 l -7.75,37.16 h 54.91 l 7.75,-37.16 m -154.56,0 H 764.973 v 37.16 h 461.397 l 7.76,-37.16"/>
			<path id="path342" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1644.3,3103.93 h -255.61 l -7.75,37.16 h 255.61 l 7.75,-37.16"/>
			<path id="path344" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3103.93 h 8.4102 v 37.1602 h -8.4102 z"/>
			<path id="path346" style={{"fill":"#f0f0ee","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1333.78,3103.93 h -99.65 l -7.76,37.16 h 99.66 l 7.75,-37.16"/>
			<path id="path348" style={{"fill":"#e5e5e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3103.93 h 7.47656 v 37.1602 H 757.496 Z"/>
			<path id="path350" style={{"fill":"#ebebe9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 698.641,3141.09 h -10.864 l 2.364,28.71 h 8.5 v -28.71"/>
			<path id="path352" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2062.53,3141.09 h -22.54 v 28.71 h 24.9 l -2.36,-28.71 m -1305.034,0 h -58.855 v 28.71 h 58.855 v -28.71"/>
			<path id="path354" style={{"fill":"#eaeae7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2039.99,3141.09 h -23.04 v 28.71 h 23.04 v -28.71 m -31.45,0 h -371.99 l -6,28.71 h 377.99 v -28.71 m -627.6,0 h -54.91 l -6,28.71 h 54.91 l 6,-28.71 m -154.57,0 H 764.973 v 28.71 h 455.407 l 5.99,-28.71"/>
			<path id="path356" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1636.55,3141.09 h -255.61 l -6,28.71 h 255.61 l 6,-28.71"/>
			<path id="path358" style={{"fill":"#e8e9e6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2008.54,3141.09 h 8.4102 v 28.707 h -8.4102 z"/>
			<path id="path360" style={{"fill":"#ebebe8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1326.03,3141.09 h -99.66 l -5.99,28.71 h 99.65 l 6,-28.71"/>
			<path id="path362" style={{"fill":"#e6e6e4","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 757.496,3141.09 h 7.47656 v 28.707 H 757.496 Z"/>
			<path id="path364" style={{"fill":"#f3f2f1","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 960.133,1542.91 h 47.637 v 530.92 h -47.637 v -530.92"/>
			<path id="path366" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 966.266,1543.27 h 13.457 v 530.93 h -13.457 v -530.93"/>
			<path id="path368" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 985.355,1543.27 h 5.274 v 530.93 h -5.274 v -530.93"/>
			<path id="path370" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 807.629,1510.55 h 352.641 c 0,24.53 -19.89,44.42 -44.43,44.42 H 852.055 c -24.535,0 -44.426,-19.89 -44.426,-44.42"/>
			<path id="path372" style={{"fill":"#ededeb","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 898.352,1744.23 v 0 c 7.535,0 13.699,6.17 13.699,13.7 v 348.1 c 0,7.54 -6.164,13.7 -13.699,13.7 -7.536,0 -13.704,-6.16 -13.704,-13.7 v -348.1 c 0,-7.53 6.168,-13.7 13.704,-13.7"/>
			<path id="path374" style={{"fill":"#dadbd8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 770.734,1947.63 h 426.426 l -49.05,297.99 H 819.789 l -49.055,-297.99"/>
			<path id="path376" style={{"fill":"#f3f2f1","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 4438.89,113.238 C 4438.89,50.6992 3570.82,0 2500,0 1429.18,0 561.109,50.6992 561.109,113.238 c 0,62.532 868.071,113.231 1938.891,113.231 1070.82,0 1938.89,-50.699 1938.89,-113.231"/>
			<path id="path378" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1044.48,2066.89 -13.28,184.41 9.97,0.72 13.28,-184.42 -9.97,-0.71"/>
			<path id="path380" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1056.33,1902.28 -5,69.38 9.98,0.72 5,-69.38 -9.98,-0.72"/>
			<path id="path382" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 3377.11,1124 H 1235.53 c -48.44,0 -90.92,39.64 -94.41,88.07 l -105.22,1461.3 c -3.49,48.44 33.29,88.07 81.72,88.07 H 3259.2 c 48.44,0 90.92,-39.63 94.41,-88.07 l 105.23,-1461.3 c 3.48,-48.43 -33.29,-88.07 -81.73,-88.07"/>
			<path id="path384" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 3385.32,1124 H 1243.74 c -48.44,0 -90.92,39.64 -94.41,88.07 l -105.22,1461.3 c -3.49,48.44 33.29,88.07 81.72,88.07 h 2141.58 c 48.44,0 90.92,-39.63 94.41,-88.07 l 105.23,-1461.3 c 3.48,-48.43 -33.29,-88.07 -81.73,-88.07"/>
			<path id="path386" style={{"fill":"#90b4e3","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3364.64,1124 h -69.05 c -8.51,37.82 -14.69,68.52 -17.42,88.28 -2.5,18.08 -3.66,36.7 -3.66,55.78 0,0.6 0.01,1.2 0.01,1.79 h 54.02 c 5.54,0 10.64,1.41 15,3.89 1.05,-9.02 2.13,-17.94 3.23,-26.72 0.01,0.03 0.01,0.07 0.02,0.11 2.98,-23.7 6.15,-46.53 9.52,-68.18 2.64,-16.96 5.43,-35.35 8.33,-54.95 m -314.46,0 H 1926.93 1243.74 v 0 c -48.44,0 -90.92,39.64 -94.41,88.07 l -105.22,1461.3 c -0.16,2.18 -0.24,4.33 -0.23,6.47 0.07,45.45 35.69,81.6 81.95,81.6 h 1749.95 c -1.49,-7.34 -2.11,-15.39 -1.29,-23.67 0.73,-7.01 2.53,-13.95 5.05,-20.36 H 1129 c -2.42,0 -4.81,-0.1 -7.17,-0.3 -54.75,-4.5 -43.56,-87.77 11.69,-87.77 h 1834.76 c 37.47,-25.69 75.97,-46.67 114.96,-66.62 8.07,-4.13 16.16,-8.18 24.25,-12.15 l -2.02,-15.14 H 1188.38 c -17.63,0 -30.9,-14.29 -29.63,-31.92 l 86.54,-1201.73 c 1.27,-17.63 16.59,-31.93 34.22,-31.93 h 1742.22 c 1.47,-28.73 4.87,-55.69 10.56,-80.27 4.75,-20.53 10.79,-42.5 17.89,-65.58 m 202.39,1480.12 c -14.52,8.97 -34.11,17.62 -54.57,25.22 h 80.75 c 5.87,0 11.12,0.94 15.73,2.63 -3.18,-4.89 -6.87,-14.63 -8.39,-23.35 -11.37,-1.36 -22.68,-2.9 -33.52,-4.5 m 51.76,33.23 c 27.36,20.98 18.25,76.12 -26.53,79.76 -2.39,0.2 -4.8,0.3 -7.22,0.3 h -287.09 c -8.42,6.13 -16.24,12.5 -23.04,18.86 -1.88,1.83 -4,3.66 -5.49,5.45 l -3.99,4.27 c -0.88,0.88 -1,0.99 -1.18,0.99 -0.06,0 -0.13,-0.01 -0.22,-0.01 -0.03,-0.02 -0.06,-0.03 -0.09,-0.03 -0.72,0 -0.61,5.75 3.97,13.33 0.22,0.39 0.45,0.78 0.69,1.17 h 313.27 c 46.59,0 87.67,-36.66 93.84,-82.56 0.48,-4.91 0.64,-9.76 0.39,-14.47 -23.24,-6.53 -41.24,-13.11 -44.41,-16.79 -0.99,-1.15 -1.81,-4.2 -2.34,-8.18 -4.12,-0.69 -7.68,-1.39 -10.56,-2.09"/>
			<path id="path388" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 3270.58,2717.41 H 1129 c -2.42,0 -4.81,-0.1 -7.17,-0.3 -54.75,-4.5 -43.56,-87.77 11.69,-87.77 h 2145.23 c 55.25,0 54.44,83.27 -0.95,87.77 -2.39,0.2 -4.8,0.3 -7.22,0.3"/>
			<path id="path390" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1184.78,2673.37 c 0.8,-11.15 -7.59,-20.19 -18.74,-20.19 -11.15,0 -20.84,9.04 -21.64,20.19 -0.8,11.15 7.58,20.19 18.74,20.19 11.15,0 20.84,-9.04 21.64,-20.19"/>
			<path id="path392" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1253.46,2673.37 c 0.8,-11.15 -7.59,-20.19 -18.74,-20.19 -11.15,0 -20.84,9.04 -21.64,20.19 -0.81,11.15 7.58,20.19 18.73,20.19 11.15,0 20.85,-9.04 21.65,-20.19"/>
			<path id="path394" style={{"fill":"#fafaf9","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 1322.14,2673.37 c 0.8,-11.15 -7.59,-20.19 -18.74,-20.19 -11.15,0 -20.84,9.04 -21.64,20.19 -0.81,11.15 7.58,20.19 18.73,20.19 11.15,0 20.84,-9.04 21.65,-20.19"/>
			<path id="path396" style={{"fill":"#ffffff","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="M 3328.54,1269.85 H 1279.51 c -17.63,0 -32.95,14.3 -34.22,31.93 l -86.54,1201.73 c -1.27,17.63 12,31.92 29.63,31.92 h 2049.03 c 17.63,0 32.96,-14.29 34.23,-31.92 l 86.53,-1201.73 c 1.27,-17.63 -11.99,-31.93 -29.63,-31.93"/>
			<path id="path398" style={{"fill":"#ffffff","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2465.3,1727.64 -33.36,463.19 -101.07,60.83 h -278.04 l 37.74,-524.02 h 374.73"/>
			<path id="path400" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2095.08,1732.48 h 365.01 l -32.8,455.49 -97.76,58.84 h -271.49 z m 370.22,-9.69 h -374.73 c -2.55,0 -4.65,1.96 -4.84,4.5 L 2048,2251.31 c -0.1,1.34 0.37,2.67 1.28,3.65 0.92,0.99 2.21,1.55 3.55,1.55 h 278.04 l 2.5,-0.7 101.07,-60.83 c 1.35,-0.81 2.22,-2.24 2.34,-3.8 l 33.35,-463.2 c 0.1,-1.34 -0.37,-2.66 -1.28,-3.65 -0.92,-0.98 -2.21,-1.54 -3.55,-1.54"/>
			<path id="path402" style={{"fill":"#e7e8e5","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2431.94,2190.83 -101.07,60.83 37.11,-80.59 z"/>
			<path id="path404" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2341.86,2239.4 28.75,-62.45 49.56,15.31 z m 26.12,-73.18 c -1.85,0 -3.59,1.07 -4.4,2.82 l -37.11,80.59 c -0.89,1.94 -0.41,4.22 1.17,5.64 1.59,1.42 3.91,1.64 5.73,0.54 l 101.07,-60.83 c 1.64,-0.99 2.54,-2.84 2.31,-4.74 -0.23,-1.9 -1.55,-3.48 -3.38,-4.04 l -63.96,-19.76 -1.43,-0.22"/>
			<path id="path406" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2209.69,2015.56 c 0.84,-11.69 -7.95,-21.17 -19.64,-21.17 -11.7,0 -21.86,9.48 -22.7,21.17 -0.84,11.69 7.95,21.17 19.65,21.17 11.69,0 21.85,-9.48 22.69,-21.17"/>
			<path id="path408" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2347.05,2015.56 c 0.84,-11.69 -7.96,-21.17 -19.65,-21.17 -11.69,0 -21.85,9.48 -22.69,21.17 -0.84,11.69 7.95,21.17 19.64,21.17 11.7,0 21.86,-9.48 22.7,-21.17"/>
			<path id="path410" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2385.8,1878.26 c -2.52,0 -4.65,1.95 -4.83,4.5 -2.45,34.04 -55.84,61.73 -119,61.73 -41.85,0 -79.43,-12.44 -98.06,-32.47 -8.29,-8.91 -12.13,-18.52 -11.4,-28.56 0.19,-2.67 -1.82,-4.99 -4.49,-5.18 -2.68,-0.17 -4.99,1.81 -5.18,4.48 -0.91,12.66 3.92,25.06 13.97,35.86 20.41,21.94 60.71,35.56 105.16,35.56 69.29,0 125.81,-31.06 128.66,-70.72 0.2,-2.67 -1.81,-4.99 -4.48,-5.18 l -0.35,-0.02"/>
			<path id="path412" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2131.67,2058.45 c -1.18,0 -2.36,0.43 -3.29,1.29 -1.97,1.83 -2.08,4.89 -0.26,6.85 l 21.24,22.88 c 1.82,1.97 4.89,2.07 6.84,0.26 1.97,-1.82 2.08,-4.89 0.26,-6.85 L 2135.22,2060 c -0.95,-1.03 -2.25,-1.55 -3.55,-1.55"/>
			<path id="path414" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2375.84,2058.45 c -1.18,0 -2.37,0.43 -3.3,1.3 l -24.53,22.89 c -1.96,1.82 -2.07,4.89 -0.24,6.84 1.82,1.96 4.89,2.07 6.85,0.24 l 24.53,-22.88 c 1.95,-1.82 2.06,-4.89 0.23,-6.85 -0.95,-1.02 -2.24,-1.54 -3.54,-1.54"/>
			<path id="path416" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2022.57,1624.09 h 22.6 l 32.61,-43.34 -3.12,43.34 h 22.82 l 5.64,-78.33 h -22.82 l -32.43,43.01 3.1,-43.01 h -22.76 l -5.64,78.33"/>
			<path id="path418" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2137.55,1584.76 c 0.57,-7.9 2.45,-13.58 5.64,-17.04 3.18,-3.45 7.31,-5.18 12.36,-5.18 5.2,0 9.11,1.69 11.71,5.08 2.61,3.38 3.6,9.45 2.97,18.21 -0.54,7.38 -2.41,12.76 -5.63,16.17 -3.22,3.4 -7.38,5.1 -12.47,5.1 -4.88,0 -8.67,-1.73 -11.38,-5.19 -2.71,-3.45 -3.77,-9.17 -3.2,-17.15 z m -24.21,0.11 c -0.92,12.79 1.92,22.75 8.53,29.87 6.62,7.12 16.28,10.69 28.99,10.69 13.04,0 23.34,-3.51 30.89,-10.5 7.56,-7 11.79,-16.81 12.7,-29.42 0.66,-9.15 -0.34,-16.66 -3,-22.52 -2.66,-5.86 -6.78,-10.42 -12.37,-13.68 -5.59,-3.25 -12.73,-4.88 -21.42,-4.88 -8.83,0 -16.25,1.4 -22.24,4.22 -5.99,2.81 -11,7.26 -15.04,13.36 -4.03,6.08 -6.38,13.71 -7.04,22.86"/>
			<path id="path420" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2267,1606.35 3.09,-42.8 h 5.93 c 5.05,0 8.61,0.56 10.67,1.69 2.05,1.12 3.59,3.08 4.6,5.87 1.01,2.8 1.29,7.33 0.83,13.6 -0.59,8.3 -2.36,13.98 -5.28,17.05 -2.93,3.06 -7.53,4.59 -13.8,4.59 z m -25.48,17.74 h 35.96 c 7.09,0 12.88,-0.96 17.39,-2.89 4.5,-1.92 8.3,-4.68 11.41,-8.28 3.11,-3.6 5.48,-7.78 7.1,-12.55 1.63,-4.78 2.64,-9.83 3.02,-15.18 0.6,-8.37 0.12,-14.86 -1.46,-19.47 -1.57,-4.62 -3.94,-8.48 -7.1,-11.6 -3.15,-3.11 -6.64,-5.19 -10.45,-6.22 -5.2,-1.42 -9.96,-2.14 -14.27,-2.14 h -35.96 l -5.64,78.33"/>
			<path id="path422" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2368.24,1575.63 -10.68,28.16 -6.52,-28.16 z m 6.24,-16.94 H 2347 l -2.89,-12.93 h -24.71 l 23.8,78.33 h 26.4 l 35.07,-78.33 h -25.34 l -4.85,12.93"/>
			<path id="path424" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2393.69,1624.09 h 73.57 l 1.39,-19.34 h -24.68 l 4.25,-58.99 h -24.21 l -4.24,58.99 h -24.69 l -1.39,19.34"/>
			<path id="path426" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 2517.41,1575.63 -10.67,28.16 -6.53,-28.16 z m 6.24,-16.94 h -27.48 l -2.88,-12.93 h -24.72 l 23.8,78.33 h 26.4 l 35.08,-78.33 h -25.35 l -4.85,12.93"/>
			<path id="path428" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3536.88,2544.82 c 8.94,4.47 19.59,10.27 29.44,15.67 10.03,5.52 20.01,11.26 29.84,17.24 19.59,12.06 39.33,23.98 58.08,37.23 37.82,26.05 73.25,54.95 105.12,86.53 4.18,3.82 7.73,8.05 11.6,12.09 l 5.7,6.12 2.86,3.06 1.43,1.53 0.1,0.09 -0.31,-0.49 c -1.43,-2.67 -0.24,-1.86 0.31,0.72 0.67,2.48 1.23,6.53 1.41,10.91 0.64,18.16 -2.95,41.04 -7.29,63.18 -8.91,44.85 -22.38,90.98 -35.71,136.18 l 38.86,17.03 c 24.33,-43.46 44.62,-87.84 61.12,-135.97 7.86,-24.38 15.09,-48.86 17.19,-78.94 0.39,-7.66 0.56,-15.71 -0.83,-25.1 -1.46,-9.24 -3.8,-20.3 -12.23,-32.84 l -1.66,-2.31 -1.29,-1.68 -1.54,-1.87 -3.08,-3.75 -6.18,-7.49 c -4.17,-4.94 -8.12,-10.15 -12.55,-14.78 -34.49,-38.29 -72.85,-72.11 -113.8,-101.51 -20.37,-14.89 -41.2,-29.04 -62.83,-41.9 -10.77,-6.52 -21.68,-12.78 -32.77,-18.86 -11.23,-6.24 -21.91,-11.67 -34.67,-17.65 l -36.32,77.56"/>
			<path id="path430" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3464.44,199.672 c -14.58,-2.91 -25.55,-2.41 -29.79,1.457 -1.81,1.641 -2.47,3.949 -2.03,7.051 0.23,1.679 1,2.23 1.65,2.519 5.13,2.321 19.8,-4.758 30.17,-11.027 z m -16.5,-5.844 c 7.74,0 16.2,1.602 22.11,3.004 l 1.5,1.59 -0.86,2 c -2.87,1.887 -28.31,18.297 -38.06,13.887 -1.53,-0.688 -3.47,-2.219 -3.94,-5.567 -0.63,-4.441 0.48,-7.992 3.29,-10.543 3.57,-3.258 9.52,-4.371 15.96,-4.371"/>
			<path id="path432" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3455.35,230.73 c 1.62,0 3.07,-0.57 4.38,-1.738 5.44,-4.812 7.31,-18.344 7.76,-26.371 -7.96,6.359 -17.46,19.93 -16.31,25.68 0.19,0.949 0.73,2.07 3.26,2.379 z m 14.25,-33.949 1,0.27 0.98,1.711 c 0,1.027 -0.11,25.136 -9.22,33.199 -2.42,2.148 -5.23,3.039 -8.39,2.648 -4.97,-0.597 -6.32,-3.718 -6.68,-5.527 -1.89,-9.441 13.34,-27.512 21.33,-32.043 l 0.98,-0.258"/>
			<path id="path434" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3462.23,2794.95 c -9.74,-49.7 -30.27,-150.37 4.5,-183.54 0,0 -13.59,-50.42 -105.94,-50.42 -101.56,0 -48.54,50.41 -48.54,50.41 55.43,13.24 54,54.36 44.34,92.98 l 105.64,90.57"/>
			<path id="path436" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3292.84,2591.37 c -15.94,-2.16 -2.38,39.14 4.1,43.42 14.96,9.88 208.51,23.89 207.25,0.4 -0.8,-10.1 -5.55,-29.84 -14.01,-36.54 -3.36,-2.65 -13.19,-1.08 -32.49,0.5 -29.42,2.4 -80.83,4.8 -164.85,-7.78"/>
			<path id="path438" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3324.37,2605.28 c -12.66,-4.29 -11.46,37.32 -7.14,42.34 9.97,11.58 166.63,51.83 171.29,29.05 1.79,-9.83 2.65,-29.47 -2.72,-37.09 -5.38,-7.62 -51.41,4.98 -161.43,-34.3"/>
			<path id="path440" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3266.1,2884.54 c -1.22,-0.08 -2.47,0.42 -3.31,1.45 -11.2,13.7 -25.75,12.3 -25.89,12.28 -2.21,-0.23 -4.14,1.34 -4.38,3.52 -0.23,2.17 1.34,4.13 3.52,4.37 0.77,0.08 19,1.85 32.9,-15.15 1.39,-1.69 1.13,-4.19 -0.56,-5.58 l -2.28,-0.89"/>
			<path id="path442" style={{"fill":"#f14358","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3246.72,2835.61 c 0,0 -8.56,-30.07 -19.74,-45.27 9.69,-7.42 24.41,-2.14 24.41,-2.14 l -4.67,47.41"/>
			<path id="path444" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3251.6,2847.75 c 0.67,-6.72 -2.36,-12.52 -6.76,-12.96 -4.39,-0.43 -8.49,4.67 -9.15,11.39 -0.66,6.73 2.36,12.53 6.76,12.96 4.39,0.44 8.49,-4.66 9.15,-11.39"/>
			<path id="path446" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3244.43,2858.96 -16.61,3.07 c 0,0 9.53,-11.8 16.61,-3.07"/>
			<path id="path448" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3567,198.762 h -83.76 l 6.57,193.98 h 83.76 L 3567,198.762"/>
			<path id="path450" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3476.61,208.461 h 94.1 c 3.43,0 6.2,-2.379 6.69,-5.719 l 10.72,-74.461 c 1.11,-7.722 -5.53,-15.031 -13.43,-14.89 -32.81,0.57 -48.62,2.488 -90,2.488 -25.46,0 -62.66,-2.641 -97.82,-2.641 -34.38,0 -37.03,34.762 -22.39,37.91 65.64,14.122 76.01,33.59 98.13,52.161 3.98,3.351 8.99,5.152 14,5.152"/>
			<path id="path452" style={{"fill":"#c3c5c2","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3570.25,292.699 h -0.06 l 0.65,19.36 h 0.05 l -0.64,-19.36"/>
			<path id="path454" style={{"fill":"#dc834c","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3570.19,292.699 h -83.74 l 0.65,19.36 h 83.74 l -0.65,-19.36"/>
			<path id="path456" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3233.73,2495.18 c -51.82,20.28 -101.26,42.36 -150.49,67.54 -49.16,25.15 -97.54,51.94 -144.05,87.73 -11.57,9.12 -23.02,19.07 -34.22,30.71 -2.78,3 -5.57,5.77 -8.31,9.22 -2.88,3.62 -5.05,5.74 -8.79,11.31 -6.76,9.71 -11.99,22.77 -13.38,36.08 -1.31,13.34 1.07,26.07 4.91,36.21 3.82,10.33 8.83,18.76 14.07,26.07 10.51,14.65 22.15,25.47 33.89,35.2 23.43,19.39 48.21,33.5 73.03,46.67 12.4,6.58 25.04,12.51 37.84,17.97 12.86,5.56 25.41,10.71 39.06,15.41 l 17.75,-38.53 c -43.61,-27.49 -88.8,-57.71 -122.01,-91.59 -8.07,-8.39 -15.13,-17.18 -19.58,-24.91 -4.75,-7.87 -4.7,-13.76 -3.88,-13.3 0.41,0 0.24,0.18 1.4,-0.98 l 3.99,-4.27 c 1.49,-1.79 3.61,-3.62 5.49,-5.45 7.86,-7.34 17.06,-14.7 27,-21.69 19.84,-14.13 41.72,-27.35 64.33,-39.7 22.55,-12.52 45.73,-24.5 69.35,-35.87 47.13,-22.56 95.98,-44.28 143.92,-64.12 l -31.32,-79.71"/>
			<path id="path458" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3119.69,2904.08 13.85,30.04 -62.96,21.86 c 0,0 -19.61,-59.03 3.77,-86.26 19.9,3.17 36.9,16.06 45.34,34.36"/>
			<path id="path460" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3133.54,2999.06 -50.33,14.51 -12.63,-57.59 62.96,-21.86 v 64.94"/>
			<path id="path462" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3783.5,2931.21 7.33,70.2 -64.88,-15.25 c 0,0 -0.95,-65.81 33.37,-76.29 l 24.18,21.34"/>
			<path id="path464" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3759.58,3050.55 -42.27,-19.74 8.64,-44.65 64.88,15.25 -31.25,49.14"/>
			<path id="path466" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3475.4,2893.07 c 3.06,-83.08 6.12,-118.18 -31.42,-164.66 -56.46,-69.93 -159.17,-55.51 -186.98,24.98 -25.03,72.45 -25.81,196.14 51.78,236.95 76.45,40.21 163.57,-14.2 166.62,-97.27"/>
			<path id="path468" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3439.21,2728.56 c 81.56,35.25 135.2,109.55 112.55,225.75 -21.66,111.15 -96.71,122.14 -127.71,99.08 -31,-23.07 -108.27,11.31 -154.64,-27.72 -80.34,-67.61 -4.38,-140.49 35.18,-183.69 23.64,-48.58 54.6,-148 134.62,-113.42"/>
			<path id="path470" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3405.35,2974.25 c 27.51,-39.84 80.3,-51.1 117.9,-25.13 37.59,25.97 45.76,79.31 18.25,119.16 -27.52,39.85 -80.31,51.1 -117.91,25.13 -37.59,-25.96 -45.76,-79.31 -18.24,-119.16"/>
			<path id="path472" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3426.52,3045.6 c -11.77,66.98 51.61,118.63 136.36,93.67 84.75,-24.96 39.92,-100.09 22.54,-160.09 -17.37,-60 28.24,-117.14 47.3,-71.45 19.06,45.69 -12.98,59.79 -12.98,59.79 0,0 89.98,-23.32 6.39,-124.77 -83.6,-101.45 -159.28,10.57 -138.82,76.68 16.54,53.43 -49.66,62.85 -60.79,126.17"/>
			<path id="path474" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3341.82,3020.29 c -38.69,20.18 -104.21,36.5 -141.5,-26.24 -17.63,-29.66 -10.8,-69.89 -10.8,-69.89 l 113.89,-7.58 38.41,103.71"/>
			<path id="path476" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3176.12,2943.84 -0.16,0.01 c -1.38,0.08 -2.42,1.27 -2.34,2.65 0.11,1.7 2.86,41.93 27.14,67.16 58.69,60.98 127.5,10.4 147.19,-6.56 1.05,-0.9 1.16,-2.48 0.26,-3.52 -0.9,-1.05 -2.48,-1.16 -3.52,-0.27 -18.85,16.23 -84.68,64.7 -140.32,6.89 -23,-23.9 -25.73,-63.61 -25.76,-64.02 -0.08,-1.32 -1.18,-2.34 -2.49,-2.34"/>
			<path id="path478" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3318.53,2845.28 c -0.13,-16.18 -7.37,-32.03 -16.56,-42.77 -13.82,-16.16 -29.66,-8.2 -33.29,9.72 -3.27,16.13 -0.03,43.97 17.44,53.73 17.21,9.62 32.55,-2.17 32.41,-20.68"/>
			<path id="path480" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3300.53,2084.4 c 0,0 5.34,-581.44 55.78,-905.45 40.74,-261.7 116.13,-866.891 116.13,-866.891 h 114.23 c 0,0 11.16,584.242 -9.52,843.081 -52.33,654.98 82.71,777.43 -26.26,929.26 h -250.36"/>
			<path id="path482" style={{"fill":"#2b3c43","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3586.67,312.059 h -114.23 c 0,0 -2.3,18.523 -6.33,50.48 l 34.72,15.422 -34.9,72.687 -14.94,31.114 c -0.22,1.758 -0.44,3.508 -0.67,5.289 l 9.51,4.769 v 0 c 0,0 -7.31,26.399 -19,69.231 -25.48,197.89 -60.85,465.849 -84.52,617.899 -3.37,21.65 -6.54,44.48 -9.52,68.18 17.17,95.49 40.54,286.02 30.49,448.43 43.45,129.54 84.96,258.76 88.41,373.58 h 95.39 c 25.35,-41 33.66,-81.24 33.66,-132.05 0,-52.9 -9.01,-117.28 -17.18,-205.95 -1.65,-17.96 -3.27,-36.9 -4.78,-56.96 -4.66,-62.15 -8.2,-134.88 -8.2,-221.37 0,-84.95 3.42,-183.17 12.57,-297.67 9.94,-124.49 12.52,-324.249 12.52,-498.081 0,-76.329 -0.49,-147.668 -1.08,-205.43 -0.01,-0.828 -0.02,-1.668 -0.03,-2.5 -0.85,-82.801 -1.89,-137.07 -1.89,-137.07 M 3325.25,1471.4 c -1.3,17.66 -2.53,35.41 -3.7,53.14 v 0.02 c 1.17,-17.76 2.4,-35.48 3.7,-53.16"/>
			<path id="path484" style={{"fill":"#627fb7","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3346.77,1247.02 c -1.1,8.78 -2.18,17.7 -3.23,26.72 0.01,0 0.02,0.01 0.02,0.01 1.06,-9 2.13,-17.85 3.23,-26.62 -0.01,-0.04 -0.01,-0.08 -0.02,-0.11"/>
			<path id="path486" style={{"fill":"#a7a9a8","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3343.54,1273.74 c -7.23,62.02 -13.26,129.3 -18.29,197.66 5.03,-68.36 11.07,-135.62 18.31,-197.65 0,0 -0.01,-0.01 -0.02,-0.01"/>
			<path id="path488" style={{"fill":"#1b262a","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3346.79,1247.13 c -1.1,8.77 -2.17,17.62 -3.23,26.62 -7.24,62.03 -13.28,129.29 -18.31,197.65 -1.3,17.68 -2.53,35.4 -3.7,53.16 16.87,56.24 36.49,113.65 55.73,171 10.05,-162.41 -13.32,-352.94 -30.49,-448.43"/>
			<path id="path490" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3456.82,309.801 h 145.48 l 7.64,51.039 -153.96,5.211 0.84,-56.25"/>
			<path id="path492" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3498.33,2575.2 c 13.69,27.21 87.25,44.33 127.46,44.27 l 30.1,-133.65 c 0,0 -80.11,-118.9 -113.43,-106.29 -39.36,14.87 -73.45,137.37 -44.13,195.67"/>
			<path id="path494" style={{"fill":"#2251aa","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3568.44,2386.65 c 9.53,55.62 23.27,122.06 42.75,205.73 v 0 0 c 0,0 -29.11,6.33 -69.23,11.82 27.2,9.75 60.74,15.27 83.6,15.27 0.08,0 0.16,0 0.23,0 l 30.1,-133.65 c 0,0 -50.02,-74.24 -87.45,-99.17"/>
			<path id="path496" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3369.12,327.109 c 3.43,5.61 22.56,10.789 37.18,13.473 -13.2,-13.41 -24.81,-20.883 -31.61,-20.184 -2.38,0.25 -4.2,1.493 -5.59,3.801 l -0.2,2.512 z m 44.41,15.383 0.06,1.957 -2.05,1.031 c -4.2,-0.628 -41.22,-6.5 -46.23,-17.07 -0.96,-2.051 -0.83,-4.211 0.39,-6.25 2.04,-3.41 4.93,-5.328 8.58,-5.699 12.13,-1.262 29.65,15.57 39.01,25.719 l 0.24,0.312"/>
			<path id="path498" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3374.38,355.078 1.58,1.801 c 2.25,2.012 4.86,2.902 7.99,2.691 8.98,-0.59 19.18,-9.879 23.83,-14.609 -11.85,-1 -31.06,2.59 -33.66,7.75 -0.15,0.309 -0.54,1.07 0.26,2.367 z m 39.15,-12.586 0.18,0.387 -0.37,1.93 c -0.63,0.73 -15.46,17.82 -29.13,18.722 -4.19,0.27 -7.86,-0.972 -10.9,-3.699 -4.11,-3.684 -3.76,-6.883 -2.73,-8.91 4.33,-8.582 30.93,-11.57 41.66,-9.34 l 1.29,0.91"/>
			<path id="path500" style={{"fill":"#f89e63","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3500.83,377.961 -76.61,-34.02 -34.14,71.039 -44.61,93.051 -5.23,10.809 76.52,34.09 5.92,-12.18 43.25,-90.102 34.9,-72.687"/>
			<path id="path502" style={{"fill":"#dc834c","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3390.08,414.98 v 0 l -16.16,33.711 74.9,37.598 17.11,-35.641 -75.85,-35.668"/>
			<path id="path504" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3210.69,2084.4 c 0,0 -244.73,-608.35 -178.4,-894.82 59.95,-258.9 325.04,-749.209 325.04,-749.209 l 102.5,51.449 c 0,0 -163.88,591.88 -181.66,720.46 -34.59,250.19 187.76,603.34 187.76,872.12 h -255.24"/>
			<path id="path506" style={{"fill":"#2b3c43","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3357.33,440.371 c 0,0 -24.79,45.859 -60.92,116.918 -15.95,31.371 -34.12,67.66 -53.33,107.09 -42.84,87.891 -90.9,191.332 -131.39,290.73 -20.19,49.561 -38.5,98.141 -53.33,143.231 -10.7,32.52 -19.58,63.22 -26.07,91.24 -7.9,34.13 -11.39,72.83 -11.4,114.52 -0.08,276.55 152.74,684.9 184.19,766.01 1.04,-0.61 2.42,-0.97 3.93,-0.97 h 256.68 c -3.45,-114.82 -44.96,-244.04 -88.41,-373.58 -19.24,-57.35 -38.86,-114.76 -55.73,-171 v -0.02 0 c -26.84,-89.48 -46.72,-176.02 -47.03,-254.69 0,-0.59 -0.01,-1.19 -0.01,-1.79 0,-19.08 1.16,-37.7 3.66,-55.78 17.78,-128.58 181.66,-720.46 181.66,-720.46 l -9.51,-4.769 -1.5,-0.762 -74.9,-37.598 -16.59,-8.32"/>
			<path id="path508" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3410.55,354.582 76.09,55.379 c 2.76,2.019 6.41,1.73 8.77,-0.691 l 52.48,-53.891 c 5.44,-5.59 4.38,-15.41 -2.09,-19.938 -26.86,-18.859 -40.78,-26.601 -74.24,-50.953 -20.58,-14.988 -61.6,-48.109 -90.03,-68.797 -27.8,-20.242 -50.4,6.309 -40.41,17.469 44.76,50.051 54.19,80.988 61.14,109.02 1.26,5.058 4.24,9.461 8.29,12.402"/>
			<path id="path510" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3350.73,418.68 132.31,60.511 -16.98,53.918 -141.68,-64.629 26.35,-49.8"/>
			<path id="path512" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3275.64,2582.01 c -10.98,28.41 -106.57,59.57 -154.53,70.45 l -21.07,-157.68 c 0,0 78.31,-112.55 112.69,-103.23 40.62,10.98 86.43,129.6 62.91,190.46"/>
			<path id="path514" style={{"fill":"#2251aa","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3166.29,2415.97 c -32.78,30.71 -66.25,78.81 -66.25,78.81 l 21.07,157.68 c 35.6,-8.07 97.46,-27.33 131.46,-48.34 -44.95,-6.61 -81.91,-14.17 -81.91,-14.17 0,0 -9.95,-3.51 -9.96,-68.28 0,-25 1.48,-59.12 5.59,-105.7"/>
			<path id="path516" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3170.66,2589.95 c 0,0 -39.9,-14.06 40.03,-505.55 135,0 274.51,0 340.2,0 -5.71,138.45 -5.87,223.83 60.3,507.98 0,0 -71.28,15.5 -144.46,19.03 -57.25,2.76 -104.15,4.59 -154.48,-0.01 -66.35,-6.05 -141.59,-21.45 -141.59,-21.45"/>
			<path id="path518" style={{"fill":"#2251aa","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3550,2106.92 h -342.94 c -36.98,231.24 -46.36,351.87 -46.36,414.75 0.01,64.77 9.96,68.28 9.96,68.28 0,0 75.24,15.4 141.59,21.45 22.18,2.03 43.68,2.8 65.71,2.8 27.96,0 56.75,-1.25 88.77,-2.79 73.18,-3.53 144.46,-19.03 144.46,-19.03 -19.48,-83.67 -33.22,-150.11 -42.75,-205.73 -1.7,-9.9 -3.26,-19.45 -4.7,-28.7 -11.67,-74.98 -15.17,-129.86 -15.17,-183.94 0,-22.06 0.58,-43.99 1.43,-67.09"/>
			<path id="path520" style={{"fill":"#406fbf","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3555.98,2104.36 15.34,-30.45 c 1.19,-2.36 -1.61,-4.77 -5.54,-4.77 h -356.77 c -3.07,0 -5.61,1.5 -5.8,3.43 l -3.07,30.45 c -0.21,2.11 2.45,3.9 5.8,3.9 h 344.5 c 2.53,0 4.77,-1.04 5.54,-2.56"/>
			<path id="path522" style={{"fill":"#6d97d6","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3565.78,2069.14 h -356.77 c -1.51,0 -2.89,0.36 -3.93,0.97 -1.07,0.61 -1.77,1.48 -1.87,2.46 l -3.07,30.45 c -0.01,0.08 -0.01,0.17 -0.01,0.25 0.01,2 2.59,3.65 5.81,3.65 h 344.5 c 2.53,0 4.77,-1.04 5.54,-2.56 l 15.34,-30.45 c 0.19,-0.37 0.28,-0.74 0.28,-1.1 0.01,-1.96 -2.5,-3.67 -5.82,-3.67"/>
			<path id="path524" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3509.71,2065.84 h 9.23 c 1.84,0 3.24,0.95 3.11,2.11 l -4.33,39.56 c -0.12,1.16 -1.73,2.11 -3.57,2.11 h -9.22 c -1.84,0 -3.24,-0.95 -3.11,-2.11 l 4.32,-39.56 c 0.13,-1.16 1.74,-2.11 3.57,-2.11"/>
			<path id="path526" style={{"fill":"#17282f","fillOpacity":"1","fillRule":"nonzero","stroke":"none"}} d="m 3286.07,2065.84 h 9.23 c 1.84,0 3.24,0.95 3.11,2.11 l -4.32,39.56 c -0.13,1.16 -1.74,2.11 -3.58,2.11 h -9.22 c -1.84,0 -3.24,-0.95 -3.11,-2.11 l 4.32,-39.56 c 0.13,-1.16 1.74,-2.11 3.57,-2.11"/>
		</g>
	</g>
</svg>
	)
}

export default DataNotFound
