import { gql, useLazyQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { CompanyListBody } from "../../components";
import { companySearch } from "../../graphql/queries";
import { SearchHelper } from "../../helpers";
import { CompanySearchResponse } from "../../types";
import { SearchSideBar } from "./components";
const queryString = require("qs");

interface SearchState {
  NameContains: string;
  Verticals: string[];
  LeadInvestorName: string[];
  InvestorName: string[];
  NextSeries: string;
  pageNum: number;
}

const INITIAL_STATE_FOR_SEARCH: SearchState = {
  NameContains: "",
  pageNum: 1,
  Verticals: [],
  LeadInvestorName: [],
  InvestorName: [],
  NextSeries: ""
};

const handleSearchInput = (locationString: string): SearchState => {
  const searchInput = queryString.parse(locationString, { arrayFormat: "indices", strictNullHandling: true, ignoreQueryPrefix: true }) as Partial<SearchState>;
  if (searchInput.pageNum && typeof searchInput.pageNum === "string") searchInput.pageNum = parseInt(searchInput.pageNum);

  return { ...JSON.parse(JSON.stringify(INITIAL_STATE_FOR_SEARCH)), ...searchInput };
};

const Search = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState<SearchState>(JSON.parse(JSON.stringify(handleSearchInput(location.search))));

  const [getSearchResult, { loading, data }] = useLazyQuery<{CompanySearch:CompanySearchResponse}>(gql(companySearch), {
    variables: SearchHelper.transformSearchData(searchData)
  });


  const onChange = (id: any, value: any) => {
    let searchState = {};
    if (id === "pageNum") {
      searchState = { ...searchData, pageNum: value };
    } else {
      searchState = { ...searchData, pageNum: 1, [id]: value };
    }
    const searchString = queryString.stringify(searchState, { arrayFormat: "indices", strictNullHandling: true });
    navigate(`/search?${searchString}`);
  };
  useEffect(()=>{
    getSearchResult();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const searchData = JSON.parse(JSON.stringify(handleSearchInput(location.search)));
    setSearchData(searchData);
    getSearchResult({
      variables: SearchHelper.transformSearchData(searchData)
    });
  }, [getSearchResult, location.search]);

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      {/* <Grid item xs={12}>
        <Typography variant="h3">Search Companies</Typography>
      </Grid> */}
      <Grid item xs={12} md={4} lg={3} xl={12}>
        <SearchSideBar searchData={searchData} setSearchData={setSearchData} onChange={onChange} />
      </Grid>
      <Grid item xs={12} md={8} lg={9} xl={12}>
        <CompanyListBody
          loading={loading}
          data={data?.CompanySearch}
          page={searchData.pageNum}
          onChange={onChange}
        ></CompanyListBody>
      </Grid>
    </Grid>
  );
};

export default Search;
