import { Box, Chip, Skeleton, Typography } from "@mui/material";
import React from "react";
import { DPbaseProps, _tags } from "../../../../types";
import { withErrorBoundary } from "../../../../components/ErrorBoundary";

interface IndustryTagsProps extends DPbaseProps {
  data?: _tags[];
}

const IndustryTags = ({ data, loading }: IndustryTagsProps) => {
  return (
    <Box id="industry-tags" sx={{ marginBottom: 1.5, marginTop: 1.5 }}>
      <Typography variant="h5" sx={{ marginBottom: 0.5 }}>
        Industry Tags
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {loading
          ? [...new Array(5)].map((d, i) => <Skeleton key={i} sx={{ marginRight: 0.5, marginBottom: 0.5, width: `${Math.floor(Math.random() * 100)}px`, height: "24px", display: "inline-block", borderRadius: "24px" }} />)
          : data?.map((vertical, index) => (
              <Chip
                key={`tag-${vertical.entity_urn}-${index}`}
                label={vertical.display_value ?? ""}
                clickable
                color="default"
                variant="outlined"
                size="small"
                sx={{
                  marginRight: 0.5,
                  marginBottom: 0.5
                }}
              />
            ))}
      </Box>
    </Box>
  );
};

export default withErrorBoundary(IndustryTags);
