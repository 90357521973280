function cssHexToRgba(hex: string, a = 1):string {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return `rgba(${r}, ${g}, ${b}, ${a})` //return 23,14,45 -> reformat if needed
  }
  return hex;
}
//eslint-disable-next-line
export default {
    cssHexToRgba
};
