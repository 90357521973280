import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, Divider, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import { sortBy } from "lodash";
import moment from "moment";
import React from "react";
import { TractionMetrics, _Metrics } from "../../../../types";
import GrowthIndexGraph from "../../../../views/Company/components/GrowthIndexGraph";

interface HeadcountGrowthProps {
  routeRefs: any;
  loading: boolean;
  data: {
    headcount: TractionMetrics;
  } | null;
}

const formatDataForGraphs = (data: _Metrics[], industry: string) => {
  const dataList = data
    .splice(0, 12)
    // .reverse()
    .map(d => {
      return {
        y: d.metric_value ?? 0,
        x: moment(d.timestamp)
          .format("L")
          .replaceAll("/", "-")
      };
    });
  return [
    {
      id: industry,
      data: dataList
    }
  ];
};

const HeadCountGrowth = ({ routeRefs, loading, data }: HeadcountGrowthProps) => {
  const [value, setValue] = React.useState("headcount");

  /*
   * @description Tab menu change handler.
   */
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // console.log("data from HeadCountGrowth:::", data);

  return (
    <Grid container spacing={2} id="headCount">
      <Grid item md={12} ref={routeRefs?.current[0]}>
        <Typography variant="h5" sx={{ marginTop: 1.5, marginBottom: 1.5 }}>
          Headcount
        </Typography>
        <Divider />
      </Grid>
      <Grid item md={3}>
        {loading ? (
          <Skeleton height={450} />
        ) : data ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography variant="subtitle1">Headcount Details</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderBottom: 1, backgroundColor: value === "headcount" ? "#e4e4e4" : "transparent" }}>
                  <Typography
                    onClick={e => {
                      e.preventDefault();
                      setValue("headcount");
                    }}
                    sx={{
                      cursor: "pointer",
                      color: value === "headcount" ? "red" : "inherit"
                    }}
                  >
                    {"All"}
                  </Typography>
                </TableCell>
                <TableCell align="right" sx={{ borderBottom: 1, backgroundColor: value === "All" ? "#e4e4e4" : "transparent" }}>
                  {data?.headcount["14d_ago"].value}
                </TableCell>
              </TableRow>
              {data &&
                sortBy(Object.keys(data))?.map(dataKey => {
                  if (dataKey === "headcount") return null;
                  //@ts-ignore
                  const item = data[dataKey as any] as TractionMetrics;

                  return item["14d_ago"].value ? (
                    <TableRow key={`${dataKey}`}>
                      <TableCell
                        sx={{
                          backgroundColor: value === dataKey ? "#e4e4e4" : "transparent"
                        }}
                      >
                        <Typography
                          onClick={e => {
                            e.preventDefault();
                            setValue(dataKey);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: value === dataKey ? "red" : "inherit"
                          }}
                        >
                          {dataKey}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: value === dataKey ? "#e4e4e4" : "transparent"
                        }}
                        align="right"
                      >
                        {item["14d_ago"].value}
                      </TableCell>
                    </TableRow>
                  ) : null;
                })}
            </TableBody>
          </Table>
        ) : (
          "Data Not found"
        )}
      </Grid>

      <Grid item md={9}>
        {loading ? (
          <Skeleton height={450} />
        ) : data ? (
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              {data && (
                <TabList onChange={handleTabChange} aria-label="Team growth signals" variant="scrollable" scrollButtons="auto">
                  <Tab label={"All"} value={`${"headcount"}`} />
                  {sortBy(Object.keys(data)).map(dataKey => {
                    if (dataKey === "headcount") return null;
                    //@ts-ignore
                    const item = data[dataKey as any] as TractionMetrics;

                    return item["14d_ago"].value ? <Tab label={dataKey} value={`${dataKey}`} /> : null;
                  })}
                </TabList>
              )}
            </Box>

            {/* Tabpanels Starts from here */}

            {data && (
              <TabPanel value={"headcount"}>
                <GrowthIndexGraph inputData={formatDataForGraphs(data.headcount.metrics, "headcount")} legendY={"Headcount"} title={<div> Headcount Growth All</div>} />
              </TabPanel>
            )}

            {data &&
              sortBy(Object.keys(data))?.map(dataKey => {
                if (dataKey === "headcount") return null;
                //@ts-ignore
                const item = data[dataKey as any] as TractionMetrics;
                const _data = formatDataForGraphs(item.metrics, dataKey);
                // console.log(`HeadcountGrowth::${dataKey}`, _data);
                return item["14d_ago"].value ? <TabPanel value={dataKey}>{_data && _data.length > 0 ? <GrowthIndexGraph inputData={_data} legendY={"Headcount"} title={<div> Headcount Growth {dataKey}</div>} /> : null}</TabPanel> : null;
              })}
          </TabContext>
        ) : (
          "Data Not found"
        )}
      </Grid>
    </Grid>
  );
};

export default HeadCountGrowth;
