import { gql, useLazyQuery, useMutation } from "@apollo/client";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Card, Divider, Stack, Typography, useTheme } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowId, GridRowModes, GridRowModesModel } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCompaniesUserView, updateCompaniesUserView } from "../../graphql/mutations";
import { getAllCompaniesUserView } from "../../graphql/queries";
import { CompaniesUserView, CompaniesUserViewInput, ViewData, ViewsListItem } from "../../types";
import { DEFAULT_VIEW_ID, INITIAL_STATE } from "./utils";

const COLUMNS: GridColDef[] = [
  { headerName: "Sl. No.", field: "sl", width: 80, headerClassName: "views-list-header" },
  {
    headerName: "Name",
    field: "name",
    flex: 0.35,
    renderCell: params => (
      <Link to={"/companies-list/" + params.row.id} state={{ view: params.row }}>
        {params.value}
      </Link>
    ),
    headerClassName: "views-list-header",
    editable: true
  },
  { headerName: "Description", field: "description", editable: true, flex: 0.75, headerClassName: "views-list-header" },
  { headerName: "Last viewed", field: "lastViewedAt", flex: 0.25, valueFormatter: params => moment(params.value).fromNow(), headerClassName: "views-list-header" },
  { headerName: "Created At", field: "createdAt", flex: 0.25, type: "date", valueFormatter: params => dayjs(params.value).toString(), headerClassName: "views-list-header" }
];

export const viewsListBuilder = (data: CompaniesUserView<string>[]): ViewsListItem[] => {
  // const savedView = sessionStorage.getItem("view-default-view");
  data = [
    {
      id: DEFAULT_VIEW_ID,
      userId: "",
      name: "All companies list",
      description: "Default view will load all records",
      viewType: "LIST",
      viewData: JSON.stringify({ density: "standard", state: INITIAL_STATE })
    },
    ...data
  ];
  return data.map((d, index) => {
    let parseData: string | ViewData = JSON.parse(d.viewData);
    if (typeof parseData !== "object") parseData = (JSON.parse(parseData) as any) as ViewData;
    return { ...d, sl: index + 1, viewData: parseData };
  });
};

// type ViewListItem = CompaniesUserView<string> & { sl: number };

const CompaniesViewsList = () => {
  /** MUI theme */
  const theme = useTheme();
  /** React router dom location api */
  const location = useLocation();

  /** Navigation API  */
  const navigate = useNavigate();
  /** Get query params from the routes */
  const { id } = useParams<{ id: string }>();

  ///Queries

  /** Get all views queries */
  const [getAllViews, { loading }] = useLazyQuery<{ getAllCompaniesUserView: CompaniesUserView<string>[] }>(gql(getAllCompaniesUserView), { fetchPolicy: "network-only" });
  //Mutations
  /** Delete record */
  const [deleteRecordById] = useMutation<{ deleteCompaniesUserView: CompaniesUserView<string>[] }, { id: string }>(gql(deleteCompaniesUserView));
  /** Mutation API for the update the new view */
  const [updateView, { loading: updateViewLoading }] = useMutation<{ createCompaniesUserView: CompaniesUserView<string> }, { input: Partial<CompaniesUserViewInput> }>(gql(updateCompaniesUserView), { fetchPolicy: "network-only" });

  /** grid row State */
  const [rows, setRows] = React.useState<ViewsListItem[]>([]);

  /** Rows modal for updating rows */
  //Row update state
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  /*** Load list data and set it to the rows state */
  const loadListData = React.useCallback(async () => {
    const request = await getAllViews();
    const rows = (viewsListBuilder(request.data?.getAllCompaniesUserView ?? []) as any) as ViewsListItem[];
    setRows(rows);
  }, [getAllViews]);

  /** Delete click handler */
  const deleteView = React.useCallback(
    async (id: GridRowId) => {
      const delResponse = await deleteRecordById({ variables: { id: "" + id } });
      const rows = (viewsListBuilder(delResponse.data?.deleteCompaniesUserView ?? []) as any) as ViewsListItem[];
      setRows(rows);
      toast.success("View deleted successfully!");
      //handle logic for del.
    },
    [deleteRecordById]
  );

  /** Rows edit model cancel event handler */
  const handleCancelClick = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true }
      });

      const editedRow = rows.find(row => row.id === id);
      if (editedRow!.isNew) {
        setRows(rows.filter(row => row.id !== id));
      }
    },
    [rowModesModel, rows]
  );

  /** row on edit save handler for modesModal */
  const handleSaveClick = React.useCallback(
    (id: GridRowId) => () => {
      // console.log("handleSaveClick");
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  /** Rows actions edit click handler */
  const handleEditClick = React.useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  /** Navigate to the id */
  const navigateToId = React.useCallback(
    (id: GridRowId) => {
      const view = rows.find(v => v.id === id);
      navigate("/companies-list/" + id, { state: { view } });
    },
    [navigate, rows]
  );

  /** Build the columns with special columns */

  const columns = React.useMemo<GridColDef<ViewsListItem>[]>(
    () => [
      ...COLUMNS,
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        headerClassName: "views-list-header",
        width: 100,
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [<GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />, <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />];
          }
          return id === DEFAULT_VIEW_ID
            ? [<GridActionsCellItem icon={<VisibilityIcon fontSize="inherit" />} label="Open current view" onClick={() => navigateToId(id)} />]
            : [
                <GridActionsCellItem icon={<VisibilityIcon fontSize="inherit" />} label="Open current view" onClick={() => navigateToId(id)} />,
                <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => deleteView(id)} />
              ];
        }
      }
    ],
    [deleteView, handleCancelClick, handleEditClick, handleSaveClick, navigateToId, rowModesModel]
  );

  const processRowUpdate = async (newRow: ViewsListItem, oldRow: ViewsListItem): Promise<ViewsListItem> => {
    const { name, description } = newRow;

    const vars: Partial<CompaniesUserViewInput> = {
      id: newRow.id
    };

    if (isEmpty(name.trim())) {
      toast.warn("Name should not be empty !");
      return oldRow;
    }

    if (oldRow.name !== newRow.name) {
      vars.name = name.trim();
    }

    if (oldRow.description !== newRow.description && !isEmpty(description.trim())) {
      vars.description = description.trim();
    }

    if (Object.keys(vars).length > 1) {
      await updateView({ variables: { input: vars } });
      toast.success("View details updated successfully");
      return newRow;
    } else {
      toast.info("No records for update !");
      return oldRow;
    }
  };

  React.useEffect(() => {
    if (!id) loadListData();
  }, [id, loadListData, location]);

  if (id) return <Outlet />;
  return (
    <Stack spacing={3} sx={{ m: 2, p: 2 }} component={Card}>
      <Typography variant="h3">List of all available views</Typography>
      <Divider />
      <Box
        sx={{
          height: 600,
          "& .views-list-header": {
            backgroundColor: theme.palette.grey["200"],
            color: theme.palette.primary["light"]
          }
        }}
      >
        <DataGridPro
          columns={columns}
          rows={rows}
          loading={loading || updateViewLoading}
          rowModesModel={rowModesModel}
          editMode="row"
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={error => {
            toast.error(error.message);
            // handleCancelClick()
          }}
        />
      </Box>
    </Stack>
  );
};

export default CompaniesViewsList;
