import { Grid, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RenegadeProcess } from "./components";
import { PortfolioList } from "./components/PortfolioListPro";
import { WeeklyLeadsPro } from "./components/WeeklyLeadsPro";

/***
 *@description Dashboard component for landing UI after login
 */
const Dashboard = () => {
  const [fullView, setFullView] = React.useState<any>(null);
  const [escInfoOpen , setEscInfoOpen] = useState(false);

  const toggleFullViewHandler = (nameKey: string) => {
    setFullView((d: any) => (d ? null : nameKey));
    if (!fullView) {
      localStorage.setItem("fullView", nameKey);
      setEscInfoOpen(true)
    }
    else localStorage.removeItem("fullView");
  };

  useEffect(() => {
    const prevFullViewState = localStorage.getItem("fullView");
    if (prevFullViewState) setFullView(prevFullViewState);
  }, []);

  const handleEscapeFromFullView = () => {
    setFullView(null);
  };

  const dashboardComponents: any = {
    renegadeProcess: <RenegadeProcess />,
    weeklyLeadsPro: <WeeklyLeadsPro nameKey={"weeklyLeadsPro"} onToggleFullView={toggleFullViewHandler} fullView={fullView === "weeklyLeadsPro"} />,
    portfolioList: <PortfolioList />
  };

  useEffect(() => {
    const handleKeyPress = (event:KeyboardEvent) => {
      // console.log(event.code)
      if (event.code === "Escape") {
        // Handle the Esc key press here
        handleEscapeFromFullView()
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <React.Fragment>
    <Grid container spacing={2} sx={{ p: 2 }}>
      {fullView ? (
        <Grid item xs={12}>
          {dashboardComponents[fullView]}
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item xl={12} lg={12} md={12} sm={12}>
            {dashboardComponents["renegadeProcess"]}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12}>
            {dashboardComponents["portfolioList"]}
            {dashboardComponents["weeklyLeadsPro"]}
            {/* <WeeklyLeadsPro rootEL={parentWrapper} />
        <PortfolioList /> */}
          </Grid>
        </React.Fragment>
      )}
    </Grid>
    <Snackbar  message={<Typography>Use <i>Esc</i> key for exit full view</Typography>} open={escInfoOpen} autoHideDuration={5000} onClose={() => setEscInfoOpen(false) }/>
    </React.Fragment>
  );
};

export default Dashboard;
