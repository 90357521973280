import { useLazyQuery } from "@apollo/client";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import gql from "graphql-tag";
import { debounce } from "lodash";
import * as React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { searchInvestor } from "../../../../graphql/queries";
import { Investors } from "../../../../types";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CoInvestorsEditCell = (params: GridRenderEditCellParams) => {
  const { id, field } = params;
  const [value, setValue] = React.useState<Investors[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly Investors[]>([]);
  const [searchInvestors, { data, loading }] = useLazyQuery<{ searchInvestor: { investor: Investors[] } }>(gql(searchInvestor));

  //Data grid context api.
  const apiRef = useGridApiContext();

  // debouncing for searchTerm
  const debouncedFetch = React.useMemo(
    () =>
      debounce((input: string) => {
        handleInvestorsSearch(input);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleInvestorsSearch = (queryText: string) => {
    searchInvestors({ variables: { search_term: queryText } });
  };

  React.useEffect(() => {
    if (params.value) {
      unstable_batchedUpdates(() => {
        setValue(params.value);
        // setOptions(oldOption => [...oldOption, ...params.value]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    console.log("as");
    if (inputValue.length > 0) debouncedFetch(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  React.useEffect(() => {
    const investorOptions: readonly Investors[] = data?.searchInvestor.investor ?? [];
    if (investorOptions && investorOptions.length > 0) setOptions([...investorOptions]);
  }, [data]);

  // React.useEffect(() => {
  //   console.log("options", options);
  // }, [options]);

  return (
    <Autocomplete
      id="Co-investors-edit-cell-autocomplete"
      sx={{ width: "100%" }}
      getOptionLabel={option => option?.investor}
      // filterOptions={x => x}
      multiple
      limitTags={2}
      options={options}
      autoComplete
      includeInputInList
      // filterSelectedOptions
      disableCloseOnSelect
      value={value}
      loading={loading}
      noOptionsText="Empty Co Investors"
      onChange={(event: any, newValue:readonly Investors[]) => {
        setValue([...newValue]);
        apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      ChipProps={{
        variant: "outlined",
        size: "small"
      }}
      renderInput={params => <TextField {...params} label="Search for co investor" fullWidth />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.investor}
        </li>
      )}
    />
  );
};

export default CoInvestorsEditCell;
