import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { isEmpty } from "lodash";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CompaniesUserView, ViewData } from "../../types";

interface ModalDialogForTheCreateNewViewProps {
    addViewOpen: boolean;
    setAddViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
    saveHandler?: (params: {
      name: string;
      description: string;
    }) => Promise<
      | false
      | {
          createCompaniesUserView: CompaniesUserView;
        }
      | null
      | undefined
    >;
    loading?: boolean;
    updateViewsList?:(view:CompaniesUserView<ViewData>) => void;
  }
  
  const ModalDialogForTheCreateNewView = ({ addViewOpen, setAddViewOpen, saveHandler, loading  , updateViewsList}: ModalDialogForTheCreateNewViewProps) => {
    /** state for the modal form of the save new view */
    const [modalFormState, setModalFormState] = React.useState({
      name: "",
      description: ""
    });
  
    /** navigation api */
    const navigate = useNavigate();
  
    /** Form change handler */
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
  
      setModalFormState(_prevState => ({ ..._prevState, [name]: value }));
    };
  
    /** Form submission handler */
    const handleOnSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const { name, description } = modalFormState;
  
      if (!isEmpty(name.trim())) {
        if (saveHandler) {
          const response = await saveHandler({ name:name.trim(), description:description.trim() });
          if (response) {
            const view = response.createCompaniesUserView as CompaniesUserView<string>;

            if(updateViewsList) updateViewsList({...view, viewData:JSON.parse(view.viewData)})

            toast.success(`View ${view.name} is saved successfully`);
            unstable_batchedUpdates(() => {
              setModalFormState({
                name: "",
                description: ""
              });
              setAddViewOpen(false);
            });
            navigate("/companies-list/" + view.id, { state: { viewData: view.viewData } });
          } else toast.warn("Something wrong happen pleas tr again");
        }
      } else toast.error("Name is required field");
    };
  
    return (
      <Dialog maxWidth="xs" open={addViewOpen} aria-labelledby="add-new-view-title" aria-describedby="add-new-view-desc">
        <DialogTitle id="add-new-view-title">Save current view</DialogTitle>
        <DialogContent dividers>
          <DialogContentText gutterBottom id="add-new-view-desc" variant="body2">
            Enter the details for saving current views as a new view. You can also put a sort description for your references.
          </DialogContentText>
          <Stack spacing={3} sx={{ marginTop: 2 }}>
            <TextField type="text" label="Name for current view" name="name" id="view-name" fullWidth required value={modalFormState.name} onChange={handleOnChange} />
            <TextField label="Write a description for your reference (Optional)" name="description" id="view-desc" fullWidth multiline rows={6} value={modalFormState.description} onChange={handleOnChange} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} size="small" startIcon={<SaveIcon fontSize="inherit" />} variant="outlined" onClick={handleOnSubmit}>
            Save
          </Button>
          <Button size="small" disabled={loading} startIcon={<CloseIcon fontSize="inherit" />} variant="contained" disableElevation onClick={() => setAddViewOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  export default ModalDialogForTheCreateNewView;