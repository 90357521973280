import { ApolloProvider } from "@apollo/client";
import { Authenticator } from "@aws-amplify/ui-react";
import AppRoutes from "./Routes";
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from "@apollo/client";
import { ThemeProvider } from "@mui/material";
import { Amplify, Auth, Storage } from "aws-amplify";
import { createAuthLink } from "aws-appsync-auth-link";
import AppGlobalStyles from "./global.styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AppSyncConfig from "./aws-exports";
import theme from "./theme";
import { LicenseInfo } from "@mui/x-license-pro";
const url = AppSyncConfig.aws_appsync_graphqlEndpoint;
const region = AppSyncConfig.aws_project_region;

Amplify.configure(AppSyncConfig);

Storage.configure({ track: false, level: "private" });
// const customTheme = { button: { backgroundColor: theme?.palette?.primary.main, color: theme?.palette?.primary.contrastText } };
LicenseInfo.setLicenseKey("a6529009800a51f7667c079b91515ba9Tz02NzczMCxFPTE3MTcyNTgyMzMxNDMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const auth = {
  type: AppSyncConfig.aws_appsync_authenticationType,
  jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken()
};

const link = ApolloLink.from([
  // @ts-ignore
  createAuthLink({ url, region, auth }),
  // @ts-ignore
  createHttpLink({ uri: url })
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const AppWithAuth = () => {
  return (
    <Authenticator.Provider>
      <ThemeProvider theme={theme}>
        <AppRoutes />
        <AppGlobalStyles />
        <ToastContainer theme="colored" />
      </ThemeProvider>
    </Authenticator.Provider>
  );
};
const App = () => (
  <ApolloProvider client={client}>
    <AppWithAuth />
  </ApolloProvider>
);
export default App;
