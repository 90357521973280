import { Box, Link } from "@mui/material";
import ShowMore from "../../../../components/ShowMore";
import { EmployeeData } from "../../../../types";
import ExpandingSection from "../ExpandingSection";
import ExperienceCard from "../ExperienceCard";
import React from 'react'

interface PropTypes {
  data: EmployeeData;
}

const Certifications = (props: PropTypes) => {
  const { data } = props;
  const certificationData = data["certifications"].map(cert => {
    var date = cert.certificatestart && cert.certificatestart !== "-" && cert.certificateend && cert.certificateend !== "-" ? " - " : "";
    if (cert.certificatestart && cert.certificatestart !== "-") {
      date = cert.certificatestart + date;
    }
    if (cert.certificateend && cert.certificateend !== "-") {
      date += cert.certificateend;
    }
    return (
      <ExperienceCard
        data={{
          header: cert.certificate,
          subHeader:
            cert.issuerlink && cert.issuerlink !== "-" ? (
              <Link color="inherit" href={cert.issuerlink}>
                {cert.issuer}
              </Link>
            ) : (
              cert.issuer
            ),
          subTitle1: date,
          image: cert.issuerlogo && cert.issuerlogo !== "-" ? cert.issuerlogo : "/images/default-corporate-image.jpg"
        }}
      />
    );
  });

  return (
    <Box sx={{ width: "100%" }}>
      <ExpandingSection title="Certifications" itemIndex={"Certificate"} data={data["certifications"]} fullBody={<ShowMore data={certificationData} trimIndex={4} />} />
    </Box>
  );
};

export default Certifications;
