import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Button, CircularProgress, DialogContent, DialogTitle, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Skeleton, Stack, TextField, useTheme } from "@mui/material";
import { isArray, sortBy, uniqueId } from "lodash";
import React from "react";
import { DragDropContext, Draggable, DropResult, Droppable, ResponderProvided } from "react-beautiful-dnd";
import { unstable_batchedUpdates } from "react-dom";
import { toast } from "react-toastify";

interface SingleSelectColsManageOptsViewProps<T> {
  open: boolean;
  closeHandler: () => void;
  label: string;
  optionsList: T[];
  onOptionsListChange?: (newList: T[]) => void;
  getOptionName?: (options: T) => string;
  getOptionDescriptions?: (option: T) => string;
  getOptionId: (option: T) => string | number;
  getSortingKey: (option: T) => string | number;
  loading?: boolean;
  onOptionChange: (item: Partial<BaseOption>, reason: "create" | "update" | "delete") => Promise<T | T[] | undefined>;
  reorderChangeHandler: (items: T[]) => void;
  dataLoading?: boolean;
}

interface BaseOption {
  id: number | string;
  name: string;
  description: string;
}

enum OptionsListItemsMods {
  view = "VIEW",
  edit = "EDIT",
  new = "NEW",
  delete = "DELETE"
}

const SingleSelectColsManageOptsView = <T extends {}>({ open, label, optionsList, loading, dataLoading, closeHandler, getOptionId, getOptionName, getOptionDescriptions, onOptionChange, reorderChangeHandler, getSortingKey }: SingleSelectColsManageOptsViewProps<T>) => {
  /** Options state  */
  const [options, setOptions] = React.useState<T[]>([]);

  /** List item mods modal */
  const [modesModal, setModesModal] = React.useState<((T | BaseOption) & { mode: OptionsListItemsMods }) | null>(null);

  /** Update handler */
  const handleUpdate = async (optionItem: BaseOption) => {
    if (modesModal?.mode === OptionsListItemsMods.delete) {
      await onOptionChange(optionItem, "delete");
      return;
    } else {
      const updatedOption = await onOptionChange(optionItem, modesModal?.mode === OptionsListItemsMods.new ? "create" : "update");
      if (isArray(updatedOption)) return updatedOption.find(i => getOptionId(i) === optionItem.id);
      else return updatedOption;
    }
  };

  /** handle modes modal change */

  const handleModalModalChange = (changedModesModal: ((T | BaseOption) & { mode: OptionsListItemsMods }) | null) => {
    const _currentMode = modesModal?.mode;
    if (_currentMode === OptionsListItemsMods.new) {
      unstable_batchedUpdates(() => {
        setOptions(prev => [...prev.filter(i => getOptionId(i) !== getOptionId(modesModal as T))]);
        setModesModal(changedModesModal);
      });
    } else setModesModal(changedModesModal);
  };

  /** Add new option button handler */
  const addNewItemHandler = () => {
    const newOpt = { id: uniqueId("new_opt_form"), name: "", description: "" };
    unstable_batchedUpdates(() => {
      //@ts-ignore
      setOptions(prev => [...prev, { ...newOpt }]);
      setModesModal({ ...JSON.parse(JSON.stringify(newOpt)), mode: OptionsListItemsMods.new });
    });
  };

  /** On drag end handler */
  const dragEndHandler = (result: DropResult, provided: ResponderProvided) => {
    const srcIndex = result.source.index;
    const destIndex = result.destination?.index;
    const _options = [...options];
    if (destIndex !== undefined) {
      const sourceData = _options[srcIndex];

      //Remove data from source index;
      _options.splice(srcIndex, 1);
      // Add  Data at destination index;
      _options.splice(destIndex, 0, sourceData);
      // console.log("_options::", _options);
      setOptions([..._options]);
      reorderChangeHandler(_options)
    }
  };

  /** Update options list while change in the options list */
  React.useEffect(() => {
    setOptions(sortBy(optionsList, item => getSortingKey(item)));
  }, [getSortingKey, optionsList]);

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          height: "calc(100% - 64px)",
          width: 450,
          marginTop: "64px"
        }
      }}

      // onClose={toggleDrawer(anchor, false)}
    >
      <DialogTitle id="add-new-view-title">{label}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeHandler}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {loading ? (
          <Stack>
            {[...new Array(5)].map((i, index) => (
              <Skeleton key={`skelton-${index}`} height={40} />
            ))}
          </Stack>
        ) : null}
        {options.length > 0 ? (
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable droppableId="droppable-list">
              {provided => (
                <List subheader={"Options list for funnel stages"} ref={provided.innerRef} {...provided.droppableProps}>
                  {options.map((opt: T | BaseOption, index) => (
                    <OptionsListItem
                      //props
                      option={opt}
                      index={index}
                      key={`drag-item-key-${getOptionId(opt as T)}`}
                      getOptionId={getOptionId}
                      getOptionName={getOptionName}
                      getOptionDescriptions={getOptionDescriptions}
                      handleOptionChange={handleUpdate}
                      loading={dataLoading}
                      modesModal={modesModal}
                      onModesModalChange={handleModalModalChange}
                    />
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        ) : null}
        {!(modesModal?.mode === OptionsListItemsMods.new) ? (
          <Button sx={{ width: "100%", marginBottom: 2 }} startIcon={<AddIcon fontSize="inherit" />} onClick={addNewItemHandler}>
            Add new
          </Button>
        ) : null}
        {/* <OptionsList optionsList={optionsList} loading={loading} /> */}
      </DialogContent>
    </Drawer>
  );
};

interface OptionsListItemProps<T> {
  option: T | BaseOption;
  index: number;
  loading?: boolean;
  getOptionId: (option: T) => string | number;
  getOptionName?: (options: T) => string;
  getOptionDescriptions?: (option: T) => string;
  handleOptionChange: (optionItem: BaseOption) => Promise<T | undefined>;
  // listItemMode: OptionsListItemsMods;
  modesModal: ((T | BaseOption) & { mode: OptionsListItemsMods }) | null;
  onModesModalChange: (modesModal: ((T | BaseOption) & { mode: OptionsListItemsMods }) | null) => void;
}

const OptionsListItem = <T extends {}>({ option, index, loading, modesModal, getOptionId, getOptionName, getOptionDescriptions, handleOptionChange, onModesModalChange }: OptionsListItemProps<T>) => {
  /** MUI theme api hook */
  const theme = useTheme();
  /** Options forms state  */
  const [optFormState, setOptFormState] = React.useState({ name: "", description: "" });
  /** List item mods modal */
  // const [modesModal, setModesModal] = React.useState<(BaseOption & { mode: OptionsListItemsMods }) | null>(null);

  /** Handle make edit mode open */
  const handleEditMode = () => {
    onModesModalChange({
      ...option,
      mode: OptionsListItemsMods.edit
    });
    setOptFormState({
      name: getOptionName ? getOptionName(option as T) : (option as BaseOption).name,
      description: getOptionDescriptions ? getOptionDescriptions(option as T) : (option as BaseOption).description
    });
  };

  const handleDelMode = () => {
    onModesModalChange({
      ...option,
      mode: OptionsListItemsMods.delete
    });
  };

  const handleSave = async () => {
    const { name, description } = optFormState!;
    const _optionUpdated = await handleOptionChange({ id: getOptionId(option as T), name, description });
    if (_optionUpdated) handleModeCancel();
  };

  const handleDeleteCall = async () => {
    const _optionUpdated = await handleOptionChange(option as BaseOption);
    if (_optionUpdated) {
      handleModeCancel();
      toast.info("Option removed successfully !");
    }
  };

  const handleModeCancel = () => {
    onModesModalChange(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setOptFormState(prev => ({ ...prev, [name]: value }));
  };

  if (modesModal && getOptionId(modesModal as T) === getOptionId(option as T) && modesModal.mode === OptionsListItemsMods.delete)
    return (
      <ListItem
        // disableGutters
        sx={{ borderBottom: 1, borderColor: theme.palette.grey["300"], backgroundColor: theme.palette.error.light }}
        secondaryAction={
          loading ? (
            <CircularProgress size={"inherit"} color="secondary" />
          ) : (
            <Stack direction={"row"}>
              <IconButton title="No leave it" onClick={handleModeCancel}>
                <CancelIcon sx={{ fontSize: 16 }} color="action" />
              </IconButton>
              <IconButton title="Yes delete it" onClick={handleDeleteCall}>
                <DoneIcon sx={{ fontSize: 16 }} color="action" />
              </IconButton>
            </Stack>
          )
        }
      >
        <ListItemText sx={{ color: "#fff" }}>Delete this Funnel stage option item?</ListItemText>
      </ListItem>
    );

  if (modesModal && getOptionId(modesModal as T) === getOptionId(option as T) && [OptionsListItemsMods.edit, OptionsListItemsMods.new].includes(modesModal.mode))
    return (
      <ListItem
        disableGutters
        sx={{ borderBottom: 1, borderColor: theme.palette.grey["300"] }}
        secondaryAction={
          <Stack>
            <IconButton onClick={handleSave}>
              <SaveIcon sx={{ fontSize: 16 }} />
            </IconButton>
            <IconButton onClick={handleModeCancel}>
              <CancelIcon sx={{ fontSize: 16 }} color="primary" />
            </IconButton>
          </Stack>
        }
      >
        <Stack spacing={1} sx={{ width: "100%" }}>
          <TextField size="small" disabled={loading} name="name" label={"Option name"} required fullWidth value={optFormState.name} onChange={handleChange} />
          <TextField size="small" disabled={loading} multiline rows={2} name="description" fullWidth label={"Option description"} value={optFormState.description} onChange={handleChange} />
        </Stack>
      </ListItem>
    );

  return (
    <Draggable draggableId={getOptionId(option as T) + ""} index={index}>
      {(provided, snapshot) => (
        <ListItem
          disableGutters
          sx={{
            borderBottom: 1,
            borderColor: theme.palette.grey["300"],
            "&.dragging": {
              backgroundColor: theme.palette.grey["300"]
            }
          }}
          secondaryAction={
            <Stack direction={"row"}>
              <IconButton onClick={handleEditMode}>
                <EditIcon sx={{ fontSize: 16 }} />
              </IconButton>
              <IconButton onClick={handleDelMode}>
                <DeleteIcon sx={{ fontSize: 16 }} color="primary" />
              </IconButton>
            </Stack>
          }
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={snapshot.isDragging ? "dragging" : ""}
        >
          <ListItemIcon sx={{ cursor: "move" }} {...provided.dragHandleProps}>
            <DragIndicatorIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText primary={getOptionName ? getOptionName(option as T) : (option as BaseOption).name} secondary={getOptionDescriptions ? getOptionDescriptions(option as T) : (option as BaseOption).description} />
        </ListItem>
      )}
    </Draggable>
  );
};

export default SingleSelectColsManageOptsView;
