import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, Grid, IconButton, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { ObjectLiteral } from "../../../../types";


const PREFIX = "ExpandingSection";

const classes = {
  fullWidth: `${PREFIX}-fullWidth`,
  displayRight: `${PREFIX}-displayRight`,
  subHeader: `${PREFIX}-subHeader`,
  noPadding: `${PREFIX}-iframeRoot`
};

const Root = styled("div")(({ theme }) => ({
  [`&. ${classes.fullWidth}`]: {
    width: "100%"
  },
  [`&. ${classes.displayRight}`]: {
    textAlign: "right"
  },
  [`&. ${classes.subHeader}`]: {
    fontSize: theme.typography.pxToRem(15),
    marginBottom: "2px",
    whiteSpace: "pre-wrap"
  },
  [`&. ${classes.noPadding}`]: {
    padding: "0px"
  }
}));

const renderShort = <T extends ObjectLiteral>(data: T, itemIndex: string): string => {
  var shortString = "";
  for (var i = 0; i < data.length; i++) {
    shortString += data[i][itemIndex];
    if (i !== data.length - 1) {
      shortString += "    •    ";
    }
  }
  return shortString;
};

interface PropTypes<T> {
  data: T;
  title: string;
  fullBody: any;
  itemIndex: string;
}

const ExpandingSection = <T extends ObjectLiteral>(props: PropTypes<T>) => {
  const { data, title, fullBody, itemIndex } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    setIsOpen(!isOpen);
  };

  return (
    <Root className={classes.fullWidth}>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Typography variant="h4" component="h2">
            {title + " (" + data.length + ")"}
          </Typography>
        </Grid>
        <Grid item className={classes.displayRight} xs={2}>
          <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} className={classes.noPadding}>
            {!isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {!isOpen ? <Typography className={classes.subHeader}> {renderShort(data, itemIndex)}</Typography> : fullBody}
        </Grid>
      </Grid>
      <Divider />
    </Root>
  );
};

export default ExpandingSection;
