import { Box, Link, Typography } from "@mui/material";
import ShowMore from "../../../../components/ShowMore";
import { EmployeeData } from "../../../../types";
import ExperienceCard from "../ExperienceCard";
import React from "react";

interface PropTypes {
  className?: string;
  data: EmployeeData;
}

const Jobs = (props: PropTypes) => {
  const { data } = props;
  const sortedVols = data["volunteer"];
  sortedVols.sort(function(a, b) {
    return parseInt(b.startepoch) - parseInt(a.startepoch);
  });
  return (
    <Box
      sx={{
        width: "100%"
      }}
    >
      <Typography gutterBottom variant="h4" component="h2">
        Volunteer
      </Typography>
      <ShowMore
        data={sortedVols.map(vol => {
          var date = vol.startdate && vol.enddate ? " - " : "";
          if (vol.startdate) {
            date = vol.startdate + date;
          }
          if (vol.enddate) {
            date += vol.enddate;
          }
          return (
            <ExperienceCard
              data={{
                header: vol.volunteerrole,
                subHeader:
                  vol.companylink && vol.companylink !== "-" ? (
                    <Link color="inherit" href={vol.companylink}>
                      {vol.company}
                    </Link>
                  ) : (
                    vol.company
                  ),
                subTitle1: date,
                subTitle2: vol.cause,
                description: vol.description,
                link: vol.companylink,
                image: vol.imagelink && vol.imagelink !== "-" ? vol.imagelink : "/images/default-corporate-image.jpg"
              }}
            />
          );
        })}
        trimIndex={4}
      />
    </Box>
  );
};

export default Jobs;
