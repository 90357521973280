import { Grid, Typography, styled } from "@mui/material";
import React from "react";

const PREFIX = "ErrorBoundary";
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  heading: `${PREFIX}-heading`,
  titleWrapper: `${PREFIX}-titleWrapper`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(4)
  },
  [`& .${classes.content}`]: {
    paddingTop: 150,
    textAlign: "center"
  }
}));

interface PropTypes {
  children: React.ReactNode;
  errorMessage?: string;
  onError?: Function;
}
interface StatType {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<PropTypes, StatType> {
  constructor(props: PropTypes | Readonly<PropTypes>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    this.setState({
      hasError: true
    });
    if (this.props.onError) this.props.onError();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Root className={classes.root}>
          <Grid
            container
            spacing={4}
            sx={{
              justifyContent: "center"
            }}
          >
            <Grid item lg={6} xs={12}>
              <div className={classes.content}>
                <Typography variant="h1">{this.props.errorMessage ?? "Oops! Something went wrong."}</Typography>
              </div>
            </Grid>
          </Grid>
        </Root>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
