import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, TextField, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { useState } from "react";

type changePasswordProps = {
  className?: string;
};

const ChangePassword = (props: changePasswordProps) => {
  const { className, ...rest } = props;

  const [values, setValues] = useState({
    oldPassword: "",
    confirmPassword: "",
    newPassword: ""
  });
  const [resultText, setResultText] = useState({
    text: "",
    isRed: false,
    inProgress: false
  });
  const handleChange = (event: SelectChangeEvent) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const updatePassword = (event: any) => {
    event.preventDefault();
    if (values.newPassword.length < 6) {
      setResultText({
        ...resultText,
        text: "Password must be 6 charecters long.",
        isRed: true,
        inProgress: false
      });
    } else if (values.confirmPassword !== values.newPassword) {
      setResultText({
        ...resultText,
        text: "Passwords must match.",
        isRed: false,
        inProgress: false
      });
    } else {
      setResultText({
        ...resultText,
        inProgress: true
      });
      updateAWSPW(values.oldPassword, values.newPassword);
    }
  };

  const updateAWSPW = async (oldPassword: string, newPassword: string) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, oldPassword, newPassword);
      setResultText({
        ...resultText,
        text: "Password successfully changed",
        isRed: false,
        inProgress: false
      });
    } catch (error) {
      setResultText({
        ...resultText,
        text: "Unable to update password. Please check your old password.",
        isRed: true,
        inProgress: false
      });
    }
  };
  return (
    <Card {...rest} className={className??""}>
      <form>
        <CardHeader subheader="Change password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Old Password"
            name="oldPassword"
            onChange={(e: any) => handleChange(e)}
            type="password"
            value={values.oldPassword}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            fullWidth
            label="New Password"
            name="newPassword"
            onChange={(e: any) => handleChange(e)}
            type="password"
            style={{ marginTop: "1rem" }}
            value={values.newPassword}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            name="confirmPassword"
            onChange={(e: any) => handleChange}
            style={{ marginTop: "1rem" }}
            type="password"
            value={values.confirmPassword}
            InputLabelProps={{
              shrink: true
            }}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="contained" onClick={updatePassword} disabled={resultText.inProgress}>
            Update
          </Button>
          <Typography color="success.main" variant="body1">
            <Box color={resultText.isRed ? "error.main" : "success.main"}>{resultText.text}</Box>
          </Typography>
        </CardActions>
      </form>
    </Card>
  );
};

ChangePassword.propTypes = {
  className: PropTypes.string
};

export default ChangePassword;
