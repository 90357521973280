import { GridFilterModel } from "@mui/x-data-grid-pro";
import { groupBy } from "lodash";

/** Series Options */
export const LAST_ROUND_OPTIONS = ["Seed", "Series A", "Series A-1", "Series A-2", "Series A-3", "Series B", "Series C", "Series D", "Series E", "Series F"];

/**
 * @description This will check for the isEmpty and isNotEmpty condition.
 * @param filter GridFilterModel
 * @returns boolean
 */
export const emptyConditionCheck = (filters: GridFilterModel): boolean => {
  let checked = true;
  const items = filters.items;

  // grouping filter items WRT field
  const groupedItems = groupBy(items, d => d.field);

  Object.keys(groupedItems).forEach(field => {
    //items for a felid
    const itemsOfField = groupedItems[field];
    // if the is empty and is not empty is applied for the same field then checked will be false.
    if (itemsOfField.find(d => d.operator === "isEmpty" && itemsOfField.find(d => d.operator === "isNotEmpty"))) checked = false;
  });
  return checked;
};
