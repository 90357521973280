import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, Stack } from "@mui/material";
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import SyncIcon from "@mui/icons-material/Sync";

const EMPTY_RECORD = {
  companyname: "",
  initialinvestment: dayjs().format("DD/MM/YYYY"),
  cost: 0,
  fmv: 0,
  lastround: "",
  lastrounddate: dayjs().format("DD/MM/YYYY"),
  coinvestors: [],
  ceo: {
    name: "",
    linkedinUrl: ""
  },
  companyurl: "",
  uuid: ""
};

// utility function for the random id generation
const generateRandomId = (): string => {
  const min = 100000;
  const max = 999999;
  const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
  return String(randomNum);
};

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  refetch: any;
  loading: boolean;
}

const EditToolbar = (props: EditToolbarProps) => {
  const { setRows, setRowModesModel , refetch ,loading} = props;

  const handleClick = () => {
    const id = generateRandomId();
    setRows(oldRows => [{ id, isNew: true, ...JSON.parse(JSON.stringify(EMPTY_RECORD)) }, ...oldRows]);
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "companyname" }
    }));
  };

  return (
    <GridToolbarContainer>
      <Stack direction={"row"} alignItems="center" justifyContent={"flex-end"} sx={{ width: "100%", marginBottom: 0.25 }}>
        {/* <Stack direction={"row"} alignItems="center" spacing={2} sx={{ padding: "7px 8px" }} alignContent={"center"}>
          <FormatListBulletedIcon fontSize="large" />
          <Typography variant="h5">{"Portfolio List"}</Typography>
          <IconButton aria-label="sync-data" onClick={() => refetch()} title="Refresh the table">
            {loading  ? <CircularProgress  color="inherit" size={21} /> : <SyncIcon /> }
          </IconButton>
        </Stack> */}
          <LoadingButton loading={loading} loadingIndicator="Loading…"  startIcon={<SyncIcon />} aria-label="sync-data" onClick={() => refetch()} title="Refresh the table">
            Sync
            </LoadingButton>
        
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
      </Stack>
      <Divider sx={{ width: "100%" }} />
    </GridToolbarContainer>
  );
};

export default EditToolbar;
