const verticals:string[] = [
    "AdTech",
    "Aerospace and Defense",
    "AgTech",
    "Application Software",
    "Artificial Intelligence & Machine Learning",
    "Asset Management",
    "Automation/Workflow Software",
    "Automotive",
    "Beverages",
    "Big Data",
    "Broadcasting Radio and Television",
    "Brokerage",
    "Building Products",
    "Buildings and Property",
    "Business/Productivity Software",
    "Cannabis",
    "CleanTech",
    "Clothing",
    "CloudTech & DevOps",
    "Communication Software",
    "Construction and Engineering",
    "Consulting Services (B2B)",
    "Cryptocurrency/Blockchain",
    "Cybersecurity",
    "Digital Health",
    "Distributors/Wholesale",
    "E-Commerce",
    "EdTech",
    "Educational Software",
    "Educational and Training Services (B2C)",
    "Electrical Equipment",
    "Electronic Equipment and Instruments",
    "Electronics (B2C)",
    "Energy",
    "Entertainment Software",
    "Environmental Services (B2B)",
    "FinTech",
    "Financial Software",
    "Food Products",
    "FoodTech",
    "Gaming",
    "HR Tech",
    "HealthTech",
    "Healthcare",
    "Holding Companies",
    "Home Furnishings",
    "Human Capital Services",
    "IT Consulting and Outsourcing",
    "Industrial Supplies and Parts",
    "Industrials",
    "Information Services (B2C)",
    "Infrastructure",
    "InsurTech",
    "Insurance Brokers",
    "Internet Retail",
    "Internet of Things",
    "LOHAS & Wellness",
    "Life Sciences",
    "Life and Health Insurance",
    "Logistics",
    "Machinery (B2B)",
    "Manufacturing",
    "Marketing Tech",
    "Materials and Resources",
    "Media and Information Services (B2B)",
    "Mobile",
    "Mobility Tech",
    "Movies Music and Entertainment",
    "Multimedia and Design Software",
    "National Banks",
    "Network Management Software",
    "Oil & Gas",
    "Oncology",
    "Other Commercial Products",
    "Other Commercial Services",
    "Other Communications and Networking",
    "Other Financial Services",
    "Other IT Services",
    "Other Insurance",
    "Other Services (B2C Non-Financial)",
    "Other Software",
    "Personal Products",
    "Printing Services (B2B)",
    "Publishing",
    "Real Estate Investment Trusts (REITs)",
    "Real Estate Services (B2C)",
    "Real Estate Technology",
    "Regional Banks",
    "Road",
    "Robotics and Drones",
    "SaaS",
    "Social/Platform Software",
    "Software Development Applications",
    "Specialized Finance",
    "Specialty Retail",
    "Systems and Information Management",
    "TMT",
    "Telecommunications Service Providers",
    "Virtual Reality"
]

    const columns_basic:{
        id:string,
        label:string,
        type:string,
        data?:string[],
        isMultiple?:boolean
    }[] = [
        {
            id: "NameContains",
            label: "Company Name",
            type: "TEXT"
        },
        {
            id: "NextSeries",
            label: "Next Series",
            type: "MULTI",
            data: [
                "Series A",
                "Series B",
                "Series C",
                "Series D",
                "Series E"
            ],
            isMultiple: false
        },
        {
            id: "Verticals",
            label: "Verticals",
            data: verticals,
            type: "MULTI",
            isMultiple: true
        }
    ]

/*
const columns_company = [
    {
        id: "NameContains",
        label: "Company Name",
        type: "TEXT"
    },
    {
        id: "Location",
        label: "Company Location",
        type: "MULTI",
        data: [
            "Massachusetts",
            "California",
            "New York"
        ],
        isMultiple: false
    },

    {
        id: "LastUpdated",
        label: "Date Found",
        type: "DATE"
    },

   {
    id: "MaxFounded",
    label: "Minimum Founding Date",
    type: "DATE",
    checkBox:{
        id: "IncludeEmptyFounded",
        label: "Include Companies Without a Founding Date"
    }
},  
    {
        id: "LastUpdated",
        label: "Last Updated",
        type: "DATE"
    },
    {
        id: "RecordCreated",
        label: "Record Creation",
        type: "DATE"
    },
    {
        id: "Industries",
        label: "Industries",
        data: industries,
        type: "MULTI",
        isMultiple: true
    }
    ,
    {
        id: "isB2B",
        label: "Only B2B Companies",
        type: "CHECKBOX",
    },
    {
        id: "totalFunding",
        label: "Total Funding",
        type: "SLIDER",
        short:true
    },
    {
        id: "investorsActivity",
        label: "Only Companies with Investor Activity",
        type: "CHECKBOX",
    },
    ,
    {
        id: "advisorActivity",
        label: "Only Companies with Advisor Activity",
        type: "CHECKBOX",
    }
]

const columns_employee = [
    {
        id: "Experience",
        label: "Previous Jobs",
        type: "FREE",
        data: []
    },
    {
        id: "Education",
        label: "Education",
        type: "FREE",
        data: []
    },
    {
        id: "EmployeeCount",
        label: "Management Team Size",
        type: "SLIDER",
    },
    {
        id: "EmployeeTags",
        label: "Employee Tags",
        Select: {
            id: "DepartmentToTag",
            label: "Department",
            data: departments

        },
        Select2: {
            id: "SeniorityToTag",
            label: "Seniority",
            data: ParseDepartmentandSeniority.getAllDisplaySeniorities()

        },
        Select3: {
            id: "TagToSearch",
            label: "Tag",
            data: ParseDepartmentandSeniority.getAllDisplayTags()

        },
        type: "SLIDER_SELECT",
        generateTags: (values) => {
            var retData = (values["Seniority"] ? values["Seniority"] : "Any Seniority") + " in " + (values["Department"] ? values["Department"] : "Any Departments") + 
             " with " + ParseDepartmentandSeniority.convertTagToDisplay(values["Tag"])
            return retData
        },
        generateOutput:(values) => GenerateOutputs.generateOutputForEmployeeTags(values)
    },
    {
        id: "EmployeeSkills",
        label: "Employee Skills",
        Select: {
            id: "DepartmentToSkill",
            label: "Department",
            data: departments

        },
        Select2: {
            id: "SeniorityToSkill",
            label: "Seniority",
            data: ParseDepartmentandSeniority.getAllDisplaySeniorities()

        },
        Select3: {
            id: "SkillToSearch",
            label: "Skills",
            data: [],
            freeSolo: true
        },
        type: "SLIDER_SELECT",
        generateTags: (values) => {
            var retData = (values["Seniority"] ? values["Seniority"] : "Any Seniority") + " in " + (values["Department"] ? values["Department"] : "Any Departments") + 
             " with skill in " + values["Skill"]
            return retData
        },
        generateOutput:(values) => GenerateOutputs.generateOutputForSkills(values)
    },
]

const columns_employeeAnalytics = [
    {
        id: "DepartmentCountList",
        label: "Employees Per Department",
        Select: {
            id: "DepartmentToCount",
            label: "Department",
            data: departments

        },
        Select2: {
            id: "SeniorityToCount",
            label: "Seniority",
            data: ParseDepartmentandSeniority.getAllDisplaySeniorities()

        },
        Slide: {
            id: "CountOfDepartment",
            label: "Employee Range",
            isSmall: true,
        },
        type: "SLIDER_SELECT",
        generateTags: (values) => {
            var retData = (values["Seniority"] ? values["Seniority"] : "Any Seniority") + " in " + (values["Department"] ? values["Department"] : "Any Departments") +  " with " + 
            values["EmployeeRange"][0] + " to " + values["EmployeeRange"][1] +  " People"
            return retData
        },
        generateOutput: (values) => GenerateOutputs.generateOutputForDepartmentCount(values)
    },
    {
        id: "DepartmentStrengthList",
        label: "Signal Score Per Department",
        Select: {
            id: "DepartmentToSignal",
            label: "Department",
            data: departments

        },
        Select2: {
            id: "SeniorityToSignal",
            label: "Seniority",
            data: ParseDepartmentandSeniority.getAllDisplaySeniorities()

        },
        Slide: {
            id: "SignalOfDepartment",
            label: "Signal Range",
        },
        type: "SLIDER_SELECT",
        generateTags: (values) => {
            var retData = (values["Seniority"] ? values["Seniority"] : "Any Seniority") + " in " + (values["Department"] ? values["Department"] : "Any Departments") +  " with " +
            values["SignalRange"][0] + " to " + values["SignalRange"][1] + " Signal Score"
            return retData
        },
        generateOutput:(values) => GenerateOutputs.generateOutputForSignalStrength(values)
    },
    {
        id: "DepartmentDeltaList",
        label: "Signal Score Above Average",
        Select: {
            id: "DepartmentSignalDelta",
            label: "Department",
            data: departments

        },
        Select2: {
            id: "SenioritySignalDelta",
            label: "Seniority",
            data: ParseDepartmentandSeniority.getAllDisplaySeniorities()

        },
        Select3: {
            id: "PeerGroup",
            label: "Peer Group",
            data: industries
        },
        Slide: {
            id: "SignalDelta",
            label: "Signal Delta Range",
        },
        type: "SLIDER_SELECT",
        generateOutput:(values) => GenerateOutputs.generateOutputForSignalDelta(values),
        generateTags: (values) => {
            var retData = (values["Seniority"] ? values["Seniority"] : "Any Seniority") + " in " + (values["Department"] ? values["Department"] : "Any Departments")  + " with Signal Score " + 
            values["SignalRange"][0] + " to " + values["SignalRange"][1] + " Above " + values["Industry"] + " Industry Average"
            return retData
        }
    },
]
*/
//eslint-disable-next-line
export default {
    columns_basic
};
