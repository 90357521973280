import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const PREFIX = "SearchSort";

const classes = {
  root: `${PREFIX}-root`,
  formControl: `${PREFIX}-formControl`,
  labelRoot: `${PREFIX}-labelRoot`
};


const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
      width: "100%"
    },
    [`& .${classes.formControl}`]: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    [`& .${classes.labelRoot}`]: {
      fontSize: "16px"
    }
  })
);

const SearchSort = (props: any) => {
 

  const { value, onChange } = props;

  return (
    <Root className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.labelRoot} id="sort-label">
          Sort By
        </InputLabel>
        <Select labelId="sort-label" id="sort" color="primary" onChange={e => onChange("sort", e.target.value)} value={value}>
          <MenuItem value={"relevance"}>
            <em>Relevance</em>
          </MenuItem>
          <MenuItem value={"alphabetical"}>
            <em>Alphabetical</em>
          </MenuItem>
          <MenuItem value={"creation_new"}>
            <em>Newest</em>
          </MenuItem>
          <MenuItem value={"creation_old"}>
            <em>Oldest</em>
          </MenuItem>
        </Select>
      </FormControl>
    </Root>
  );
};
export default SearchSort;
