import React from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { Login } from "./Login";
import { RequireAuth } from "./components/RequireAuth";
import { Main as MainLayout } from "./layouts";
import { CompaniesList, Company, CompanyAnalytics, CompanyDetails, Dashboard, Employee, InvestmentForms, KanbanViewPOC, NotFound, Search, Settings, UploadFiles, WeeklyLeads } from "./views";

const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route index element={<Login />} />
      <Route path="/" element={<MainLayout />}>
        {/* Dashboard Page */}
        <Route
          path="dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />

        {/* Search Page */}
        <Route
          path="search"
          element={
            <RequireAuth>
              <Search />
            </RequireAuth>
          }
        />

        {/* Accounts Settings */}
        <Route
          path="/account"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />

        {/* Editable table */}

        <Route
          path="companies-list"
          element={
            <RequireAuth>
              <CompaniesList.CompaniesViewsList />
            </RequireAuth>
          }
        >
          <Route
            path=":id"
            element={
              <RequireAuth>
                <CompaniesList.CompaniesListView />
              </RequireAuth>
            }
          />
        </Route>

        {/* Kan-ban view */}
        <Route
          path="kanban-view"
          element={
            <RequireAuth>
              <KanbanViewPOC />
            </RequireAuth>
          }
        />

        <Route
          path="investmentforms"
          element={
            <RequireAuth>
              <InvestmentForms />
            </RequireAuth>
          }
        />
         <Route
          path="/company-analytics"
          element={
            <RequireAuth>
              <CompanyAnalytics />
            </RequireAuth>
          }
        />
        <Route
          path="leads"
          element={
            <RequireAuth>
              <WeeklyLeads />
            </RequireAuth>
          }
        />

        <Route
          path="employees/:employeeID"
          element={
            <RequireAuth>
              <Employee />
            </RequireAuth>
          }
        />

        {/* Company details page */}
        <Route
          path="/companies/:id"
          element={
            <RequireAuth>
              <Company />
            </RequireAuth>
          }
        />
        <Route
          path="/company-details/:id"
          element={
            <RequireAuth>
              <CompanyDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/data-management"
          element={
            <RequireAuth>
              <UploadFiles />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path="/*"
        element={
          <RequireAuth>
            <NotFound />
          </RequireAuth>
        }
      />
    </React.Fragment>
  )
);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};
export default AppRoutes;
