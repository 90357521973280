import { DatePicker as KeyboardDatePicker, LocalizationProvider as MuiPickersUtilsProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Autocomplete, Grid, TextField, styled } from "@mui/material";
import FilterData from "./FilterData";

const PREFIX = "SearchToolbarItems";
const classes = {
  root: `${PREFIX}-root`,
  textField: `${PREFIX}-textField`,
  labelRoot: `${PREFIX}-labelRoot`,
  textFieldRoot: `${PREFIX}-textFieldRoot`,
  formLabel: `${PREFIX}-formLabel`,
  gridRoot: `${PREFIX}-gridRoot`
};
const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3)
    }
  },
  [`& .${classes.textField}`]: {
    width: "100%",
    marginTop: "16px"
  },
  [`& .${classes.labelRoot}`]: {
    fontSize: "16px"
  },
  [`& .${classes.textFieldRoot}`]: {
    marginTop: "16px"
  },
  [`& .${classes.formLabel}`]: {
    height: "100%"
  },
  [`& .${classes.gridRoot}`]: {
    paddingTop: "28px"
  }
}));

const SearchToolbarItems = props => {
  const { onChange, minCreate, maxCreate, minUpdate, maxUpdate, minCross, maxCross, nameStarts, nameContains, descContains, companyCategories, requiredWebsites } = props;

  return (
    <Root classes={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4} classes={{ root: classes.gridRoot }}>
          <TextField
            className={classes.textField}
            variant="standard"
            id="nameStarts"
            value={nameStarts}
            onChange={e => onChange(e.target.id, e.target.value)}
            label="Company Name Starts With"
            FormHelperTextProps={{
              classes: {
                root: classes.gridRoot
              }
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            className={classes.textField}
            variant="standard"
            id="nameContains"
            value={nameContains}
            onChange={e => onChange(e.target.id, e.target.value)}
            label="Company Name Contains"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot
              }
            }}
          />
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid item xs={4}>
          <Autocomplete
            multiple
            id="companyCategories"
            value={companyCategories}
            onChange={(e, v) => onChange("companyCategories", v)}
            options={FilterData.Catagories}
            getOptionLabel={option => option}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Company Categories"
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.labelRoot
                  }
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password"
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            options={FilterData.DataSources}
            onChange={(e, v) => onChange("requiredWebsites", v)}
            id="requiredWebsites"
            value={requiredWebsites}
            getOptionLabel={option => option}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label="Required Websites"
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.labelRoot
                  }
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password"
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}></Grid>
        {/*
        <Grid item xs={4}>
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            autoSelect
            onChange = {(e,v) => onChange("prevJobs", v)}
            value = {prevJobs}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Employees' Previous Jobs"
                InputLabelProps={{
                  shrink: true,
                  classes:{
                root: classes.labelRoot
              }
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
        <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            freeSolo
            autoSelect
            onChange = {(e,v) => onChange("prevEd", v)}
            value = {prevEd}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Employees' Education"
                InputLabelProps={{
                  shrink: true,
                  classes:{
                root: classes.labelRoot
              }
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
        </Grid>
        */}
        <MuiPickersUtilsProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={4}>
            <TextField
              className={classes.textField}
              variant="standard"
              label="Company Description Includes"
              id="descContains"
              value={descContains}
              onChange={e => onChange(e.target.id, e.target.value)}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              margin="normal"
              id="minCross"
              clearable={true}
              label="Minimum Cross-site Date"
              value={minCross}
              onChange={value => onChange("minCross", value)}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              margin="normal"
              id="maxCross"
              label="Maximum Cross-site Date"
              value={maxCross}
              clearable={true}
              onChange={value => onChange("maxCross", value)}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot
                }
              }}
            />
          </Grid>

          <Grid item s={3}>
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              margin="normal"
              id="minCreate"
              label="Minimum Creation Date"
              clearable={true}
              value={minCreate}
              onChange={value => onChange("minCreate", value)}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot
                }
              }}
            />
          </Grid>
          <Grid item s={3}>
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              margin="normal"
              id="maxCreate"
              clearable={true}
              label="Maximum Creation Date"
              value={maxCreate}
              onChange={value => onChange("maxCreate", value)}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot
                }
              }}
            />
          </Grid>
          <Grid item s={3}>
            <KeyboardDatePicker
              clearable={true}
              format="MM/dd/yyyy"
              margin="normal"
              id="minUpdate"
              label="Minimum Last Updated"
              value={minUpdate}
              onChange={value => onChange("minUpdate", value)}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot
                }
              }}
            />
          </Grid>
          <Grid item s={3}>
            <KeyboardDatePicker
              clearable={true}
              format="MM/dd/yyyy"
              margin="normal"
              id="maxUpdate"
              label="Maximum Last Updated"
              value={maxUpdate}
              onChange={value => onChange("maxUpdate", value)}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot
                }
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    </Root>
  );
};
export default SearchToolbarItems;
