
import { styled } from "@mui/material";
import clsx from "clsx";
import React from "react";

const StyledDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(4)
}));

interface PropTypes {
  className?: string;
}

const Footer = (props: PropTypes) => {
  const { className } = props;

  return <StyledDiv className={clsx(className)}></StyledDiv>;
};

export default Footer;
