
import React from "react";
import { useQuery ,  gql} from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { listSavedSearchs } from "../../../../graphql/queries";
import { SavedSearchedList } from "../../../../types";
import { SearchTable } from "./components";

const PREFIX = "SavedSearches";

const classes = {
  root: `${PREFIX}-root`,
  mainHeading: `${PREFIX}-mainHeading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  noMargin: `${PREFIX}-noMargin`,
  skeletonStyle: `${PREFIX}-skeletonStyle`,
  hidingGrid: `${PREFIX}-hidingGrid`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%"
  },
  [`& .${classes.mainHeading}`]: {
    textAlign: "center",
    width: "100%",
    minWidth: 650,
    paddingBottom: "4px"
  },
  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },

  [`& .${classes.noMargin}`]: {
    marginTop: "0px !important",
    paddingTop: "0px !important"
  },

  [`& .${classes.skeletonStyle}`]: {
    width: "100%",
    height: "250px"
  },
  [`& .${classes.hidingGrid}`]: {
    padding: "0px !important"
  }
}));

const SavedSearches = () => {
  const { loading, data } = useQuery<{ listSavedSearchs: SavedSearchedList }>(gql(listSavedSearchs), {
    variables: {
      limit: 1000
    }
  });
  const [page, setPage] = useState<number>(0);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  return (
    <Root className={classes.root}>
      <Grid container spacing={2} className={classes.noMargin}>
        <Grid item xs={12} lg={10} className={classes.noMargin}>
          <Typography variant="h3" component="h2" className={classes.mainHeading}>
            My Saved Searches
          </Typography>
        </Grid>

        <Grid item xs={12} lg={10} className={classes.noMargin}>
          {!loading && <SearchTable tableLength={data?.listSavedSearchs.items.length} data={data?.listSavedSearchs.items.slice(page * 10, (page + 1) * 10)} handleChangePage={handleChangePage} page={page} />}
        </Grid>
      </Grid>
    </Root>
  );
};

export default SavedSearches;
