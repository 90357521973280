import { Table,  TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import {  CompanyTableColumns, ObjectLiteral } from '../../types';

interface PropType<T>{
  columns:CompanyTableColumns<T>[],
  body:React.ReactNode,
  renderColumns: (column:CompanyTableColumns<T>) =>  React.ReactNode
}

const TableHeader = <T extends ObjectLiteral>(props:PropType<T>) => {
  const { columns, body , renderColumns } = props;  

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {/* {columns.map((column) => (
                
              ))} */}
              {columns.map(column =>  renderColumns(column))}
            </TableRow>
          </TableHead>
          {body}
          </Table>
      </TableContainer>
  );
}
export default TableHeader;

