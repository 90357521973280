import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { TabPanel } from "../../components";
import { Favorites, SavedSearches } from "./components";

const PREFIX = "UserFavorites";

const classes = {
  root: `${PREFIX}-root`,
  tabBarRoot: `${PREFIX}-tabBarRoot`,
  center: `${PREFIX}-center`,
  tabs: `${PREFIX}-tabs`,
  tabBody: `${PREFIX}-tabBody`,
  maxWidth: `${PREFIX}-maxWidth`,
  paperRoot: `${PREFIX}-paperRoot`,
  mainHeading: `${PREFIX}-mainHeading`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    padding: theme.spacing(3)
  },
  [`& .${classes.tabBarRoot}`]: {
    flexGrow: 1,
    display: "flex"
  },
  [`& .${classes.center}`]: {
    textAlign: "center"
  },
  [`& .${classes.tabs}`]: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "350px",
    backgroundColor: theme.palette.background.paper,
    height: "fit-content",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    marginTop: "40px"
  },
  [`& .${classes.tabBody}`]: {
    width: "100%"
  },
  [`& .${classes.maxWidth}`]: {
    maxWidth: "100%"
  },
  [`& .${classes.paperRoot}`]: {
    height: "fit-content"
  },
  [`& .${classes.mainHeading}`]: {
    textAlign: "center",
    width: "100%",
    minWidth: 650,
    paddingBottom: "4px"
  }
}));
const TabBar = () => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.tabBarRoot}>
            <Tabs value={value} onChange={handleChange} variant="fullWidth" indicatorColor="primary" textColor="primary" aria-label="icon tabs example" orientation="vertical" className={classes.tabs}>
              <Tab className={classes.maxWidth} icon={<FavoriteIcon />} label="COMPANY FAVORITES" />
              <Tab className={classes.maxWidth} icon={<SearchIcon />} label="SAVED SEARCHES" />
            </Tabs>
            <TabPanel value={value} className={classes.tabBody} index={0}>
              <Favorites />
            </TabPanel>
            <TabPanel value={value} className={classes.tabBody} index={1}>
              <SavedSearches />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </Root>
  );
};

export default TabBar;
