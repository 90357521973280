import { useQuery , gql } from "@apollo/client";
import { Link, Skeleton, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
// import gql from "graphql-tag";
import { companySearch } from "../../../../../../graphql/queries";
import { SearchHelper } from "../../../../../../helpers";

const queryString = require("qs");



const handleSearchInput = locationString => {
  const searchInput = queryString.parse(locationString, { arrayFormat: "indices", strictNullHandling: true, ignoreQueryPrefix: true });
  if (searchInput.pageNum) searchInput.pageNum = parseInt(searchInput.pageNum);
  if (searchInput.DepartmentStrengthList) searchInput.pageNum = parseInt(searchInput.pageNum);
  
  if (searchInput.page) searchInput.page = parseInt(searchInput.page);
  searchInput.IncludeEmptyFounded = searchInput.IncludeEmptyFounded === "true";
  searchInput.isB2B = searchInput.isB2B === "true";
  searchInput.investorsActivity = searchInput.investorsActivity === "true";
  searchInput.advisorActivity = searchInput.advisorActivity === "true";

  return { ...SearchHelper.getDefaultInput(), ...searchInput };
};

const NewResultsText = props => {
  
  const { className, searchString, lastViewed, ...rest } = props;
  const theme = useTheme();
  const  searchData = handleSearchInput(searchString);
  searchData["RecordCreated"] = new Date(parseInt(lastViewed) * 1000).toJSON().split("T");
  const {  loading, data } = useQuery(gql(companySearch), {
    variables: SearchHelper.transformSearchData(searchData, true)
  });
  
  return (
    <div>
      {!loading ? (
        <Link sx={{ color: theme.palette.info.main}} className={clsx(className)} {...rest}>
          {data.companySearch.recordCount} New Results
        </Link>
      ) : (
        <Typography {...rest}>
          <Skeleton />
        </Typography>
      )}
    </div>
  );
};

export default NewResultsText;
