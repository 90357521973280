/* eslint-disable */
import { Rating } from '@mui/material';
import React from 'react';
const scoreCardOGColumns = [
    /*{
        name: "Website URL (from Company)",
        id: "websitefinal",
        format: value => <Link color="inherit" variant="body1" href={"https://" + value} > {"https://" + value}
        </Link>
    },*/
    {
        name: "Feedback is For",
        id: "feedbackfor"
    },
    {
        name: "Date of Meeting",
        id: "dateofmeeting"
    },
    {
        name: "Meeting Context",
        id: "meetingcontext"
    },
    {
        name: "Which Renegades were There",
        id: "whichrenegades"
    },
    {
        name: "Who from the Company?",
        id: "whofromcompany"
    },
    {
        name: "Team",
        id: "team"
    },
    {
        name: "Team Opinion Strength",
        id: "teamstrength",
        format: value => <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={5} size="large" />

    },
    {
        name: "Team Opinion Rationale",
        id: "teamrationale"
    },
    {
        name: "Market",
        id: "market"
    },
    {
        name: "Market Opinion Strength",
        id: "marketstrength",
        format: value => <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={5} size="large" />

    },
    {
        name: "Market Opinion Rationale",
        id: "marketrationale"
    },
    {
        name: "Product",
        id: "product"
    },
    {
        name: "Product Opinion Strength",
        id: "productstrength",
        format: value => <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={5} size="large" />

    },
    {
        name: "Product Opinion Rationale",
        id: "productrationale"
    },
    {
        name: "Business Model/Engine",
        id: "businessmodel"
    },
    {
        name: "Business Model/Engine Opinion Strength",
        id: "businessmodelstrength",
        format: value => <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={5} size="large" />

    },
    {
        name: "Business Model/Engine Opinion Rationale",
        id: "businessmodelrationale"
    },
    {
        name: "Potential Deal",
        id: "potentialdeal"
    },
    {
        name: "Potential Deal Opinion Strength",
        id: "potentialdealstrength",
        format: value => <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={5} size="large" />

    },
    {
        name: "Potential Deal Opinion Rationale",
        id: "potentialdealrationale"
    },
    {
        name: "Feedback on Requested Topics",
        id: "feedbacktopics"
    },
    {
        name: "Do you want to move this forward?",
        id: "moveforward"
    },
    {
        name: "Rationale behind moving forward",
        id: "rationaleforward"
    },
    {
        name: "What is the probability that this will get funded by a top-tier partner?",
        id: "toptierprob"
    },
    {
        name: "Next Steps",
        id: "nextsteps"
    },

]

const scoreCardAColumns = [
    {
        name: "Author",
        id: "author"
    },
    {
        name: "Company URL",
        id: "companyurl"
    },
    {
        name: "Meeting Date",
        id: "meetingdate"
    },
    {
        name: "Scorecard Date",
        id: "completedate"
    },
    {
        name: "Deal Source Firm Name",
        id: "dealsourcef"
    },
    {
        name: "Deal Source Person LinkedIn (URL)",
        id: "dealsourceli"
    },
    {
        name: "Sourced By",
        id: "sourcedby"
    },
    {
        name: "Advance company or pass?",
        id: "advorpass"
    },
    {
        name: "Reason(s) For Advancing?",
        id: "reasonadv"
    },
    {
        name: "Explanation For Advancing",
        id: "adadr"
    },
    {
        name: "Reason(s) For Passing?",
        id: "reasonpass"
    },
    {
        name: "Explanation For Passing",
        id: "passadr"
    },
]


const scoreCardBColumns = [
    {
        name: "Author",
        id: "author"
    },
    {
        name: "Company URL",
        id: "companyurl"
    },
    {
        name: "Meeting Date",
        id: "meetingdate"
    },
    {
        name: "Scorecard Date",
        id: "completedate"
    },
    {
        name: "Deal Lead",
        id: "deallead"
    },
    {
        name: "Founder Strength",
        id: "founderstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Justification For Founder Strength",
        id: "founderjust"

    },
    {
        name: "Team Strength",
        id: "teamstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Justification For Team Strength",
        id: "teamjust"

    },
    {
        name: "Market Strength",
        id: "marketstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Justification For Market Strength",
        id: "marketjust"

    },
    {
        name: "Product Strength",
        id: "productstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Justification For Product Strength",
        id: "productjust"

    },
    {
        name: "Business Model/Engine Strength",
        id: "bmstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Justification For Business Model/Engine Strength",
        id: "bmjust"

    },
    {
        name: "Deal Strength",
        id: "dealstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Justification For Potential Deal Strength",
        id: "dealjust"

    },
    {
        name: "Move Forward To IC?",
        id: "moveic"

    },
    {
        name: "Additional explanation for passing?",
        id: "noreason"

    },
    {
        name: "Additional reasoning for moving forward",
        id: "yesreason"

    },
    {
        name:"Please elaborate on the extra diligence necessary to move the company forward",
        id: "ddreason"
    },
    {
        name: "Additional explanation for passing",
        id: "noreason"

    },
    {
        name: "What is the probability that this will get funded by a top-tier partner in the next round?",
        id: "toptierprob",
        format: (v) => v && v !== "" ? v + "%": ""

    },
    {
        name: "What is the probability that this will get funded by a top-tier partner two rounds from now?",
        id: "toptierprob2",
        format: (v) => v && v !== "" ? v + "%" : ""
    },
]

// const scoreCardCSubColumns = {
//     "Founder":[
//         {
//             name: "Founders are able to attract top talent?",
//             id: "f1",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Can grow with Company?",
//             id: "f2",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Ability to Fundraise?",
//             id: "f3",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Relevant Experience / Background?",
//             id: "f4",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Quality of Network?",
//             id: "f5",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Coachability?",
//             id: "f6",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Good judge of talent?",
//             id: "f7",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Urgency to recruit top talent?",
//             id: "f8",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Ability to manage and prune low performing talent?",
//             id: "f9",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         }
//     ],
//     "Team":[
//         {
//             name: "Missing key executives?",
//             id: "t1",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Quality of Existing Team?",
//             id: "t2",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Ability to Execute?",
//             id: "t3",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Leadership team is able to recruit top talent?",
//             id: "t4",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         }
//     ],
//     "Market":[
//         {
//             name: "Size of Market?",
//             id: "m1",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Market Value (Highly Valued? Multiples?)?",
//             id: "m2",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Market Positioning?",
//             id: "m3",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//         {
//             name: "Rate of Change?",
//             id: "m4",
//             format: value => value != "" && value != "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value
    
//         },
//     ]
// }
const scoreCardCColumns = [
    {
        name: "Author",
        id: "author"
    },
    {
        name: "Company URL",
        id: "companyurl"
    },
    {
        name: "Meeting Date",
        id: "meetingdate"
    },
    {
        name: "Scorecard Date",
        id: "completedate"
    },
    {
        name: "Deal Lead",
        id: "deallead"
    },
    {
        name: "Founder Strength",
        id: "founderstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value


    },
    {
        name: "Elaborate on Founder Strength",
        id: "founderexp",
    },
    {
        name: "Founders are able to attract top talent?",
        id: "f1",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Can grow with Company?",
        id: "f2",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Ability to Fundraise?",
        id: "f3",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Relevant experience / background?",
        id: "f4",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Quality of network?",
        id: "f5",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Coachability?",
        id: "f6",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Good judge of talent?",
        id: "f7",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Urgency to recruit top talent?",
        id: "f8",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Ability to manage and prune low performing talent?",
        id: "f9",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Team Strength",
        id: "teamstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Elaborate on Team Strength",
        id: "teamexp",

    },
    {
        name: "Missing key executives for the long term?",
        id: "t1",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Quality of Existing Team?",
        id: "t2",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Ability to Execute?",
        id: "t3",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Leadership team is able to recruit top talent?",
        id: "t4",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Missing key executives essential for the current business?",
        id: "t5",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Market Strength",
        id: "marketstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Elaborate on Market Strength",
        id: "marketexp",

    },
    {
        name: "Size of Market?",
        id: "m1",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Market Value (Highly Valued? Multiples?)?",
        id: "m2",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Market Positioning?",
        id: "m3",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Rate of Change?",
        id: "m4",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Market dynamics? (oligopoly, competition, etc)",
        id: "m5",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name:"Is the company B2B or B2C?",
        id:"btype"

    },
    {
        name: "Elaborate on Product Strength (B2C)",
        id: "b2bprodstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name:"Product Strength (B2C)",
        id:"b2bprodexp"

    },
    {
        name: "Differentiation?",
        id: "b2bp1",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Stickiness of product",
        id: "b2bp2",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Compelling feature set and roadmap?",
        id: "b2bp3",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Customer Satisfaction?",
        id: "b2bp4",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },

    {
        name: "Switching Cost?",
        id: "b2bp5",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Must have?",
        id: "b2bp6",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },

    {
        name: "Platform potential?",
        id: "b2bp7",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },

    {
        name: "Do they build a data advantage?",
        id: "b2bp8",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Business Model/Engine Strength (B2B)",
        id: "b2bbmstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Elaborate on Business Model/Engine Strength (B2B)",
        id: "b2bbmexp",

    },
    {
        name: "Revenue Growth",
        id: "b2bbm1",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Capital efficiency (burn, cash, etc)",
        id: "b2bbm2",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Margin structure",
        id: "b2bbm3",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Repeatability of sales process",
        id: "b2bbm4",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Upsell ability",
        id: "b2bbm5",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Pricing power",
        id: "b2bbm6",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Sales velocity",
        id: "b2bbm7",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Efficient GTM (Payback period, sales engine)",
        id: "b2bbm8",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Duration of contracts (single year, multi year, etc)",
        id: "b2bbm9",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "ACV",
        id: "b2bbm10",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Churn",
        id: "b2bbm11",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },

    {
        name: "Product Strength (B2C)",
        id: "b2cprodstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Elaborate on Product Strength (B2C)",
        id: "b2cprodexp",

    },
    {
        name: "Differentiation?",
        id: "b2cp1",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Customer Satisfaction?",
        id: "b2cp2",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Organic growth/ Virality?",
        id: "b2cp3",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Brand Voice?",
        id: "b2cp4",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Network effect?",
        id: "b2cp5",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Retention?",
        id: "b2cp6",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Business Model/Engine Strength (B2C)",
        id: "b2cbmstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Elaborate on Business Model/Engine Strength (B2C)",
        id: "b2cbmexp",

    },
    {
        name: "Revenue Growth",
        id: "b2cbm1",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Capital efficiency (burn, cash, etc)",
        id: "b2cbm2",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Margin structure",
        id: "b2cbm3",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Cohort behavior",
        id: "b2cbm4",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Pricing power",
        id: "b2cbm5",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Multiple marketing channels",
        id: "b2cbm6",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "User growth",
        id: "b2cbm7",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Marketing efficiency? (Rating)",
        id: "b2cbm8",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Engagement (DAU, MAU, session duration, etc)",
        id: "b2cbm9",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Potential Deal Strength",
        id: "pstr",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Elaborate on Potential Deal Strength",
        id: "pexp",

    },
    {
        name: "Syndicate with deep pockets?",
        id: "p1",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Quality of existing cap table",
        id: "p2",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Brand signal of firms",
        id: "p3",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Quality of board",
        id: "p4",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Valuation of deal",
        id: "p5",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Ability to write our preferred check size",
        id: "p6",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },

    {
        name: "Any potential red flags?",
        id: "redflag",

    },
    {
        name: "As we think of other companies that we have invested in, what decile does this company fit into compared to those companies at the time they came into IC? (Zero is off scale)",
        id: "icdecile",
        format: (v) => (parseInt(v) * 10) + "%"

    },
    {
        name: "Size of the bet",
        id: "betsize"

    },
    {
        name: "What is the probability that this will get funded by a top-tier partner in the next round?",
        id: "toptierprob",
        format: (v) => v + "%"

    },
    {
        name: "What is the probability that this will get funded by a top-tier partner two rounds from now?",
        id: "toptierprob2",
        format: (v) => v + "%"

    },
    {
        name: "What is the probability that the company will exit for 10x or more the current valuation?",
        id: "exit10x",
        format: (v) => v + "%"

    },    
    {
        name: "Has the company solved key hard/uncertain problems (trained monkeys)?",
        id: "monkey",
        format: value => value !== "" && value !== "Not Applicable" ? <Rating name="read-only" value={parseFloat(value)} readOnly precision={0.25} max={7} size="large" /> : value

    },
    {
        name: "Please elaborate on if the company has trained monkeys or built pedestals",
        id: "monkeyexp",

    }
]

/*

b2cbm1: String
b2cbm2: String
b2cbm3: String
b2cbm4: String
b2cbm5: String
b2cbm6: String
b2cbm7: String
b2cbm8: String
b2cbmexp: String
b2cbmstr: String
p1: String
p2: String
p3: String
p4: String
pexp: String
pstr: String
*/


function scoreCardProcess(scorecards) {
    var scorecards = scorecards.filter(e => e.author)
    var aggregate = {
        teamstrength: 0,
        marketstrength: 0,
        productstrength: 0,
        businessmodelstrength: 0,
        potentialdealstrength: 0,
        author: "Average",
        team: 0,
        market: 0,
        product: 0,
        businessmodel: 0,
        potentialdeal: 0,
        dateofmeeting: "",
        toptierprob: 0
    }
    for (const scorecard of scorecards) {
        aggregate["teamstrength"] += parseInt(scorecard["teamstrength"])
        aggregate["marketstrength"] += parseInt(scorecard["marketstrength"])
        aggregate["productstrength"] += parseInt(scorecard["productstrength"])
        aggregate["businessmodelstrength"] += parseInt(scorecard["businessmodelstrength"])
        aggregate["potentialdealstrength"] += parseInt(scorecard["potentialdealstrength"])
        aggregate["team"] += (scorecard["team"] === "🔥" ? 1 : [scorecard["team"] === "😞" ? -1 : 0])
        aggregate["market"] += (scorecard["market"] === "🔥" ? 1 : [scorecard["market"] === "😞" ? -1 : 0])
        aggregate["product"] += (scorecard["product"] === "🔥" ? 1 : [scorecard["product"] === "😞" ? -1 : 0])
        aggregate["businessmodel"] += (scorecard["businessmodel"] === "🔥" ? 1 : [scorecard["businessmodel"] === "😞" ? -1 : 0])
        aggregate["potentialdeal"] += (scorecard["potentialdeal"] === "🔥" ? 1 : [scorecard["potentialdeal"] === "😞" ? -1 : 0])

        aggregate["dateofmeeting"] = scorecard["dateofmeeting"]
        var score = scorecard["toptierprob"].replace("%", "")
        aggregate["toptierprob"] += parseInt(score)

    }
    aggregate["teamstrength"] = aggregate["teamstrength"] / scorecards.length
    aggregate["marketstrength"] = aggregate["marketstrength"] / scorecards.length
    aggregate["productstrength"] = aggregate["productstrength"] / scorecards.length
    aggregate["businessmodelstrength"] = aggregate["businessmodelstrength"] / scorecards.length
    aggregate["potentialdealstrength"] = aggregate["potentialdealstrength"] / scorecards.length

    aggregate["team"] = Math.round(aggregate["team"] / scorecards.length)
    aggregate["market"] = Math.round(aggregate["market"] / scorecards.length)
    aggregate["product"] = Math.round(aggregate["product"] / scorecards.length)
    aggregate["businessmodel"] = Math.round(aggregate["businessmodel"] / scorecards.length)
    aggregate["potentialdeal"] = Math.round(aggregate["potentialdeal"] / scorecards.length)

    aggregate["team"] = aggregate["team"] >= 0 ? "🔥" : "😞"
    aggregate["market"] = aggregate["market"] >= 0 ? "🔥" : "😞"
    aggregate["product"] = aggregate["product"] >= 0 ? "🔥" : "😞"
    aggregate["businessmodel"] = aggregate["businessmodel"] >= 0 ? "🔥" : "😞"
    aggregate["potentialdeal"] = aggregate["potentialdeal"] >= 0 ? "🔥" : "😞"

    aggregate["toptierprob"] = (aggregate["toptierprob"] / scorecards.length) + "%"



    var scoreCardsAll = [aggregate, ...scorecards]
    return scoreCardsAll
}

function getOGDebrief(scoreCardColumns) {

    var headersDebrief = scoreCardColumns.filter(elem => {
        var removeColumns = ["author"]
        return !removeColumns.includes(elem.id)

    })
    return headersDebrief
}

function getADebrief(scoreCardColumns) {

    var headersDebrief = scoreCardColumns.filter(elem => {
        var removeColumns = ["feedbackfor", "websitefinal", "meetingcontext", "whichrenegades"]
        return !removeColumns.includes(elem.id)

    })
    return headersDebrief
}

function getOGHeaders() {
    return scoreCardOGColumns
}

function filterNullHeaders(headers, scorecards) {
    var keyArray = []

    for (const scorecard of scorecards) {
        var keysTrim = Object.keys(scorecard).filter(value=>{

            return scorecard[value] && scorecard[value] !== "" && scorecard[value] !== "Not Applicable"
        })
        keyArray.push(...keysTrim)
    }
    keyArray = [...new Set(keyArray)]

    return headers.filter(header => keyArray.includes(header.id))
}

function traigeScoreCards(data, type) {
    if (type === "Original") {
        var scorecards = data.scorecards
        if (scorecards != null) {
            return {
                headers: getOGHeaders(scoreCardOGColumns),
                data: scoreCardProcess(scorecards)
            }
        }
        return null
    } else if (type === "ScorecardA") {
        var scorecards = data.scorecardsa
        if (scorecards != null) {
            return {
                headers: filterNullHeaders(scoreCardAColumns,scorecards),
                data: scorecards.filter(e => e.author)
            }
        }
        return null
    } else if (type === "ScorecardB") {
        var scorecards = data.scorecardsb
        if(scorecards !== null){
            return {
                headers: filterNullHeaders(scoreCardBColumns,scorecards),
                data: scorecards.filter(e => e.author)
            }
        }
    } else if (type === "ScorecardC") {
        var scorecards = data.scorecardsc
        if (scorecards != null) {
            return {
                headers: getADebrief(filterNullHeaders(scoreCardCColumns,scorecards)),
                data: scorecards.filter(e => e.author)
            }
        }

        return null
    } else if (type === "ScorecardPartner") {
        var scorecards = data.scorecardspartner
        if (scorecards != null) {
            return {
                headers: getADebrief(filterNullHeaders(scoreCardCColumns,scorecards)),
                data: scorecards.filter(e => e.author)
            }
        }

        return null
    }
}

function traigeDebriefCards(data, type) {
    if (type === "Original") {
        var scorecards = data.scorecards
        if (scorecards != null) {
            return {
                headers: getOGDebrief(scoreCardOGColumns),
                data: scorecards.filter(e => e.author)
            }
        }

        return null
    } else if (type === "ScorecardA") {
        var scorecards = data.scorecardsa
        if (scorecards != null) {
            return {
                headers: getADebrief(filterNullHeaders(scoreCardAColumns,scorecards)),
                data: scorecards.filter(e => e.author)
            }
        }

        return null
    } else if (type === "ScorecardB") {
        var scorecards = data.scorecardsb
        if (scorecards != null) {
            return {
                headers: getADebrief(filterNullHeaders(scoreCardBColumns,scorecards)),
                data: scorecards.filter(e => e.author)
            }
        }

        return null
    } else if (type === "ScorecardB") {
        var scorecards = data.scorecardsb
        if (scorecards != null) {
            return {
                headers: getADebrief(filterNullHeaders(scoreCardBColumns,scorecards)),
                data: scorecards.filter(e => e.author)
            }
        }

        return null
    } else if (type === "ScorecardC") {
        var scorecards = data.scorecardsc
        if (scorecards !== null) {
            return {
                headers: getADebrief(filterNullHeaders(scoreCardCColumns,scorecards)),
                data: scorecards.filter(e => e.author)
            }
        }

        return null
    } else if (type === "ScorecardPartner") {
        var scorecards = data.scorecardspartner
        if (scorecards !== null) {
            return {
                headers: getADebrief(filterNullHeaders(scoreCardCColumns,scorecards)),
                data: scorecards.filter(e => e.author)
            }
        }

        return null
    }
}
export default {
    traigeScoreCards,
    traigeDebriefCards
};