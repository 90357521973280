import faker from "@faker-js/faker";
import { EditableDataRow } from "../../types";
import { randomColor } from "../../views/EditableView/utils";
export type Item = {
  id: string;
  content: String;
  namefinal:string;
  websitefinal:string;
  finaldescription:string
};
export type Column = {
  id: string;
  label: string;
  color?: string;
  items: EditableDataRow[];
};

export const fakeData: Column[] = [
  {
    id: "col-0",
    label: "Actionable",
    color: randomColor(),
    items: []
  },
  {
    id: "col-1",
    label: "Lead",
    color: randomColor(),
    items: []
  },
  {
    id: "col-2",
    label: "Evaluation",
    color: randomColor(),
    items: []
  },
  {
    id: "col-3",
    label: "Diligence",
    items: []
  },
  {
    id: "col-4",
    label: "Partner Meeting",
    color: randomColor(),
    items: []
  },
  {
    id: "col-5",
    label: "Decision",
    color: randomColor(),
    items: []
  },
  {
    id: "col-6",
    label: "Legal",
    color: randomColor(),
    items: []
  },
  {
    id: "col-7",
    label: "Invested",
    color: randomColor(),
    items: []
  },
  {
    id: "col-8",
    label: "Following",
    color: randomColor(),
    items: []
  },
  {
    id: "col-9",
    label: "Passed",
    color: randomColor(),
    items: []
  },
  {
    id: "col-10",
    label: "Missed",
    color: randomColor(),
    items: []
  }
];

export interface PointPerson {
  name:string,
  avatar:string
}

export interface BrandInfo {
  brandName:string,
  brandLogo:string
}

const newBrandData = ():BrandInfo =>{
  return {
    brandLogo:faker.image.business(),
    brandName:faker.company.companyName() 
  }
}

const newPointPerson = (): PointPerson => {
  return {
    name:faker.name.firstName() + " " +faker.name.lastName(),
    avatar:faker.internet.avatar() 
  }
}

export const pointPersonData = (count?:number) => [...Array(count??30)].map(d => newPointPerson());
export const corporateData = (count?:number) => [...Array(count??30)].map(d => newBrandData());



/*{
  "draggableId": "myvel.com",
  "type": "DEFAULT",
  "source": {
      "index": 0,
      "droppableId": "lead"
  },
  "reason": "DROP",
  "mode": "FLUID",
  "destination": {
      "droppableId": "actionable",
      "index": 0
  },
  "combine": null
}*/

/*{
  "draggableId": "hubbster.io",
  "type": "DEFAULT",
  "source": {
      "index": 2,
      "droppableId": "lead"
  },
  "reason": "DROP",
  "mode": "FLUID",
  "destination": {
      "droppableId": "actionable",
      "index": 0
  },
  "combine": null
}*/