import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import AssessmentTwoToneIcon from "@mui/icons-material/AssessmentTwoTone";
import CloseFullIcon from "@mui/icons-material/CloseFullscreen";
import ExpandIcon from "@mui/icons-material/OpenInFull";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Card, Chip, CircularProgress, Divider, IconButton, Popover, Skeleton, Snackbar, Stack, Typography } from "@mui/material";
import Alert, { AlertProps } from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DataGridPro, GridColDef, GridFilterModel, GridRenderEditCellParams, GridRowModel, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, getGridNumericOperators, gridClasses, useGridApiContext } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { debounce, isEmpty, isEqual } from "lodash";
import React from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { CompanyNameCell, RenderSocialsCell } from "../../../../components";
import { withErrorBoundary } from "../../../../components/ErrorBoundary";
import { interestedWeeklyLead } from "../../../../graphql/mutations";
import { getLeadListRejectReasons, weeklyLeadsListQuery } from "../../../../graphql/queries";
import { WeeklyLead } from "../../../../types";
import { buildFinalQuery } from "../utils";
import { emptyConditionCheck } from "../../../../helpers/DataGridHelpers";
import PercentileDataRenderCell from "../../../../components/TableRenderCells/PercentileDataRenderCell";


/**
 * @description Render interesting edit cell component.
 * @param props GridRenderEditCellParams
 * @returns JSX
 */
//Edit cell component for the interesting column
const InterestingEditComponent = (props: GridRenderEditCellParams) => {
  //Query for the reject reason list.
  const { data, loading } = useQuery<{ getLeadListRejectReasons: { id: number; title: string }[] }>(gql(getLeadListRejectReasons));
  const { id, field, row } = props;

  //state for the storing anchor element for the showing popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  //state for the list of check reasons.
  const [checked, setChecked] = React.useState<{ id: number; title: string }[]>([]);

  //Data grid context api.
  const apiRef = useGridApiContext();

  // Handle close method for the popover,
  /**
   * @description this function will set the change to the state of the data grid.
   * and also stop the editing mode.
   */
  const handleClose = async () => {
    // const value: { interesting?: boolean; comment?: number[] } = {};
    let value;
    const commentIds = checked.map(item => item.id);
    const existingComments = row.comment;

    const isCommentChanged = !isEqual(existingComments, commentIds);
    if (isCommentChanged) {
      value = { interesting: false, comment: commentIds };
    } else {
      value = row.interesting;
    }
    await apiRef.current.setEditCellValue({ id, field, value });
    apiRef.current.stopCellEditMode({ id, field });
    setAnchorEl(null);
  };

  // Effect for the setting the anchor element once the component is renders.
  React.useEffect(() => {
    setAnchorEl(props.api.getCellElement(id, field));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (row.comment && data?.getLeadListRejectReasons && row.comment.length > 0) {
      const rejectReasons = [...data.getLeadListRejectReasons];
      setChecked(row.comment.map((id: number) => rejectReasons.find(reason => reason.id === id)));
    }
    return () => {
      setChecked([]);
    };
  }, [row.comment, data?.getLeadListRejectReasons]);

  // checkbox toggling
  const handleToggle = (value: { id: number; title: string }) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const checkBoxPopoverOpen = Boolean(anchorEl);
  const popoverId = checkBoxPopoverOpen ? `rejectReasonChangePopper-${id}` : undefined;

  return (
    <Popover
      id={popoverId}
      open={checkBoxPopoverOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
    >
      <Box sx={{ maxWidth: 500 }}>
        {row.comment && row.comment.length > 0 ? (
          <Typography variant="h5" color={"red"} sx={{ paddingTop: 2, paddingLeft: 2, paddingBottom: 2.25 }}>
            Rejection reasons
          </Typography>
        ) : (
          <Box sx={{ p: 2, paddingBottom: 2.25 }}>
            <Typography variant="h5">
              Mark this lead as not interesting?<sup style={{ color: "red" }}>*</sup>
            </Typography>
            <Typography variant="subtitle1">Please select at least one reason why ? </Typography>
          </Box>
        )}

        <Divider />
        {row.comment && row.comment.length > 0 ? (
          <Stack direction={"row"} spacing={1} flexWrap={"wrap"} sx={{ minWidth: 300, padding: 2 }} alignItems={"flex-start"}>
            {row.comment.map((id: number) => {
              const label = data?.getLeadListRejectReasons.find(reason => reason.id === id)?.title;
              return <Chip size="small" label={label} sx={{ marginBottom: 0.5 }} />;
            })}
          </Stack>
        ) : (
          <List sx={{ width: "100%", maxWidth: 360, maxHeight: 320, overflowY: "auto", bgcolor: "background.paper" }} dense>
            {loading &&
              [0, 1, 2, 3, 4, 5].map(val => (
                <ListItem key={`skeleton-${val}`} disablePadding dense>
                  <Skeleton sx={{ width: 300, height: 36, marginLeft: 1, marginRight: 1 }} />
                </ListItem>
              ))}
            {data &&
              data?.getLeadListRejectReasons.map(reason => {
                const labelId = `reject-reason-list-${reason.id}`;

                return (
                  <ListItem key={`reasonListItem-${reason.id}`} disablePadding dense>
                    <ListItemButton disabled={row.comment && row.comment.length > 0} role={undefined} onClick={handleToggle(reason)} dense>
                      <ListItemIcon>
                        <Checkbox edge="start" checked={checked.indexOf(reason) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={reason.title} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        )}
      </Box>
    </Popover>
  );
};


/**
 * @description Weekly Leads list table column definition list
 */
const weeklyLeadsCol: GridColDef[] = [
  {
    field: "namefinal",
    headerName: "Company Name",
    filterable: false,
    minWidth: 200,
    flex: 0.35,
    renderCell: params => <CompanyNameCell params={params} keys={{url:"websitefinal" , name:"namefinal" }}  />
  },
  {
    field: "socials",
    headerName: "Social Links",
    type: "string",
    editable: false,
    filterable: false,
    sortable: false,

    // flex: 0.15,
    minWidth: 160,
    renderCell(params) {
      return <RenderSocialsCell {...params}/>
    }
  },
  {
    field: "leaddate",
    headerName: "Date Generated",
    minWidth: 80,
    flex: 0.15,
    type: "date",
    align: "center",
    valueFormatter: params => dayjs(params.value).format("DD/MM/YYYY"),
    renderCell: params => (
      <Typography>
        <Moment format="L">{params.row.leaddate}</Moment>
      </Typography>
    )
  },
  {
    field: "totalfunding",
    headerName: "Total Raised(in $ million)",
    minWidth: 80,
    type: "number",
    align: "center",
    flex: 0.15,
    valueFormatter: params => parseInt(params.value),
    renderCell: params => <Typography>{params.row.totalfunding} </Typography>
    // valueGetter: (params: GridValueGetterParams) => `$ ${params.row.totalraised} million`
  },
  {
    field: "investors",
    headerName: "Investors",
    type: "number",
    minWidth: 150,
    sortable: false,
    filterable: false,
    flex: 0.25,
    renderCell: params => <Typography>{params.row.investors}</Typography>
  },

  {
    field: "departmentcount",
    headerName: "Headcount",
    type: "number",
    minWidth: 130,
    flex: 0.15,
    valueFormatter: params => parseInt(params.value),
    renderCell: params => <Typography> {params.formattedValue} </Typography>
  },
  {
    field: "totalwebgrowthpercentile",
    headerName: "Website growth percentile",
    type: "number",
    minWidth: 100,
    flex: 0.1,
    valueFormatter: params => parseFloat((parseFloat(params.value) * 100).toFixed(2)),
    renderCell: params => <PercentileDataRenderCell {...params} />
  },
  {
    field: "totalwebgrowthpercentileewm",
    headerName: "Website growth percentile calculated with Exponentially weighted moving average",
    type: "number",
    minWidth: 100,
    flex: 0.1,
    valueFormatter: params => parseFloat((parseFloat(params.value) * 100).toFixed(2)),
    renderCell: params => <PercentileDataRenderCell {...params} />
  },
  {
    field: "interesting",
    headerName: "Interesting?",
    type: "boolean",
    editable: true,
    width: 100,
    renderEditCell(params) {
      return <InterestingEditComponent {...params} />;
    }
  }
];

const PAGE_SIZE = 10;

const computeMutation = (newRow: GridRowModel, oldRow: GridRowModel): boolean => {
  return !isEqual(oldRow.interesting, newRow.interesting);
};

const CustomGridToolBar = ({ refetch, loading, fullView, handleToggle }: { refetch: any; loading: boolean; fullView: boolean; handleToggle: () => void }) => (
  <GridToolbarContainer>
    <Stack direction={"row"} alignItems="center" justifyContent={"space-between"} sx={{ width: "100%", marginBottom: 0.25 }}>
      <Stack direction={"row"} alignItems="center" spacing={2} sx={{ padding: "7px 8px" }} alignContent={"center"}>
        <AssessmentTwoToneIcon fontSize="large" />
        <Typography variant="h5">{"Weekly leads list"}</Typography>
        <IconButton aria-label="sync-data" onClick={() => refetch()} title="Refresh the table">
          {loading ? <CircularProgress color="inherit" size={21} /> : <SyncIcon />}
        </IconButton>
      </Stack>
      <Stack direction={"row"} spacing={1}>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <IconButton title={fullView ? "Exit full view" : "View in full space"} color="primary" size="small" onClick={handleToggle}>
          {fullView ? <CloseFullIcon fontSize="inherit" /> : <ExpandIcon fontSize="inherit" />}
        </IconButton>
      </Stack>
    </Stack>
    <Divider sx={{ width: "100%" }} />
  </GridToolbarContainer>
);

interface WeeklyLeadsProps {
  nameKey: string;
  onToggleFullView: (nameKey: string) => void;
  fullView: boolean;
}

const WeeklyLeadDataGridPro = ({ nameKey, onToggleFullView, fullView }: WeeklyLeadsProps) => {
  //lazy query used here to implement the same query in page change also.
  const [queryWeeklyLeads, { data, loading, error, refetch }] = useLazyQuery<{ weeklyLeadsListQuery: { count: number; result: WeeklyLead[] } }>(gql(weeklyLeadsListQuery));

  //mutation query for the editing the cell
  const [updateWeeklyLeads, { loading: mutationLoading }] = useMutation(gql(interestedWeeklyLead));

  // state for row count and data , this us because for the prevention when data will be undefined.
  const [rowState, setRowState] = React.useState<{ count: number; data: WeeklyLead[] }>({ data: [], count: 0 });
  // taking pagination modal as state because of the server side pagination implementation
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE
  });

  // query state for server side filter option
  const [filters, setFilters] = React.useState<GridFilterModel | null>(null);

  const [snackbar, setSnackbar] = React.useState<Pick<AlertProps, "children" | "severity"> | null>(null);

  //Sorting state
  const [sort, setSort] = React.useState<GridSortModel | null>(null);

  //modify column filter
  const columns = React.useMemo(
    () =>
      weeklyLeadsCol.map(col => {
        if (col.type !== "number") {
          return col;
        }

        return {
          ...col,
          filterOperators: getGridNumericOperators().filter(
            // operator => !["isAnyOf", "isEmpty", "isNotEmpty"].includes(operator.value)
            operator => operator.value !== "isAnyOf"
            // (operator) => operator.value === '=' || operator.value === '!=' || operator.value === ">" || operator.value === ">=" || operator.value === "<" || operator.value === "<=" || operator.value === "isEmpty" || operator.value === "isNotEmpty",
          )
        };
      }),
    []
  );

  // Define the debounced state change handler
  const filterQueryWithDebounce = debounce((filters: GridFilterModel) => {
    const { pageSize } = paginationModel;

    const vars: any = {
      limit: pageSize,
      offset: pageSize * paginationModel.page,
      filters: buildFinalQuery(filters, columns)
    };
    if (sort) vars.sort = JSON.stringify(sort);

    queryWeeklyLeads({
      variables: vars
    });
  }, 3000); // Set the debounce delay to 500 milliseconds

  // Define the debounced state change handler
  const sortingWithDebounce = debounce((sort: GridSortModel) => {
    const { pageSize } = paginationModel;

    const vars: any = {
      limit: pageSize,
      offset: pageSize * paginationModel.page,
      sort: JSON.stringify(sort)
    };
    if (filters?.items && filters.items.length > 0) vars.filters = buildFinalQuery(filters, columns);

    queryWeeklyLeads({
      variables: vars
    });
  }, 1000); // Set the debounce delay to 500 milliseconds

  /***
   * @description Handler for the filter option change.
   */
  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    // As per the discussion with backend team for these two cases value should be the name of the operator itself.
    const items = filterModel?.items
      .map(item => {
        if (item.operator === "isNotEmpty" || item.operator === "isEmpty") return { ...item, value: item.operator };
        return item;
      })
      //Removing empty value object from thee items array. so filter will occur on valid object only
      .filter(item => !isEmpty(item.value));

    const _filters = { ...filterModel, items };

    if (!emptyConditionCheck(_filters)) toast.error("Please revisit the combination of filter options.");
    // isEmpty and isNotEmpty should not be applied for the same felid.

    setFilters(_filters);
  }, []);

  //On sorting change handler
  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setSort(sortModel);
  }, []);

  //handle close Snackbar for the response of the mutation
  const handleCloseSnackbar = () => setSnackbar(null);

  /**
   * @description Process row update function.
   */

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const isMutation = computeMutation(newRow, oldRow);
      if (isMutation) {
        try {
          const { interesting, comment } = newRow.interesting;
          const response = await updateWeeklyLeads({ variables: { input: { uuid: newRow.uuid, interested: interesting, comment } } });
          setSnackbar({ children: "Record updated successfully", severity: "success" });
          return { ...response.data.interestedWeeklyLead, id: response.data.interestedWeeklyLead.uuid };
        } catch (error) {
          setSnackbar({ children: "something wrong", severity: "error" });
          return oldRow;
        }
      } else {
        return oldRow;
      }
    },
    [updateWeeklyLeads]
  );

  // Full view toggle handler
  const handleToggle = () => {
    onToggleFullView(nameKey);
  };

  //effect for the debouncing query for the filter query
  React.useEffect(() => {
    const { pageSize } = paginationModel;

    // call the filter query debounce here
    if (filters?.items && filters.items.length > 0) filterQueryWithDebounce(filters);

    //this action will occur when all filter will be  removed
    if (filters?.items.length === 0) {
      // refetching the data for the current page.
      queryWeeklyLeads({
        variables: {
          limit: pageSize,
          offset: pageSize * paginationModel.page
        }
      });
    }
    return () => {
      filterQueryWithDebounce.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  //fetch data on render || or on pagination change
  React.useEffect(() => {
    const { pageSize } = paginationModel;
    const vars: any = {
      limit: pageSize,
      offset: pageSize * paginationModel.page
    };
    if (filters) vars.filters = buildFinalQuery(filters, columns);
    if (sort) vars.sort = JSON.stringify(sort);
    queryWeeklyLeads({
      variables: vars
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  //Handle Query result.
  React.useEffect(() => {
    setRowState({
      data: data?.weeklyLeadsListQuery?.result ? data.weeklyLeadsListQuery.result.map(d => ({ ...d, id: d.uuid })) : [],
      count: data?.weeklyLeadsListQuery?.count ?? 0
    });
  }, [data]);

  //Error handlers for the query
  React.useEffect(() => {
    if (error) toast.error(error.message);
  }, [error]);

  //effect for the debouncing query for the sorting query
  React.useEffect(() => {
    // call the sorting query debounce here
    if (sort) sortingWithDebounce(sort);

    return () => {
      sortingWithDebounce.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  React.useLayoutEffect(() => {
    if (fullView) setPaginationModel(_d => ({ ..._d, pageSize: _d.pageSize === 10 ? 50 : 10 }));
  }, [fullView]);

  return (
    <Box component={Card} elevation={1}>
      <Box height={!fullView ? "calc(100vh - 96px - 78px - 64px)" : "calc(100vh - 96px)"} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        <DataGridPro
          columns={columns}
          rows={rowState.data}
          loading={loading || mutationLoading}
          pagination
          pageSizeOptions={[10, 20, 50]}
          rowCount={rowState.count}
          getRowHeight={() => "auto"}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          paginationModel={paginationModel}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          // experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={error => {
            toast.error(error.message);
          }}
          slots={{
            toolbar: CustomGridToolBar
          }}
          slotProps={{
            toolbar: { refetch, loading, fullView, handleToggle }
          }}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1.5
            }
          }}
        />
      </Box>
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
};

export default withErrorBoundary(WeeklyLeadDataGridPro);
