import { Box, Link, Typography } from "@mui/material";
import ShowMore from "../../../../components/ShowMore";
import { EmployeeData } from "../../../../types";
import ExperienceCard from "../ExperienceCard";
import React from 'react'

interface PropTypes {
  data: EmployeeData;
}

const Jobs = (props: PropTypes) => {
  const { data } = props;

  const sortedJobs = data["jobs"];
  sortedJobs.sort(function(a, b) {
    return parseInt(b.startepoch) - parseInt(a.startepoch);
  });
  return (
    <Box sx={{ width: "100%" }}>
      <Typography gutterBottom variant="h4" component="h2">
        Experience
      </Typography>
      <ShowMore
        data={sortedJobs.map(job => {
          var date = job.startdate && job.startdate !== "-" && job.enddate && job.enddate !== "-" ? " - " : "";
          if (job.startdate && job.startdate !== "-") {
            date = job.startdate + date;
          }
          if (job.enddate && job.enddate !== "-") {
            date += job.enddate;
          }
          var tags = job.companytags;
          if (job.rank >= 7) {
            tags.push("Company Size: Very Large");
          } else if (job.rank >= 5) {
            tags.push("Company Size: Large");
          } else if (job.rank >= 4) {
            tags.push("Company Size: Medium");
          }
          return (
            <ExperienceCard
              data={{
                header: job.companyrole,
                subHeader:
                  job.companylink && job.companylink !== "-" ? (
                    <Link color="inherit" href={job.companylink}>
                      {job.company}
                    </Link>
                  ) : (
                    job.company
                  ),
                subTitle1: date,
                subTitle2: job.location,
                description: job.description,
                image: job.imagelink && job.imagelink !== "-" ? job.imagelink : "/images/default-corporate-image.jpg",
                tags: job.companytags
              }}
            />
          );
        })}
        trimIndex={4}
      />
    </Box>
  );
};

export default Jobs;
