import AccountBoxIcon from "@mui/icons-material/ManageAccounts";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BallotIcon from "@mui/icons-material/Ballot";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SearchIcon from "@mui/icons-material/Search";
import TableViewIcon from "@mui/icons-material/TableView";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Divider, Drawer, styled, useTheme } from "@mui/material";
import clsx from "clsx";
import { Pages } from "../../../../types";
import { Profile, SidebarNav } from "./components";

const PREFIX = "Sidebar2";
const classes = {
  root: `${PREFIX}-root`,
  divider: `${PREFIX}-divider`,
  nav: `${PREFIX}-nav`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(1)
  },
  [`& .${classes.divider}`]: {
    margin: theme.spacing(2, 0)
  },
  [`& .${classes.nav}`]: {
    marginBottom: theme.spacing(2)
  }
}));

interface PropsType {
  open: boolean | undefined;
  variant: "persistent" | "temporary";
  onClose: () => void;
  handleSidebarOpen: () => void;
  className?: string;
  sidebarWidth: number;
}

const Sidebar = (props: PropsType) => {
  const { open, variant, onClose, className, handleSidebarOpen, sidebarWidth } = props;
  const theme = useTheme();

  const pages: Pages[] = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />
    },
    {
      title: "Search",
      href: "/search",
      icon: <SearchIcon />
    },

    {
      title: "Investment Forms",
      href: "/investmentforms",
      icon: <FormatListBulletedIcon />
    },
    {
      title: "Weekly Leads",
      href: "/leads",
      icon: <AssessmentIcon />
    },

    {
      title: "Companies List",
      href: "./companies-list",
      icon: <TableViewIcon />
    },
    // {
    //   title: "Editable View",
    //   href: "/editable-view",
    //   icon: <TableViewIcon />
    // },
    {
      title: "Kanban View",
      href: "kanban-view",
      icon: <BallotIcon />
    },
    {
      title: "Manage data source",
      href: "/data-management",
      icon: <CloudUploadIcon />
    },
    {
      title: "Company Analytics",
      href: "/company-analytics",
      icon: <BarChartIcon />
    },
    {
      title: "Account Settings",
      href: "/account",
      icon: <AccountBoxIcon />
    }
  ];
  return (
    <Drawer
      anchor="left"
      PaperProps={{
        sx: {
          width: `${sidebarWidth}px`,
          [theme.breakpoints.up("lg")]: {
            marginTop: 8,
            height: "calc(100% - 64px)"
          }
        }
      }}
      onClose={onClose}
      open={open}
      variant={variant}
      elevation={1}
    >
      <Root className={clsx(classes.root, className)}>
        <div style={{ marginTop: "50px" }}>
          <Profile closeDrawer={() => handleSidebarOpen()} />
        </div>
        <Divider className={classes.divider} />

        <SidebarNav className={classes.nav} pages={pages} closeDrawer={() => handleSidebarOpen()} />
      </Root>
    </Drawer>
  );
};

export default Sidebar;
