import { TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import React from "react";

const CeoEditCell = (params: GridRenderEditCellParams) => {
  const { id, field } = params;
  const apiRef = useGridApiContext();

  const [ceo, setCeoData] = React.useState<{
    name: string;
    linkedinUrl: string;
  }>({
    name: "",
    linkedinUrl: ""
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    setCeoData(_ceo => ({
      ..._ceo,
      [name]: value
    }));
  };

  const saveValueToRow = () => {
    apiRef.current.setEditCellValue({ id, field, value: ceo });
  };

  // Effect for the setting the anchor element once the component is renders.
  React.useEffect(() => {
    if (params.value) setCeoData(params.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={1} sx={{ background: "white", width: "100%", paddingTop: 0.25 }}>
      <TextField onBlur={saveValueToRow} label="CEO Name" tabIndex={1} name="name" value={ceo.name} onChange={handleChange} size="small" variant="outlined" />
      <TextField onBlur={saveValueToRow} label="Linkedin Url" tabIndex={1} name="linkedinUrl" value={ceo.linkedinUrl} onChange={handleChange} size="small" variant="outlined" />
    </Stack>
  );
};

export default CeoEditCell;
