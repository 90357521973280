import { Done as DoneIcon } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Blocks from "editorjs-blocks-react-renderer";
import moment from "moment";
import React, { useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../../../EditableView/NotesCell/constants";

type Notes = { id: string; title: string; data: any };

interface NotesEditorProps {
  onChange?: (notes: Notes) => void;
}

interface NotesPreviewProps {
  notes: Notes[];
  addNewNotesHandler?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReactEditorJS = createReactEditorJS();

export const NotesEditor = ({ onChange }: NotesEditorProps) => {
  const [ntitle, setTitle] = useState("");
  const editorCore = React.useRef(null);

  /**
   * @description This is Notes editor initializer function. will store data in state.
   */
  const handleInitialize = React.useCallback((instance: any) => {
    editorCore.current = instance;
  }, []);

  /***
   *@description Onclick handler for saving data.
   *
   */
  const handleSaveData = async () => {
    // @ts-ignore
    const savedData = await editorCore.current?.save();
    const notesData: Notes = {
      id: moment()
        .unix()
        .toString(),
      title: ntitle,
      data: savedData
    };

    // console.log(notesData);
    if (onChange) onChange(notesData);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "220px",
          "& .inputTitle": {
            outline: "none",
            "&::placeholder": {
              color: "grey",
              fontSize: "14px",
              fontWeight: "bold"
            }
          }
        }}
      >
        <input placeholder="Add a title" className={"inputTitle"} onChange={e => setTitle(e.target.value)} />
        <Button startIcon={<DoneIcon />} size="small" onClick={() => handleSaveData()}>
          Save
        </Button>
      </Box>
      <ReactEditorJS onInitialize={handleInitialize} tools={EDITOR_JS_TOOLS} placeholder="Add notes content" />
    </>
  );
};

export const PreviewNotes = ({ notes, addNewNotesHandler }: NotesPreviewProps) => {
  return (
    <div style={{ height: "350px", overflowY: "auto", padding: "10px" }}>
      <div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "12px" }}>
        <Typography sx={{ display: "flex", justifyContent: "space-between", width: "220px" }}>Notes</Typography>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            if (addNewNotesHandler) addNewNotesHandler(true);
          }}
        >
          Add New
        </Button>
      </div>

      <div>
        {notes.map((item, index) => {
          return (
            <Accordion key={item.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Title:{item.title}</Typography>
              </AccordionSummary>
              <NotesDetailsBox>
                <Blocks data={item.data} />
              </NotesDetailsBox>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

const NotesDetailsBox = styled(Box)`
  width: 100%;
  flex-grow: 1;
  padding: 15px;
  padding-left: 30px;
  overflow-y: auto;
  & > * {
    margin-bottom: 15px;
    letter-spacing: 0.5px;
  }
  ul,
  ol {
    padding-left: 25px;
  }
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
