import NoteIcon from "@mui/icons-material/Notes";
import { Badge, IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import AddCommentIcon from "@mui/icons-material/AddComment";

interface NotesRenderCellProps {
  params: GridRenderCellParams;
  onNotesClick: (rowModal: any) => void;
}

const NotesRenderCell = ({ params, onNotesClick }: NotesRenderCellProps) => {
  const row = params.row;
  if (params.value)
    return (
      <IconButton title="Manage user notes" onClick={() => onNotesClick(row)}>
        <Badge color="secondary" badgeContent={params.value.length} max={10}>
          <NoteIcon />
        </Badge>
      </IconButton>
    );
  return (
    <IconButton title="Manage user notes" onClick={() => onNotesClick(row)}>
      <AddCommentIcon />
    </IconButton>
  );
};

export default NotesRenderCell;
