/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://645ufhitirbfxcp4kbke7ikkku.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:3158e579-22bb-4d8f-9f40-0f174992aa41",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_pStAbrqYq",
    "aws_user_pools_web_client_id": "65dq1e5pbahfafne2q9u3ru7kt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sourcingv2webb711330d186443c7b9e43045caf0c896182230-juliana",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
