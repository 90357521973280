import { useQuery , gql } from "@apollo/client";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TabPanel } from "../../components";

import { leadsListQuery } from "../../graphql/queries";
import React from "react";
import { LeadsList } from "./components";

const PREFIX = "TabBar";

const classes = {
  root: `${PREFIX}-root`,
  tabBarRoot: `${PREFIX}-tabBarRoot`,
  center: `${PREFIX}-center`,
  tabs: `${PREFIX}-tabs`,
  tabBody: `${PREFIX}-tabBody`,
  maxWidth: `${PREFIX}-maxWidth`,
  paperRoot: `${PREFIX}-paperRoot`,
  mainHeading: `${PREFIX}-mainHeading`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
    padding: theme.spacing(3)
  },

  [`& .${classes.tabBarRoot}`]: {
    flexGrow: 1,
    display: "flex"
  },

  [`& .${classes.center}`]: {
    textAlign: "center"
  },

  [`& .${classes.tabs}`]: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "350px",
    backgroundColor: theme.palette.background.paper,
    height: "fit-content",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    marginTop: "40px"
  },

  [`& .${classes.tabBody}`]: {
    width: "100%"
  },

  [`& .${classes.maxWidth}`]: {
    maxWidth: "100%"
  },

  [`& .${classes.paperRoot}`]: {
    height: "fit-content"
  },

  [`& .${classes.mainHeading}`]: {
    textAlign: "center",
    width: "100%",
    minWidth: 650,
    paddingBottom: "4px"
  }
}));

const TabBar = (props: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  const { loading, data } = useQuery(gql(leadsListQuery));

  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={10} lg={8}></Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.tabBarRoot}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" aria-label="icon tabs example" orientation="vertical" variant="scrollable" className={classes.tabs}>
              <Tab className={classes.maxWidth} icon={<FavoriteIcon />} label="In Network Leads" />
              <Tab className={classes.maxWidth} icon={<SearchIcon />} label="Out of Network Leads" />
            </Tabs>
            <TabPanel value={value} className={classes.tabBody} index={0}>
              <LeadsList type={"InNetwork"} data={data ? data.LeadsListQuery.innetwork : []} loading={loading} />
            </TabPanel>
            <TabPanel value={value} className={classes.tabBody} index={1}>
              <LeadsList type={"OutNetwork"} data={data ? data.LeadsListQuery.outnetwork : []} loading={loading} />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </Root>
  );
};

export default TabBar;
