/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPortfolio = /* GraphQL */ `
  mutation CreatePortfolio($input: CreatePortfolioInput!) {
    createPortfolio(input: $input) {
      success
      message
      data {
        companyname
        initialinvestment
        cost
        fmv
        lastround
        lastrounddate
        coinvestors {
          investor
          dealid
          __typename
        }
        ceo {
          name
          linkedinUrl
          __typename
        }
        companyurl
        uuid
        __typename
      }
      __typename
    }
  }
`;
export const updatePortfolio = /* GraphQL */ `
  mutation UpdatePortfolio($input: UpdatePortfolioInput!) {
    updatePortfolio(input: $input) {
      success
      message
      data {
        companyname
        initialinvestment
        cost
        fmv
        lastround
        lastrounddate
        coinvestors {
          investor
          dealid
          __typename
        }
        ceo {
          name
          linkedinUrl
          __typename
        }
        companyurl
        uuid
        __typename
      }
      __typename
    }
  }
`;
export const interestedWeeklyLead = /* GraphQL */ `
  mutation InterestedWeeklyLead($input: MarkInterested) {
    interestedWeeklyLead(input: $input) {
      uuid
      totalfunding
      departmentcount
      totalwebgrowthpercentile
      totalwebgrowthpercentileewm
      cihcgpercentile
      totalreviewpercentile
      namefinal
      leaddate
      uniqueid
      websitefinal
      seriesmerged
      interesting
      comment
      socials {
        LINKEDIN {
          url
          follower_count
          username
          status
        }
        TWITTER {
          url
          follower_count
          username
          status
        }
        CRUNCHBASE {
          url
          follower_count
          username
          status
        }
        PITCHBOOK {
          url
          follower_count
          username
          status
        }
        FACEBOOK {
          url
          follower_count
          username
          status
        }
        ANGELLIST {
          url
          follower_count
          username
          status
        }
        __typename
      }
      lastVisitedAt
      __typename
    }
  }
`;
export const userActivityLog = /* GraphQL */ `
  mutation UserActivityLog($input: UserActivityLogInput) {
    userActivityLog(input: $input) {
      success
      error
      data {
        id
        userId
        activityType
        params
        activityTime
        __typename
      }
      __typename
    }
  }
`;
export const uploadCompanyData = /* GraphQL */ `
  mutation UploadCompanyData($input: CompanyDataInput) {
    uploadCompanyData(input: $input) {
      success
      message
      __typename
    }
  }
`;
export const createCompaniesUserView = /* GraphQL */ `
  mutation CreateCompaniesUserView($input: CompaniesUserViewInput!) {
    createCompaniesUserView(input: $input) {
      id
      userId
      name
      description
      viewType
      viewData
      createdAt
      updatedAt
      lastViewedAt
      __typename
    }
  }
`;
export const updateCompaniesUserView = /* GraphQL */ `
  mutation UpdateCompaniesUserView($input: CompaniesUserViewInput!) {
    updateCompaniesUserView(input: $input) {
      id
      userId
      name
      description
      viewType
      viewData
      createdAt
      updatedAt
      lastViewedAt
      __typename
    }
  }
`;
export const deleteCompaniesUserView = /* GraphQL */ `
  mutation DeleteCompaniesUserView($id: ID!) {
    deleteCompaniesUserView(id: $id) {
      id
      userId
      name
      description
      viewType
      viewData
      createdAt
      updatedAt
      lastViewedAt
      __typename
    }
  }
`;
export const createFunnelStage = /* GraphQL */ `
  mutation CreateFunnelStage($input: FunnelStageInput!) {
    createFunnelStage(input: $input) {
      id
      name
      description
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFunnelStage = /* GraphQL */ `
  mutation UpdateFunnelStage($input: FunnelStageInput!) {
    updateFunnelStage(input: $input) {
      id
      name
      description
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFunnelStageOrder = /* GraphQL */ `
  mutation UpdateFunnelStageOrder($input: FunnelStageOrderInput!) {
    updateFunnelStageOrder(input: $input) {
      id
      name
      description
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFunnelStage = /* GraphQL */ `
  mutation DeleteFunnelStage($id: ID!) {
    deleteFunnelStage(id: $id) {
      id
      name
      description
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyFunnelStage = /* GraphQL */ `
  mutation UpdateCompanyFunnelStage($input: CompanyFunnelStage!) {
    updateCompanyFunnelStage(input: $input) {
      uuid
      company_name
      website
      seriesmerged
      growthpercentile3evm
      growthpercentile6evm
      growthpercentile12evm
      cihcgpercentile
      reviewgrowthpercentile6
      reviewgrowthpercentile3
      headcount
      company_location {
        address_formatted
        location
        street
        city
        state
        zip
        country
        __typename
      }
      total_funding
      last_funding_date
      founding_date
      socials {
        __typename
      }
      funnel_stage
      total_notes
      created_at
      updated_at
      __typename
    }
  }
`;
export const manageCompanyNote = /* GraphQL */ `
  mutation ManageCompanyNote($input: CompanyNoteInput!) {
    manageCompanyNote(input: $input) {
      id
      userId
      uuid
      title
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyFavorite = /* GraphQL */ `
  mutation CreateCompanyFavorite(
    $input: CreateCompanyFavoriteInput!
    $condition: ModelCompanyFavoriteConditionInput
  ) {
    createCompanyFavorite(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompanyFavorite = /* GraphQL */ `
  mutation UpdateCompanyFavorite(
    $input: UpdateCompanyFavoriteInput!
    $condition: ModelCompanyFavoriteConditionInput
  ) {
    updateCompanyFavorite(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompanyFavorite = /* GraphQL */ `
  mutation DeleteCompanyFavorite(
    $input: DeleteCompanyFavoriteInput!
    $condition: ModelCompanyFavoriteConditionInput
  ) {
    deleteCompanyFavorite(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSavedSearch = /* GraphQL */ `
  mutation CreateSavedSearch(
    $input: CreateSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    createSavedSearch(input: $input, condition: $condition) {
      id
      name
      searchJSON
      lastViewed
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSavedSearch = /* GraphQL */ `
  mutation UpdateSavedSearch(
    $input: UpdateSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    updateSavedSearch(input: $input, condition: $condition) {
      id
      name
      searchJSON
      lastViewed
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSavedSearch = /* GraphQL */ `
  mutation DeleteSavedSearch(
    $input: DeleteSavedSearchInput!
    $condition: ModelSavedSearchConditionInput
  ) {
    deleteSavedSearch(input: $input, condition: $condition) {
      id
      name
      searchJSON
      lastViewed
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWorkflow = /* GraphQL */ `
  mutation CreateWorkflow(
    $input: CreateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    createWorkflow(input: $input, condition: $condition) {
      id
      name
      chartJSON
      creationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWorkflow = /* GraphQL */ `
  mutation UpdateWorkflow(
    $input: UpdateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    updateWorkflow(input: $input, condition: $condition) {
      id
      name
      chartJSON
      creationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWorkflow = /* GraphQL */ `
  mutation DeleteWorkflow(
    $input: DeleteWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    deleteWorkflow(input: $input, condition: $condition) {
      id
      name
      chartJSON
      creationDate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
