import { Box, Link, Typography } from "@mui/material";
import React from "react";
import ShowMore from "../../../../components/ShowMore";
import ExperienceCard from "../ExperienceCard";

const Education = props => {
  const { data } = props;

  const sortedEds = data["ed"];
  sortedEds.sort(function(a, b) {
    return parseInt(b.startepoch) - parseInt(a.startepoch);
  });
  return (
    <Box sx={{ width: "100%" }}>
      <Typography gutterBottom gutterTop variant="h4" component="h2">
        Education1
      </Typography>
      <ShowMore
        data={sortedEds.map(ed => {
          var date = "";
          if (ed.startdate) {
            date = ed.startdate;
          }
          if (ed.enddate && (ed.enddate !== "Present" || date !== "")) {
            if (date !== "") {

              
              date += " - ";
            }
            date += ed.enddate;
          }

          var subHeader = "";
          if (ed.schooldegree) {
            subHeader = ed.schooldegree;
          }
          if (ed.subject) {
            if (subHeader !== "") {
              subHeader += " ";
            }
            subHeader += ed.subject;
          }
          return (
            <ExperienceCard
              data={{
                header:
                  ed.schoollink && ed.schoollink !== "-" ? (
                    <Link color="inherit" href={ed.schoollink}>
                      {ed.school}
                    </Link>
                  ) : (
                    ed.school
                  ),
                subHeader: subHeader,
                subTitle1: date,
                image: ed.imagelink && ed.imagelink !== "-" ? ed.imagelink : "/images/default-corporate-image.jpg",
                tags: ed.schooltags
              }}
            />
          );
        })}
        trimIndex={4}
      />
    </Box>
  );
};

export default Education;
