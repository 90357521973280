import React from "react";

interface Props {
  className?: string;
  height:number;
}

const PitchBook = (props: Props) => {
  return (
    <svg fill="none" viewBox="42.153 -3.166 31.668 30.873" xmlns="http://www.w3.org/2000/svg" height={props.height}>
      <defs>
        <clipPath id="clip0_815_128">
          <rect height="30" width="196.667" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_815_128)" transform="matrix(1, 0, 0, 1, 43.025501, -2.402439)">
        <path d="M22.2507 0C4.91619 1.41042 -2.2415 15.7751 0.609525 24.8062C2.28081 22.23 5.39189 19.425 11.7536 18.3863C11.3857 10.7783 14.1384 5.47014 22.2507 0Z" fill="#1D5080" />
        <path
          d="M13.3637 19.4789C8.98413 20.1968 5.93648 21.3848 4.02734 23.186C4.0852 25.5935 4.77423 27.9434 6.02528 29.9998C7.50629 26.3817 12.3679 23.8213 19.7571 23.8022C17.959 15.1301 21.9771 7.18535 28.2246 1.85181C25.1324 2.30981 22.103 3.12199 19.1958 4.27239C14.4388 8.85625 12.666 14.7648 13.3637 19.4789Z"
          fill="#1D5080"
        />
      </g>
    </svg>
  );
};

export default PitchBook;
