import { Box } from "@mui/material";
import { ShowMore } from "../../../../components";
import React from 'react';
import { EmployeeData } from "../../../../types";
import ExpandingSection from '../ExpandingSection';
import ExperienceCard from '../ExperienceCard';


interface PropTypes {
    data:EmployeeData
}

const Awards = (props:PropTypes) => {
    const { data } = props;
    const awardsData =  <ShowMore data=
    {data["awards"].map(award => {

        var description = award.description1 && award.description1 !== "-" && award.description2 && award.description2 !== "-" ? " \n " : ""
        if (award.description1 && award.description1 !== "-") {
            description = award.description1 + description
        }
        if (award.description2 && award.description2 !== "-") {
            description += award.description2
        }
        return (
            <ExperienceCard data={{
                header: award.award,
                description: description 
            }} />)
    })} trimIndex={3}/>
    return (
        <Box sx={{width:"100%"}}>
            <ExpandingSection title="Awards" itemIndex={"Award"} data={data["awards"]} fullBody={awardsData} />
        </Box>
    );

};

export default Awards;
