import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { DPbaseProps, HarmonicData } from "../../../../types";
import { CrunchBaseIcon, PitchBookIcon } from "../../../../assets";
import { withErrorBoundary } from "../../../../components/ErrorBoundary";

interface CompanyOverviewShortTableProps extends DPbaseProps {
  data: HarmonicData;
}

const CompanyOverviewShortTable = ({ data, loading }: CompanyOverviewShortTableProps) => {
  return (
    <Box>
      <OverviewTable>
        {loading ? (
          <tbody>
            {[...new Array(6)].map((d, i) => (
              <tr key={i}>
                <td>
                  <Skeleton variant="rounded" sx={{ width: "110px" }} />
                </td>
                <td>
                  <Skeleton variant="rounded" sx={{ width: "60px" }} />
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>Last Series</td>
              <td>{data?.funding?.last_funding_type ?? "-NA-"}</td>
            </tr>
            <tr>
              <td>Total Funding</td>
              <td>$ {(parseInt(data?.funding?.funding_total) / 1e6).toFixed(4)} million</td>
            </tr>

            <tr>
              <td>Founding Year</td>
              <td>{2018}</td>
            </tr>
            <tr>
              <td>Company signal</td>
              <td>{"coming soon..."}</td>
            </tr>
            <tr>
              <td>Employee Count</td>
              <td>{data?.headcount ?? "-NA-"}</td>
            </tr>
            <tr>
              <td>Last Fundraising Date</td>
              <td>{data?.funding?.last_funding_at ? moment(data?.funding?.last_funding_at).format("L") : "-NA-"}</td>
            </tr>
            <tr>
              <td>Social Links</td>
              <td>
                <Stack spacing={0.75} direction="row" justifyContent={"flex-end"}>
                  {/* <LinkedInIcon /> <FacebookIcon /> <TwitterIcon /> */}
                  {data?.socials?.LINKEDIN?.url ? (
                    <a target={"_blank"} href={data?.socials?.LINKEDIN?.url} rel="noreferrer">
                      <LinkedInIcon sx={{ height: "24px" }} />
                    </a>
                  ) : null}
                  {data?.socials?.FACEBOOK?.url ? (
                    <a target={"_blank"} href={data?.socials?.FACEBOOK?.url} rel="noreferrer">
                      <FacebookIcon sx={{ height: "24px" }} />
                    </a>
                  ) : null}
                  {data?.socials?.TWITTER?.url ? (
                    <a target={"_blank"} href={data?.socials?.TWITTER?.url} rel="noreferrer">
                      <TwitterIcon sx={{ height: "24px" }} />
                    </a>
                  ) : null}
                  {data?.socials?.PITCHBOOK?.url ? (
                    <a target={"_blank"} href={data?.socials?.PITCHBOOK?.url} rel="noreferrer">
                      <PitchBookIcon height={24} />
                    </a>
                  ) : null}
                  {data?.socials?.CRUNCHBASE?.url ? (
                    <a target={"_blank"} href={data?.socials?.CRUNCHBASE?.url} rel="noreferrer">
                      <CrunchBaseIcon height={24} />
                    </a>
                  ) : null}
                </Stack>
              </td>
            </tr>
          </tbody>
        )}
      </OverviewTable>
    </Box>
  );
};

const OverviewTable = styled.table`
  border: none;
  border-collapse: collapse;
  width: 100%;
  tr {
    td {
      padding: 8px 0;
      &:first-child {
        font-weight: 500;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
`;

export default withErrorBoundary(CompanyOverviewShortTable);
