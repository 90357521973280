import { TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";

const CompanyNameEditCell = (params: GridRenderEditCellParams) => {
  const { id, row , field  } = params;
  // const apiRef = useGridApiContext();

  const [companyname, setCompanyName] = React.useState<string>("");

  const [companyurl, setCompanyUrl] = React.useState<string>("");

  //Data grid context api.
  const apiRef = useGridApiContext();

  const setValueToRow = (event: React.FocusEvent<HTMLInputElement>) => {
    apiRef.current.setEditCellValue({ id, field, value:{companyname,companyurl } });
  };

  // Effect for the setting the anchor element once the component is renders.
  React.useEffect(() => {
    unstable_batchedUpdates(() => {
      setCompanyName(params.value);
      setCompanyUrl(row.companyurl);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={1} sx={{ background: "white", width: "100%", paddingTop: 0.25 }}>
      <TextField onBlur={setValueToRow}  label="Company Name" tabIndex={1} name="companyname" value={companyname} onChange={e => setCompanyName(e.target.value)} size="small" variant="outlined"  disabled={!row.isNew}/>
      <TextField onBlur={setValueToRow} label="Company Url" tabIndex={1} name="companyurl" value={companyurl} onChange={e => setCompanyUrl(e.target.value)} size="small" variant="outlined" />
    </Stack>
  );
};

export default CompanyNameEditCell;
