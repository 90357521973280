import clsx from "clsx";
import { forwardRef } from "react";
import { NavLinkProps, NavLink as RouterLink } from "react-router-dom";
import React from 'react'
import { Button, List, ListItem, Tooltip, Typography, colors, styled } from "@mui/material";
import { Pages } from "../../../../../../types";

interface PropsType<Pages> {
  pages: Pages[];
  className?: string;
}

const PREFIX = "SidebarNav-ListItem";
const classes = {
  item: `${PREFIX}-item`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  textColor: `${PREFIX}-textColor`,
  active: `${PREFIX}-active`
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${classes.item}`]: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "center"
  },
  [`& .${classes.button}`]: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: "0px"
  },
  [`& .${classes.icon}`]: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  [`& .${classes.textColor}`]: {
    color: "white"
  },
  [`& .${classes.active}`]: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef<HTMLDivElement, NavLinkProps>((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props: PropsType<Pages>) => {
  const { pages, className } = props;

  return (
    <List className={clsx(className)}>
      {pages.map(page => (
        <StyledListItem className={classes.item} disableGutters key={page.title}>
          <Tooltip
            title={
              <Typography variant="body1" className={classes.textColor}>
                {page.title}
              </Typography>
            }
            aria-label="add"
            placement="right"
          >
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
            </Button>
          </Tooltip>
        </StyledListItem>
      ))}
    </List>
  );
};

export default SidebarNav;
