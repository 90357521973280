import React from "react";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { LAST_ROUND_OPTIONS } from "../../../../helpers/DataGridHelpers";

const CompanyLastRoundEditCell = (params: GridRenderEditCellParams) => {
  const { id, field } = params;
  const [lastround, setLlastround] = React.useState({
    value: "",
    error: false
  });

  //Data grid context api.
  const apiRef = useGridApiContext();

  const handleChange = (e: SelectChangeEvent) => {
    setLlastround(_oldval => ({ ..._oldval, value: e.target.value }));
  };
  const setValueToRow = () => {
    apiRef.current.setEditCellValue({ id, field, value: lastround.value.trim() });
  };

  React.useEffect(() => {
    if (params.value) setLlastround(_oldVal => ({ ..._oldVal, value: params.value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl fullWidth required error={lastround.error}>
      <InputLabel id="last-round-menu-select-menu">Last Round</InputLabel>
      <Select labelId="last-round-menu-select-menu" id="demo-simple-select" value={lastround.value} label="Last Round" name="lastround" onBlur={setValueToRow} onChange={handleChange}>
        {LAST_ROUND_OPTIONS.map((item, index) => (
          <MenuItem value={item} key={`last-round-menu-select-options-${index + 1}`}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {/* <FormHelperText>{data.lastround.errorMessage}</FormHelperText> */}
    </FormControl>
  );
};

export default CompanyLastRoundEditCell;
