import { gql, useQuery } from "@apollo/client";
import { Divider, Paper, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { searchInvestor } from "../../../../graphql/queries";
import SideBarData from "./SideBarData";
import { SearchBlock } from "./components";

type SearchProps = {
  searchData: any;
  setSearchData: any;
  onChange: Function;
};


const SearchSideBar = (props: SearchProps) => {
  const theme = useTheme();
  const { searchData, onChange } = props;
  const [investorString, setInvestorString] = useState("");
  const [leadInvestorString, setLeadInvestorString] = useState("");
  const { data: iData } = useQuery(gql(searchInvestor), {
    variables: {
      InvestorName: investorString
    }
  });

  // console.log("searchsideBarProps" , props)

  const { data: leadIData } = useQuery(gql(searchInvestor), {
    variables: {
      InvestorName: leadInvestorString
    }
  });
  const sideBarItems = JSON.parse(JSON.stringify(SideBarData.columns_basic));
  sideBarItems.push(
    {
      id: "LeadInvestorName",
      label: "Lead Investors",
      data: (leadIData?.searchInvestor?.investorNames || []).map((elem: any) => {
        return elem.investor;
      }),
      type: "MULTI",
      isMultiple: true,
      autoCompleteValue: leadInvestorString,
      onAutoCompleteChange: setLeadInvestorString
    },
    {
      id: "InvestorName",
      label: "Investors",
      data: (iData?.searchInvestor?.investorNames || []).map((elem: any) => {
        return elem.investor;
      }),
      type: "MULTI",
      isMultiple: true,
      autoCompleteValue: investorString,
      onAutoCompleteChange: setInvestorString
    }
  );

  return (
    <Paper
      sx={{
        padding: theme.spacing(1),
        [theme.breakpoints.between("md", "xl")]: {
          position: "sticky",
          top: 80
        }
      }}
    >
      <Typography variant="h5" component="h5">
        Filter Search
      </Typography>
      <Divider />
      <SearchBlock onChange={onChange} isOpen={true} items={sideBarItems} values={searchData} />
    </Paper>
  );
};

export default SearchSideBar;
