import React from 'react'
import {GridRenderCellParams} from "@mui/x-data-grid-pro"
import {Box, Chip} from "@mui/material"
import { Investors } from '../../../../types'

const CoInvestorsRenderCell = (params:GridRenderCellParams) => {
  return(
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        maxHeight: "100px",
        overflowY: "auto"
      }}
    >
      { params.value && params.value.map((item: Investors, index: number) => <Chip size="small" key={`co-inves${index}`} sx={{ margin: "2.5px 2.5px 2.5px 0" }} label={item.investor} variant="outlined" />)}
    </Box>
  )
}

export default CoInvestorsRenderCell