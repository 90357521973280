import { Box, Link } from "@mui/material";
import ShowMore from "../../../../components/ShowMore";
import { EmployeeData } from "../../../../types";
import ExpandingSection from "../ExpandingSection";
import ExperienceCard from "../ExperienceCard";
import React from 'react'


interface PropTypes {
  className?: string;
  data: EmployeeData;
}

const Patents = (props: PropTypes) => {
  const { data } = props;

  const patentData = (
    <ShowMore
      data={data["patents"].map(patent => {
        return (
          <ExperienceCard
            data={{
              header:
                patent.patentlink && patent.patentlink !== "-" ? (
                  <Link color="inherit" href={patent.patentlink}>
                    {patent.title}
                  </Link>
                ) : (
                  patent.title
                ),
              subTitle1: patent.filingdate,
              subTitle2: patent.patentnum,
              description: patent.description
              //link: patent.patentlink,
            }}
          />
        );
      })}
      trimIndex={3}
    />
  );
  return (
    <Box sx={{ width: "100%" }}>
      <ExpandingSection title="Patents" itemIndex={"title"} data={data["patents"]} fullBody={patentData} />
    </Box>
  );
};

export default Patents;
