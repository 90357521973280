  const transformSearchData = (data) => {
    var outData = {}

    outData["Offset"] = (parseInt(data.pageNum) - 1) * 25
    outData["Verticals"] = data["Verticals"]

    outData["NameContains"] = data["NameContains"] ? data["NameContains"].trim() : ""
    outData["InvestorName"] = data["InvestorName"]
    outData["LeadInvestorName"] = data["LeadInvestorName"]
    outData["NextSeries"] = data["NextSeries"] ? data["NextSeries"].trim() : ""

    return outData
  }
  const defaultInput = {
    NameContains: "",
    pageNum: 1,
    Verticals: [],
    LeadInvestorName: [],
    InvestorName: [],
    NextSeries:""
  }

  function getDefaultInput(){
      return JSON.parse(JSON.stringify(defaultInput))
  }
  //eslint-disable-next-line
  export default {
    transformSearchData,
    getDefaultInput
    
  };