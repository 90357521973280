import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Accordion as ExpansionPanel, AccordionDetails as ExpansionPanelDetails, AccordionSummary as ExpansionPanelSummary, Grid, Typography, styled } from '@mui/material';
import SearchToolBarItems from '../SearchToolBarItems';

const PREFIX = "SearchToolbar";
const classes = {
  root: `${PREFIX}-root`,
  secondaryHeading: `${PREFIX}-headingText`,
  details: `${PREFIX}-details`,
  column: `${PREFIX}-column`,
  link: `${PREFIX}-link`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },
  [`& .${classes.secondaryHeading}`] : {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  [`& .${classes.details}`]: {
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classes.column}`]: {
    flexBasis: '33.33%',
  },
  [`& .${classes.helper}`] : {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  [`& .${classes.link}`] : {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const SearchToolbar = props => {
  const { onSubmit, ...rest } = props;

  return (
    <Root className={classes.root}>
      <ExpansionPanel >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Grid container   
          alignItems="center"
          justify="center"
          spacing={3}>
          <Grid item xs={4}>
          <div className={classes.column}>
          <Typography className={classes.secondaryHeading}>Select Search Parameters</Typography>
          </div>
          </Grid>
          <Grid item xs={6}>
          <div className={classes.column}/>
          
          </Grid>
          <Grid item xs={2}>

          <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick = {onSubmit} /** es6 **/
        endIcon={<SearchIcon/>}>
          Search
        </Button>
          </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
        <SearchToolBarItems 
        {...rest}
        />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Root>
  );
}

export default SearchToolbar