import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { UserContext } from "../../../../../../components";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { Link as RouterLink } from "react-router-dom";



interface PropsType {
  className?: string;
  closeDrawer: () => void;
}

const Profile = (props: PropsType) => {
  const {closeDrawer } = props;
  const theme = useTheme();

  const { values, avatar } = React.useContext<any>(UserContext);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", minHeight: "fit-content" }}>
      <Avatar
        alt="Person"
        sx={{
          width: 60,
          height: 60
        }}
        component={RouterLink}
        src={avatar ?? ""}
        to="/account"
        onClick={closeDrawer}
      />

      <Typography
        sx={{
          marginTop: theme.spacing(1)
        }}
        variant="body1"
        gutterBottom
      >
        {!isEmpty(values.firstName) ? values?.firstName + " " + values?.lastName : ""}
      </Typography>
      <Typography variant="body2">{values.title}</Typography>
    </Box>
  );
};

export default Profile;
