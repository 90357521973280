import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Divider, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { GridApi, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { isEqual } from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CompaniesUserView, ViewData } from "../../types";
import { DEFAULT_VIEW_ID } from "./utils";

interface CustomGridToolBarPro {
  apiRef: React.MutableRefObject<GridApi>;
  setAddViewOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  viewList?: CompaniesUserView<ViewData>[];
  updateViewHandler?: (
    viewId: string,
    name: string
  ) => Promise<
    | false
    | {
        createCompaniesUserView: CompaniesUserView<string>;
      }
    | null
    | undefined
  >;
}

const getView = (type: "local" | "session", view: string = "defaultView") => {
  const state = (type === "session" ? sessionStorage : localStorage).getItem(view);
  if (state && state !== "undefined") return JSON.parse(state);
  else return {};
};

const CustomGridToolBar = ({ apiRef, setAddViewOpen: setNewViewFormOpen, viewList, updateViewHandler }: CustomGridToolBarPro) => {
  /** Get query params from the routes */
  const { id } = useParams<{ id: string }>();

  /** State for menu list anchor store */
  const [viewListAnchorEl, setViewsListAnchorEl] = React.useState<null | HTMLElement>(null);

  /** react-router-dom  navigation api */
  const navigate = useNavigate();
  const currentState = apiRef?.current?.exportState();

  const menuListOpen = Boolean(viewListAnchorEl); // Boolean handler for the menu opener
  const currentView = viewList?.find(v => v.id === id) ?? viewList?.find(v => v.id === DEFAULT_VIEW_ID); // current loaded view object

  /** Export state handler */
  const createNewViewHandler = () => {
    // localStorage.setItem("defaultView", JSON.stringify(currentState));
    if (setNewViewFormOpen) setNewViewFormOpen(true);
  };

  const recordChange = (event: any) => {
    console.log("Density Change", event.target.value);
  };

  /** See All menu list click handler */
  const handleSeeAllViewList = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setViewsListAnchorEl(event.currentTarget);
  };

  /** get the view object from id */
  const getViewForId = React.useCallback(
    (id?: string) => {
      if (!id) return {};
      return viewList?.find(v => v.id === id)?.viewData.state;
    },
    [viewList]
  );

  /** Update current view handler */
  const updateCurrentView = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (updateViewHandler && currentView?.id) {
      const response = await updateViewHandler(currentView?.id, currentView.name);
      if (response) toast.success("Saved successfully!");
    }
  };

  /** Record the state of the table in session storage , on every state change event happens*/
  React.useEffect(() => {
    if (currentState) sessionStorage.setItem(`view-${id}`, JSON.stringify(currentState));
  }, [currentState, id]);

  // React.useEffect(() => {
  //   localStorage.setItem(`view-${id}`, JSON.stringify(currentState));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const isStateChanged = isEqual(getViewForId(id), getView("session", `view-${id}`));
  return (
    <GridToolbarContainer>
      <Stack sx={{ width: "100%" }} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Stack spacing={0.25}>
          <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{ cursor: "pointer" }}>
            <Typography variant="body2">
              {currentView?.name ?? ""}
              {!isStateChanged ? <sup style={{ color: "red" }}>*</sup> : null}
            </Typography>
            {viewList && viewList.length > 0 ? (
              <IconButton id="view-list-button" color="primary" size="small" type="button" title="See all views" onClick={handleSeeAllViewList} aria-controls={menuListOpen ? "all-available-views-list" : undefined} aria-haspopup="true" aria-expanded={menuListOpen ? "true" : undefined}>
                <KeyboardArrowDownIcon fontSize="inherit" />
              </IconButton>
            ) : null}
          </Stack>
          <Divider />
          <Stack direction={"row"} spacing={1}>
            <IconButton color="primary" size="small" type="button" title="Save view" disabled={id === "default-view" || isStateChanged} onClick={updateCurrentView}>
              <SaveIcon fontSize="inherit" />
            </IconButton>
            <IconButton color="primary" size="small" type="button" title="Save as new view" onClick={createNewViewHandler}>
              <SaveAsIcon fontSize="inherit" />
            </IconButton>
          </Stack>
          {viewList && viewList.length > 0 ? (
            <Menu
              id="all-available-views-list"
              anchorEl={viewListAnchorEl}
              open={menuListOpen}
              onClose={() => setViewsListAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "view-list-button"
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
            >
              {viewList.map(view => (
                <MenuItem
                  key={`views-${view.id}`}
                  onClick={event => {
                    event.preventDefault();
                    navigate("/companies-list/" + view.id, { state: { view: view } });
                    setViewsListAnchorEl(null);
                  }}
                >
                  {view.name}
                </MenuItem>
              ))}
            </Menu>
          ) : null}
        </Stack>
        <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          color="primary"
          placeholder="Search by company name ..."
          debounceMs={500}
          sx={{ minWidth: 300 }}
          //quickFilterParser={handleQuickFilterParser}
        />
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <GridToolbarDensitySelector onSelect={recordChange} />
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </Stack>
      </Stack>
      <Divider />
    </GridToolbarContainer>
  );
};

export default CustomGridToolBar;
