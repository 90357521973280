import { useQuery, gql } from "@apollo/client";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import LinkTwoToneIcon from "@mui/icons-material/LinkTwoTone";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Link, ListItemIcon, ListSubheader, MenuItem, MenuList, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { processListQuery } from "../../../../graphql/queries";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

interface ProcessItem {
  id: string;
  title: string;
  link: string;
}

interface ProcessListItem {
  uuid: string;
  title: string;
  items: string | ProcessItem[];
}

const RenegadeProcess = () => {
  const { data, loading, error } = useQuery<{ processListQuery: string[] }>(gql(processListQuery));
  const [processData, setProcessData] = useState<ProcessListItem[] | null>();
  // const theme = useTheme();

  useEffect(() => {
    if (data) {
      const _data = data.processListQuery.map(d => {
        const _d = JSON.parse(d) as ProcessListItem;
        _d.items = JSON.parse((_d.items as any) as string) as ProcessItem[];
        return _d;
      });
      setProcessData(_data);
    }
  }, [data]);

  //Log error for graphql
  useEffect(() => {
    if (error) console.log("RenegadeProcessErrors", error);
  }, [error]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="renegadeProcess-content" id="renegadeProcess-header">
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <ArticleTwoToneIcon fontSize="large" />
          <Typography variant="h5">Our Process</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails id="renegadeProcess-content">
        {loading && (
          <Box>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        )}
        {data && (
          <Grid container spacing={2}>
            {processData &&
              processData
                .sort((a, b) => parseInt(a.uuid) - parseInt(b.uuid))
                .map(process => {
                  // console.log("fromLoop", process);
                  const { uuid, items, title } = process;
                  return (
                    <Grid item md={3} lg={3} xl={3} xs={12} key={`psItem-${uuid}`}>
                      <MenuList
                        component="div"
                        dense
                        aria-labelledby={`process-${title}`}
                        subheader={
                          <ListSubheader component="h4" id={`process-${title}`}>
                            {title}
                          </ListSubheader>
                        }
                      >
                        {typeof items === "object"
                          ? items.map(prItem => {
                              const { link, title: prTitle, id } = prItem;
                              return (
                                <MenuItem key={`processItem-${uuid}-item-${id}`} divider>
                                  <ListItemIcon>
                                    <LinkTwoToneIcon />
                                  </ListItemIcon>
                                  <Link target="_blank" underline="hover" href={link}>
                                    {prTitle}
                                  </Link>
                                </MenuItem>
                              );
                            })
                          : null}
                      </MenuList>
                    </Grid>
                  );
                })}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export default RenegadeProcess;
