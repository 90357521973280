import { useLazyQuery , gql} from "@apollo/client";
import { Avatar, Box, Grid, Link, Skeleton, Typography } from "@mui/material";
import DataNotFound from "../../../../assets/Svgs/DataNotFound";
import { companyPersonDataByUUIDQuery } from "../../../../graphql/queries";
import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import { PersonData } from "../../../../types";

interface PersonDetailsProps {
  uuid: string;
}

const PersonDetails = ({ uuid }: PersonDetailsProps) => {
  const [getPersonsData, { data, loading, error }] = useLazyQuery<{
    CompanyPersonDataByUUIDQuery: {
      result: {
        company_uuid: string;
        person_data: PersonData[];
      };
    };
  }>(gql(companyPersonDataByUUIDQuery));

  //calling Query on render
  React.useEffect(() => {
    if (uuid)
      getPersonsData({
        variables: {
          uuid
        }
      });
  }, [getPersonsData, uuid]);

  //execute Response of the query.
  React.useEffect(() => {
    // const { data, error } = personDataResponse;
    if (error) toast.error(`${error.message}`);
    if (data) console.log("PersonsData:::",data);
  }, [error ,data]);

  if (loading)
    return (
      <Grid container spacing={2}>
        {[...new Array(10)].map((d, i) => (
          <Grid md={3} key={`skelton__${i}`}>
            <Skeleton sx={{ width: "100%", height: 75 }} variant="rounded" />
          </Grid>
        ))}
      </Grid>
    );

  return (
    <Grid container spacing={2}>
      {data?.CompanyPersonDataByUUIDQuery.result.person_data ? (
        data?.CompanyPersonDataByUUIDQuery.result.person_data.map((d, index) =>{
            const currentPosition = d.unstandardized_experience__internal.find(e => e.is_current_position);
            return (
                <Grid item md={3}>
                  <Box sx={{display:"flex"}}>
                    <Box sx={{marginRight:1}}>
                      <Avatar src={d.profile_picture_url} />
                    </Box>
                    <Box>
                      <Typography variant="h6" component={Link} target="_blank" href={d.socials.LINKEDIN.url}>{d.full_name}</Typography>
                      <Typography>{currentPosition?.title}</Typography>
                      <Typography><b>Date started:  </b>{currentPosition?.start_date && moment(currentPosition.start_date).format("LL")}</Typography>
                    </Box>
                  </Box>
                </Grid>
              )
        } )
      ) : (
        <DataNotFound />
      )}
    </Grid>
  );
};

export default PersonDetails;
