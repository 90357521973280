import { gql, useLazyQuery, useMutation } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Button, CircularProgress, DialogContent, DialogTitle, Drawer, IconButton, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { JSONContent } from "@tiptap/react";
import moment from "moment";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { NotesEditor } from "../../components/NotesEditor";
import { manageCompanyNote } from "../../graphql/mutations";
import { getCompanyNotes } from "../../graphql/queries";
import { GetAllCompaniesListData, NotesData, NotesInput } from "../../types";

// User notes editor cell;>>
interface UserNotesManagementViewProps<T> {
  open: boolean;
  selectedRow: T;
  label: string;
  loading?: boolean;
  updateNewNotesState: (currentRow: T, newNotes: NotesData<JSONContent>[]) => T;
  setSelectedRow: React.Dispatch<React.SetStateAction<GetAllCompaniesListData | null>>;
  getCompanyPageLink: (d: T) => string;
  getCompanYName: (d: T) => string;
  getCompanYWebUrl: (d: T) => string;
  getNotesList: (d: T) => NotesData<JSONContent>[];
  getUuid: (d: T) => string;
  saveNewNotes: (data: NotesData<JSONContent>) => void;
  updateNotes: (data: NotesData<JSONContent>) => void;
}

type NotesView = "newNotes" | "editNotes" | "noNotes" | "viewsList" | "loading";

/**
//Algo for the closing the modal popup\
  1. Create a state for component from the selected row.
  2. API for add edit
  3. 
*/

const UserNotesManagementView = <T extends {}>({ open, label, selectedRow, loading, getCompanYName, getCompanYWebUrl, getCompanyPageLink, getNotesList, getUuid, updateNewNotesState, setSelectedRow }: UserNotesManagementViewProps<T>) => {
  const [currentRow, setCurrentRow] = React.useState<T | null>();

  //States
  const [notesEditModel, setNotesEditModel] = React.useState<NotesData<JSONContent> | null>();
  const [notesView, setNotesView] = React.useState<NotesView>("noNotes");

  //Queries
  const [getAllNotes] = useLazyQuery<{ getCompanyNotes: NotesData<string>[] }, { uuid: string }>(gql(getCompanyNotes));

  //Mutations
  const [manageCompanyNotes, { loading: manageCompanyNotesLoading }] = useMutation<{ manageCompanyNote: NotesData<string>[] }, { input: NotesInput }>(gql(manageCompanyNote));

  const closeHandler = () => {
    // setNewNotes(false);
    unstable_batchedUpdates(() => {
      setCurrentRow(null);
      setNotesEditModel(null);
      setSelectedRow(null);
    });
  };

  const cancelNotesEditView = () => {
    unstable_batchedUpdates(() => {
      // if (notesEditModel) setNotesEditModel(null);
      setNotesView("viewsList");
    });
  };

  /** Save new notes  */
  const onSaveNotes = async (data: JSONContent) => {
    try {
      //@ts-ignore
      const titleText = data.content && data.content[0].content && data.content[0].content[0]?.text;

      const manageNotesInput: NotesInput = {
        uuid: getUuid(currentRow!),
        content: JSON.stringify(data),
        title: titleText ?? "Untitled notes"
      };
      if (notesEditModel && notesView === "editNotes") manageNotesInput.id = notesEditModel.id;
      const response = await manageCompanyNotes({ variables: { input: manageNotesInput } });
      const _createdNotes = response.data?.manageCompanyNote;
      if (_createdNotes && _createdNotes.length > 0) {
        setCurrentRow(prevState =>
          updateNewNotesState(
            prevState!,
            _createdNotes.map(note => ({ ...note, content: JSON.parse(note.content) }))
          )
        );
        setNotesView("viewsList");
      }
    } catch (error) {
      //@ts-ignore;
      toast.error(error?.message);
      console.log(error);
    }
  };

  /** Add new notes list */

  const editNotes = (notesModel: NotesData<JSONContent>) => {
    unstable_batchedUpdates(() => {
      setNotesEditModel(notesModel);
      setNotesView("editNotes");
    });
  };

  const handleAddNewNotes = () => {
    setNotesView("newNotes");
  };

  /** Get notes for by uuid and build the state */
  const prepareNotesList = async (uuid: string) => {
    setNotesView("loading");
    try {
      const requestAllNotes = await getAllNotes({ variables: { uuid } });
      const notesList = requestAllNotes.data?.getCompanyNotes;
      if (notesList) {
        unstable_batchedUpdates(() => {
          setNotesView(notesList.length > 0 ? "viewsList" : "noNotes");
          setCurrentRow({
            ...selectedRow,
            notes: notesList.map(n => {
              return { ...n, content: JSON.parse(n.content as string) };
            })
          });
        });
      }
    } catch (error) {
      setNotesView("noNotes");
      //@ts-ignore;
      toast.error(error?.message);
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (selectedRow) prepareNotesList(getUuid(selectedRow));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  const notesViews: { [items in NotesView]: JSX.Element | null } = {
    noNotes: <NotesEditor onSaveNotes={onSaveNotes} cancelNotesEditView={cancelNotesEditView} loading={manageCompanyNotesLoading} />,
    editNotes: <NotesEditor onSaveNotes={onSaveNotes} cancelNotesEditView={cancelNotesEditView} content={notesEditModel?.content} editable={true} loading={manageCompanyNotesLoading} />,
    newNotes: <NotesEditor onSaveNotes={onSaveNotes} cancelNotesEditView={cancelNotesEditView} newNotes={true} loading={manageCompanyNotesLoading} />,
    viewsList: currentRow ? (
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        subheader={
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{`Notes list of ${getCompanYName(currentRow!)}`}</Typography>
            <Button size="small" startIcon={<AddIcon />} onClick={handleAddNewNotes}>
              Add new
            </Button>
          </Stack>
        }
      >
        {getNotesList(currentRow!).map((listItem, index) => (
          <ListItem
            key={`notesListItem-${index}`}
            secondaryAction={
              <IconButton onClick={() => editNotes(listItem)}>
                <EditIcon />
              </IconButton>
            }
          >
            <ListItemText primary={listItem.title} secondary={`Added on ${moment(listItem.createdAt).calendar()}`} />
          </ListItem>
        ))}
      </List>
    ) : null,
    loading: (
      <Stack direction={"row"} justifyContent={"center"} alignContent={"center"} sx={{ maxHeight: "100%" }}>
        <CircularProgress />
      </Stack>
    )
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: {
          height: "calc(100% - 64px)",
          width: 450,
          marginTop: "64px"
        }
      }}
    >
      <DialogTitle id="manage-notes-title">
        <Stack direction={"row"} spacing={1} alignItems="center">
          <Avatar
            sx={{ width: "40px", height: "40px" }}
            src={"//logo.clearbit.com/" + getCompanYWebUrl(selectedRow)}
            variant="rounded"
            sizes="small"
            imgProps={{
              onError: (e: React.SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = "/images/default-corporate-image.jpg")
            }}
          />
          <Typography component={Link} variant="h5" to={getCompanyPageLink(selectedRow)}>
            {getCompanYName(selectedRow)}
          </Typography>
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={closeHandler}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{notesViews[notesView]}</DialogContent>
    </Drawer>
  );
};

export default UserNotesManagementView;
