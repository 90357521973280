import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import { Avatar, Box, Grid, Link, Skeleton, Typography } from "@mui/material";
import React from "react";
import { DPbaseProps, _location, _website } from "../../../../types";

interface CompanyAvatarProps extends DPbaseProps {
  website: _website;
  location: _location;
}

const CompanyAvatar = ({ website, location, loading }: CompanyAvatarProps) => {
  const websitefinal = !website?.is_broken?website?.url :"https://example.com";
  const locationfinal = `${location?.address_formatted}`
  return (
    <Grid container spacing={1}>
      <Grid item xs="auto">
        {loading ? (
          <Skeleton variant="rounded" sx={{ width: "80px", height: "80px" }} />
        ) : (
          <Avatar
            sx={{ width: "80px", height: "80px", border: "1px solid #f2f2f2" }}
            src={"//logo.clearbit.com/" + websitefinal}
            variant="rounded"
            sizes="small"
            imgProps={{
              onError: (e: React.SyntheticEvent<HTMLImageElement>) => (e.currentTarget.src = "/images/default-corporate-image.jpg")
            }}
          />
        )}
      </Grid>
      <Grid item xs>
        <Box sx={{ height: "80px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LanguageTwoToneIcon fontSize="small" sx={{ marginRight: 0.5 }} />{" "}
            {loading ? (
              <Skeleton variant="rounded" sx={{ width: "120px", height: "20px" }} />
            ) : (
              <Link href="#" target="_blank">
                {websitefinal}
              </Link>
            )}
          </Box>
          {/* <Divider sx={{ margin: "5px 0" }} /> */}
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
            <LocationOnTwoToneIcon fontSize="small" sx={{ marginRight: 0.5 }} />
            {loading ? <Skeleton variant="rounded" sx={{ width: "120px", height: "20px" }} /> : <Typography variant="overline">{locationfinal}</Typography>}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CompanyAvatar;
